const getColumnObj = ({ field, ...rest }) => {
  return {
    field: field,
    headerAlign: "left",
    flex: 1,
    ...rest,
  };
};
export class GridColumns {
  constructor() {
    this.columns = [];
  }

  serialNo(props) {
    this.columns.push(getColumnObj({ field: "S.No.", ...props }));
    return this;
  }

  id(props) {
    this.columns.push(getColumnObj({ field: "ID", ...props }));
    return this;
  }

  name(props) {
    this.columns.push(getColumnObj({ field: "Name", ...props }));
    return this;
  }

  type(props) {
    this.columns.push(getColumnObj({ field: "Type", ...props }));
    return this;
  }

  pocType(props) {
    this.columns.push(getColumnObj({ field: "POC Type", ...props }));
    return this;
  }

  gender(props) {
    this.columns.push(getColumnObj({ field: "Gender", ...props }));
    return this;
  }

  designationInIC(props) {
    this.columns.push(getColumnObj({ field: "Designation In IC", ...props }));
    return this;
  }

  from(props) {
    this.columns.push(getColumnObj({ field: "From", ...props }));
    return this;
  }

  to(props) {
    this.columns.push(getColumnObj({ field: "To", ...props }));
    return this;
  }

  date(props) {
    this.columns.push(getColumnObj({ field: "Date", ...props }));
    return this;
  }

  noOfEmp(props) {
    this.columns.push(getColumnObj({ field: "No Of Employees", ...props }));
    return this;
  }

  clientCode(props) {
    this.columns.push(getColumnObj({ field: "Client Code", ...props }));
    return this;
  }

  priorityLevel(props) {
    this.columns.push(getColumnObj({ field: "Priority Level", ...props }));
    return this;
  }

  industry(props) {
    this.columns.push(getColumnObj({ field: "Industry", ...props }));
    return this;
  }

  registrationType(props) {
    this.columns.push(getColumnObj({ field: "Registration Type", ...props }));
    return this;
  }

  district(props) {
    this.columns.push(getColumnObj({ field: "District", ...props }));
    return this;
  }

  state(props) {
    this.columns.push(getColumnObj({ field: "State", ...props }));
    return this;
  }

  city(props) {
    this.columns.push(getColumnObj({ field: "City", ...props }));
    return this;
  }

  email(props) {
    this.columns.push(getColumnObj({ field: "Email", ...props }));
    return this;
  }

  phone(props) {
    this.columns.push(getColumnObj({ field: "Phone", ...props }));
    return this;
  }

  designation(props) {
    this.columns.push(getColumnObj({ field: "Designation", ...props }));
    return this;
  }

  designationInOrg(props) {
    this.columns.push(getColumnObj({ field: "Designation In Org", ...props }));
    return this;
  }

  location(props) {
    this.columns.push(getColumnObj({ field: "Location", ...props }));
    return this;
  }

  region(props) {
    this.columns.push(getColumnObj({ field: "Region", ...props }));
    return this;
  }

  org(props) {
    this.columns.push(getColumnObj({ field: "Organization", ...props }));
    return this;
  }

  status(props) {
    this.columns.push(getColumnObj({ field: "Status", ...props }));
    return this;
  }

  actions({ renderCell, ...rest }) {
    this.columns.push(
      getColumnObj({
        field: "Actions",
        headerAlign: "center",
        flex: 1,
        renderCell: renderCell,
        ...rest,
      }),
    );
    return this;
  }

  getCols() {
    return this.columns;
  }
}
