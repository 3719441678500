import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";
import { getKey } from "../../../../../utils/getKey";

const bgData =
  "[ ] (“Organization”) is an equal opportunity employer and is committed to creating a healthy and safe working environment that enables employees to work without fear of prejudice, gender bias and in a harassment free workplace to all without regard to race, caste, religion, colour, ancestry, marital status, gender, age, nationality,ethnic origin or disability. Organization strivesto establish and maintain a work environment in which all individuals are respected and treated with dignity and does not tolerate any form of discrimination or harassment. This ‘Policy on Prevention of Sexual Harassment of women at workplace’ (“Policy”) reinforces that commitment. This Policy has been framed specifically in line with the provisions of “The Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013” and rules framed thereunder along with all amendments from time to time (“Act/Law”). As such, reference shall always be made to the Act for any aspect relating to sexual harassment not explicitly covered herein. In the event of any conflict between this Policy and the Act, the Act will prevail.";

function Background({ serialNo, text = [bgData] }) {
  const styles = policyStyles;
  console.log("text ---->", text);

  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{serialNo}. Background</Text>
      {text.map((para, i) => {
        return (
          <Text key={getKey(i)} style={styles.text}>
            {para}
          </Text>
        );
      })}
    </View>
  );
}

export default Background;
