import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function Amendments({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{serialNo}. AMENDMENTS</Text>
      <Text style={styles.text}>
        Organization reserves the right to amend the Policy from time to time in
        order to comply with any laws / rules / regulations that come into
        effect from time to time, related to sexual harassment at workplace.
        This Policy shall be reviewed bi-annually or as and when key issues get
        raised to incorporate amendments.
      </Text>
      <Text style={styles.text}>
        This Policy does not intend to create any contractual obligation for or
        with Organization, and in case of dispute with the Law and the Policy,
        the Law shall prevail.
      </Text>
    </View>
  );
}

export default Amendments;
