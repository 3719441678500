import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { ColorModeContext } from "../../../constants/theme";
import AnnualReportLogo from "../../../assets/logos/AnnulaReportLogo.svg";
import { SingleOrgARTable } from "../../../components/tables";
import { useSelector } from "react-redux";

function AllLocAR() {
  const { mode } = React.useContext(ColorModeContext);
  const { user } = useSelector((state) => state.auth);
  console.log(user);

  return (
    <Box
      className="tw-p-10"
      sx={{
        background: `${mode === "dark" ? "#000000" : " #FFF"}`,
        height: "100vh",
        width: "100%",
        margin: 0,

        display: "flex",
        flexDirection: "column",
        gap: "50px",
        padding: "40px",
      }}
    >
      <Stack
        direction={"row"}
        columnGap={4}
        justifyContent={"flex-start"}
        alignItems={"center"}
        marginBottom={2}
        marginTop={10}
      >
        <img src={AnnualReportLogo} alt="" />
        <Typography
          className="tw-font-semibold tw-text-4xl"
          sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
        >
          Annual Report
        </Typography>
      </Stack>
      <SingleOrgARTable
        color="#EFFFE5"
        source="admin"
        ID={user?.user?.org_id}
        boxClass="data-grid-div"
      />
    </Box>
  );
}

export default AllLocAR;
