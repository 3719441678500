import React from "react";
import {
  ContainerLayout,
  ReminderScreenLayout,
} from "../../components/Layouts";
import { Typography } from "@mui/material";
import { ColorModeContext } from "../../constants/theme";
import { useSelector } from "react-redux";
import { useGetRemindersByOrgQuery } from "../../store/reminders/reminderApi";

function AdminReminders() {
  const { mode } = React.useContext(ColorModeContext);
  const { user } = useSelector((state) => state.auth?.user);
  const { data: reminders, isFetching: remindersFetching } =
    useGetRemindersByOrgQuery({
      org_id: user?.org_id,
    });

  return (
    <ContainerLayout>
      <Typography
        className="tw-text-3xl tw-font-bold"
        sx={{ color: `${mode === "dark" ? "#FFFFFF" : " #000000"}` }}
      >
        Reminders
      </Typography>
      <ReminderScreenLayout
        reminders={reminders?.reminders || []}
        loading={remindersFetching}
      />
    </ContainerLayout>
  );
}

export default AdminReminders;
