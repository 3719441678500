import React from "react";
import { Box, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { GridColumns } from "../../utils/gridColumns";
import { Status } from "../../utils/options";
import { FilterButton, ViewDetailsButton } from "../Buttons";
import { GridActionsLayout } from "../Layouts";
import { ArchiveStatusFilters } from "../Filters";
import { useGetPolicyByOrgQuery } from "../../store/policy/policyApi";

const PolicyArchivesCom = (props) => {
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState("all");
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 4,
  });
  const [rowCountState, setRowCountState] = React.useState();
  const { data: policyData, isFetching } = useGetPolicyByOrgQuery({
    org_id: props.orgId,
    ...paginationModel,
    status: selectedStatus,
  });

  React.useEffect(() => {
    let temp = [];
    console.log(policyData?.policies);
    if (policyData?.policies) {
      temp = policyData?.policies?.map((policy, indx) => {
        return {
          id: policy?.id,
          "S.No.": indx + 1,
          Status: policy?.status,
          Date: new Date(policy?.createdAt).toLocaleDateString("en-GB"),
          file: policy?.file,
        };
      });
      setRows(temp || []);
    }
  }, [policyData]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      policyData?.count ? policyData?.count : prevRowCountState,
    );
  }, [policyData?.count, setRowCountState]);

  const handleStatusChange = (e) => {
    console.log("Status", e.target.value);
    setSelectedStatus(e.target.value);
  };
  const columns = [
    ...new GridColumns()
      .serialNo()
      .date()
      .status()
      .actions({
        renderCell: (params) => {
          return (
            <GridActionsLayout>
              <ViewDetailsButton
                placeholder="View Document"
                handleClick={() => window.open(params.row.file, "_blank")}
                buttonStyles={{
                  backgroundColor: "#E4F4FF",
                  color: "#4B4B4B",
                  padding: "4px 8px",
                  borderRadius: "2rem",
                  "&:hover": {
                    backgroundColor: "#E4F4FF",
                  },
                }}
              />
            </GridActionsLayout>
          );
        },
      })
      .getCols(),
  ];
  return (
    <Box
      display={"flex"}
      flex={1}
      flexDirection={"column"}
      gap={4}
      className={props.styles}
    >
      <Stack direction={"row"} justifyContent={"right"}>
        <FilterButton
          buttonStyles={{ height: "32px" }}
          onClick={() => setOpen(!open)}
        />
      </Stack>
      <ArchiveStatusFilters
        open={open}
        selectedStatus={selectedStatus}
        handleStatusChange={handleStatusChange}
        status={[Status.APPROVAL, Status.APPROVED, Status.REJECTED]}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isFetching}
        disableRowSelectionOnClick
        pageSizeOptions={[5]}
        rowCount={rowCountState}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        sx={{
          "& .MuiDataGrid-toolbarContainer": {
            paddingY: 2,
            paddingX: 1,
          },
          "& .MuiDataGrid-row": {
            marginY: "0.82rem",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
            backgroundColor: "transparent",
            color: "#4B4B4B",
          },
        }}
      />
    </Box>
  );
};

export default PolicyArchivesCom;
