import React from "react";
import { Box, Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import { ColorModeContext } from "../../constants/theme";

function NumericalOverview({ data = [] }) {
  const bigScreen = useMediaQuery("(min-width: 840px)");
  const { mode } = React.useContext(ColorModeContext);

  return (
    <Box
      display={"flex"}
      flexDirection={bigScreen ? "row" : "column"}
      paddingY={"30px"}
      paddingX={!bigScreen ? "15px" : 0}
      width={"100%"}
      borderRadius={"8px"}
      gap={10}
      boxShadow={"0px 5px 20px rgba(0, 0, 0, 0.1)"}
    >
      {data.map((d, i) => (
        <React.Fragment key={d.id}>
          <Stack
            key={d.id}
            direction={"column"}
            justifyContent={"space-between"}
            gap={"32px"}
            flex={1}
            alignItems={"center"}
          >
            <Typography
              className="inter-font"
              sx={{
                color: mode === "dark" ? "white" : "#0E9CFF",
                letterSpacing: "1px",
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {d.text}
            </Typography>
            <Typography
              className="inter-font"
              sx={{
                letterSpacing: "1px",
                fontSize: "36px",
                fontWeight: "600",
                color: mode === "dark" ? "white" : "#000",
              }}
            >
              {d.number}
            </Typography>
          </Stack>
          {i < data.length - 1 && (
            <Divider
              orientation={bigScreen ? "vertical" : "horizontal"}
              flexItem
            />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}

export default NumericalOverview;
