import React from "react";
import { Line } from "react-chartjs-2";
// eslint-disable-next-line no-unused-vars
import { Chart as ChartJS, Tooltip } from "chart.js/auto";

ChartJS.register(Tooltip);

function LineGraph({ data, options, style }) {
  const newOptions = {
    responsive: true,
    maintainAspectRatio: true,
  };
  const newStyle = {
    width: "100%",
  };
  return (
    <Line
      data={data}
      options={options || newOptions}
      style={style || newStyle}
    />
  );
}

export default LineGraph;
