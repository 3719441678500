import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import Roboto from "../../../../../src/assets/font/Roboto-Bold.ttf";
import RobotoRegular from "../../../../../src/assets/font/Roboto-Regular.ttf";
import { currentDate } from "../../../../utils/currentDate";
import RegistrationTable from "../RegistrationTable";
import { RegistrationStyles } from "../../PDFStyling/Registration/RegistrationStyle";

Font.register({
  family: "MyFont",
  fonts: [{ src: Roboto, fontWeight: "bold" }],
});
Font.register({
  family: "RegularFont",
  fonts: [{ src: RobotoRegular, fontWeight: "normal" }],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 80,
    fontSize: 8,
  },
  bottomText: {
    display: "flex",
    fontFamily: "RegularFont",
    textAlign: "left",
  },
});

const PuneLetterDoc = ({ ICEmployee, representativeName, orgData }) => {
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={RegistrationStyles.title}>
          ON LETTERHEAD OF THE ORGANIZATION
        </Text>
        <View style={RegistrationStyles.container}>
          <Text style={RegistrationStyles.date}>Date- {currentDate}</Text>
          <Text style={RegistrationStyles.address}>
            To,{"\n"} Office of District Women and Child Development Officer,{" "}
            {"\n"}Gulmarg Park Housing Society, {"\n"}3rd Floor, Near Vijay
            Bakery, {"\n"}Somwar Peth, Pune – 411011{"\n"}
          </Text>
          <Text style={RegistrationStyles.subject}>
            Subject: Details regarding the Internal Committee (“IC”) constituted
            by {orgData.name} as per the Order dated 23rd July, 2021 issued by
            the Pune District Women and Child Development Department
          </Text>
          <Text style={RegistrationStyles.name}>Dear Madam/Sir</Text>

          <Text style={RegistrationStyles.text}>
            Please find below the details of the members of the IC constituted
            by {orgData.name} for its workplace situated in Pune having address
            at {orgData.address}
          </Text>
          <View style={{ marginTop: 15 }}>
            <RegistrationTable ICEmployee={ICEmployee} />
          </View>
          <View style={{ paddingTop: 20 }}>
            <Text style={styles.bottomText}>Yours faithfully</Text>
            <Text style={styles.bottomText}>{representativeName}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PuneLetterDoc;
