import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function ActionDuringPendencyOfInquiry({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {serialNo}. CAN ANY ACTION BE TAKEN DURING PENDENCY OF INQUIRY?
      </Text>
      <Text style={styles.text}>
        During the pendency of an inquiry, on a written request made by the
        Complainant, the IC may recommend any of the following to Organization:
      </Text>
      <Text style={styles.subHeading}>
        a) In case Complainant and/or Respondent are Employees, any one or more
        of the following may be recommended:
      </Text>
      <View style={styles.subContainer}>
        <Text style={styles.point}>
          i) Grant leave to the Complainant up to a period of 3 (three) months
          (the leave granted shall be in addition to the leave the Complainant
          is otherwise entitled to)
        </Text>
        <Text style={styles.point}>
          ii) Transfer the Complainant or Respondent to any other workplace
        </Text>
        <Text style={styles.point}>
          iii) Restrain the Respondent from reporting on the work performance of
          the Complainant or writing their confidential report and assign the
          same to another Employee (if applicable)
        </Text>
        <Text style={styles.point}>
          iv) In case the complaint has been filed by an intern or an apprentice
          working under the Respondent, restrain the Respondent from supervising
          any official / academic activity of the Complainant and assign the
          same to another Employee.
        </Text>
        <Text style={styles.point}>
          v) Direct the Respondent to not communicate with the Complainant in
          any manner including any mode of electronic communication such as text
          messages, emails, , video/audio calls, on any social media platform or
          via any other mode of electronic communication.
        </Text>
        <Text style={styles.point}>
          vi) In case complaint is filed by a Complainant, the same benefits as
          above would apply, depending on facts and circumstances.
        </Text>
        <Text style={styles.text}>
          The Organization reserves the right to require any of the parties to
          take any leaves during pendency of inquiry any time after receipt of a
          complaint of sexual harassment until the IC has concluded its inquiry,
          if required, based on recommendation of IC. Any such decision will be
          communicated in writing to the party by the Organization. This is to
          ensure that there is no retaliation against any party. The
          Organization reserves the right to require parties to work from home
          or from an alternate Organization location during the pendency of
          inquiry.
        </Text>
      </View>

      {/* In case Respondent is a Visitor / Third Party, any one or more of the following may
be recommended: */}
      <Text style={styles.subHeading}>
        b) In case Respondent is a Visitor / Third Party, any one or more of the
        following may be recommended:
      </Text>
      <Text style={styles.text}>
        If the Respondent is a Visitor / Third Party adequate steps to ensure
        that they do not enter Organization premises or contact/threaten
        Complainant/Witnesses directly or indirectly using any mode of
        communication. IC to suggest appropriate
      </Text>
    </View>
  );
}

export default ActionDuringPendencyOfInquiry;
