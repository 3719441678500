import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function Confedentiality({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{serialNo}. Confedentiality</Text>
      <Text style={styles.text}>
        All discussions / decisions pertaining to an incident of sexual
        harassment must be kept confidential. The contents of the complaint
        made, the identity and addresses of the Complainant, Respondent and
        witnesses, any information relating to conciliation and inquiry
        proceedings, inquiry report including recommendations of the IC and
        action taken on the Respondent shall not be published, communicated or
        made known to the public, press and media in any manner. Any violation
        thereto shall subject to disciplinary action and further dealt with in
        accordance with the Act.
      </Text>
    </View>
  );
}

export default Confedentiality;
