import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function HowToFileComplaint({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{serialNo}. HOW TO FILE A COMPLAINT?</Text>
      <Text style={styles.text}>
        The Complainant can make a complaint of Sexual Harassment to the IC. The
        following need to be kept in mind:
      </Text>
      <View style={styles.subContainer}>
        <Text style={styles.point}>a) The complaint has to be in writing.</Text>
        <Text style={styles.point}>
          b) The Complainant should provide (if possible) as many specific
          details as possible including incidents, dates and people that are
          involved in their complaint. Details with respect to the following may
          be provided while filing a complaint:
        </Text>
        <Text style={styles.subPoint}>
          i) The alleged event, matter or issue that is subject to the complaint
          (time/date/place etc.)
        </Text>
        <Text style={styles.subPoint}>
          ii) The name and details of the Respondent against whom the complaint
          is being filed
        </Text>
        <Text style={styles.subPoint}>
          iii) Names and addresses of the witnesses (if any)
        </Text>
        <Text style={styles.subPoint}>
          iv) TDetails of documentary evidence (if any) such as WhatsApp chat
          records, SMS, E-mails, CCTV Footage, Audio / Video recording etc. and
        </Text>
        <Text style={styles.subPoint}>
          v) Any additional information, documentation or other evidence
          available to support the complaint (if any)
        </Text>

        <Text style={styles.point}>
          c) Complaint must be made within a period of 3 (three) months from the
          date of incident and in case of a series of incidents, within a period
          of 3 (three) months from the date of last incident.
        </Text>
        <Text style={styles.point}>
          d) The IC, at its discretion, can extend the time limit for receiving
          a complaint to another period of 3 (three) months if it is satisfied
          that the circumstances were such which prevented the Complainant from
          filing a complaint within the first 3 (three) months.
        </Text>
        <Text style={styles.point}>
          e) IC will record the reasons for such extension of 3 (three) months
          in writing.
        </Text>
      </View>
      <Text style={styles.text}>
        If the Complainant needs assistance in writing the complaint, they can
        seek help from IC. The complaint can be submitted electronically at
        ______@____.com to the IC or with any of the IC members (details
        provided in ANNEXURE 1). Complaints can also be physically submitted at
        __________________ with any of the IC members (Details provided in
        Annexure 1).
      </Text>
    </View>
  );
}

export default HowToFileComplaint;
