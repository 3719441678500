import { createSlice } from "@reduxjs/toolkit";
import dropdownApi from "./dropdownApi";

const initialState = {
  loading: false,
  dropdowns: {
    PriorityLevels: [],
    EmploymentLevels: [],
    WorkLocations: [],
    ServiceRules: [],
    POCTypes: [],
    RegistrationTypes: [],
    Departments: [],
    AverageAgeBrackets: [],
    TypesOfLocations: [],
    AllLanguages: [],
    AllZones: [],
    Region: [],
  },
  error: null,
};

const dropdownSlice = createSlice({
  name: "dropdown",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      dropdownApi.endpoints.getPriorityLevels.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        state.dropdowns.PriorityLevels = payload?.priority_level;
      },
    );
    builder.addMatcher(
      dropdownApi.endpoints.getEmployementLevels.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        state.dropdowns.EmploymentLevels = payload?.employmentlevel;
      },
    );
    builder.addMatcher(
      dropdownApi.endpoints.getWorkLocations.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        state.dropdowns.WorkLocations = payload?.locationOfWork;
      },
    );
    builder.addMatcher(
      dropdownApi.endpoints.getServiceRules.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        state.dropdowns.ServiceRules = payload?.servicesRule;
      },
    );
    builder.addMatcher(
      dropdownApi.endpoints.getAllPocTypes.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        state.dropdowns.POCTypes = payload?.poc;
      },
    );
    builder.addMatcher(
      dropdownApi.endpoints.getAllRegistrationTypes.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        state.dropdowns.RegistrationTypes = payload?.registration;
      },
    );
    builder.addMatcher(
      dropdownApi.endpoints.getDepartments.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        state.dropdowns.Departments = payload?.department;
      },
    );
    builder.addMatcher(
      dropdownApi.endpoints.getAverageAgeBrackets.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        state.dropdowns.AverageAgeBrackets = payload?.avgAgeBracket;
      },
    );
    builder.addMatcher(
      dropdownApi.endpoints.getTypesOfLocations.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        state.dropdowns.TypesOfLocations = payload?.locationType;
      },
    );
    builder.addMatcher(
      dropdownApi.endpoints.getAllLanguages.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        state.dropdowns.AllLanguages = payload?.language;
      },
    );
    builder.addMatcher(
      dropdownApi.endpoints.getAllZones.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        state.dropdowns.AllZones = payload?.zone;
      },
    );
    builder.addMatcher(
      dropdownApi.endpoints.getRegion.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        state.dropdowns.Region = payload?.regionMapping;
      },
    );
  },
});

export default dropdownSlice.reducer;
