import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function WhatIsSexualHarassment({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{serialNo}. WHAT IS SEXUAL HARASSMENT?</Text>
      <View style={styles.subContainer}>
        <Text style={styles.text}>
          For the purposes of this Policy, the following terms shall have the
          meaning indicated below
        </Text>
        <Text style={styles.point}>
          a. Physical contact and advances including touching, deliberately
          brushing against another person, interference with an individual’s
          normal work movement or assault.
        </Text>
        <Text style={styles.point}>
          b. Demand or requests for sexual favours, offensive or unwelcome
          flirtations, unwanted sexual advances or propositions, threats or
          suggestive or insulting sounds.
        </Text>
        <Text style={styles.point}>
          c. Making sexually coloured remarks containing sexual tones/undertones
          including repeated sexual innuendoes, sexual epithets, derogatory
          slurs, sexually explicit jokes.
        </Text>
        <Text style={styles.point}>
          d. Showing pornography such as derogatory or sexually explicit
          posters, cartoons, photographs, magazines, drawings, or other printed
          items; suggestive objects, pictures; emails, screen savers; or other
          electronic communications; graphic commentaries and leering or obscene
          gestures.
        </Text>
        <Text style={styles.point}>
          e. The inappropriate behaviours listed above may be committed either
          verbally or in written form via letters, video calls/audio calls,
          phone calls, texts, e-mails, , on any social media platform or through
          any other electronic communication.
        </Text>
        <Text style={styles.point}>
          f. Having posters, photographs, paintings, etc in the background with
          any sexual context while being on video calls
        </Text>
        <Text style={styles.point}>
          g. Any other unwelcome visual, verbal, or physical conduct of a sexual
          nature.
        </Text>
      </View>

      <View style={styles.subContainer}>
        <Text style={styles.text}>
          These circumstances, among other circumstances, if it occurs or if it
          is present in relation to or connected with any act of sexual
          harassment, may amount to sexual harassment:
        </Text>
        <Text style={styles.point}>
          a. When either implicitly or explicitly it becomes a condition of the
          individuals present or future employment
        </Text>
        <Text style={styles.point}>
          b. Implied or explicit promise of preferential treatment in employment
        </Text>
        <Text style={styles.point}>
          c. Implied or explicit threat of detrimental treatment in employment.
        </Text>
        <Text style={styles.point}>
          d. The harassment has a purpose or effect of unreasonably interfering
          with the work performance or creating an environment, which is
          intimidating, hostile or offensive
        </Text>
        <Text style={styles.point}>
          e. Humiliating treatment likely to affect health or safety
        </Text>
      </View>

      <Text style={styles.text}>
        Everyone must exercise their own good judgment to avoid engaging in
        conduct that may be perceived by others as Sexual Harassment. In all the
        above cases, it is not the intention of the person behaving but the
        impact on the woman who is subjected to such behavior that will matter.
      </Text>
    </View>
  );
}

export default WhatIsSexualHarassment;
