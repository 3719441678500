import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function Confidentiality({ serialNo }) {
  const styles = policyStyles;

  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{serialNo}. Confidentiality</Text>
      <Text style={styles.text}>
        All discussions / decisions pertaining to an incident of sexual
        harassment must be kept confidential. Therefore, contents of the
        complaint made, the identity and addresses of the Complainant,
        Respondent and witnesses, any information relating to conciliation and
        inquiry proceedings, inquiry report including recommendations of the IC
        and action taken on the Respondent shall not be published, communicated
        or made known to the public, press and media in any manner. However,
        information may be disseminated regarding the justice secured to any
        victim without disclosing the name, address, identity or any other
        particulars calculated to lead to the identification of the Complainant
        and witnesses.
      </Text>
      <Text style={styles.text}>
        Appropriate information will be shared with Government authorities as
        per the Act. If any person breaches confidentiality, they shall be
        liable for penalty as per service rules and / or Law. Organization shall
        recover a sum of rupees five thousand (5,000) as penalty from such
        person and may take any other action as it may deem fit.
      </Text>
    </View>
  );
}

export default Confidentiality;
