import React, { useContext } from "react";
import { Box, Stack, TextField } from "@mui/material";
import { NavigationBar } from "../../../components/General";
import { ColorModeContext } from "../../../constants/theme";
import {
  GridActionsLayout,
  NumericalOverview,
} from "../../../components/Layouts";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import BreadcrumbFont from "../../../components/Typography/BreadcrumbFont";
import {
  useGetOrgTrainingMappedQuery,
  useGetTrainingStatusQuery,
} from "../../../store/training/TrainingAPI";
import useDebouncedSearch from "../../../utils/hooks/useDebounceSearch";
import { GridColumns } from "../../../utils/gridColumns";
import { TakeActionButton } from "../../../components";

const breadcrumbs = [
  <BreadcrumbFont key={1} color="#005D9E" text={"AGGREGATE TRAININGS"} last />,
];

function AllOrgTraining() {
  const navigate = useNavigate();
  const { mode } = useContext(ColorModeContext);
  const [rows, setRows] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const { searchText, debouncedResults } = useDebouncedSearch();
  const { data: trainingData, isFetching } = useGetOrgTrainingMappedQuery({
    ...paginationModel,
    ...searchText,
  });
  const { data: trainingStatus } = useGetTrainingStatusQuery();
  const [rowCountState, setRowCountState] = React.useState(
    trainingData?.count || 0,
  );

  React.useEffect(() => {
    let temp = [];
    console.log(trainingData);
    if (trainingData?.org) {
      temp = trainingData?.org?.map((org) => {
        return {
          id: org?.id,
          Organization: org?.name || "",
          Status: org?.status || "",
        };
      });
    }
    setRows(temp || []);
  }, [trainingData]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      trainingData?.count ? trainingData?.count : prevRowCountState,
    );
  }, [trainingData?.count, setRowCountState]);

  const numericalData = [
    {
      id: 1,
      text: "TRAININGS PENDING",
      number: trainingStatus?.totalTrainingPending || "0",
    },
    {
      id: 2,
      text: "NO. OF COMPANIES TRAININGS PENDING",
      number: trainingStatus?.pending || "0",
    },
    {
      id: 3,
      text: "NO. OF COMPANIES TRAININGS COMPLETED",
      number: trainingStatus?.completed || "0",
    },
  ];

  const columns = [
    ...new GridColumns()
      .org()
      .status()
      .actions({
        renderCell: (params) => {
          return (
            <GridActionsLayout>
              <TakeActionButton
                handleClick={() =>
                  navigate(`/superadmin/training/${params.row.id}`)
                }
              />
            </GridActionsLayout>
          );
        },
      })
      .getCols(),
  ];

  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      paddingX={"60px"}
      paddingY={"35px"}
      rowGap={"46px"}
      sx={{ background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}` }}
    >
      <NavigationBar breadcrumbs={breadcrumbs} />
      <NumericalOverview data={numericalData} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={5}
        className="superadmin-data-grid-div"
      >
        <Stack direction={"row"} justifyContent={"flex-start"}>
          <TextField
            type="text"
            name="search"
            onChange={debouncedResults}
            sx={{
              width: "302px",
              "& .MuiInputBase-root": {
                height: "32px",
              },
            }}
            placeholder="Search By Company Name"
          />
        </Stack>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isFetching}
          disableRowSelectionOnClick
          pageSizeOptions={[5]}
          rowCount={rowCountState}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: `${mode === "dark" ? " #181818" : "#EEF8FF"}`,
            },
            "& .MuiDataGrid-toolbarContainer": {
              paddingY: 2,
              paddingX: 1,
            },

            "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
              backgroundColor: "transparent",
              color: "#4B4B4B",
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default AllOrgTraining;
