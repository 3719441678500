import React from "react";
import RegistrationLayout from "../../../components/Layouts/RegistrationLayout";
import { Box } from "@mui/material";
import { DownloadButton, RegistrationCard } from "../../../components";
import RegistrationLogo from "../../../assets/logos/RegistrationLogo.svg";
import PageHeading from "../../../components/General/PageHeading";
import RegistrationList from "../../../utils/RegistrationList";

const TelanganaRegistration = () => {
  const [agree, setAgree] = React.useState(false);
  const checkboxHandler = () => {
    setAgree(!agree);
  };

  return (
    <RegistrationLayout>
      <PageHeading label="Telanganan Registration" logo={RegistrationLogo} />
      <RegistrationCard
        checkboxHandler={checkboxHandler}
        order={RegistrationList[0].telangana.order}
        orderUrl={RegistrationList[0].telangana.orderUrl}
        confirmation={RegistrationList[0].telangana.confirmation}
      />
      <Box display={"flex"} justifyContent={"center"}>
        <DownloadButton
          type="submit"
          label="Go to Telangana registration portal"
          styles={{ width: "50%", marginBottom: "10rem", marginTop: "5rem" }}
          disabledValue={!agree}
          onClick={() => {
            window.open(`https://tshebox.tgwdcw.in/`, "_blank");
          }}
        />
      </Box>
    </RegistrationLayout>
  );
};

export default TelanganaRegistration;
