export const validate = (type, text) => {
  console.log(type, text);

  if (type === "phone") {
    if (text) {
      let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

      return re.test(text);
    }
    return false;
  }
  return true;
};

export const phonevalidations = {
  validate: (value) => {
    let re = /(7|8|9)\d{9}/;
    return re.test(value) || "Please match the format";
  },
};

export const selectFieldValidate = (val = "select") => {
  return {
    validate: (value) => value !== val || "This field is required",
  };
};
