import React, { useContext, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import TextAreaField from "../../../components/Form/TextAreaField";
import InputField from "../../../components/Form/InputField";
import { useCreateTrainingMutation } from "../../../store/training/TrainingAPI";
import { useNavigate } from "react-router-dom";
import { useGetTrainingTypesQuery } from "../../../store/dropdown/dropdownApi";
import { SelectField2 } from "../../../components/Form";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import { BackdropLoader } from "../../../components/General";
import { ColorModeContext } from "../../../constants/theme";
import SaveAndCancelBtn from "../../../components/Buttons/SaveAndCancelBtn";

const breadcrumbs = [
  <Typography className="tw-text-[11px]" key="100">
    Trainings
  </Typography>,
  <Typography className="tw-text-dark-blue tw-text-[11px]" key="200">
    Add trainings
  </Typography>,
];

const InitativeTrainingAdd = () => {
  const { mode } = useContext(ColorModeContext);
  const [addNewTraining, { isLoading, isError, isSuccess, data, error }] =
    useCreateTrainingMutation();
  const { data: trainingTypeData } = useGetTrainingTypesQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      console.log("success----->", data);
      Swal.fire({
        icon: "success",
        title: "Training added Successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/home/training");
    }
    if (isError) {
      console.log(isError);
      console.log("error -->", error);
      alert(error?.data?.msg || "Something went wrong");
    }
    if (isLoading) {
      console.log("loading", isLoading);
    }
  }, [isSuccess, isError, isLoading, data]);

  const onSubmit = (data) => {
    console.log("day=d", data);
    const body = {
      training_type: data.training_type,
      status: data.status,
      pending_reason: data.pending_reason,
      date: data.date,
      mode_of_training: data.mode_of_training,
      training_group: data.training_group,
      inititative_name: data.inititative_name,
      description: data.description,
    };
    addNewTraining(body);
  };

  const { register, handleSubmit, control } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
    defaultValues: "initialValues",
  });

  return (
    <Box
      className="tw-p-10"
      sx={{
        height: "auto",
        width: "100%",
        maxWidth: "1300px",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}`,
      }}
    >
      <Toolbar />
      <Box className="tw-flex tw-justify-start tw-space-x-3">
        <IconButton
          className="tw-rounded-lg tw-shadow-lg tw-w-14 tw-h-14"
          sx={{ background: `${mode === "dark" ? "#1A1A1A" : "#FFFFFF"}` }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Box
          className="tw-flex-1 tw-rounded-lg tw-shadow-lg tw-py-3 tw-px-8"
          sx={{ background: `${mode === "dark" ? "#1A1A1A" : "#FFFFFF"}` }}
        >
          <Breadcrumbs
            sx={{
              display: "flex",
              height: "100%",
            }}
            separator={<NavigateNextIcon fontSize="small" />}
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
      </Box>
      {isLoading && <BackdropLoader open={isLoading} />}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="tw-rounded-3 tw-mb-4 sm:tw-mb-8">
            <Box className="tw-w-full tw-mb-4 sm:tw-mb-6 lg:tw-mb-12">
              <Typography
                className="tw-font-semibold heading1"
                sx={{ color: `${mode === "dark" ? "#FFF" : "#000"}` }}
              >
                Add Trainings
              </Typography>
            </Box>
            <Box className="tw-w-80 tw-my-10">
              <SelectField2
                label={"Training Type"}
                placeholder={"Select"}
                name={"training_type"}
                menus={trainingTypeData?.trainingType}
                control={control}
              />
            </Box>

            <Box
              className="tw-p-3 sm:tw-p-6 lg:tw-p-12 tw-rounded-3 tw-mb-4 sm:tw-mb-8"
              sx={{
                background: `${mode === "dark" ? "#1A1A1A" : "#FFF6F2"}`,
              }}
            >
              <Box className="tw-w-80">
                <InputField
                  label={"Name of Initiative"}
                  placeholder="Initiative"
                  name={"inititative_name"}
                  register={register}
                />
              </Box>
              <Box className="tw-my-10 ">
                <TextAreaField
                  register={register}
                  label={"Initiative Description "}
                  placeholder={"Description"}
                  name={"description"}
                />
              </Box>
            </Box>

            <Box className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-justify-end tw-space-y-4 sm:tw-space-x-8 sm:tw-space-y-0 tw-mt-6 md:tw-mt-6 lg:tw-mt-16">
              <SaveAndCancelBtn
                handleCancle={() => {
                  navigate("/home/training");
                }}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default InitativeTrainingAdd;
