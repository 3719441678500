import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../constants";

export const oiwApi = createApi({
  reducerPath: "oiwApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
      console.log("building policy headers");
      headers.set(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
      );
      return headers;
    },
  }),
  tagTypes: ["OrgOiwMapping", "OiwStatus", "OiwOrg"],

  endpoints: (build) => ({
    uploadOiw: build.mutation({
      query: (body) => ({
        url: `/v1/orderinwriting/upload`,
        method: "POST",
        body,
      }),
    }),
    updateOiw: build.mutation({
      query: ({ id, body }) => ({
        url: `/v1/orderinwriting/update/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["OrgOiwMapping", "OiwStatus"],
    }),
    getOrgOiwMapped: build.query({
      query: (params) => ({
        url: `/v1/orderinwriting/org-oiw-mapped`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 1000,
      providesTags: ["OrgOiwMapping"],
    }),
    getOiwStatus: build.query({
      query: () => ({
        url: `/v1/orderinwriting/oiw-status`,
        method: "GET",
      }),
      keepUnusedDataFor: 1800,
      providesTags: ["OiwStatus"],
    }),
    updateOiwStatus: build.mutation({
      query: (body) => ({
        url: `/v1/orderinwriting/update-status`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["OrgOiwMapping", "OiwStatus"],
    }),
    getOiwByOrg: build.query({
      query: (params) => ({
        url: `/v1/orderinwriting/get-by-org`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 1800,
      providesTags: ["OiwOrg"],
    }),
  }),
});

export const {
  useUploadOiwMutation,
  useGetOrgOiwMappedQuery,
  useGetOiwStatusQuery,
  useUpdateOiwStatusMutation,
  useUpdateOiwMutation,
  useGetOiwByOrgQuery,
} = oiwApi;
