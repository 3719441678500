import React from "react";
import { Grid, Typography } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useGetAllZonesQuery } from "../../store/dropdown/dropdownApi";
import { CheckboxField2, InputField2 } from "../Form";

function GenderDistributionField({
  control,
  gender_men_name,
  gender_women_name,
  gender_other_name,
  gender_men_error,
  gender_women_error,
  gender_other_error,
  checkboxName,
  validations,
}) {
  const watchedCheckbox = useWatch({ control, name: checkboxName });
  useGetAllZonesQuery();

  return (
    <>
      <Grid item xs={12}>
        <Typography fontWeight={"bold"} mb={2}>
          Gender Distribution
        </Typography>
        <hr />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <CheckboxField2
          label={"Prefer Not To Say"}
          name={checkboxName}
          control={control}
          // defaultValue={false}
        />
      </Grid>
      {!watchedCheckbox && (
        <Grid item xs={12} sm={6} lg={4}>
          <InputField2
            label={"Men %"}
            placeholder={"Enter"}
            name={gender_men_name}
            control={control}
            type="number"
            validations={validations}
            error={gender_men_error}
            defaultValue={""}
          />
        </Grid>
      )}
      {!watchedCheckbox && (
        <Grid item xs={12} sm={6} lg={4}>
          <InputField2
            label={"Women %"}
            placeholder={"Enter"}
            name={gender_women_name}
            control={control}
            type="number"
            validations={validations}
            error={gender_women_error}
            defaultValue={""}
          />
        </Grid>
      )}
      {!watchedCheckbox && (
        <Grid item xs={12} sm={6} lg={4}>
          <InputField2
            label={"Other %"}
            placeholder={"Enter"}
            name={gender_other_name}
            control={control}
            type="number"
            validations={validations}
            error={gender_other_error}
            defaultValue={""}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <hr />
      </Grid>
    </>
  );
}

export default GenderDistributionField;
