import React, { useContext } from "react";
import { Button } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { ColorModeContext } from "../../constants/theme";

function FilterButton({ buttonStyles = {}, onClick = () => null }) {
  const { mode } = useContext(ColorModeContext);
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      sx={{
        color: `${mode === "dark" ? "#FFFFFF" : "#4B4B4B"}`,
        border: `${
          mode === "dark"
            ? "1px solid #DEDEDE2B"
            : "1px solid rgba(33, 33, 33, 0.17)"
        }`,
        gap: 1,
        width: "80px",
        height: "35px",
        ...buttonStyles,
      }}
    >
      <FilterAltIcon />
      Filter
    </Button>
  );
}

export default FilterButton;
