import React from "react";
import { Text, View } from "@react-pdf/renderer";
import LocationTable from "./LocationTable";
import { tableStyles } from "../../PDFStyling/OIW/OiwStyle";

const arr = [1, 2, 3, 4, 5];

export const tableData = arr.map((x) => {
  return {
    name: `Member${x}`,
    designation: "To be filled",
    email: "Male",
    dateInIc: "10/05/2020",
  };
});

const AdditionICMembers = ({ newIcMembers }) => {
  return (
    <View>
      <Text style={tableStyles.title}>Addition of the IC members</Text>
      <View style={tableStyles.table}>
        <View style={tableStyles.tableRow}>
          <Text style={tableStyles.headerCell}>Sr. No.</Text>
          <Text style={tableStyles.headerCell}>Name</Text>
          <Text style={tableStyles.headerCell}>Designation </Text>
          <Text style={tableStyles.headerCell}>Email ID</Text>
          <Text style={tableStyles.headerCell}>Effective date in the IC</Text>
        </View>
        <LocationTable newIcMembers={newIcMembers} />
      </View>
    </View>
  );
};

export default AdditionICMembers;
