import {
  Typography,
  Button,
  Grid,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { FromHeading } from "../../../components/OrgDetails";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { InputField2, SelectField2 } from "../../../components/Form";
import {
  EmployeesSize,
  LanguagesOfCommunication,
} from "../../../utils/dropdowns";
import { useSelector } from "react-redux";
import {
  useGetAllLanguagesQuery,
  useGetAllZonesQuery,
  useGetAverageAgeBracketsQuery,
  useGetTypesOfLocationsQuery,
} from "../../../store/dropdown/dropdownApi";
import {
  useGetLocationQuery,
  useUpdateLocationMutation,
} from "../../../store/org/orgApi";

import { ContainerLayout } from "../../../components/Layouts";
import StateCityFields from "../../OrganizationDetails/StateCityFields";
import { NavigationBar } from "../../../components/General";
import ZoneField from "../../../components/OtherFormFields/ZoneField";
import GenderDistributionField from "../../../components/OtherFormFields/GenderDistributionField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { selectFieldValidate } from "../../../utils/validate";
import Swal from "sweetalert2";

function LocationEdit() {
  const navigate = useNavigate();
  const TabScreen = useMediaQuery("(min-width: 640px)");

  const url = window.location.href;
  const ID = url.substring(url.lastIndexOf("/") + 1);

  useGetTypesOfLocationsQuery();
  useGetAllLanguagesQuery();
  useGetAllZonesQuery();
  useGetAverageAgeBracketsQuery();
  const { data, isSuccess: locSuccess } = useGetLocationQuery(ID);
  const [updateLocation, { isSuccess: updateSuccess }] =
    useUpdateLocationMutation();

  const { dropdowns } = useSelector((state) => state.dropdown);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    register,
    resetField,
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
    defaultValues: { preferNoToSay: false },
  });

  useEffect(() => {
    if (locSuccess) {
      console.log("loc data ---->", data);
      const obj = { ...data.location };
      for (let key in obj) {
        if (obj[key] === null) {
          obj[key] = "";
        }
      }
      if (!obj.location_type_id) {
        obj.location_type_id = " ";
      }
      if (!obj.avg_age_bracket_id) {
        obj.avg_age_bracket_id = " ";
      }
      if (!obj.zone_id) {
        obj.zone_id = " ";
      }
      if (!obj.preferred_language_of_communication) {
        obj.preferred_language_of_communication = " ";
      }
      delete obj["city"];
      delete obj["state"];
      obj.preferNoToSay = false;
      console.log("reset obj --->", obj);
      reset(obj);
    }
    if (updateSuccess) {
      Swal.fire({
        title: "Updated",
        text: "Location has been updated",
        icon: "success",
        timer: 1500,
      });
      navigate(-1);
    }
  }, [locSuccess, updateSuccess]);

  const onSubmit = (data) => {
    console.log(data);
    const obj = {};
    obj.location_name = data.location_name;
    obj.location_type_id =
      data.location_type_id !== " " ? data.location_type_id : null;
    obj.no_of_employee = data.no_of_employee;
    obj.avg_age_bracket_id =
      data.avg_age_bracket_id !== " " ? data.avg_age_bracket_id : null;
    obj.is_zone_assigned = data.is_zone_assigned;
    if (data.zone_id !== " " && data.is_zone_assigned) {
      obj.zone_id = data.zone_id;
    } else {
      obj.zone_id = null;
    }
    if (data.gender_men_percentage !== "") {
      obj.gender_men_percentage = data.gender_men_percentage;
    }
    if (data.gender_women_percentage !== "") {
      obj.gender_women_percentage = data.gender_women_percentage;
    }
    if (data.gender_other_percentage !== "") {
      obj.gender_other_percentage = data.gender_other_percentage;
    }
    obj.address_line_1 = data.address_line_1;
    obj.address_line_2 = data.address_line_2;
    obj.preferred_language_of_communication =
      data.preferred_language_of_communication;
    if (data.preferNoToSay) {
      obj.gender_men_percentage = null;
      obj.gender_other_percentage = null;
      obj.gender_women_percentage = null;
    }
    if (data.state === "select") {
      obj.state = null;
    } else {
      obj.state = data.state;
    }
    if (data.city === "select") {
      obj.city = null;
    } else {
      obj.city = data.city;
    }
    obj.postalcode = data.postalcode;
    obj.country = data.country;
    console.log(obj);
    updateLocation({ id: ID, body: obj });
  };

  const validations = {
    required: "This field is required",
  };

  const breadcrumbs = [
    <Typography className="tw-text-black tw-text-[11px]" key="1000">
      company profile
    </Typography>,
    <Typography className="tw-text-dark-blue tw-text-[11px]" key="2000">
      Edit Location
    </Typography>,
  ];

  return (
    <ContainerLayout paddingTop="0">
      <NavigationBar breadcrumbs={breadcrumbs} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-w-full tw-mb-4 sm:tw-mb-6 lg:tw-mb-12">
          <FromHeading text="Location" />
        </div>

        <div className="tw-bg-light-blue tw-p-3 sm:tw-p-6 lg:tw-p-12 tw-rounded-3 tw-mb-4 sm:tw-mb-8">
          <Grid container spacing={TabScreen ? 4 : 2}>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField2
                label={
                  <>
                    <Typography
                      display={"inline-block"}
                      sx={{ marginRight: 1 }}
                    >
                      Location Name
                    </Typography>
                    <Tooltip
                      title={
                        "Give a name to your location. This is the name this location will be referred with throughout CompliMate"
                      }
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize={"inherit"}
                        className="hover:tw-cursor-pointer"
                      />
                    </Tooltip>
                  </>
                }
                placeholder={"Enter"}
                name={`location_name`}
                control={control}
                validations={validations}
                defaultValue=""
                error={errors?.location_name?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectField2
                label={"Type of Location"}
                placeholder={"Select"}
                control={control}
                menus={
                  dropdowns?.TypesOfLocations?.length > 0
                    ? dropdowns?.TypesOfLocations
                    : []
                }
                name={`location_type_id`}
                error={errors?.location_type_id?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectField2
                label={"No of employees"}
                placeholder={"Select"}
                menus={EmployeesSize}
                name={`no_of_employee`}
                control={control}
                is_id="name"
                error={errors?.no_of_employee?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectField2
                label={"Average age bracket"}
                placeholder="Select"
                menus={
                  dropdowns?.AverageAgeBrackets?.length > 0
                    ? dropdowns?.AverageAgeBrackets
                    : []
                }
                name={`avg_age_bracket_id`}
                control={control}
                error={errors?.avg_age_bracket_id?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectField2
                label={"Preferred language of communication"}
                placeholder={"Select"}
                menus={
                  dropdowns?.AllLanguages?.length > 0
                    ? dropdowns?.AllLanguages
                    : LanguagesOfCommunication
                }
                control={control}
                is_id="name"
                name={`preferred_language_of_communication`}
              />
            </Grid>
            <ZoneField
              register={register}
              checkboxName={`is_zone_assigned`}
              zoneName={`zone_id`}
              control={control}
              validations={validations}
              zoneError={errors?.zone_id?.message}
            />
            <GenderDistributionField
              register={register}
              control={control}
              gender_men_name={`gender_men_percentage`}
              gender_men_error={errors?.gender_men_percentage?.message}
              gender_women_name={`gender_women_percentage`}
              gender_women_error={errors?.gender_women_percentage?.message}
              gender_other_name={`gender_other_percentage`}
              gender_other_error={errors?.gender_other_percentage?.message}
              validations={{ required: "This field is required" }}
              checkboxName={`preferNoToSay`}
            />
            <Grid item xs={12}>
              <InputField2
                label={"Registered Address 1"}
                placeholder={"Enter"}
                name={`address_line_1`}
                control={control}
                validations={validations}
                defaultValue=""
                error={errors?.address_line_1?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField2
                label={"Registered Address 2"}
                placeholder={"Enter"}
                name={`address_line_2`}
                control={control}
                // validations={validations}
                defaultValue=""
                error={errors?.address_line_2?.message}
              />
            </Grid>
            <StateCityFields
              control={control}
              stateName={"state"}
              cityName={"city"}
              resetField={resetField}
              validations={selectFieldValidate()}
              stateError={errors?.state?.message}
              cityError={errors?.city?.message}
            />
            <Grid item xs={12} sm={6} lg={4}>
              <InputField2
                label={"Postal Code"}
                placeholder={"Enter"}
                name={`postalcode`}
                control={control}
                validations={validations}
                defaultValue=""
                error={errors?.postalcode?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField2
                label={"Country"}
                placeholder={"Enter"}
                name={`country`}
                control={control}
                validations={validations}
                defaultValue=""
                error={errors?.country?.message}
              />
            </Grid>
          </Grid>
        </div>

        {/* Submit Buttons */}
        <div className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-justify-end tw-space-y-5 sm:tw-space-x-8 sm:tw-space-y-0 tw-mt-6 md:tw-mt-8 lg:tw-mt-16">
          <Button
            variant="contained"
            type="submit"
            onClick={() => console.log(errors)}
            className="tw-bg-black tw-text-white sm:tw-min-w-[30%]  sm:tw-h-10"
          >
            Save Changes
          </Button>
        </div>
      </form>
    </ContainerLayout>
  );
}

export default LocationEdit;
