import { Navigate } from "react-router-dom";
import ChangePassword from "../Dialog/ChangePassword";
const Protected = ({ roles = [], children }) => {
  const USER = JSON.parse(localStorage.getItem("user"));
  if (!roles.includes(USER?.user?.role)) {
    return <Navigate to="/" replace />;
  }

  if (USER?.user?.role !== "SUPER_ADMIN" && !USER?.user?.password_changed) {
    return <ChangePassword open />;
  }
  return children;
};
export default Protected;
