import { Status } from "./options";

export const getPendingCount = (org) => {
  let pending = 0;
  if (org?.ic_status === Status.PENDING) {
    pending++;
  }
  if (
    org?.policy_status === Status.PENDING ||
    org?.policy_status === Status.APPROVAL ||
    org?.policy_status === Status.REJECTED
  ) {
    pending++;
  }
  if (
    org?.registration_status === Status.PENDING ||
    org?.registration_status === Status.APPROVAL ||
    org?.registration_status === Status.REJECTED
  ) {
    pending++;
  }
  if (org?.annual_report_status === Status.PENDING) {
    pending++;
  }
  if (org?.training_status === Status.PENDING) {
    pending++;
  }
  if (
    org?.oiw_status === Status.PENDING ||
    org?.oiw_status === Status.APPROVAL ||
    org?.oiw_status === Status.REJECTED
  ) {
    pending++;
  }
  return pending;
};

export const getCompletedCount = (org) => {
  let completed = 0;
  if (org?.ic_status === Status.COMPLETE) {
    completed++;
  }
  if (org?.policy_status === Status.COMPLETE) {
    completed++;
  }
  if (org?.registration_status === Status.COMPLETE) {
    completed++;
  }
  if (org?.annual_report_status === Status.COMPLETE) {
    completed++;
  }
  if (org?.training_status === Status.COMPLETE) {
    completed++;
  }
  if (org?.oiw_status === Status.COMPLETE) {
    completed++;
  }
  return completed;
};
