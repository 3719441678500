import { Box } from "@mui/material";
import React, { useContext } from "react";
import Logo from "../../assets/Logo.svg";
import { ColorModeContext } from "../../constants/theme";

function FormLayout({ children, ...restProps }) {
  const { mode } = useContext(ColorModeContext);
  return (
    <Box
      margin="auto"
      maxWidth="100%"
      width="100%"
      height="100%"
      {...restProps}
    >
      <Box
        width={"100%"}
        height="361px"
        sx={{
          background: `${mode === "dark" ? "#4B4B4B" : "#EEF8FF"}`,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={Logo} height={"50%"} style={{ marginTop: "4rem" }} />
      </Box>
      {children}
    </Box>
  );
}

export default FormLayout;
