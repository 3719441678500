import React, { useContext } from "react";
import { Box, IconButton } from "@mui/material";
import { useDeleteContactMutation } from "../../store/org/orgApi";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import { ColorModeContext } from "../../constants/theme";
import { DataGrid } from "@mui/x-data-grid";
import { EditButton, GridActionsLayout } from "../../components";
import { GridColumns } from "../../utils/gridColumns";
import Swal from "sweetalert2";

const EmployeeManagerTable = ({ data, isLoading, refetch }) => {
  const { mode } = useContext(ColorModeContext);
  const [deleteEmployee, { isSuccess: employeeRemoved }] =
    useDeleteContactMutation();
  const navigate = useNavigate();

  const handleRemoveEmployee = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove Employee?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEmployee(id);
      }
    });
  };

  React.useEffect(() => {
    if (employeeRemoved) {
      Swal.fire({
        title: "Removed",
        text: "Employee has been removed",
        icon: "success",
        timer: 1500,
      });
      refetch();
    }
  }, [employeeRemoved]);

  const columns = [
    ...new GridColumns()
      .serialNo()
      .name()
      .email()
      .phone()
      .designation()
      .actions({
        renderCell: (params) => {
          return (
            <GridActionsLayout>
              <EditButton
                onClick={() =>
                  navigate(`/home/edit-employee-manager/${params?.row?.id}`)
                }
              />
              <IconButton
                onClick={() => {
                  handleRemoveEmployee(params?.row?.id);
                }}
              >
                <ClearIcon />
              </IconButton>
            </GridActionsLayout>
          );
        },
      })
      .getCols(),
  ];

  return (
    <Box height={"50vh"}>
      <DataGrid
        loading={isLoading}
        rows={data}
        columns={columns}
        disableRowSelectionOnClick
        pageSizeOptions={[5]}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            background: `${mode === "dark" ? " #181818" : "#FFE8F2"}`,
          },
          "& .MuiDataGrid-toolbarContainer": {
            paddingY: 2,
            paddingX: 1,
          },

          "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
            backgroundColor: "transparent",
            color: "#4B4B4B",
          },
        }}
      />
    </Box>
  );
};

export default EmployeeManagerTable;
