import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function ProcedureForRedressal({ serialNo }) {
  return (
    <View style={policyStyles.container}>
      <Text style={policyStyles.heading}>
        {serialNo}. Procedure For Redressal
      </Text>
      <Text style={policyStyles.text}>
        The Organization is committed to providing a supportive environment to
        resolve concerns of sexual harassment as under:
      </Text>
      <Text style={policyStyles.text}>
        The purpse of the Policy isto provide protection against sexual
        harassment of women at workplace and the prevention and redressal of
        complaints of sexual harassment and matters related to it.
      </Text>

      {/* Redressal Mechanism and Internal Committee */}
      <Text style={policyStyles.subHeading}>
        4.1 Redressal Mechanism and Internal Committee
      </Text>
      <Text style={policyStyles.subText}>
        Organization has constituted an Internal Committee (“IC”) for receiving
        and redressing complaints related to Sexual Harassment at Workplace,
        pursuant to the relevant provisions of the Act. The details of the
        members of the IC, including their names, contact information and other
        details is attached as Annexure- 1. The details of the members of the IC
        will also be displayed at the office notice board and be published in
        the “Legal & Compliance” intranet.
      </Text>

      {/* Filing a complaints*/}
      <Text style={policyStyles.subHeading}>4.1 Filing a complaints</Text>
      <Text style={policyStyles.subText}>
        A complaint must be reported in writing within a period of 3 (three)
        months of occurrence of an act of Sexual Harassment and in case of a
        series of incidents, within a period of 3 (three) months from the date
        of last incident to any member of the IC as per details provided in
        Annexure I. The IC can extend the timeline for filing the complaint, for
        reasons to be recorded in writing, by a period of 3 (three) months. A
        complaint can also be reported electronically at ________ as per the
        details mentioned in Annexure ‘1’ below.Complainant should submit the
        complaint along with supporting documents and the names of the
        witnesses.
      </Text>
      <Text style={policyStyles.subText}>
        For details regarding complaints that can be filed on behalf of an
        aggrieved woman on account of her physical incapacity, mental incapacity
        or any other reason, please refer to Anneure ‘2’ below
      </Text>

      {/* Procedure for handling complaints */}
      <Text style={policyStyles.subHeading}>
        4.3 Procedure for handling complaints
      </Text>

      <Text style={policyStyles.heading3}>4.3.1 Process for Conciliation:</Text>
      <Text style={policyStyles.text3}>
        Prior to initiating an inquiry, the IC may, at the written request of
        the Complainant, take steps to conciliate the matter between the
        Complainant and the Respondent. In case a settlement is arrived at, the
        IC will record & report the same to the management for taking
        appropriate action. The IC will also provide copies of the settlement,
        as recorded, to the Complainant and the Respondent. If conciliation has
        been reached and the action implemented, the IC will not be required to
        conduct any further inquiry. Monetary settlement cannot be made the
        basis of such conciliation.
      </Text>

      <Text style={policyStyles.heading3}>4.3.1 Procedure for Inquiry:</Text>
      <Text style={policyStyles.text3}>
        If the Complainant does not wish to go for conciliation or where a
        settlement could not be arrived at through conciliation or if the
        Complainant informs the IC that conciliation reached between the parties
        is not complied with, the IC shall proceed to make inquiry into the
        complaint as per the process prescribed under this Policy.
      </Text>

      <View style={policyStyles.container2}>
        <Text style={policyStyles.text}>
          i) The IC shall send copy of the complaint to the Respondent within a
          period of 7 (seven) working days.{" "}
        </Text>
        <Text style={policyStyles.text}>
          ii) The Respondent shall file the reply to the complaint with all
          supporting documents, and names and addresses of witnesses (if any),
          within a period of 10 (ten) working days.
        </Text>
        <Text style={policyStyles.text}>
          iii) The Complainant and Respondent shall not be allowed to bring in
          any legal practitioner to represent them in their case at any stage of
          the proceedings before the IC.
        </Text>
        <Text style={policyStyles.text}>
          iv) During the pendency of an inquiry, IC shall have the power to
          issue interim directions to / with regard to any person participating
          in the proceedings before it as more particularly stated in Annexure
          ‘2‘ below.
        </Text>
        <Text style={policyStyles.text}>
          v) The IC shall have the right to terminate the inquiry proceedings or
          to give an ex-parte decision on the complaint, if the Complainant or
          Respondent fails, without sufficient cause, to present themselves, for
          3 (three) consecutive hearings convened by the Presiding Officer. Such
          termination or ex-parte order may not be passed without giving a
          notice in writing, 15 (fifteen) days in advance, to the Complainant or
          Respondent as the case maybe.
        </Text>
        <Text style={policyStyles.text}>
          vi) In conducting the inquiry, at any point of time, the Complaint
          shall be heard by a minimum of 3 (three) members including the
          Presiding Officer.
        </Text>
        <Text style={policyStyles.text}>
          vii) The IC shall make an inquiry into the complaint in accordance
          with the principles of natural justice.
        </Text>
      </View>

      {/* Procedure for handling complaints */}
      <Text style={policyStyles.subHeading}>4.4 Submission of Report</Text>
      <Text style={policyStyles.subText}>
        The IC shall conduct its inquiry in a timely manner and shall submit a
        written report to the management as soon as practically possible and in
        any case, not later than 90 (Ninety) days from the date of receipt of
        the complaint. The inquiry report shall specify details of the
        allegations against the Respondent, the statements made and evidence
        presented by the Complainant, Respondent and / or witnesses, IC’s
        findings along with a statement giving reasons for the findings arrived
        at by the IC and IC’s recommendations. Details are provided in Annexure
        ‘2’ below.
      </Text>
      <Text style={policyStyles.subText}>
        The Organization is required to act upon the recommendations within 60
        days and confirm to the IC
      </Text>
    </View>
  );
}

export default ProcedureForRedressal;
