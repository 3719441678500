import React from "react";
import { Link } from "react-router-dom";

function ViewAllButton({ url = "", linkText = "View All", classes = "" }) {
  return (
    <Link
      to={url}
      className={`tw-text-[#6AC2FF] tw-text-sm tw-font-normal ${classes}`}
    >
      {linkText}
    </Link>
  );
}

export default ViewAllButton;
