import { Button, Dialog, Stack, Typography } from "@mui/material";
import React from "react";

function ConfirmDialog({ message, open, handleClick, handleClose }) {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "80%", maxHeight: 435, padding: 4 },
      }}
      maxWidth="xs"
      open={open}
    >
      <Stack gap={4}>
        <Typography>{message}</Typography>
        <Stack direction={"row"} gap={2} justifyContent={"flex-end"}>
          <Button
            variant="outlined"
            sx={{ backgroundColor: "white", color: "black" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleClick}>
            Yes
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}

export default ConfirmDialog;
