import { InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React from "react";

function SelectField({
  label,
  placeholder,
  register,
  name,
  validations,
  error,
  defaultValue = 0,
  required_val = "name",
  menus = [],
}) {
  return (
    <div className="tw-gap-2 tw-flex tw-flex-col">
      <InputLabel
        className="tw-text-xs sm:tw-text-sm tw-w-min tw-py-1"
        htmlFor="orgName"
      >
        <Typography>{label}</Typography>
      </InputLabel>
      <Select
        {...register(name, { ...validations })}
        defaultValue={defaultValue}
        onChange={() => {
          console.log("Temp");
        }}
      >
        <MenuItem hidden disabled value={0}>
          {placeholder}
        </MenuItem>
        {menus.map((m) => {
          return (
            <MenuItem
              key={m.id}
              value={required_val === "name" ? m.name : m.id}
            >
              {m.name}
            </MenuItem>
          );
        })}
      </Select>
      {error && <span className="tw-text-red-700 tw-text-xs">{error}</span>}
    </div>
  );
}

export default SelectField;
