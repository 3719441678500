import Roboto from "../../../../../src/assets/font/Roboto-Bold.ttf";
import RobotoRegular from "../../../../../src/assets/font/Roboto-Regular.ttf";
import { Font, StyleSheet } from "@react-pdf/renderer";

Font.register({
  family: "MyFont",
  fonts: [{ src: Roboto, fontWeight: "bold" }],
});
Font.register({
  family: "RegularFont",
  fonts: [{ src: RobotoRegular, fontWeight: "normal" }],
});

export const RegistrationStyles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 60,
    fontSize: 8,
  },
  title: {
    display: "flex",
    fontFamily: "RegularFont",
    textAlign: "center",
  },
  container: {
    marginTop: 30,
  },
  date: {
    textAlign: "left",
    paddingY: 20,
    fontFamily: "RegularFont",
  },
  address: {
    marginTop: 20,
    fontFamily: "RegularFont",
  },
  subject: {
    marginTop: 15,
    textAlign: "center",
    fontFamily: "MyFont",
    display: "flex",
  },
  name: {
    marginTop: 15,
    display: "flex",
    fontFamily: "RegularFont",
    textAlign: "left",
  },
  text: {
    marginTop: 15,
    display: "flex",
    fontFamily: "RegularFont",
    textAlign: "left",
  },
  bottomText: {
    display: "flex",
    fontFamily: "RegularFont",
    textAlign: "right",
  },
  POCText: {
    marginTop: "5px",
    display: "flex",
    textAlign: "right",
    fontFamily: "MyFont",
  },
});

export const TableStyle = StyleSheet.create({
  table: {
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "1px solid black",
    fontSize: 10,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: 10,
  },
  headerCell: {
    borderLeft: "1px solid black",
    borderBottom: "1px solid black",
    fontFamily: "MyFont",
    padding: 5,
    flex: 1,
    textAlign: "center",
    fontSize: 6,
    fontWeight: "bold",
  },
  cell: {
    borderLeft: "1px solid black",
    borderBottom: "1px solid black",
    fontFamily: "RegularFont",
    padding: 5,
    flex: 1,
    textAlign: "center",
    fontSize: 6,
  },
});
