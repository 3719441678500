import React from "react";
import { Stack } from "@mui/material";

function GridActionsLayout({children, ...rest}) {
  return (
    <Stack
      direction={"row"}
      columnGap={2}
      flex={1}
      justifyContent={"center"}
      alignItems={"center"}
      {...rest}
    >
      {children}
    </Stack>
  );
}

export default GridActionsLayout;
