import React from "react";
import { Alert, Button, InputLabel, Stack, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { FileUploadOutlined } from "@mui/icons-material";
import { DownloadButton } from "../Buttons";

function SingleUploadLayout({
  handleChange,
  handleUpload,
  label,
  done = false,
  fileName,
  isLoading = false,
  alert,
  handleAlertClose = () => null,
}) {
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      gap={12}
      margin={"auto"}
      height={"60vh"}
      maxWidth={"500px"}
      className="tw-py-16"
    >
      {/* {alert && ( */}
      <Alert
        sx={{ marginBottom: -8, visibility: alert ? "visible" : "hidden" }}
        severity={alert?.severity}
        onClose={handleAlertClose}
      >
        {alert?.message}
      </Alert>
      {/* )} */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        gap={2}
        alignItems={"flex-start"}
      >
        <InputLabel>{label}</InputLabel>
        <Stack gap={1}>
          <Stack direction={"row"} justifyContent={"flex-end"} gap={2}>
            <Button
              sx={{
                backgroundColor: "white",
                height: "35px",
                width: "120px",
              }}
              component="label"
              variant="outlined"
            >
              Choose File
              <input
                hidden
                type="file"
                accept={".pdf"}
                onChange={handleChange}
              />
            </Button>
            <Button
              component="label"
              variant="contained"
              className="sm:tw-gap-2"
              sx={{
                backgroundColor: "#4B4B4B",
                height: "35px",
                width: "120px",
              }}
              onClick={handleUpload}
            >
              {done ? <DoneIcon /> : <FileUploadOutlined />}
              Upload
            </Button>
          </Stack>
          <Typography fontSize={11}>{fileName}</Typography>
        </Stack>
      </Stack>
      <DownloadButton
        label="Submit"
        loading={isLoading}
        styles={{ width: "100%", height: "35px" }}
      />
    </Stack>
  );
}

export default SingleUploadLayout;
