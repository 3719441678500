import { Typography, Grid, useMediaQuery, Box, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import { NewInputField, NewSelectField, SelectField2 } from "./Form";
import { useSelector } from "react-redux";
import {
  useGetAllLanguagesQuery,
  useGetAllZonesQuery,
  useGetAverageAgeBracketsQuery,
  useGetRegionQuery,
  useGetTypesOfLocationsQuery,
} from "../store/dropdown/dropdownApi";
import { CloseButton } from "./";
import ZoneField from "./OtherFormFields/ZoneField";
import GenderDistributionField from "./OtherFormFields/GenderDistributionField";
import { EmployeesSize, LanguagesOfCommunication } from "../utils/dropdowns";
import { ColorModeContext } from "../constants/theme";
import StateCityFields from "../pages/OrganizationDetails/StateCityFields";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function AddLocationSection({
  idx,
  remove,
  register,
  errors,
  control,
  resetField,
  children,
}) {
  const { mode } = useContext(ColorModeContext);
  const TabScreen = useMediaQuery("(min-width: 640px)");

  const InputField = NewInputField;
  const SelectField = NewSelectField;

  useGetTypesOfLocationsQuery();
  useGetAllLanguagesQuery();
  useGetAllZonesQuery();
  useGetAverageAgeBracketsQuery();
  useGetRegionQuery();
  const { dropdowns } = useSelector((state) => state.dropdown);

  const validations = {
    required: "This field is required",
  };

  return (
    <Box
      className="tw-p-3 sm:tw-p-6 lg:tw-p-12 tw-rounded-3 tw-mb-4 sm:tw-mb-8"
      position={"relative"}
      sx={{
        background: `${mode === "dark" ? "#1A1A1A" : "#DDF1FF"}`,
      }}
    >
      <CloseButton
        styles={{
          position: "absolute",
          top: "15px",
          right: "15px",
        }}
        handleClick={() => remove(idx)}
      />
      <Grid container spacing={TabScreen ? 4 : 2}>
        <Grid item xs={12} sm={6} lg={4}>
          <InputField
            label={
              <>
                <Typography display={"inline-block"} sx={{ marginRight: 1 }}>
                  Location Name
                </Typography>
                <Tooltip
                  title={
                    "Give a name to your location. This is the name this location will be referred with throughout CompliMate"
                  }
                  arrow
                >
                  <InfoOutlinedIcon
                    fontSize={"inherit"}
                    className="hover:tw-cursor-pointer"
                  />
                </Tooltip>
              </>
            }
            placeholder={"Give a name to your location"}
            name={`locations.${idx}.location_name`}
            validations={validations}
            register={register}
            error={
              errors?.locations?.length > idx &&
              errors?.locations[idx]?.location_name?.message
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <SelectField2
            control={control}
            label={"Type of Location"}
            placeholder={"Select"}
            menus={
              dropdowns?.TypesOfLocations?.length > 0
                ? dropdowns?.TypesOfLocations
                : []
            }
            name={`locations.${idx}.location_type_id`}
            validations={{
              validate: (value) => {
                return value !== " " || "This field is required";
              },
            }}
            error={
              errors?.locations?.length > idx &&
              errors?.locations[idx]?.location_type_id?.message
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <SelectField2
            control={control}
            label={"Region"}
            placeholder="Select"
            menus={dropdowns?.Region}
            name={`locations.${idx}.region_id`}
            validations={{
              validate: (value) => {
                return value !== " " || "This field is required";
              },
            }}
            // defaultValue={" "}
            error={
              errors?.locations?.length > idx &&
              errors?.locations[idx]?.region_id?.message
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <SelectField
            label={"No of employees"}
            placeholder={"Select"}
            menus={EmployeesSize}
            name={`locations.${idx}.no_of_employee`}
            register={register}
            validations={validations}
            error={
              errors?.locations?.length > idx &&
              errors?.locations[idx]?.no_of_employee?.message
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <SelectField2
            control={control}
            label={"Average age bracket"}
            placeholder="Select"
            menus={
              dropdowns?.AverageAgeBrackets?.length > 0
                ? dropdowns?.AverageAgeBrackets
                : []
            }
            name={`locations.${idx}.avg_age_bracket_id`}
            validations={{
              validate: (value) => {
                return value !== " " || "This field is required";
              },
            }}
            error={
              errors?.locations?.length > idx &&
              errors?.locations[idx]?.avg_age_bracket_id?.message
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <SelectField
            label={"Preferred language of communication"}
            placeholder={"Select"}
            menus={LanguagesOfCommunication}
            name={`locations.${idx}.preferred_language_of_communication`}
            register={register}
            validations={validations}
            error={
              errors?.locations?.length > idx &&
              errors?.locations[idx]?.preferred_language_of_communication
                ?.message
            }
          />
        </Grid>
        <ZoneField
          register={register}
          checkboxName={`locations.${idx}.is_zone_assigned`}
          zoneName={`locations.${idx}.zone_id`}
          control={control}
          validations={{
            validate: (value) => {
              return value !== " " || "This field is required";
            },
          }}
          zoneError={
            errors?.locations?.length > idx &&
            errors?.locations[idx]?.zone_id?.message
          }
        />
        <GenderDistributionField
          register={register}
          control={control}
          gender_men_name={`locations.${idx}.gender_men_percentage`}
          gender_men_error={
            errors?.locations?.length > idx &&
            errors?.locations[idx]?.gender_men_percentage?.message
          }
          gender_women_name={`locations.${idx}.gender_women_percentage`}
          gender_women_error={
            errors?.locations?.length > idx &&
            errors?.locations[idx]?.gender_women_percentage?.message
          }
          gender_other_name={`locations.${idx}.gender_other_percentage`}
          gender_other_error={
            errors?.locations?.length > idx &&
            errors?.locations[idx]?.gender_other_percentage?.message
          }
          validations={{ required: "This field is required" }}
          checkboxName={`locations.${idx}.preferNoToSay`}
        />
        <Grid item xs={12}>
          <InputField
            label={"Registered Address 1"}
            placeholder={"Enter"}
            name={`locations.${idx}.address_line_1`}
            register={register}
            validations={validations}
            error={
              errors?.locations?.length > idx &&
              errors?.locations[idx]?.address_line_1?.message
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            label={"Registered Address 2"}
            placeholder={"Enter"}
            name={`locations.${idx}.address_line_2`}
            register={register}
            // validations={validations}
            error={
              errors?.locations?.length > idx &&
              errors?.locations[idx]?.address_line_2?.message
            }
          />
        </Grid>
        <StateCityFields
          stateName={`locations.${idx}.state`}
          cityName={`locations.${idx}.city`}
          control={control}
          resetField={resetField}
          validations={{
            validate: (value) => {
              return value !== "select" || "This field is required";
            },
          }}
          stateError={
            errors?.locations?.length > idx &&
            errors?.locations[idx]?.state?.message
          }
          cityError={
            errors?.locations?.length > idx &&
            errors?.locations[idx]?.city?.message
          }
        />
        <Grid item xs={12} sm={6} lg={4}>
          <InputField
            label={"Country"}
            placeholder={"Enter"}
            name={`locations.${idx}.country`}
            register={register}
            validations={validations}
            error={
              errors?.locations?.length > idx &&
              errors?.locations[idx]?.country?.message
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <InputField
            label={"Postal Code"}
            placeholder={"Enter"}
            name={`locations.${idx}.postalcode`}
            register={register}
            validations={validations}
            error={
              errors?.locations?.length > idx &&
              errors?.locations[idx]?.postalcode?.message
            }
          />
        </Grid>
        {children}
      </Grid>
    </Box>
  );
}

export default AddLocationSection;
