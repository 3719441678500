import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { NavigationBar } from "../../components/General";
import { OrgTable } from "./tables";
import { ColorModeContext } from "../../constants/theme";

function AggregateOrg() {
  const { mode } = useContext(ColorModeContext);
  const breadcrumbs = [
    <Typography
      className="tw-text-dark-blue tw-text-[11px] tw-font-semibold tw-tracking-[1px] tw-leading-6 tw-font inter-font"
      key="100"
    >
      AGGREGATE ORGANIZATION DETAILS
    </Typography>,
  ];

  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      paddingX={"60px"}
      paddingY={"35px"}
      rowGap={"46px"}
      sx={{ background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}` }}
    >
      <NavigationBar breadcrumbs={breadcrumbs} />
      <OrgTable />
    </Box>
  );
}

export default AggregateOrg;
