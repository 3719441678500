import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ children }) => {
  const USER = JSON.parse(localStorage.getItem("user"));

  if (USER) {
    if (USER?.user?.role === "SUPER_ADMIN") {
      return <Navigate to="/superadmin/dashboard" />;
    } else if (USER?.user?.role === "COMPLIANCE_MANAGER") {
      return <Navigate to="/home/internal-committee" />;
    } else {
      return <Navigate to="/home/dashboard" />;
    }
  }
  return children;
};

export default PublicRoute;
