import React, { useContext } from "react";
import {
  Button,
  Stack,
  TextField,
  Typography,
  Toolbar,
  Box,
} from "@mui/material";
import EmployeeManagerLogo from "../../assets/logos/EmployManagerLogo.svg";
import { useNavigate } from "react-router-dom";
import EmployeeManagerTable from "./EmployeeManagerTable";
import { useGetContactsQuery } from "../../store/org/orgApi";
import { ColorModeContext } from "../../constants/theme";
import { useSelector } from "react-redux";
import useDebouncedSearch from "../../utils/hooks/useDebounceSearch";

const EmployeeManagerList = () => {
  const { user } = useSelector((state) => state?.auth?.user);
  const navigate = useNavigate();
  const { mode } = useContext(ColorModeContext);
  const { searchText, debouncedResults } = useDebouncedSearch();
  const [rows, setRows] = React.useState([]);
  const { data, error, isFetching, isSuccess, refetch } = useGetContactsQuery({
    org_id: user?.org_id,
    ...searchText,
  });

  React.useEffect(() => {
    let tempRows = [];
    if (data?.employee) {
      tempRows = data?.employee?.map((emp, i) => {
        return {
          id: emp.id,
          "S.No.": i + 1,
          Name: emp.fullName || "N/A",
          Email: emp.email || "N/A",
          Phone: emp.phone_number || "N/A",
          Designation: emp.designation || "N/A",
        };
      });
    }
    setRows(tempRows || []);
  }, [data]);

  return (
    <>
      <Box
        className="tw-p-10 data-grid-div"
        sx={{
          background: `${mode === "dark" ? "#000000" : " #FFF"}`,
          height: "auto",
          width: "100%",
          margin: 0,
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <Toolbar />
        <Typography
          className=" tw-flex tw-flex-row tw-text-heading-black tw-font-semibold lg: tw-mt-10 heading1"
          sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
        >
          <Box className="tw-flex tw-flex-row">
            <img
              src={EmployeeManagerLogo}
              className="tw-w-10 tw-mr-20 tw-mt-50"
            />
          </Box>
          Employee manager
        </Typography>
        <Box className="tw-flex lg:tw-justify-between">
          <Stack direction={"row"} justifyContent={"space-between"}>
            <TextField
              type="text"
              name="search"
              size="small"
              placeholder="Search"
              onChange={debouncedResults}
            />
          </Stack>

          <Stack direction={"row"} gap={2}>
            {" "}
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#4B4B4B",
              }}
              onClick={() => navigate("/home/add-employee-manager")}
            >
              Add New
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#4B4B4B",
              }}
              onClick={() => navigate("/home/employee-manager")}
            >
              Bulk Upload
            </Button>
          </Stack>
        </Box>
        <EmployeeManagerTable
          data={rows}
          error={error}
          isLoading={isFetching}
          isSuccess={isSuccess}
          refetch={refetch}
        />
      </Box>
    </>
  );
};

export default EmployeeManagerList;
