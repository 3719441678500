import React from "react";
import { ReminderScreenLayout } from "../../components/Layouts";
import { Stack, Typography } from "@mui/material";
import { ColorModeContext } from "../../constants/theme";
import { useGetSuperadminRemindersQuery } from "../../store/reminders/reminderApi";

function SuperadminReminders() {
  const { mode } = React.useContext(ColorModeContext);
  const { data: reminders, isFetching: remindersFetching } =
    useGetSuperadminRemindersQuery();

  return (
    <Stack flex={1} gap={2} paddingX={8} paddingY={6}>
      <Typography
        className="tw-text-3xl tw-font-bold"
        sx={{ color: `${mode === "dark" ? "#FFFFFF" : " #000000"}` }}
      >
        Reminders
      </Typography>
      <ReminderScreenLayout
        reminders={reminders?.reminders || []}
        loading={remindersFetching}
      />
    </Stack>
  );
}

export default SuperadminReminders;
