import React from "react";
import UploadLogo from "../../assets/logos/UploadLogo.svg";
import { Box, Stack, Typography } from "@mui/material";
import { ColorModeContext } from "../../constants/theme";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { StatusBar } from "../../components";
import { useSelector } from "react-redux";
import { useGetOrgDetailsQuery } from "../../store/org/orgApi";
import { Status } from "../../utils/options";

const UploadStyle = styled(Box)(({ theme }) => ({
  ".headingStyle": {
    fontWeight: 700,
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
    },
  },
  ".container": {
    margin: "24px",
    padding: "50px",
    alignItems: "center",
    width: "80%",
    display: "flex",
    justifyContent: "center",
    borderRadius: "1rem",
    "&:hover": {
      background: "#DFDFDF",
    },
  },
  ".containerText": {
    fontWeight: 500,
    fontSize: "24px",
    color: "#4B4B4B",
    textAlign: "center",
    marginRight: "1rem",
  },
}));
const Upload = () => {
  const { mode } = React.useContext(ColorModeContext);
  const OrganizationDetails = useSelector((state) => state.auth);
  const { user } = OrganizationDetails;
  const orgId = user?.user?.org_id;
  const navigate = useNavigate();

  const { data: org } = useGetOrgDetailsQuery(orgId);

  const uploadDoc = [
    {
      id: 1,
      name: "Upload Registration",
      color: "#DBFFFF",
      link: "/home/upload-registration",
      status: (
        <StatusBar
          label={
            org?.org?.registration_status === Status.APPROVED
              ? "COMPLETED"
              : org?.org?.registration_status
          }
          status={org?.org?.registration_status}
        />
      ),
    },
    {
      id: 2,
      name: "Upload Order in writing",
      color: "#FFF0DE",
      link: "/home/upload-order-in-writing",
      status: (
        <StatusBar
          label={
            org?.org?.oiw_status === Status.APPROVED
              ? "COMPLETED"
              : org?.org?.oiw_status
          }
          status={org?.org?.oiw_status}
        />
      ),
    },
    {
      id: 3,
      name: "Upload Company Policy",
      color: "#EFFFE5",
      link: "/home/upload-company-policy",
      status: (
        <StatusBar
          label={
            org?.org?.policy_status === Status.APPROVED
              ? "COMPLETED"
              : org?.org?.policy_status
          }
          status={org?.org?.policy_status}
        />
      ),
    },
  ];

  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      paddingX={"60px"}
      paddingY={"10px"}
      height={"100vh"}
      rowGap={"46px"}
      sx={{ background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}` }}
    >
      <UploadStyle>
        <Stack
          direction={"row"}
          columnGap={4}
          justifyContent={"flex-start"}
          alignItems={"center"}
          marginBottom={2}
          marginTop={10}
        >
          <img src={UploadLogo} alt="" />
          <Typography
            className="headingStyle"
            sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
          >
            Upload Documents
          </Typography>
        </Stack>
        <Box marginTop={"4rem"}>
          {uploadDoc.map((upload) => (
            <Box
              className="container"
              key={upload.id}
              bgcolor={upload.color}
              onClick={() => {
                navigate(upload.link);
              }}
            >
              <Typography className="containerText">{upload.name}</Typography>
              <Typography> {upload.status}</Typography>
            </Box>
          ))}
        </Box>
      </UploadStyle>
    </Box>
  );
};

export default Upload;
