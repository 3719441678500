import React from "react";
import {
  Box,
  Drawer,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ColorModeContext } from "../../constants/theme";
import DashboardLogo from "../../assets/logos/DashboardLogo.svg";
import InternalCommitteeLogoSmall from "../../assets/logos/InternalCommitteeLogoSmall.svg";
import ComplaintsLogo from "../../assets/logos/complaintsLogo.svg";
import AnnualReportLogo from "../../assets/logos/AnnulaReportLogo.svg";
import TrainingLogo from "../../assets/logos/TrainingLogo.svg";
import CompanyProfileLogo from "../../assets/logos/CompanyProfileLogo.svg";
import CompanyPolicyLogo from "../../assets/logos/CompanyPolicyLogo.svg";
import RegistrationLogo from "../../assets/logos/RegistrationLogo.svg";
import OrderWritingLogo from "../../assets/logos/OrderWritingLogo.svg";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import LogoutIcon from "@mui/icons-material/Logout";
import ArchiveLogo from "../../assets/logos/ArchivesLogo.svg";

function SuperadminDrawer({ open, setOpen }) {
  const { mode, toggleMode } = React.useContext(ColorModeContext);
  const navigate = useNavigate();

  const logoutHandler = () => {
    console.log("sfds");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("mode");
    navigate("/");
    window.location.reload();
  };

  const menus = [
    {
      id: "dashboard123",
      text: "Dashboard",
      link: "/superadmin/dashboard",
      img: <img src={DashboardLogo} />,
    },
    {
      id: "register123",
      text: "Register Organization",
      link: "/superadmin/org-details",
      img: <img src={RegistrationLogo} />,
    },
    {
      id: "internal123",
      text: "All Internal Committees",
      link: "/superadmin/ic",
      img: <img src={InternalCommitteeLogoSmall} />,
    },
    {
      id: "training123",
      text: "All Trainings",
      link: "/superadmin/training",
      img: <img src={TrainingLogo} />,
    },
    {
      id: "annual123",
      text: "All Annual Reports",
      link: "/superadmin/annual-report",
      img: <img src={AnnualReportLogo} />,
    },
    {
      id: "complaints123",
      text: "All Complaints",
      link: "",
      img: <img src={ComplaintsLogo} />,
    },
    {
      id: "company123",
      text: "All Company Profiles",
      link: "/superadmin/aggregate-org",
      img: <img src={CompanyProfileLogo} />,
    },
    {
      id: "ploicy123",
      text: "All Company Policies",
      link: "/superadmin/company-policy",
      img: <img src={CompanyPolicyLogo} />,
    },
    {
      id: "registrations123",
      text: "All Registrations",
      link: "/superadmin/registration",
      img: <img src={RegistrationLogo} />,
    },
    {
      id: "oiw123",
      text: "All Order In Writings",
      link: "/superadmin/order-in-writing",
      img: <img src={OrderWritingLogo} />,
    },
    {
      id: "archives123",
      text: "All Archives",
      link: "/superadmin/archives",
      img: <img src={ArchiveLogo} />,
    },
  ];

  const isSelected = (url) => {
    const x = window.location.pathname.includes(url);
    return url && x;
  };

  return (
    <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
      <Box className=" tw-overflow-y-scroll tw-min-h-full  tw-flex tw-flex-col tw-py-6 tw-px-4">
        {menus.map((obj) => (
          <Link
            key={obj.id}
            to={obj.link}
            className={`tw-w-full tw-text-black tw-py-2 tw-text-start tw-px-2 ${
              isSelected(obj.link)
                ? "tw-bg-sidebar-hover-light"
                : "tw-bg-transparent"
            } hover:tw-bg-sidebar-hover-light tw-rounded-lg`}
          >
            <ListItemButton>
              <ListItemIcon>{obj.img}</ListItemIcon>
              <ListItemText primary={obj.text} />
            </ListItemButton>
          </Link>
        ))}

        <Link
          onClick={toggleMode}
          className="tw-w-full tw-text-black tw-py-2 tw-text-start tw-px-2 hover:tw-bg-sidebar-hover-light tw-rounded-lg"
        >
          <ListItemButton>
            <ListItemIcon>
              <Brightness4Icon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={`${mode === "light" ? "Light" : "Dark"} mode`}
            />
          </ListItemButton>
        </Link>

        <Link
          onClick={() => logoutHandler()}
          className="tw-w-full tw-text-black tw-py-2 tw-text-start tw-px-2 hover:tw-bg-sidebar-hover-light tw-rounded-lg"
        >
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </Link>
      </Box>
    </Drawer>
  );
}

export default SuperadminDrawer;
