import React from "react";
import { DownloadButton, OverviewLayout } from "../../../components";
import {
  Alert,
  Box,
  Container,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import { NavigationBar } from "../../../components/General";
import AWS from "aws-sdk";
import { useForm } from "react-hook-form";
import { useUploadRegistrationMutation } from "../../../store/uploadRegistration/uploadRegistrationApi";
import { useLocation, useNavigate } from "react-router-dom";
import UploadRegistrationInput from "./UploadRegistrationInput";
import Swal from "sweetalert2";

const breadcrumbs = [
  <Typography
    className="tw-text-[11px] tw-font-semibold tw-tracking-[1px] tw-leading-6 tw-font inter-font"
    key="100"
  >
    UPLOAD REGISTRATION
  </Typography>,
  <Typography
    className="tw-text-dark-blue tw-text-[11px] tw-font-semibold tw-tracking-[1px] tw-leading-6 tw-font inter-font"
    key="100"
  >
    UPLOAD DOCUMENT SENT BY COURIER
  </Typography>,
];

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
  region: "ap-south-1",
});

const UploadRegistrationByHand = () => {
  const [uploadRegistration, { isLoading, isSuccess, isError }] =
    useUploadRegistrationMutation();

  const location = useLocation();
  const routingData = location.state;

  const initialState = {
    signedFileURL: null,
    courierFilerURL: null,
    deliveredFileURL: null,
    letterUploaded: false,
    courierUploaded: false,
    deliveryUploaded: false,
    alert: null,
  };

  const handleStateChange = (state, action) => {
    switch (action.type) {
      case "stampedLetterURL":
        return { ...state, stampedLetterURL: action.payload };
      case "stampedLetterUploaded":
        return {
          ...state,
          stampedLetterUploaded: !state.stampedLetterUploaded,
        };
      case "alert":
        return { ...state, alert: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = React.useReducer(handleStateChange, initialState);

  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
  });

  React.useEffect(() => {
    if (isSuccess) {
      dispatch({
        type: "alert",
        payload: null,
      });
      Swal.fire({
        title: "Uploaded",
        text: "Document has been uploded",
        icon: "success",
        timer: 1500,
      });
      navigate("/home/upload");
    }
  }, [isSuccess]);

  React.useEffect(() => {
    if (isError) {
      dispatch({
        type: "alert",
        payload: {
          severity: "error",
          message: "Something went wrong. please try again",
        },
      });
    }
  }, [isError]);

  const onSubmitStampedLetter = (data) => {
    try {
      const s3 = new AWS.S3();
      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: "registration file/" + data.letter[0].name,
        Body: data.letter[0],
        ContentType: "application/pdf",
      };
      s3.upload(params, (err, data) => {
        if (err) {
          console.log(err);
          dispatch({
            type: "alert",
            payload: {
              severity: "error",
              message: "Something went wrong. please try again",
            },
          });
        } else {
          console.log("File uploaded Successfully!", data);
          dispatch({
            type: "stampedLetterURL",
            payload: data?.Location,
          });
          dispatch({ type: "stampedLetterUploaded" });
        }
      });
    } catch (error) {
      console.log("Error uploading file:", error);
    }
  };

  const handleFinalUpload = async () => {
    if (!state.stampedLetterURL) {
      dispatch({
        type: "alert",
        payload: {
          severity: "error",
          message: "Please upload document",
        },
      });
    } else {
      await uploadRegistration({
        region_id: routingData.region,
        location_id: routingData.location,
        sending_mode: routingData.mode,
        org_id: routingData.org_id,
        stamped_letter: state.stampedLetterURL,
      });
    }
  };

  return (
    <OverviewLayout>
      <Container
        className="tw-p-20"
        sx={{
          height: "100vh",
          width: "100%",
          maxWidth: "1300px",
          margin: 0,
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <NavigationBar breadcrumbs={breadcrumbs} />
        <Stack marginTop={4} rowGap={3}>
          <Typography fontSize={"20px"} fontWeight={"600"}>
            Upload Document Delivered BY HAND
          </Typography>

          {state.alert && (
            <Box className="lg:tw-mr-20">
              <Alert
                severity={state.alert?.severity}
                onClose={() => {
                  dispatch({ type: "alert", payload: null });
                }}
              >
                {state.alert?.message}
              </Alert>
            </Box>
          )}

          <Box className="tw-mt-8 lg:tw-mr-20">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              gap={2}
              alignItems={"flex-start"}
            >
              <InputLabel>Stamped copy of the letter</InputLabel>
              <form onSubmit={handleSubmit(onSubmitStampedLetter)}>
                <UploadRegistrationInput
                  register={register}
                  name={"letter"}
                  control={control}
                  success={state.stampedLetterUploaded}
                  error={errors?.letter?.message}
                  validations={{ required: "Required" }}
                />
              </form>
            </Stack>
          </Box>
          <Box display={"flex"} justifyContent={"center"} className="tw-mt-24">
            <DownloadButton
              label="Upload"
              loading={isLoading}
              onClick={handleFinalUpload}
            />
          </Box>
        </Stack>
      </Container>
    </OverviewLayout>
  );
};

export default UploadRegistrationByHand;
