import {
  Box,
  Collapse,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext } from "react";
import { FilterButton, SearchButton } from "../Buttons";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { ColorModeContext } from "../../constants/theme";
import {
  useDeleteIcArchivesMutation,
  useGetIcArchivesQuery,
} from "../../store/ic/icApi";
import { useGetLocationByOrgQuery } from "../../store/org/orgApi";
import { DataGrid } from "@mui/x-data-grid";
import debouce from "lodash.debounce";
import { GridColumns } from "../../utils/gridColumns";
import { GridActionsLayout } from "../Layouts";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Swal from "sweetalert2";

const IcArchivesCom = (props) => {
  const { mode } = useContext(ColorModeContext);
  const [locations, setLocations] = React.useState([]);
  const [searchText, setSearchText] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [params, setParams] = React.useState({});
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [rowCountState, setRowCountState] = React.useState();
  const [selectedMemeber, setSelectedMember] = React.useState("all");
  const [open, setOpen] = React.useState(false);

  const {
    data: icArchivesData,
    isFetching,
    refetch,
  } = useGetIcArchivesQuery({
    ...paginationModel,
    ...searchText,
    ...params,
    member: selectedMemeber,
    org_id: props.orgId,
  });
  const [deleteIcArchives, { isSuccess: deleteSuccess }] =
    useDeleteIcArchivesMutation();
  const { data: locationData } = useGetLocationByOrgQuery({
    id: props.orgId,
  });

  React.useEffect(() => {
    let temp = [];
    console.log(locationData?.location);
    if (locationData?.location) {
      temp = locationData.location.map((loc) => {
        return { name: loc.location_name, id: loc.id };
      });
    }
    setLocations(temp || []);
  }, [locationData]);

  const handleRemoveICLogs = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove IC Memeber?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteIcArchives(id);
      }
    });
  };

  const onSubmit = (data) => {
    console.log(data);
    const params = {
      location: data.location,
      from_date: moment(data.fromDate).format("YYYY-MM-DD HH:mm:ss.SSSZ"),
      to_date: moment(data.toDate).format("YYYY-MM-DD HH:mm:ss.SSSZ"),
    };
    console.log(params);
    setParams(params);
  };

  React.useEffect(() => {
    console.log(icArchivesData);
    let temp = [];
    if (icArchivesData?.iclogs) {
      temp = icArchivesData?.iclogs?.map((logs) => {
        return {
          id: logs?.id,
          Name: `${logs?.employee?.firstName}  ${logs?.employee?.lastName} `,
          Gender: logs?.employee?.gender,
          Email: logs?.employee?.email,
          "Designation in IC": logs?.designation_in_ic?.name,
          From: new Date(logs?.from_date).toLocaleDateString("en-GB"),
          To: new Date(logs?.to_date).toLocaleDateString("en-GB"),
        };
      });
    }
    setRows(temp || []);
  }, [icArchivesData]);
  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      icArchivesData?.count ? icArchivesData?.count : prevRowCountState,
    );
  }, [icArchivesData?.count, setRowCountState]);

  const { handleSubmit, control } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
    defaultValues: "initialValues",
  });

  const handleChange = (e) => {
    setSearchText({ like: e.target.value });
  };

  React.useEffect(() => {
    if (deleteSuccess) {
      Swal.fire({
        title: "Removed",
        text: "IC Logs has been removed",
        icon: "success",
        timer: 1500,
      });
      refetch();
    }
  }, [deleteSuccess]);

  const debouncedResults = React.useMemo(() => {
    return debouce(handleChange, 600);
  }, []);

  React.useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handeMemberChange = (e) => {
    setSelectedMember(e.target.value);
  };

  let columns = [];

  if (props.action === true) {
    columns = [
      ...new GridColumns()
        .name()
        .gender()
        .email()
        .designationInIC()
        .from()
        .to()
        .actions({
          renderCell: (params) => {
            return (
              <GridActionsLayout>
                <IconButton onClick={() => handleRemoveICLogs(params.row.id)}>
                  <CloseOutlinedIcon />
                </IconButton>
              </GridActionsLayout>
            );
          },
        })
        .getCols(),
    ];
  } else {
    columns = [
      ...new GridColumns()
        .name()
        .gender()
        .email()
        .designationInIC()
        .from()
        .to()
        .getCols(),
    ];
  }

  return (
    <Box
      display={"flex"}
      flex={1}
      flexDirection={"column"}
      gap={4}
      className={props.styles}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          padding={"26px"}
          bgcolor={mode === "dark" ? "#151515" : "#F9EFFF"}
          width={"100%"}
          className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-5"
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
          >
            <InputLabel>Location</InputLabel>
            <Controller
              name={"location"}
              control={control}
              defaultValue={"select"}
              render={({ field: { value, onChange, ref } }) => (
                <Select
                  sx={{ height: "40px", flex: 1 }}
                  className="tw-border-black  tw-rounded-lg"
                  value={value}
                  inputRef={ref}
                  onChange={onChange}
                >
                  <MenuItem value={"select"}>Select</MenuItem>
                  {locations?.map((loc) => (
                    <MenuItem key={loc.id} value={loc.id}>
                      {loc.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
          >
            <InputLabel>From</InputLabel>
            <Controller
              control={control}
              name="fromDate"
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  defaultValue={null}
                  className="tw-border-black  tw-rounded-lg"
                  sx={{ height: "60px", flex: 1 }}
                  onChange={onChange}
                  format="DD/MM/YYYY"
                  value={moment(value)}
                />
              )}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
          >
            <InputLabel>To</InputLabel>
            <Controller
              control={control}
              name="toDate"
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  defaultValue={null}
                  onChange={onChange}
                  format="DD/MM/YYYY"
                  sx={{ height: "60px", flex: 1 }}
                  // className="tw-bg-input-bg"
                  value={moment(value)}
                />
              )}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <SearchButton />
          </Box>
        </Box>
      </form>
      <Stack direction="column" gap={2}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <TextField
            type="text"
            name="search"
            onChange={debouncedResults}
            sx={{
              width: "302px",
              "& .MuiInputBase-root": {
                height: "32px",
              },
            }}
            placeholder="Search By Company Name"
          />
          <FilterButton
            buttonStyles={{ height: "32px" }}
            onClick={() => setOpen(!open)}
          />
        </Stack>
        <Collapse in={open} timeout="auto" unmountOnExit width="100%">
          <RadioGroup
            row
            name="status"
            value={selectedMemeber}
            onChange={handeMemberChange}
            sx={{ justifyContent: "flex-end" }}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel
              value="Presiding Officer"
              control={<Radio />}
              label="Presiding Officer"
            />
            <FormControlLabel
              value="Member"
              control={<Radio />}
              label="Member"
            />
            <FormControlLabel
              value="External Member"
              control={<Radio />}
              label="External Member"
            />
          </RadioGroup>
        </Collapse>
      </Stack>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isFetching}
        disableRowSelectionOnClick
        pageSizeOptions={[5]}
        rowCount={rowCountState}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            background: `${mode === "dark" ? " #181818" : "#F9EFFF"}`,
          },
          "& .MuiDataGrid-toolbarContainer": {
            paddingY: 2,
            paddingX: 1,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
            backgroundColor: "transparent",
            color: "#4B4B4B",
          },
        }}
      />
    </Box>
  );
};

export default IcArchivesCom;
