import React, { useContext } from "react";
import { Box, Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ColorModeContext } from "../../constants/theme";

function BreadCrumb({ breadcrumbs }) {
  const { mode } = useContext(ColorModeContext);
  return (
    <Box
      className="tw-flex-1 tw-rounded-lg tw-py-3 tw-px-8"
      sx={{
        background: `${mode === "dark" ? "#1A1A1A" : "#FFFFFF"}`,
        boxShadow: "0px 5px 20px rgba(85, 85, 85, 0.14)",
      }}
    >
      <Breadcrumbs
        sx={{
          display: "flex",
          height: "100%",
        }}
        separator={<NavigateNextIcon fontSize="small" />}
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Box>
  );
}

export default BreadCrumb;
