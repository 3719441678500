import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function KeyDefinitions({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{serialNo}. Key Definitions</Text>
      <Text style={styles.text}>
        For the purposes of this Policy, the following terms shall have the
        meaning indicated below
      </Text>

      {/* Sexual Harassment definition */}
      <Text style={styles.subHeading}>a. Sexual Harassment</Text>
      <View style={styles.subContainer}>
        <Text style={styles.text}>
          “Sexual Harassment” includes any one or more of the following
          unwelcome acts or behavior (whether directly or by implication)
          namely:
        </Text>
        <Text style={styles.point}>i. physical contact and advances; or</Text>
        <Text style={styles.point}>
          ii. a demand or request for sexual favours; or
        </Text>
        <Text style={styles.point}>
          iii. making sexually coloured remarks; or
        </Text>
        <Text style={styles.point}>iv. showing pornography; or </Text>
        <Text style={styles.point}>
          v. any other unwelcome physical, verbal or non-verbal conduct of
          sexual nature
        </Text>
      </View>
      <View style={styles.subContainer}>
        <Text style={styles.text}>
          These circumstances, among other circumstances, if it occurs or if it
          is present in relation to or connected with any act of sexual
          harassment, may amount to sexual harassment:
        </Text>
        <Text style={styles.point}>
          a. When either implicitly or explicitly it becomes a condition of the
          individuals present or future employment
        </Text>
        <Text style={styles.point}>
          b. Implied or explicit promise of preferential treatment in employment
        </Text>
        <Text style={styles.point}>
          c. Implied or explicit threat of detrimental treatment in employment.
        </Text>
        <Text style={styles.point}>
          d. The harassment has a purpose or effect of unreasonably interfering
          with the work performance or creating an environment, which is
          intimidating, hostile or offensive
        </Text>
        <Text style={styles.point}>
          e. Humiliating treatment likely to affect health or safety
        </Text>
      </View>
      <Text style={styles.text}>
        In all the above cases, everyone must exercise their own good judgment
        to avoid engaging in conduct that may be perceived by others as Sexual
        Harassment. In all the above cases, it is not the intention of the
        person behaving but the impact on the person who is subjected to such
        behavior that will matter.
      </Text>

      {/* Employee definition */}
      <Text style={styles.subHeading}>b. Employee</Text>
      <Text style={styles.text}>
        A person employed at a workplace for any work on regular, temporary, ad
        hoc or daily wage basis, either directly or through an agent, including
        a contractor, with or, without the knowledge of the principal employer,
        whether for remuneration or not, or working on a voluntary basis or
        otherwise, whether the terms of employment are express or implied and
        includes a co-worker, a contract worker, probationer, trainee,
        apprentice or called by any other such name. This definition of
        ‘Employee’ shall be used only for the purposes of this policy and cannot
        be used to claim rights of an employee conferred under any other law for
        the time being in force.
      </Text>

      {/* Workplace */}
      <Text style={styles.subHeading}>c. Workplace</Text>
      <Text style={styles.text}>
        In addition to the place of work (office, branch, yard etc.), it covers
        any other Organization premises (including transit houses and guest
        houses) and any place visited by the employee in connection with work
        during the course of employment or arising out of employment with the
        Organization, including travel/transportation provided by the employer
        for undertaking such a journey.
      </Text>

      {/* Aggrieved woman / Complainant */}
      <Text style={styles.subHeading}>d. Aggrieved person / Complainant</Text>
      <Text style={styles.text}>
        In relation to a workplace, a person, of any age, whether employed or
        not, who alleges to have been subjected to any act of sexual harassment
        by the respondent and includes contractual, temporary, visitors.
      </Text>
      <Text style={styles.text}>
        In case, anyone else has to file a complaint on behalf of the Aggrieved
        Person as per Law, such person shall be referred to as the
        “Complainant.” Any reference to the term ‘Complainant’ throughout this
        Policy shall mean reference to ‘Aggrieved Person and / or the
        ‘Complainant’ as applicable.
      </Text>

      {/* Respondent */}
      <Text style={styles.subHeading}>e. Respondent</Text>
      <Text style={styles.text}>
        Employees against whom a complaint has been made by the Complainant.
      </Text>
    </View>
  );
}

export default KeyDefinitions;
