export const whatIsSexualHarassment = {
  acts: {
    objective:
      "It is an unwelcome inappropriate behaviour (whether directly or by implication) which includes:",
    points: [
      `Physical contact and advances including touching, deliberately brushing against
    another person, interference with an individual’s normal work movement or assault.`,
      `Demand or requests for sexual favours, offensive or unwelcome flirtations,
    unwanted sexual advances or propositions, threats or suggestive or insulting
    sounds.`,
      `Making sexually coloured remarks containing sexual tones/undertones including
    repeated sexual innuendoes, sexual epithets, derogatory slurs, sexually explicit
    jokes.`,
      `Showing pornography such as derogatory or sexually explicit posters, cartoons,
    photographs, magazines, drawings, or other printed items; suggestive objects,
    pictures; emails, screen savers; or other electronic communications; graphic
    commentaries and leering or obscene gestures.`,
      `The inappropriate behaviours listed above may be committed either verbally or in
    written form via letters, video calls/audio calls, phone calls, texts, e-mails, , on any
    social media platform or through any other electronic communication. `,
      `Having posters, photographs, paintings, etc in the background with any sexual
    context while being on video calls`,
      `Any other unwelcome visual, verbal, or physical conduct of a sexual nature.`,
    ],
  },
  circumstances: {
    objective: `These circumstances, among other circumstances, if it occurs or if it is present in relation
    to or connected with any act of sexual harassment, may amount to sexual harassment:`,
    points: [
      `When either implicitly or explicitly it becomes a condition of the individuals present
      or future employment`,
      `Implied or explicit promise of preferential treatment in employment`,
      `Implied or explicit threat of detrimental treatment in employment.`,
      `The harassment has a purpose or effect of unreasonably interfering with the work
      performance or creating an environment, which is intimidating, hostile or offensive`,
      `Humiliating treatment likely to affect health or safety `,
    ],
  },
  conclusion: `Everyone must exercise their own good judgment to avoid engaging in conduct that may be
  perceived by others as Sexual Harassment. In all the above cases, it is not the intention of
  the person behaving but the impact on the woman who is subjected to such behavior that
  will matter.`,
};

export const whatIsWorkPlace = {
  objective: `“Workplace” shall mean all places where Organization’s work is carried out. It does not 
  only include physical workspaces but also spaces from where work is carried out remotely 
  or virtually. For example:`,
  points: [
    `All offices or other premises where Organization’s business is conducted`,
    `All Organization related activities performed at any physical or virtual site `,
    `Any social, business or other functions taking place physically or virtually where 
    the conduct or comments may have an adverse impact on the workplace or 
    workplace relations `,
    `Any alleged act of sexual harassment committed during or outside of office hours 
    using any mode of communication including video calls/audio calls, phone calls, 
    text, e-mails, , on any social media platform or through any other electronic 
    communication etc..`,
    `Any sexual harassment on any social networking website during or outside of office 
    hours`,
    `Any place visited by Employee or arising out of or during the course of employment 
    with Organization, including transport services provided by Organization for 
    undertaking such journey, audio/video conferencing applications or any other 
    communication related tools/applications.. For example, Employees who are on 
    overseas programs and trainings in course of their employment with the 
    Organization.`,
  ],
};

export const whoIsAnEmployee = [
  `“Employee” means a person employed at a workplace for any work on regular, temporary,
  ad hoc or daily wage basis, either directly or through an agent, including a contractor, with
  or, without the knowledge of the principal employer, whether for remuneration or not, or
  working on a voluntary basis or otherwise, whether the terms of employment are express
  or implied and includes a co-worker, a contract worker, probationer, trainee, apprentice or
  called by any other such name.`,
];

export const whoIsVisitor = [
  `“Visitor” or “Third Party” includes any person who is visiting the Workplace and is not
covered by any of the other categories defined by this Policy. For example, guests coming for physical/online interviews, meetings, events, programs or people walking in for
meeting existing Employees, etc.`,
];

export const SHConcernsRedressal = {
  objective: `Organization has constituted an Internal Committee (“IC”) for receiving and redressing
  complaints related to Sexual Harassment at Workplace.`,
  constitutionOfIC: {
    heading: "Constitution of the IC:",
    objective: "At all times, the IC shall comprise of the following:",
    points: [
      `A senior level woman Employee shall be appointed as Presiding Officer of the IC
      (“Presiding Officer”). The Presiding Officer shall automatically vacate her office
      upon ceasing to be Employee of Organization.`,
      `Minimum two (2) members of the IC will be appointed from amongst Employees who
      are preferably committed to the cause of women or who have had experience in
      social work or have legal knowledge in this field. These members shall automatically
      vacate office upon ceasing to be Employee of Organization.`,
      `Minimum one (1) member from amongst non-governmental organizations or
      associations committed to the cause of women or a person familiar with the issues
      relating to Sexual Harassment. This member shall vacate office upon termination of
      contract with Organization.`,
      `At least one-half of the total members nominated to the IC shall be women.`,
    ],
    conclusion: `All IC members are duty bound to handle all complaints without any bias or partiality towards
    any party. The term for the IC is a maximum of 3 years.`,
  },
  removalOfICMember: {
    heading: "Removal Of IC Member",
    objective: `An IC member shall be removed / replaced in the event that the IC member:`,
    points: [
      `breaches confidentiality`,
      `has been convicted for an offence or an inquiry into an offence under any law for the
      time being in force is pending against them`,
      `has been found guilty in any disciplinary proceedings or a disciplinary proceeding is
      pending against them`,
      `has abused their position as to render their continuance in office prejudicial to the
      public interest`,
      `Any vacancy created in the IC due to cessation of employment, resignation, death,
      disability or removal, as applicable, shall be filled by a fresh nomination by
      Organization in accordance with its guidelines and in conjunction with provision of
      Law (where applicable).`,
    ],
  },
};

export const whoCanFileComplaint = [
  `Any woman of any age whether employed or not, who alleges to have been subjected to
any act of sexual harassment by the respondent (“Aggrieved Person”) and the term
“Respondent” shall refer to the person (i.e. the Employee of the Organization as defined
above) against whom the complaint of sexual harassment has been filed.`,
];

export const restrictedVictim = {
  section1: {
    objective: `If the Aggrieved Person is unable to make a complaint on account of their physical
    incapacity, a complaint may be filed, with the written consent of the Aggrieved Person by:`,
    points: [
      `their relative or friend; or`,
      `their co-worker; or`,
      `an officer of the National Commission for Women or State Women’s Commission;
      or`,
      `any person who has knowledge of the incident`,
    ],
  },
  section2: {
    objective: `If the Aggrieved Person is unable to make a complaint on account of mental incapacity, a
    complaint may be filed by:`,
    points: [
      `their relative or friend; or`,
      `a special educator; or`,
      `a qualified psychiatrist or psychologist; or`,
      `the guardian or authority under whose care they are receiving treatment or care; or`,
      `any person who has knowledge of the incident jointly with the Aggrieved Person’s
      relative or friend or a special educator or qualified psychiatrist or psychologist, or
      guardian or authority under whose care they are receiving treatment or care.`,
    ],
  },
  conclusion: `If the Aggrieved Person for any other reason is unable to make a complaint, a complaint
    may be filed by any person who has knowledge of the incident, with their written consent. If
    the Aggrieved Person is deceased, a complaint may be filed by any person who has
    knowledge of the incident, with the written consent of their legal heir.
    Any of the persons referred to above, who may file a complaint on behalf of the Aggrieved
    Person shall be referred to as the “Complainant.” Any reference to the term ‘Complainant’
    throughout this Policy shall mean reference to ‘Aggrieved Person’ and / or the ‘Complainant’
    as applicable.`,
};

export const howToFileComplaint = {
  objective: `The Complainant can make a complaint of Sexual Harassment to the IC. The following need
  to be kept in mind:`,
  points: [
    "The complaint has to be in writing",
    `The Complainant should provide (if possible) as many specific details as possible
    including incidents, dates and people that are involved in their complaint. Details
    with respect to the following may be provided while filing a complaint:`,
    [
      `The alleged event, matter or issue that is subject to the complaint
      (time/date/place etc.)`,
      `The name and details of the Respondent against whom the complaint is
      being filed`,
      `Names and addresses of the witnesses (if any)`,
      `Details of documentary evidence (if any) such as WhatsApp chat records,
      SMS, E-mails, CCTV Footage, Audio / Video recording etc. and`,
      `Any additional information, documentation or other evidence available to
      support the complaint (if any)`,
    ],
    `Complaint must be made within a period of 3 (three) months from the date of
    incident and in case of a series of incidents, within a period of 3 (three) months
    from the date of last incident.`,
    `The IC, at its discretion, can extend the time limit for receiving a complaint to another
    period of 3 (three) months if it is satisfied that the circumstances were such which
    prevented the Complainant from filing a complaint within the first 3 (three) months.`,
    `IC will record the reasons for such extension of 3 (three) months in writing`,
  ],
  conclusion: `If the Complainant needs assistance in writing the complaint, they can seek help from IC.
  The complaint can be submitted electronically at ______@____.com to the IC or with any
  of the IC members (details provided in ANNEXURE 1). Complaints can also be physically submitted at __________________ with any of the IC members (`,
};

export const externalComplainant = {
  objective: `If any Employee/Third Party is sexually harassed by any external party who is not an
  Employee of the Organization, either in Organization premises or in that external party’s
  premises, then IC shall contact either the internal committee of that external party and / or
  the Police (as the case may be), on the written complaint of such Employee/Third Party to
  the Organization’s IC and on their request that such complaint be transferred to other IC
  and address the matter to satisfactory closure. Alternatively, Employee/Third Party may
  choose to reach out directly to IC of that external party and / or Police (as the case may be)
  and Organization will offer assistance on written request by Employee/Third Party.`,
  image:
    "https://turnkey-equihawk.s3.ap-south-1.amazonaws.com/PolicyImages/Report.png",
};

export const procedureForHandlingComplaints = {
  section1: {
    heading: "Process for Conciliation:",
    objective: `Prior to initiating an inquiry, the IC may, at the written request of the Complainant take steps
    to settle the matter between the Complainant and the Respondent through conciliation. The
    following need to be kept in mind with respect to conciliation:`,
    points: [
      `It is not mandatory for Complainant to request for Conciliation.`,
      `Conciliation cannot be done if inquiry has already started.`,
      `In case a settlement has been arrived at, the IC shall record it and forward it to
      Organization to take action as specified in the recommendation of the IC.`,
      `The IC will also provide copies of the settlement as recorded to the Complainant and
      the Respondent.`,
      `If conciliation has been reached, the IC will not be required to conduct any further
      inquiry.`,
      `Monetary settlement cannot be made the basis of such conciliation.`,
      `If Complainant feels that the terms of Settlement are not being complied with by the
      Respondent or action has not been taken by Organization, Complainant can make
      a written complaint to the IC to conduct an inquiry into the complaint.`,
    ],
  },
  section2: {
    heading: "Procedure for Inquiry:",
    objective: `If the Complainant does not wish to go for conciliation or if the conciliation reached between
    the parties is not complied with, the IC shall, upon a written request from the Complainant
    to do so, proceed to make inquiry into the complaint in the manner as provided under the
    Service Rules and Law, as may be applicable to the Respondent and if no service rules are
    applicable, then as per the process prescribed under this Policy and the Law. The following
    need to be kept in mind with respect to inquiry:`,
    points: [
      `On receipt of the complaint, the IC shall send copy of the complaint received from
      the Complainant to the Respondent within a period of 7 (seven) working days. If
      the complaint has been received on email, the IC member shall forward the same
      to the Respondent, with clear instructions that the same is not to be shared with
      anyone and strict confidentiality has to be maintained.`,
      `The Respondent shall file the reply to the complaint along with list of documents,
      and names and addresses of witnesses (if any), within a period of 10 (ten) working
      days from the date of receipt of the documents.`,
      `If the Complainant or the Respondent desires any witness/es to be called, they shall
      communicate in writing to IC the names of witness/es whom they propose to call.`,
      `If the Complainant desires to tender any documents by way of evidence before IC,
      they shall supply copies of such documents. Similarly, if Respondent desires to
      tender any documents in evidence before IC they shall supply copies of such
      documents. Both shall affix their signature on the respective documents to certify
      these to be authentic documents.`,
      `The IC shall have the powers to summon and enforce the attendance of any person
      and conduct an examination, request the discovery and production of documents
      and / or any other matter which may be necessary for the inquiry process. Any
      refusal by any Employee of Organization to attend any hearing before the IC when
      summoned or to provide to the IC, any documents and / or information within their
      power or possession shall constitute as misconduct, rendering such Employee for
      adverse action by Organization.`,
      `The IC shall have the right to terminate the inquiry proceedings or to give an exparte decision on the complaint, if the Complainant or Respondent fails, without
      sufficient cause, to present themselves, for 3 (three) consecutive hearings convened
      by the Presiding Officer. Such termination or ex-parte order may not be passed
      without giving a notice in writing, 15 (fifteen) days in advance, to the Complainant or
      Respondent as the case maybe.`,
      `In conducting the inquiry, at any point of time, the Complaint shall be heard by a
      minimum of 3 (three) members including the Presiding Officer and the External
      Member and IC shall complete the inquiry within reasonable period but not beyond
      90 (ninety) days.`,
      `IC shall notify in writing, the time and dates of its meetings to the Complainant, the
      Respondent and witnesses.`,
      `All inquiry proceedings will be documented in detail and treated as strictly
      confidential with restricted viewing only on need to know basis.`,
      `Inquiry proceedings shall be minuted / recorded in writing. They may be audio
      recorded as well (where possible). The minutes / transcribed record of the
      proceedings and the statement of witnesses shall be endorsed by the persons
      concerned and IC in token of authenticity thereof.`,
      `All colleagues and witnesses who are part of the IC proceedings will need to sign a
      confidentiality declaration.`,
      `The Complainant and Respondent shall not be allowed to bring in any legal
      practitioner to represent them in their case at any stage of the proceedings before
      the IC.`,
    ],
  },
  section3: {
    heading:
      "The things IC shall keep in mind while inquiring into a complaint:",
    points: [
      `The IC shall make an inquiry into the complaint in accordance with the principles of
      natural justice such that IC shall provide every reasonable and fair opportunity to the
      Complainant as well as to the Respondent, for putting forward and defending their
      respective side.`,
      `The IC shall be sensitive to the often covert, private and insidious nature of sexual
      harassment and shall take into account that often the Complainant may not be able
      to submit or present direct or corroborative evidence.`,
      `The IC shall take note of the respective socio-economic positions of the parties, their
      hierarchy in the Workplace, and other power differentials while giving
      recommendation.`,
      `While inquiring into a complaint, IC shall take note that in cases of harassment via
      postings and messages through electronic and social media, the IC shall consider
      the public humiliation that this involves for the Complainant as well as the unwitting
      exposure of others who are using this media to circulate such messages and
      images.`,
    ],
  },
};

export const actionDuringPendencyOfInquiry = {
  objective: `During the pendency of an inquiry, on a written request made by the Complainant, the IC
  may recommend any of the following to Organization:`,
  section1: {
    heading: `In case Complainant and/or Respondent are Employees, any one or more of the
    following may be recommended:`,
    points: [
      `Grant leave to the Complainant up to a period of 3 (three) months (the leave granted shall be in addition to the leave the Complainant is otherwise entitled to)`,
      `Transfer the Complainant or Respondent to any other workplace`,
      `Restrain the Respondent from reporting on the work performance of the
      Complainant or writing their confidential report and assign the same to another
      Employee (if applicable)`,
      `In case the complaint has been filed by an intern or an apprentice working under the
      Respondent, restrain the Respondent from supervising any official / academic
      activity of the Complainant and assign the same to another Employee.`,
      `Direct the Respondent to not communicate with the Complainant in any manner
      including any mode of electronic communication such as text messages, emails, ,
      video/audio calls, on any social media platform or via any other mode of electronic
      communication.`,
      `In case complaint is filed by a Complainant, the same benefits as above would apply,
      depending on facts and circumstances.`,
    ],
    conclusion: `The Organization reserves the right to require any of the parties to take any leaves during
    pendency of inquiry any time after receipt of a complaint of sexual harassment until the IC has
    concluded its inquiry, if required, based on recommendation of IC. Any such decision will be
    communicated in writing to the party by the Organization. This is to ensure that there is no
    retaliation against any party. The Organization reserves the right to require parties to work
    from home or from an alternate Organization location during the pendency of inquiry.`,
  },
  "In case Respondent is a Visitor / Third Party, any one or more of the following may be recommended:": `If the Respondent is a Visitor / Third Party adequate steps to ensure
  that they do not enter Organization premises or contact/threaten Complainant/Witnesses
  directly or indirectly using any mode of communication. IC to suggest appropriate disciplinary action by the concerned authority including reaching out to the Police if required
  with consent of Complainant. `,
};

export const whatIsInquiryReport = {
  objective: `On completion of inquiry, the IC shall provide a written report of its findings and
  recommendations to Organization within 10 (ten) days from date of completion of the
  inquiry. Such report shall also be made available to Complainant and Respondent. The
  inquiry report shall specify details of the allegations against the Respondent, the statements
  made and evidence presented by the Complainant, Respondent and / or witnesses, IC’s
  findings along with a statement giving reasons for the findings arrived at by the IC and IC’s
  recommendations.`,
  section1: {
    heading: `In case allegations against Respondent have not been proven:`,
    objective: `In the event that the IC arrives at the conclusion that the allegation against the Respondent
    has not been proved, it shall recommend to Organization that no action is required to be
    taken in the matter.`,
  },
  section2: {
    heading: "In case allegations against Respondent have been proven:",
    objective: `Where the IC determines that sexual harassment has taken place, to whatever extent,
    immediate corrective and preventive measures will be recommended. Severity of
    recommendation will be determined by the first time/ misunderstanding/ habitual offender
    etc.`,
    section1: {
      heading: `i.  Where Respondent is an Employee, IC may recommend any one or more of the
      following:`,
      section1: {
        objective: `Where the Committee arrives at the conclusion that the allegation against the Respondent
        has been proved, where Respondent is an Employee it will recommend to the Company:`,
        points: [
          `To take action for sexual harassment as a misconduct as per Organization Policy.`,
          `To take action as per the service rules applicable to the Respondent and where no
          service rules are applicable to take any action including:`,
          [
            `A written apology from the Respondent`,
            `A letter of warning may be given to the Respondent that will be placed in the
            personnel file`,
            `Reprimand or censure the Respondent`,
            `Withholding the promotion or reduction in rank`,
            `Withholding of pay rise or increments`,
            `Immediate transfer or suspension without pay`,
            `Termination from service / dismissal from the services of Organization`,
            `Undergoing a counselling session`,
            `Carrying out community service`,
            `deduction from salary or wages of the Respondent such sum as it may consider
            appropriate to be paid to the Complainant or to their legal heirs. In case,
            Organization is unable to make deductions from salary of the Respondent due to
            them being absent from duty or cessation of employment, it may direct the
            Respondent to pay such sum to the Complainant. In case the Respondent fails to
            pay the sum, the IC may forward the order for recovery of the sum as an arrear of
            land revenue to the concerned district officer.`,
          ],
        ],
      },
      section2: {
        objective: `IC may consider various factors as required under Law for the purpose of determining the
        sums to be paid to the Complainant and may take the following into consideration:`,
        points: [
          `the mental trauma, pain, suffering and emotional distress caused to the Complainant`,
          `the loss in the career opportunity due to the incident of sexual harassment`,
          `medical expenses incurred by the victim for physical or psychiatric treatment`,
          `the income and financial status of the Respondent`,
          `feasibility of such payment in lump sum or in instalments`,
        ],
      },
    },
    section2: {
      heading: `Where the Respondent is a Third Party, IC may recommend any one or more of the
      following:`,
      points: [
        `Warning to employing Organization/association/firm (if any)`,
        `Declaring the Workplace/Organization out of bounds to the Respondent`,
        `Withdrawal of the right to provide services to Organization for a certain period (if
          applicable)`,
        `Helping the Complainant in filing an FIR, should they desire`,
        `Debarring/blacklisting such person from Organization`,
        `Other similar or appropriate punishment`,
      ],
      conclusion: `Organization shall act upon the recommendation given by IC within 60 (sixty) days of
      receipt of the recommendation.`,
    },
  },
  section3: {
    heading: `In case it is proven that the Complaint is malicious:`,
    points: [
      `If on inquiry, it is revealed that the complaint was made with a malicious intent and
      with the motive of maligning the Respondent / tarnishing their image in Organization
      or otherwise and/or to settle personal / professional scores, strict action will be
      recommended by IC against the Complainant. Hence, in case IC arrives at a
      conclusion that allegation against the Respondent is malicious or Complainant has
      made the complaint knowing it to be false or has produced a forged or misleading
      document, IC may recommend to Organization to take an action applicable to
      Respondent if the complaint was genuine. In this regard, IC may recommend any of
      the actions specified above (As applicable).`,
      `A mere inability to substantiate a complaint or provide adequate proof need not
      attract action against the Complainant as the malicious intent on the part of the
      Complainant will have to be established through the inquiry process before an action
      against such Complainant is recommended.`,
    ],
  },
};

export const procedureForFilingAppeal = [
  `Any party aggrieved (Complainant or Respondent) by the recommendations of the IC and /
  or non-implementation of recommendations may file an appeal to the Appellate Authority
  as per service rules and/or as per other provisions of Law within 90 (ninety) days of the
  recommendations.`,
];

export const confidentiality = [
  `All discussions / decisions pertaining to an incident of sexual harassment must be kept
  confidential. Therefore, contents of the complaint made, the identity and addresses of the
  Complainant, Respondent and witnesses, any information relating to conciliation and inquiry
  proceedings, inquiry report including recommendations of the IC and action taken on the
  Respondent shall not be published, communicated or made known to the public, press and
  media in any manner. However, information may be disseminated regarding the justice
  secured to any victim without disclosing the name, address, identity or any other particulars
  calculated to lead to the identification of the Complainant and witnesses.`,
  `Appropriate information will be shared with Government authorities as per the Act. If any
  person breaches confidentiality, they shall be liable for penalty as per service rules and / or
  Law. Organization shall recover a sum of rupees five thousand (5,000) as penalty from such
  person and may take any other action as it may deem fit.`,
];

export const protectionAgainstRetaliation = [
  `There will be no retaliation against anyone who, in good faith, files a complaint or
  participates in any way in the inquiry of a complaint. Organization will not allow retaliation
  against that person. Individuals engaging in retaliatory conduct will be subject to strict
  disciplinary action and if necessary legal action and/or a police complaint. If you suspect
  that you or someone you know has been retaliated against for raising an issue, immediately
  contact the IC.`,
];

export const filingOfComplaintAgainstPolice = [
  `Nothing in these rules and procedures shall preclude anybody from simultaneously lodging a
  complaint with the police in respect of any act amounting to an offence under the law.
  Organization will provide assistance in this regard, if assistance is sought. This Policy is not
  intended to impair or limit the right of any Employee seeking a remedy available under any law.
  Proceedings under this Policy shall continue notwithstanding any proceedings initiated by a
  Complainant (as defined hereinafter) against Respondent under any law in force.`,
  `In case of a complaint of sexual harassment or any other offense against a child, as per the
  Protection of Children from Sexual Offences Act, 2012 (“POCSO”), or in case of offense by
  a child, the provisions under POCSO and Juvenile Justice (Care and Protection of Children)
  Act, 2015 may become applicable and would have to be dealt with in accordance with these
  laws. A child is any person below the age of eighteen years.`,
];

export const availabilityOfMentalHealthProfessionals = [
  `The Organization shall provide assistance, such as availing services of a mental health
  professional, to any of the concerned parties on request as may be required from time to time.`,
];

export const responsibilitiesOfEmployees = {
  points: [
    `Everyone has a personal responsibility to ensure that their behaviour is not contrary
    to this Policy.`,
    `Set an example of dignified workplace behaviour and ethical standards in line with
    Organization’s values and code of conduct.`,
    `To be mindful of professional and personal boundaries while performing your duties
    for the organization and representing the organization.`,
    `Create and sustain a healthy work environment.`,
    `Do not ignore or allow an inappropriate behaviour to continue regardless of who is
    creating that situation.`,
    `Support and cooperate during any inquiry of a complaint, including testifying as
    witness and giving full and truthful disclosure of all factual information when asked
    by the IC, while ensuring that complete confidentiality is maintained throughout,
    about any complaint of sexual harassment within Organization.`,
    `Be aware that Organization will take allegations seriously and will ask their
    cooperation in an inquiry if they bring a complaint forward.`,
    `Handle information related to known or suspected violations of this Policy in a
    discreet and confidential manner and not attempt to investigate / inquire into the
    information or suspected violations of this Policy on your own i.e. without involving
    the IC.`,
    `Report any inappropriate behavior to IC immediately.`,
    `Do not misuse the policy or the law by filing malicious complaints for settling
    personal scores, seeking revenge or for personal gains and benefits etc.`,
    `To remember that on social media, the employees represent the Organization and
    therefore to ensure that they post information/comment/opinion that is responsible,
    professional and protect the interests of the Organization.`,
    `With respect to virtual working:`,
    [
      `Ensure that video/audio calls are scheduled during working hours as much as
      possible.`,
      `State the preferred modality (video or audio) while scheduling the meeting/
      blocking other person’s calendar & allow for flexibility for the individual to
      confirm`,
      `Seek consent before requesting for a video call for a one on one conversation`,
      `Do not insist on video calls where not necessary, and/or if your colleague/s
      expresses difficulty in joining via video call.`,
      `While on video/audio calls, be mindful of the professional boundaries, and do
      not initiate conversations/jokes that could make others uncomfortable.`,
      `While on video calls, ensure that a professional dress code is followed, and
      the background does not have anything inappropriate.`,
      `Do not share/forward inappropriate or offensive posts or images on team chat
      or social media, etc`,
      `Do not forward any personal message, image, or video sent to you without
      sender’s consent`,
      `Do not repeatedly send messages to engage in personal discussions,
      comment, like on posts, pictures etc. in absence of any positive, enthusiastic
      response`,
      `Do not repetitively ask any employee to accept your friend/follow request on
      any social media platform`,
      `Do not engage in online bullying, stalking or harassment.`,
    ],
  ],
};

export const responsibilitiesOfIC = {
  points: [
    `Receive and address complaints of sexual harassment and inquire into complaints
    in detail`,
    `Assist Complainant in filing the complaint, in case the Complainant is unable to do
    so.`,
    `Inquiry shall be a priority for an IC member.`,
    `Ensure quorum of minimum three (3) members is met during all Inquiry meetings,
    with mandatory presence of the Presiding officer at all inquiry meetings.`,
    `Seeking consent from involved parties and witnesses for audio/video recording of the
    inquiry proceedings.`,
    `Follow the principles of natural justice and treat the Complainant, Respondent,
    witnesses and related persons to the inquiry with dignity and respect and give both
    the Complainant and the Respondent a fair opportunity to make their submissions.
    All members of the IC shall be acting without any prejudice or bias while conducting
    inquiries or dealing with complaints of sexual harassment in any other manner.`,
    `Make efforts to ensure that the Complainants and the witnesses are not further
    victimized or discriminated against while the complaint is pending.`,
    `Recuse themselves from their assigned job for the purpose of conducting an Inquiry
    if there is conflict of interest or a bias coming in the way of conducting a fair Inquiry.`,
    `Take appropriate measures to ensure confidentiality of the inquiry process while
    conducting inquiry physically or virtually.`,
    `Document in detail all the inquiry proceedings and the inquiry report.`,
    `Refrain from talking about a potential, pending or completed case of sexual
    harassment to any member directly or indirectly connected to any form of media,
    including print, Television, online media etc.`,
    `Review the status of all complaints received.`,
    `Recommend action against anyone who threatens or intimidates the Complainant
    or members of the IC.`,
    `Read and acquaint themselves with the Policy and attend trainings on this subject
    matter.`,
    `Compile annual reports and submit to the concerned authorities.`,
    `In the event, the complaint does not fall under the purview of sexual harassment or
    the complaint does not mean an offence of sexual harassment, forward the same to
    the concerned person / committee.`,
  ],
};

export const responsibilitiesOfOrganization = {
  points: [
    `Organization will not tolerate any act of sexual harassment. It shall treat sexual
    harassment as ‘Misconduct’ and take prompt, appropriate action for the same.`,
    ` It will organize gender-sensitization workshops and awareness programs at regular
    intervals covering all locations.`,
    `Shall raise awareness about the prevention of sexual harassment through posters,
    calendars, mugs and other mediums`,
    `To sensitize managers to conduct calls/meetings during working hours as much as
    possible, and to not insist on any employee/team member to attend video calls,
    where not necessary and/or if they express difficulty in joining via video call.`,
    `It will ensure that necessary facilities and information are provided to the IC including
    technical support for dealing with the complaint and conducting an inquiry.`,
    `Assist in securing the attendance of Respondent and witnesses before the IC and
    make available such information to the IC as it may require with regards to the
    complaint.`,
    `Provide assistance to the IC in any manner feasible to conduct a fair and expeditious
    inquiry and implement recommendations made by IC.`,
    `Provide assistance to the Complainant if the Complainant chooses to file a complaint
    in relation to the offence under the Indian Penal Code. Cause to initiate action, under
    the Indian Penal Code, against Respondent or if the Complainant so desires, where
    the Respondent is not an Employee, in the workplace at which the incident of sexual
    harassment took place.`,
    `Cause to initiate action, under the Indian Penal Code, against the Respondent, or if
    the Complainant so desires, where the Respondent is not an employee, in the
    workplace at which the incident of sexual harassment took place.`,
    `Offer counselling sessions to anyone impacted by incident of sexual harassment.`,
    `Do all other things it may deem fit for successful implementation of this Policy.`,
  ],
};

export const amendments = [
  `Organization reserves the right to amend the Policy from time to time in order to comply
  with any laws / rules / regulations that come into effect from time to time, related to sexual
  harassment at workplace. This Policy shall be reviewed bi-annually or as and when key
  issues get raised to incorporate amendments.`,
  `This Policy does not intend to create any contractual obligation for or with Organization, and
  in case of dispute with the Law and the Policy, the Law shall prevail.`,
];
