import { Paper } from "@mui/material";
import React, { useContext } from "react";
import { ColorModeContext } from "../../constants/theme";

function OverviewLayout({ children }) {
  const { mode } = useContext(ColorModeContext);
  return (
    <Paper
      className="tw-w-full tw-p-4 sm:tw-p-7 lg:tw-p-12 tw-rounded-lg tw-border tw-border-[#EDEDED]"
      sx={{
        background: `${mode === "dark" ? "#0D0D0D" : " #fff"}`,
        height: "100%",
        width: "100%",
      }}
    >
      {children}
    </Paper>
  );
}

export default OverviewLayout;
