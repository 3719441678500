import { Document, Font, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import Roboto from "../../../../../src/assets/font/Roboto-Bold.ttf";
import MumbaiRegistrationTable from "../MumbaiCity/MumbaiRegistrationTable";
import { RegistrationStyles } from "../../PDFStyling/Registration/RegistrationStyle";

Font.register({
  family: "MyFont",
  fonts: [{ src: Roboto, fontWeight: "bold" }],
});

const MumbaiSuburbanLetterDoc = ({ ICEmployee }) => {
  return (
    <Document>
      <Page style={RegistrationStyles.body}>
        <Text style={RegistrationStyles.title}>
          ON LETTERHEAD OF THE ORGANIZATION
        </Text>
        <View style={RegistrationStyles.container}>
          <Text style={RegistrationStyles.address}>
            To,{"\n"} District Officer and Assistant District Officer, {"\n"}
            10th Floor, Administrative Building, Near Chetna College, {"\n"}
            Government Colony, Bandra East, Mumbai,
            {"\n"}Maharashtra 400051{"\n"}
          </Text>
          <Text style={RegistrationStyles.name}>Respected Madam/Sir</Text>
          <Text style={RegistrationStyles.text}>
            Please find below the details of our Internal committee (I.C)
            members of our organization situated in Mumbai at the abovementioned
            address. This IC has been duly constituted, as per section 4 of the
            Sexual Harassment of Women at the Workplace (Prevention, Prohibition
            and Redressal) Act, 2013.
          </Text>
          <View style={{ marginTop: 15 }}>
            <MumbaiRegistrationTable ICEmployee={ICEmployee} />
          </View>
          <View style={{ paddingTop: 20 }}>
            <Text style={RegistrationStyles.bottomText}>Signed by</Text>
            <Text style={RegistrationStyles.POCText}>
              [Insert Name of point of contact (POC) for the organization]
            </Text>
            <Text style={RegistrationStyles.POCText}>
              [Insert POC Mobile number and email ID]
            </Text>
          </View>
        </View>
        <View style={{ marginTop: "10px" }}>
          <Text style={{ fontFamily: "MyFont" }}>CC:</Text>
          <Text style={RegistrationStyles.text}>
            District Women and Child Development Officer, Mumbai District. Bldg.
            117, Opp BDD Chawl, 1st Floor, Dr. Ambedkar Hostel Compound, Dr. G.
            M. Bhosale Marg, Worli, Mumbai - 400018{" "}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default MumbaiSuburbanLetterDoc;
