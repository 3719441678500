import React, { useContext } from "react";
import {
  Box,
  Collapse,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ColorModeContext } from "../../../constants/theme";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { states } from "../../../utils/states";
import FilterButton from "../../../components/Buttons/FilterButton";
import { useGetLocationTrainingMappedQuery } from "../../../store/training/TrainingAPI";
import {
  GridActionsLayout,
  SearchButton,
  TakeActionButton,
} from "../../../components";
import { useSelector } from "react-redux";
import TrainingLogo from "../../../assets/logos/TrainingLogo.svg";
import useDebouncedSearch from "../../../utils/hooks/useDebounceSearch";
import { GridColumns } from "../../../utils/gridColumns";
import { getKey } from "../../../utils/getKey";

function AllLocTraining() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth?.user);
  const { mode } = useContext(ColorModeContext);
  const [rows, setRows] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const { searchText, debouncedResults } = useDebouncedSearch();
  const [selectedStatus, setSelectedStatus] = React.useState("all");
  const [params, setParams] = React.useState({});
  const { data: locData, isFetching } = useGetLocationTrainingMappedQuery({
    ...paginationModel,
    ...searchText,
    ...params,
    status: selectedStatus,
    orgId: user?.org_id || "",
  });
  const [rowCountState, setRowCountState] = React.useState(locData?.count || 0);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    let temp = [];
    if (locData?.locations) {
      temp = locData?.locations?.map((loc) => {
        return {
          id: loc?.id,
          Location: loc?.location_name || "",
          Status: loc?.status || "PENDING",
        };
      });
    }
    setRows(temp || []);
  }, [locData]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      locData?.count ? locData?.count : prevRowCountState,
    );
  }, [locData?.count, setRowCountState]);

  const { handleSubmit, control, watch, resetField } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
  });
  const selectedState = watch("state");

  React.useEffect(() => {
    resetField("city");
  }, [selectedState]);

  const columns = [
    ...new GridColumns()
      .location()
      .status()
      .actions({
        renderCell: (params) => {
          return (
            <GridActionsLayout>
              <TakeActionButton
                handleClick={() => navigate(`/home/training/${params.row.id}`)}
              />
            </GridActionsLayout>
          );
        },
      })
      .getCols(),
  ];

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const onSubmit = (data) => {
    const params = {
      location_category: data.location_category,
    };
    if (data.state !== "select") {
      params.state = data.state;
    }
    if (data.city && data.city !== "select") {
      params.city = data.city;
    }
    setParams(params);
  };

  return (
    <Box
      className="tw-p-10"
      sx={{
        background: `${mode === "dark" ? "#000000" : " #FFF"}`,
        height: "100vh",
        width: "100%",
        margin: 0,

        display: "flex",
        flexDirection: "column",
        gap: "50px",
        padding: "40px",
      }}
    >
      <Stack
        direction={"row"}
        columnGap={4}
        justifyContent={"flex-start"}
        alignItems={"center"}
        marginBottom={2}
        marginTop={10}
      >
        <img src={TrainingLogo} alt="" />
        <Typography
          className="tw-font-semibold tw-text-4xl"
          sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
        >
          Trainings
        </Typography>
      </Stack>

      <Box
        display={"flex"}
        flex={1}
        flexDirection={"column"}
        gap={4}
        className="data-grid-div"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            padding={"26px"}
            bgcolor={mode === "dark" ? "#151515" : "#FFF6F2"}
            width={"100%"}
            className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 tw-gap-5"
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              gap={2}
            >
              <InputLabel>State</InputLabel>
              <Controller
                name={"state"}
                control={control}
                defaultValue={"select"}
                render={({ field: { value, onChange, ref } }) => (
                  <Select
                    sx={{ height: "40px", flex: 1 }}
                    className="tw-border-black  tw-rounded-lg"
                    value={value}
                    inputRef={ref}
                    onChange={onChange}
                  >
                    <MenuItem value={"select"}>Select</MenuItem>
                    {Object.keys(states).map((s, i) => (
                      <MenuItem key={getKey(i)} value={s}>
                        {s}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              gap={2}
            >
              <InputLabel>District</InputLabel>
              <Controller
                name={"city"}
                control={control}
                defaultValue={"select"}
                render={({ field: { value, onChange, ref } }) => (
                  <Select
                    sx={{ height: "40px", flex: 1 }}
                    value={value}
                    inputRef={ref}
                    onChange={onChange}
                    className="tw-border-black  tw-rounded-lg"
                  >
                    <MenuItem value={"select"}>Select</MenuItem>
                    {states[selectedState]?.map((c, i) => {
                      return (
                        <MenuItem key={getKey(i)} value={c}>
                          {c}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <SearchButton />
            </Box>
          </Box>
        </form>
        <Stack direction="column" gap={2}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <TextField
              type="text"
              name="search"
              onChange={debouncedResults}
              sx={{
                width: "302px",
                "& .MuiInputBase-root": {
                  height: "32px",
                },
              }}
              placeholder="Search By Company Name"
            />
            <FilterButton
              buttonStyles={{ height: "32px" }}
              onClick={() => setOpen(!open)}
            />
          </Stack>
          <Collapse in={open} timeout="auto" unmountOnExit width="100%">
            <RadioGroup
              row
              name="status"
              value={selectedStatus}
              onChange={handleStatusChange}
              sx={{ justifyContent: "flex-end" }}
            >
              <FormControlLabel value="all" control={<Radio />} label="All" />
              <FormControlLabel
                value="pending"
                control={<Radio />}
                label="Pending"
              />
              <FormControlLabel
                value="completed"
                control={<Radio />}
                label="Completed"
              />
            </RadioGroup>
          </Collapse>
        </Stack>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isFetching}
          disableRowSelectionOnClick
          pageSizeOptions={[5]}
          rowCount={rowCountState}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: `${mode === "dark" ? " #181818" : "#FFF6F2"}`,
            },
            "& .MuiDataGrid-toolbarContainer": {
              marginBottom: 1,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
              backgroundColor: "transparent",
              color: "#4B4B4B",
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default AllLocTraining;
