import React from "react";
import EmployeeTraining from "./EmployeeTraining";
import IcTrainings from "./IcTrainings";
import OtherTraining from "./OtherTraining";
import OtherInitiatives from "./OtherInitiatives";
import { Container, Stack } from "@mui/material";
import { OverviewLayout } from "../../../components/Layouts";
import { SelectYear } from "../../../components/OtherFormFields";

const trainingData = [
  {
    id: 1,
    date_of_training: "12-01-2023",
    mode_of_training: "Webinar",
    type_of_training: "Consent training",
    mode_of_description: "Training on workpalce behaviour",
    Stauts: "pending",
  },
  {
    id: 2,
    date_of_training: "12-01-2023",
    mode_of_training: "Workshop",
    type_of_training: "Sensitization training",
    mode_of_description: "Training on workpalce behaviour",
    Stauts: "completed",
  },
  {
    id: 3,
    date_of_training: "12-01-2023",
    mode_of_training: "E-Module",
    type_of_training: "Consent training",
    mode_of_description: "Training on workpalce behaviour",
    Stauts: "completed",
  },
];

const Trainings = () => {
  const [year, setYear] = React.useState(new Date().getFullYear());
  return (
    <OverviewLayout>
      <Container
        sx={{
          height: "auto",
          width: "100%",
          maxWidth: "1300px",
          margin: 0,
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          paddingY: 10,
        }}
      >
        <Stack
          direction={"row"}
          gap={2}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <SelectYear
            value={year}
            handleChange={(e) => setYear(e.target.value)}
          />
        </Stack>
        <EmployeeTraining trainingData={trainingData} params={{ year: year }} />
        <IcTrainings trainingData={trainingData} params={{ year: year }} />
        <OtherTraining trainingData={trainingData} params={{ year: year }} />
        <OtherInitiatives trainingData={trainingData} params={{ year: year }} />
      </Container>
    </OverviewLayout>
  );
};

export default Trainings;
