import React from "react";
import { Checkbox, Collapse, Stack, Typography } from "@mui/material";
import { DownArrowIconButton } from "../Buttons";

function Clause({
  heading = "",
  children,
  value = false,
  handleChange = () => null,
}) {
  const [open, setOpen] = React.useState(false);
  return (
    <Stack
      direction={"column"}
      gap={2}
      bgcolor={value === true ? "#EFFFE5" : "#F9F9F9"}
      border={"1px solid #D1D1D1"}
      borderRadius={"16px"}
      padding={"33px"}
    >
      <Stack
        direction={"row"}
        gap={3}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Checkbox checked={value} onChange={handleChange} sx={{ padding: 0 }} />
        <Typography flex={1} fontSize={"20px"} fontWeight={500}>
          {heading}
        </Typography>
        <DownArrowIconButton handleClick={() => setOpen(!open)} />
      </Stack>
      <Collapse in={open} timeout="auto" unmountOnExit width="100%">
        {children}
      </Collapse>
    </Stack>
  );
}

export default Clause;
