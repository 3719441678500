import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function FilingOfComplaintAgainstPolice({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {serialNo}. FILING OF COMPLAINT WITH POLICE
      </Text>
      <Text style={styles.text}>
        Nothing in these rules and procedures shall preclude anybody from
        simultaneously lodging a complaint with the police in respect of any act
        amounting to an offence under the law. Organization will provide
        assistance in this regard, if assistance is sought. This Policy is not
        intended to impair or limit the right of any Employee seeking a remedy
        available under any law. Proceedings under this Policy shall continue
        notwithstanding any proceedings initiated by a Complainant (as defined
        hereinafter) against Respondent under any law in force.
      </Text>
      <Text style={styles.text}>
        In case of a complaint of sexual harassment or any other offense against
        a child, as per the Protection of Children from Sexual Offences Act,
        2012 (“POCSO”), or in case of offense by a child, the provisions under
        POCSO and Juvenile Justice (Care and Protection of Children) Act, 2015
        may become applicable and would have to be dealt with in accordance with
        these laws. A child is any person below the age of eighteen years.
      </Text>
    </View>
  );
}

export default FilingOfComplaintAgainstPolice;
