import React, { useContext } from "react";
import {
  Box,
  Button,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { NavigationBar } from "../../../components/General";
import { ColorModeContext } from "../../../constants/theme";
import {
  GridActionsLayout,
  NumericalOverview,
} from "../../../components/Layouts";
import { DataGrid } from "@mui/x-data-grid";
import BreadcrumbFont from "../../../components/Typography/BreadcrumbFont";
import {
  ApproveButton,
  CloseButton,
  FilterButton,
  TakeActionButton,
  ViewDetailsButton,
} from "../../../components/Buttons";
import StatusFilters from "../../../components/Filters/StatusFilters";
import {
  useGetOrgPolicyMappedQuery,
  useGetPolicyStatusQuery,
  useUpdatePolicyStatusMutation,
} from "../../../store/policy/policyApi";
import { Status } from "../../../utils/options";
import ReasonRejection from "../../../components/Dialog/ReasonRejection";
import useDebouncedSearch from "../../../utils/hooks/useDebounceSearch";
import { GridColumns } from "../../../utils/gridColumns";

const breadcrumbs = [
  <BreadcrumbFont
    key={1}
    color="#005D9E"
    text={"AGGREGATE COMPANY POLICY"}
    last
  />,
];

const getOrgStatus = (org) => {
  if (org?.policy_status === "PENDING" || org?.policy_status === "REJECTED") {
    return "PENDING";
  } else if (
    org?.policy_status === "APPROVAL" ||
    org?.policy_status === "COMPLETED"
  ) {
    return org?.policy_status;
  } else {
    return "";
  }
};

function AllOrgPolicy() {
  const { mode } = useContext(ColorModeContext);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const { searchText, debouncedResults } = useDebouncedSearch();
  const [selectedStatus, setSelectedStatus] = React.useState("all");
  const { data: orgData, isFetching } = useGetOrgPolicyMappedQuery({
    ...paginationModel,
    ...searchText,
    status: selectedStatus,
  });
  const { data: totalStatus } = useGetPolicyStatusQuery();
  const [updatePolicyStatus, { isLoading: loadingUpdatePloicy }] =
    useUpdatePolicyStatusMutation();
  const [rowCountState, setRowCountState] = React.useState(orgData?.count || 0);
  const [openPopper] = React.useState(false);
  const [anchor, setAnchor] = React.useState(null);
  const [openApprovePopper, setOpenApprovePopper] = React.useState(false);
  const [currentOrgId, setCurrentOrgId] = React.useState(null);
  const [currentPolicyId, setCurrentPolicyId] = React.useState(null);
  const [openRejectAlert, setOpenRejectAlert] = React.useState(false);

  React.useEffect(() => {
    let temp = [];
    if (orgData?.orgs) {
      temp = orgData?.orgs?.map((org) => {
        return {
          id: org?.id,
          Organization: org?.name || "",
          Status: getOrgStatus(org),
          policy_id:
            org.policies?.length !== 0
              ? org.policies[org.policies.length - 1]?.id
              : "",
          file:
            org.policies?.length !== 0
              ? org.policies[org.policies.length - 1]?.file
              : "",
        };
      });
    }
    setRows(temp || []);
  }, [orgData]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      orgData?.count ? orgData?.count : prevRowCountState,
    );
  }, [orgData?.count, setRowCountState]);

  const numericalData = [
    {
      id: 1,
      text: "NO. OF COMPANIES COMPANY POLICY PENDING",
      number: totalStatus?.pending,
    },
    {
      id: 2,
      text: "NO. OF COMPANIES COMPANY POLICY COMPLETED",
      number: totalStatus?.completed,
    },
  ];

  const approvePolicy = () => {
    const body = {
      org_id: currentOrgId,
      id: currentPolicyId,
      status: "APPROVE",
    };
    updatePolicyStatus(body);
    setOpenApprovePopper(false);
    setAnchor(null);
    setCurrentOrgId(null);
    setCurrentPolicyId(null);
  };

  const columns = [
    ...new GridColumns().org().status().getCols(),
    {
      field: "Approval",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <GridActionsLayout>
            {params.row.Status === "APPROVAL" ? (
              <>
                <CloseButton
                  handleClick={() => {
                    setCurrentOrgId(params.row.id);
                    setCurrentPolicyId(params.row.policy_id);
                    setOpenRejectAlert(!openRejectAlert);
                  }}
                />
                <ApproveButton
                  handleClick={(e) => {
                    setOpenApprovePopper(!openPopper);
                    setAnchor(e.currentTarget);
                    setCurrentOrgId(params.row.id);
                    setCurrentPolicyId(params.row.policy_id);
                  }}
                />
              </>
            ) : (
              `"`
            )}
          </GridActionsLayout>
        );
      },
    },
    ...new GridColumns()
      .actions({
        renderCell: (params) => {
          return (
            <GridActionsLayout>
              {params.row.Status === "PENDING" ? (
                <TakeActionButton />
              ) : (
                <ViewDetailsButton
                  handleClick={() => window.open(params.row.file, "_blank")}
                  placeholder="View"
                />
              )}
            </GridActionsLayout>
          );
        },
      })
      .getCols(),
  ];

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      paddingX={"60px"}
      paddingY={"35px"}
      rowGap={"46px"}
      sx={{ background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}` }}
    >
      <NavigationBar breadcrumbs={breadcrumbs} />
      <NumericalOverview data={numericalData} />
      {openRejectAlert && (
        <ReasonRejection
          open={openRejectAlert}
          id={currentPolicyId}
          updateApi={updatePolicyStatus}
          orgId={currentOrgId}
          loading={loadingUpdatePloicy}
          onClose={() => {
            setOpenRejectAlert(!openRejectAlert);
            setCurrentOrgId(null);
            setCurrentPolicyId(null);
          }}
          name="rejection_reason"
        />
      )}

      <Popper
        modifiers={[
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
        ]}
        placement="bottom"
        open={openApprovePopper}
        anchorEl={anchor}
      >
        <Stack
          className="tw-shadow-md"
          sx={{
            padding: 2,
            backgroundColor: "white",
            borderRadius: "4px",
            gap: 3,
            border: "1px solid #4B4B4B",
          }}
        >
          <Typography>Do you want to approve?</Typography>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Button
              variant="contained"
              sx={{ height: "25px", width: "45px", backgroundColor: "#4B4B4B" }}
              onClick={() => {
                setOpenApprovePopper(false);
                setAnchor(null);
                setCurrentOrgId(null);
                setCurrentPolicyId(null);
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              sx={{ height: "25px", width: "45px", backgroundColor: "#4B4B4B" }}
              onClick={approvePolicy}
            >
              Yes
            </Button>
          </Stack>
        </Stack>
      </Popper>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={5}
        className="superadmin-data-grid-div"
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <TextField
            type="text"
            name="search"
            onChange={debouncedResults}
            sx={{
              width: "302px",
              "& .MuiInputBase-root": {
                height: "32px",
              },
            }}
            placeholder="Search By Company Name"
          />
          <FilterButton
            buttonStyles={{ height: "32px" }}
            onClick={() => setOpen(!open)}
          />
        </Stack>
        <StatusFilters
          open={open}
          selectedStatus={selectedStatus}
          handleStatusChange={handleStatusChange}
          status={[Status.APPROVAL, Status.COMPLETE, Status.PENDING]}
        />
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isFetching}
          disableRowSelectionOnClick
          pageSizeOptions={[5]}
          rowCount={rowCountState}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: `${mode === "dark" ? " #181818" : "#EEF8FF"}`,
            },
            "& .MuiDataGrid-toolbarContainer": {
              paddingY: 2,
              paddingX: 1,
            },

            "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
              backgroundColor: "transparent",
              color: "#4B4B4B",
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default AllOrgPolicy;
