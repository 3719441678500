import { Button } from "@mui/material";
import React from "react";

function AddNewButton({ onClick = () => null, buttonProps = {}, styles = {} }) {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      className="inter-font"
      sx={{
        backgroundColor: "#4B4B4B",
        fontSize: "14px",
        fontWeight: 400,
        height: "35px",
        width: "80px",
        padding: 0,
        ...styles,
      }}
      {...buttonProps}
    >
      Add new
    </Button>
  );
}

export default AddNewButton;
