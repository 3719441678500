import React from "react";
import { Button } from "@mui/material";

function ViewDetailsButton({
  handleClick,
  placeholder = "View Details",
  buttonProps = {},
  buttonStyles = {},
}) {
  return (
    <Button
      sx={{
        backgroundColor: "#4B4B4B",
        fontSize: "12px",
        height: "25px",
        paddingX: 1,
        paddingY: 1,
        ...buttonStyles,
      }}
      variant="contained"
      onClick={handleClick}
      {...buttonProps}
    >
      {placeholder}
    </Button>
  );
}

export default ViewDetailsButton;
