export const keyDefinitionsClausesSGN = {
  objective:
    "For the purposes of this Policy, the following terms shall have the meaning indicated below:",
  sexualharassment: {
    heading: "Sexual Harassment",
    acts: {
      objective:
        "“Sexual Harassment” includes any one or more of the following unwelcome acts or behavior (whether directly or by implication) namely:",
      points: [
        "physical contact and advances; or",
        "a demand or request for sexual favours; or",
        "making sexually coloured remarks; or",
        "showing pornography; or",
        "any other unwelcome physical, verbal or non-verbal conduct of sexual nature;",
      ],
    },
    circumstances: {
      objective:
        "These circumstances, among other circumstances, if it occurs or if it is present in relation to or connected with any act of sexual harassment, may amount to sexual harassment:",
      points: [
        "When either implicitly or explicitly it becomes a condition of the individuals present or future employment",
        "Implied or explicit promise of preferential treatment in employment",
        "Implied or explicit threat of detrimental treatment in employment.",
        "The harassment has a purpose or effect of unreasonably interfering with the work performance or creating an environment, which is intimidating, hostile or offensive",
        "Humiliating treatment likely to affect health or safety",
      ],
    },
    conclusion:
      "In all the above cases, everyone must exercise their own good judgment to avoid engaging in conduct that may be perceived by others as Sexual Harassment. In all the above cases, it is not the intention of the person behaving but the impact on the person who is subjected to such behavior that will matter.",
  },
  Employee:
    "A person employed at a workplace for any work on regular, temporary, ad hoc or daily wage basis, either directly or through an agent, including a contractor, with or, without the knowledge of the principal employer, whether for remuneration or not, or working on a voluntary basis or otherwise, whether the terms of employment are express or implied and includes a co-worker, a contract worker, probationer, trainee, apprentice or called by any other such name. This definition of ‘Employee’ shall be used only for the purposes of this policy and cannot be used to claim rights of an employee conferred under any other law for the time being in force.",
  Workplace:
    "In addition to the place of work (office, branch, yard etc.), it covers any other Organization premises (including transit houses and guest houses) and any place visited by the employee in connection with work during the course of employment or arising out of employment with the Organization, including travel/transportation provided by the employer for undertaking such a journey.",
  "Aggrieved Person Or Complaint": [
    "In relation to a workplace, a person, of any age, whether employed or not, who alleges to have been subjected to any act of sexual harassment by the respondent and includes contractual, temporary, visitors.",
    "In case, anyone else has to file a complaint on behalf of the Aggrieved Person as per Law, such person shall be referred to as the “Complainant.” Any reference to the term ‘Complainant’ throughout this Policy shall mean reference to ‘Aggrieved Person’ and / or the ‘Complainant’ as applicable.",
  ],
  Respondent:
    "Employees against whom a complaint has been made by the Complainant.",
};
