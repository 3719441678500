import { Box } from "@mui/material";
import React, { useContext } from "react";
import { ColorModeContext } from "../../../../constants/theme";
import ArchiveLogo from "../../../../assets/logos/ArchivesLogo.svg";
import PageHeading from "../../../../components/General/PageHeading";
import { useSelector } from "react-redux";
import { IcArchivesCom } from "../../../../components";

const IcArchives = () => {
  const OrganizationDetails = useSelector((state) => state?.auth);
  const { user } = OrganizationDetails;
  const orgId = user?.user?.org_id;

  const { mode } = useContext(ColorModeContext);

  return (
    <Box
      className="tw-p-10"
      sx={{
        background: `${mode === "dark" ? "#000000" : " #FFF"}`,
        height: "100%",
        width: "100%",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "40px",
      }}
    >
      <PageHeading label="Internal Committee Archives" logo={ArchiveLogo} />
      <IcArchivesCom orgId={orgId} styles="data-grid-div" />
    </Box>
  );
};

export default IcArchives;
