import React from "react";
import { Box, Typography } from "@mui/material";
import { ColorModeContext } from "../../../constants/theme";
import { NavigationBar } from "../../../components/General";
import ICMembersTable from "../tables/ICMembersTable";
import { getIdFromUrl } from "../../../utils/getIdFromUrl";
import {
  useDeleteICMemberMutation,
  useGetICByLocationQuery,
} from "../../../store/ic/icApi";
import Swal from "sweetalert2";

const breadcrumbs = [
  <Typography
    className="tw-[#4B4B4B] tw-text-[11px] tw-font-semibold tw-tracking-[1px] tw-leading-6 tw-font inter-font"
    key="100"
  >
    AGGREGATE IC
  </Typography>,
  <Typography
    className="tw-[#4B4B4B] tw-text-[11px] tw-font-semibold tw-tracking-[1px] tw-leading-6 tw-font inter-font"
    key="100"
  >
    ORGANIZATION LOCATIONS
  </Typography>,
  <Typography
    className="tw-text-dark-blue tw-text-[11px] tw-font-semibold tw-tracking-[1px] tw-leading-6 tw-font inter-font"
    key="100"
  >
    IC MEMBERS
  </Typography>,
];

function SingleLocIC() {
  const { mode } = React.useContext(ColorModeContext);
  const ID = getIdFromUrl();
  const [rows, setRows] = React.useState([]);
  const {
    data: icData,
    isSuccess,
    isFetching,
    refetch,
  } = useGetICByLocationQuery({
    location_id: ID,
    page: 0,
    pageSize: 5,
  });

  const [deleteIc, { isSuccess: deleteSuccess, isError: deleteError }] =
    useDeleteICMemberMutation();

  React.useEffect(() => {}, [icData]);

  React.useEffect(() => {
    let tempRows = [];
    if (icData?.ic?.employees) {
      tempRows = icData?.ic?.employees?.map((emp) => {
        return {
          id: emp?.id,
          Name: `${emp?.firstName} ${emp?.lastName}`,
          Gender: emp?.gender,
          Email: emp?.email,
          "Designation in Org": emp?.designation || "",
          "Designation in IC": emp?.designation_in_ic?.name || "",
        };
      });
    }
    setRows(tempRows || []);
  }, [icData, isSuccess]);

  const handleRemove = (body) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove IC Memeber?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteIc(body);
      }
    });
  };

  const handleDelete = (params) => {
    const body = {
      email: params?.row?.Email,
      location_id: ID,
    };
    handleRemove(body);
  };
  React.useEffect(() => {
    if (deleteSuccess) {
      Swal.fire({
        title: "Removed",
        text: "IC Memeber has been removed",
        icon: "success",
        timer: 1500,
      });
      refetch();
    }
    if (deleteError) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: "Please try again!",
      });
    }
  }, [deleteSuccess, deleteError]);

  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      paddingX={"60px"}
      paddingY={"35px"}
      rowGap={"46px"}
      sx={{ background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}` }}
    >
      <NavigationBar breadcrumbs={breadcrumbs} />
      <Box
        display={"flex"}
        flex={1}
        flexDirection={"column"}
        gap={4}
        className="superadmin-data-grid-div"
      >
        <ICMembersTable
          isFetching={isFetching}
          rows={rows}
          handleDelete={handleDelete}
        />
      </Box>
    </Box>
  );
}

export default SingleLocIC;
