export const Status = Object.freeze({
  PENDING: "PENDING",
  COMPLETE: "COMPLETED",
  APPROVAL: "APPROVAL",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
});

export const Authorization = Object.freeze({
  AUTHORIZED: "AUTHORIZED",
  UNAUTHORIZED: "UNAUTHORIZED",
});
