import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../constants";

export const annualReportApi = createApi({
  reducerPath: "annualReportApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
      console.log("building ic headers");
      headers.set(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
      );
      return headers;
    },
  }),
  tagTypes: ["GetAnnualReport"],
  endpoints: (build) => ({
    getAnnualReport: build.query({
      query: (params) => ({
        url: `/v1/annualReport/get`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 1800,
      providesTags: ["GetAnnualReport"],
    }),
    downloadAnnualReportPDF: build.mutation({
      query: (body) => ({
        url: `/v1/annualReport/generate-pdf`,
        method: "POST",
        body,
      }),
    }),
    uploadAnnualReportPDF: build.mutation({
      query: (body) => ({
        url: `/v1/annualReport/upload`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetAnnualReport"],
    }),
    getLocAnnualReportMapped: build.query({
      query: (params) => ({
        url: `/v1/annualReport/loc-ar-mapped`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 1800,
    }),
    getOrgAnnualReportMapped: build.query({
      query: (params) => ({
        url: `/v1/annualReport/org-ar-mapped`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 1800,
    }),
    getTotalAnnualReportStatus: build.query({
      query: (params) => ({
        url: `/v1/annualReport/total-status`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 1800,
    }),
    getLocationAnnualReportStatus: build.query({
      query: (params) => ({
        url: `/v1/annualReport/loc-annual-report-status`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 300,
    }),
    getAnnualReportData: build.query({
      query: (params) => ({
        url: `/v1/annualReport/data`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 1800,
    }),
  }),
});

export const {
  useGetAnnualReportQuery,
  useDownloadAnnualReportPDFMutation,
  useUploadAnnualReportPDFMutation,
  useGetLocAnnualReportMappedQuery,
  useGetOrgAnnualReportMappedQuery,
  useGetTotalAnnualReportStatusQuery,
  useGetLocationAnnualReportStatusQuery,
  useGetAnnualReportDataQuery,
} = annualReportApi;
