import { createAsyncThunk } from "@reduxjs/toolkit";

export const registerOrg = createAsyncThunk("register", async (body) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}v1/auth/register`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
    );
    if (res.status !== 200) {
      console.log("something went wrong");
      throw new Error();
    } else {
      const data = await res.json();
      console.log(data);
      return data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const createOrgDetails = createAsyncThunk("org/create", async (body) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}org/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
    );
    if (res.status !== 200) {
      console.log("something went wrong");
      throw new Error();
    } else {
      const data = await res.json();
      console.log(data);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});
