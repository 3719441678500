import React from "react";
import { Image, Page, Text, View } from "@react-pdf/renderer";
import Overview from "./Clauses/GN/Overview";
import WhatIsSexualHarassment from "./Clauses/WO/WhatIsSexualHarassment";
import WhatIsWorkPlace from "./Clauses/WO/WhatIsWorkPlace";
import WhatIsAnEmployee from "./Clauses/WO/WhatIsAnEmployee";
import WhatIsVisitor from "./Clauses/WO/WhatIsVisitor";
import SHConcernsRedressal from "./Clauses/WO/SHConcernsRedressal";
import WhoCanFileComplaint from "./Clauses/WO/WhoCanFileComplaint";
import RestrictedVictim from "./Clauses/WO/RestrictedVictim";
import HowToFileComplaint from "./Clauses/WO/HowToFileComplaint";
import ExternalComplainant from "./Clauses/WO/ExternalComplainant";
import ProcessToHandleComplaint from "./Clauses/WO/ProcessToHandleComplaint";
import ActionDuringPendencyOfInquiry from "./Clauses/WO/ActionDuringPendencyOfInquiry";
import WhatIsInquiryReport from "./Clauses/WO/WhatIsInquiryReport";
import ProcedureForFilingAppeal from "./Clauses/GN/ProcedureForFilingAppeal";
import Confidentiality from "./Clauses/WO/Confidentiality";
import ProtectionAgainstRetaliation from "./Clauses/WO/ProtectionAgainstRetaliation";
import FilingOfComplaintAgainstPolice from "./Clauses/WO/FilingOfComplaintAgainstPolice";
import AvailabilityOfMentalHealthProfessionals from "./Clauses/WO/AvailabilityOfMentalHealthProfessionals";
import ResponsibilitiesOfEmployees from "./Clauses/WO/ResponsibilitiesOfEmployees";
import ResponsibilitiesOfIC from "./Clauses/WO/ResponsibilitiesOfIC";
import ResponsibilitiesOfOrganization from "./Clauses/WO/ResponsibilitiesOfOrganization";
import Amendments from "./Clauses/WO/Amendments";
import Clause1 from "./Clauses/SHO/Clause1";
import Clause2 from "./Clauses/SHO/Clause2";

function LongPolicyView({ styles, Indices, clauses }) {
  return (
    <Page size={"A4"} style={styles.body}>
      <Text style={styles.title}>ANTI SEXUAL HARASSMENT POLICY</Text>
      <View style={styles.main}>
        <Overview />
        {clauses.whatIsSexualHarassment && (
          <WhatIsSexualHarassment serialNo={1} />
        )}
        {clauses.whatIsWorkPlace && (
          <WhatIsWorkPlace serialNo={Indices.pop()} />
        )}
        {clauses.whatIsAnEmployee && (
          <WhatIsAnEmployee serialNo={Indices.pop()} />
        )}
        {clauses.whatIsVisitor && <WhatIsVisitor serialNo={Indices.pop()} />}
        {clauses.SHConcernsRedressal && (
          <SHConcernsRedressal serialNo={Indices.pop()} />
        )}
        {clauses.whoCanFileComplaint && (
          <WhoCanFileComplaint serialNo={Indices.pop()} />
        )}
        {clauses.restrictedVictim && (
          <RestrictedVictim serialNo={Indices.pop()} />
        )}
        {clauses.howToFileComplaint && (
          <HowToFileComplaint serialNo={Indices.pop()} />
        )}
        {clauses.externalComplainant && (
          <ExternalComplainant serialNo={Indices.pop()} />
        )}
        {clauses.processToHandleComplaint && (
          <ProcessToHandleComplaint serialNo={Indices.pop()} />
        )}
        {clauses.actionDuringPendencyOfInquiry && (
          <ActionDuringPendencyOfInquiry serialNo={Indices.pop()} />
        )}
        {clauses.whatIsInquiryReport && (
          <WhatIsInquiryReport serialNo={Indices.pop()} />
        )}
        {clauses.procedureForFilingAppeal && (
          <ProcedureForFilingAppeal serialNo={Indices.pop()} />
        )}
        <Text style={styles.heading}>TIMELINES</Text>
        <Image
          src={
            "https://turnkey-equihawk.s3.ap-south-1.amazonaws.com/PolicyImages/timeline.png"
          }
        />
        {clauses.confidentiality && (
          <Confidentiality serialNo={Indices.pop()} />
        )}
        {clauses.protectionAgainstRetaliation && (
          <ProtectionAgainstRetaliation serialNo={Indices.pop()} />
        )}
        {clauses.filingOfComplaintAgainstPolice && (
          <FilingOfComplaintAgainstPolice serialNo={Indices.pop()} />
        )}
        {clauses.availabilityOfMentalHealthProfessionals && (
          <AvailabilityOfMentalHealthProfessionals serialNo={Indices.pop()} />
        )}
        {clauses.responsibilitiesOfEmployees && (
          <ResponsibilitiesOfEmployees serialNo={Indices.pop()} />
        )}
        {clauses.responsibilitiesOfIC && (
          <ResponsibilitiesOfIC serialNo={Indices.pop()} />
        )}
        {clauses.responsibilitiesOfOrganization && (
          <ResponsibilitiesOfOrganization serialNo={Indices.pop()} />
        )}
        {clauses.amendments && <Amendments serialNo={Indices.pop()} />}
        {clauses.clause1 && <Clause1 serialNo={Indices.pop()} />}
        {clauses.clause2 && <Clause2 serialNo={Indices.pop()} />}
      </View>
    </Page>
  );
}

export default LongPolicyView;
