import { Box, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { DownloadButton } from "../Buttons";
import { SelectField2 } from "../Form";

const RegistrationForm = ({
  onSubmit = () => null,
  complianceDetails,
  locationValue,
  Mode,
  agree,
  pdfPreivewLoader,
  checkboxHandler = null,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
    defaultValues: "initialValues",
  });
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="tw-mt-4">
          <div className="tw-gap-2 tw-flex tw-flex-col">
            <InputLabel
              className="tw-text-xs sm:tw-text-sm  tw-w-min tw-px-2 tw-py-1 tw-rounded-md"
              htmlFor="orgName"
            >
              <Typography>Select Compliance manager</Typography>
            </InputLabel>
            <Controller
              name={"compliance"}
              control={control}
              defaultValue=" "
              rules={{ required: "This field is required" }}
              render={({ field: { value, onChange, ref } }) => (
                <Select value={value} onChange={onChange} inputRef={ref}>
                  <MenuItem hidden disabled value=" ">
                    Select
                  </MenuItem>
                  {complianceDetails?.map((m) => {
                    return (
                      <MenuItem key={m.id} value={m.fullName}>
                        {m.email}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
            {errors.compliance && (
              <span className="tw-text-red-700 tw-text-xs">
                {errors.compliance.message}
              </span>
            )}
          </div>
        </Box>
        <Box className="tw-mt-4">
          <SelectField2
            label={"Select Location"}
            placeholder={"Select"}
            name={"location"}
            register={register}
            menus={locationValue}
            control={control}
            validations={{
              validate: (value) => {
                return value !== " " || "This field is required";
              },
            }}
            error={errors?.location?.message}
          />
        </Box>
        <Box className="tw-mt-4">
          <SelectField2
            label={"Select Mode of sending"}
            placeholder={"Select"}
            name={"mode"}
            register={register}
            menus={Mode}
            control={control}
            is_id="null"
            validations={{
              validate: (value) => {
                return value !== " " || "This field is required";
              },
            }}
            error={errors?.mode?.message}
          />
        </Box>
        {checkboxHandler && (
          <Box className="tw-my-10 tw-flex">
            <input type="checkbox" id="agree" onChange={checkboxHandler} />
            <label htmlFor="agree">
              {" "}
              <Typography className="tw-mt-2 tw-ml-4  tw-text-sm">
                I CONFIRM THAT I HAVE READ THE INSTRUCTIONS PROPOSED MUMBAI
                SUBURBAN STATE COMMISSION
              </Typography>
            </label>
          </Box>
        )}
        <Box className="tw-mt-8">
          <DownloadButton
            type="submit"
            loading={pdfPreivewLoader}
            label="View Preview"
            styles={{ width: "100%", marginBottom: "10rem" }}
            disabledValue={!agree}
          />
        </Box>
      </form>
    </Box>
  );
};

export default RegistrationForm;
