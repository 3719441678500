/* eslint-disable no-unused-vars */
import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import LineGraph from "../../components/Graphs/LineGraph";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import { ContainerLayout, ReminderLayout } from "../../components/Layouts";
import { ColorModeContext } from "../../constants/theme";
import { DoughnutChart, StatusBar } from "../../components";
import { useNavigate } from "react-router-dom";
import OrderWritingLogo from "../../assets/logos/OrderWritingLogo.svg";
import { Status } from "../../utils/options";
import { useGetLocationICStatusQuery } from "../../store/ic/icApi";
import { useSelector } from "react-redux";
import { useGetLocationAnnualReportStatusQuery } from "../../store/annualReport/annualReportApi";
import { useGetRemindersByOrgQuery } from "../../store/reminders/reminderApi";
import { useGetOrgDetailsQuery } from "../../store/org/orgApi";
import ComplianceStatusLayout from "./ComplianceStatusLayout";
import InternalCommitteeLogoSmall from "../../assets/logos/InternalCommitteeLogoSmall.svg";
import CompanyPolicyLogo from "../../assets/logos/CompanyPolicyLogo.svg";
import RegistrationLogo from "../../assets/logos/RegistrationLogo.svg";
import AnnualReportLogo from "../../assets/logos/AnnulaReportLogo.svg";
import TrainingLogo from "../../assets/logos/TrainingLogo.svg";
import { getCompletedCount, getPendingCount } from "../../utils/getStatusCount";

function Dashboard() {
  const { mode } = useContext(ColorModeContext);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth?.user);
  const { data: ICStatus, isFetching: ICFetching } =
    useGetLocationICStatusQuery({ org_id: user.org_id });
  const { data: AnnualReportStatus, isFetching: AnnualReportFetching } =
    useGetLocationAnnualReportStatusQuery({ org_id: user.org_id });
  const { data: reminders, isFetching: remindersFetching } =
    useGetRemindersByOrgQuery({
      org_id: user?.org_id,
    });
  const { data: orgDetails, isFetching: orgFetching } = useGetOrgDetailsQuery(
    user?.org_id,
  );

  React.useEffect(() => {
    console.log("org details --->", orgDetails);
  }, [orgDetails]);

  const labels = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const data = {
    labels,
    datasets: [
      {
        label: "Complaints",
        data: [10, 20, 15, 30, 10, 20, 15],
        borderColor: "gray",
        fill: true,
        backgroundColor: "#EDF8FF",
      },
    ],
  };

  const allComplianceStatus = {
    labels: [],
    datasets: [
      {
        label: "Compliances",
        data: [
          getPendingCount(orgDetails?.org) || 0,
          getCompletedCount(orgDetails?.org) || 0,
        ],
        backgroundColor: ["#43A5FF", "#00FE93"],
        borderJoinStyle: "bevel",
        cutout: "70%",
      },
    ],
  };

  const icData = {
    labels: [],
    datasets: [
      {
        label: "Locations",
        data: [ICStatus?.pending || 0, ICStatus?.completed || 0],
        backgroundColor: ["#43A5FF", "#00FE93"],
        borderJoinStyle: "bevel",
        cutout: "70%",
      },
    ],
  };
  const annualReportData = {
    labels: [],
    datasets: [
      {
        label: "Locations",
        data: [
          AnnualReportStatus?.pending || 0,
          AnnualReportStatus?.completed || 0,
        ],
        backgroundColor: ["#43A5FF", "#00FE93"],
        borderJoinStyle: "bevel",
        cutout: "70%",
      },
    ],
  };

  return (
    <ContainerLayout gap={"30px"}>
      <Typography
        className="tw-text-3xl tw-font-bold"
        sx={{ color: `${mode === "dark" ? "#FFFFFF" : " #000000"}` }}
      >
        <AutoAwesomeMosaicIcon sx={{ color: "#0060A4", marginRight: "40px" }} />
        Dashboard
      </Typography>

      <ReminderLayout
        remindersFetching={remindersFetching}
        reminders={reminders?.reminders}
        count={reminders?.count}
      />

      <Stack
        direction={"row"}
        paddingY={"23px"}
        paddingX={"40px"}
        alignItems={"center"}
        bgcolor={mode === "dark" ? "#1A1A1A" : "white"}
        boxShadow={
          "0px 0px 1px rgba(12, 14, 25, 0.24), 0px 2px 12px rgba(12, 14, 25, 0.12)"
        }
        borderRadius={"6px"}
      >
        <Stack
          flex={1}
          direction={"row"}
          gap={2}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <img width={"25px"} src={OrderWritingLogo} alt="" />
          <Typography
            fontSize={"20px"}
            fontWeight={500}
            color={mode === "dark" ? "#DCDCDC" : "#4B4B4B"}
            marginRight={4}
          >
            Order in Writing
          </Typography>
          <StatusBar label={"PENDING"} status={Status.PENDING} />
        </Stack>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#4B4B4B",
            borderRadius: "10px",
            height: "35px",
            paddingX: 6,
            fontSize: "14px",
            width: "130px",
            fontWeight: 400,
          }}
          onClick={() => navigate("/home/order-in-writing")}
          type="submit"
        >
          Generate
        </Button>
      </Stack>

      <Stack direction={"row"} gap={4}>
        <ComplianceStatusLayout
          imgSrc={InternalCommitteeLogoSmall}
          text="Internal Committee"
          label={orgDetails?.org?.ic_status || "PENDING"}
          status={orgDetails?.org?.ic_status || "PENDING"}
        />
        <ComplianceStatusLayout
          imgSrc={CompanyPolicyLogo}
          text="Company Policy"
          label={orgDetails?.org?.policy_status || "PENDING"}
          status={orgDetails?.org?.policy_status || "PENDING"}
        />
      </Stack>
      <Stack direction={"row"} gap={4}>
        <ComplianceStatusLayout
          imgSrc={RegistrationLogo}
          text="Registration"
          label={orgDetails?.org?.registration_status || "PENDING"}
          status={orgDetails?.org?.registration_status || "PENDING"}
        />
        <ComplianceStatusLayout
          imgSrc={AnnualReportLogo}
          text="Annual Report"
          label={orgDetails?.org?.annual_report_status || "PENDING"}
          status={orgDetails?.org?.annual_report_status || "PENDING"}
        />
      </Stack>
      <Stack direction={"row"} gap={4}>
        <ComplianceStatusLayout
          imgSrc={TrainingLogo}
          text="Trainings"
          label={orgDetails?.org?.training_status || "PENDING"}
          status={orgDetails?.org?.training_status || "PENDING"}
        />
        <ComplianceStatusLayout
          imgSrc={AnnualReportLogo}
          text="Posters"
          label={"PENDING"}
          status={"PENDING"}
        />
      </Stack>

      <div className="tw-grid tw-grid-cols-2 tw-gap-8">
        <Stack
          direction={"column"}
          paddingX={"40px"}
          paddingY={"23px"}
          boxShadow={
            "0px 0px 1px rgba(12, 14, 25, 0.24), 0px 2px 12px rgba(12, 14, 25, 0.12)"
          }
          borderRadius={"6px"}
          bgcolor={mode === "dark" ? "#1A1A1A" : "white"}
          gap={4}
        >
          <Typography
            fontSize={"20px"}
            fontWeight={500}
            color={mode === "dark" ? "#DCDCDC" : "#232323"}
            lineHeight={1}
          >
            Compliance Statistics
          </Typography>
          <Stack
            direction={"row"}
            gap={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <DoughnutChart
              width="240px"
              height="240px"
              data={allComplianceStatus}
              loading={orgFetching}
            />
            <Stack justifyContent={"center"} gap={2}>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <div className="tw-w-5 tw-h-5 tw-rounded tw-bg-[#43A5FF]"></div>
                <Typography
                  fontSize={"12px"}
                  fontWeight={400}
                  color={mode === "dark" ? "#DCDCDC" : "#4B4B4B"}
                >
                  Pending
                </Typography>
              </Stack>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <div className="tw-w-5 tw-h-5 tw-rounded tw-bg-[#00FE93]"></div>
                <Typography
                  fontSize={"12px"}
                  fontWeight={400}
                  color={mode === "dark" ? "#DCDCDC" : "#4B4B4B"}
                >
                  Completed
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </div>

      {/* <Stack direction={"row"} gap={4}>
        <Stack
          direction={"column"}
          paddingX={"40px"}
          paddingY={"23px"}
          boxShadow={
            "0px 0px 1px rgba(12, 14, 25, 0.24), 0px 2px 12px rgba(12, 14, 25, 0.12)"
          }
          borderRadius={"6px"}
          bgcolor={mode === "dark" ? "#1A1A1A" : "white"}
          width={"50%"}
          gap={4}
        >
          <Stack
            direction={"row"}
            gap={2}
            justifyContent={"flex-start"}
            alignItems={"flex-end"}
          >
            <Typography
              fontSize={"20px"}
              fontWeight={500}
              color={mode === "dark" ? "#DCDCDC" : "#232323"}
              lineHeight={1}
            >
              IC Statistics {"  "}{" "}
            </Typography>
            <Typography
              fontSize={"10px"}
              fontWeight={400}
              color={mode === "dark" ? "#DCDCDC" : "#000"}
            >
              (No of Locations)
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            gap={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <DoughnutChart
              width="240px"
              height="240px"
              data={icData}
              loading={ICFetching}
            />
            <Stack justifyContent={"center"} gap={2}>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <div className="tw-w-5 tw-h-5 tw-rounded tw-bg-[#43A5FF]"></div>
                <Typography
                  fontSize={"12px"}
                  fontWeight={400}
                  color={mode === "dark" ? "#DCDCDC" : "#4B4B4B"}
                >
                  Pending
                </Typography>
              </Stack>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <div className="tw-w-5 tw-h-5 tw-rounded tw-bg-[#00FE93]"></div>
                <Typography
                  fontSize={"12px"}
                  fontWeight={400}
                  color={mode === "dark" ? "#DCDCDC" : "#4B4B4B"}
                >
                  Completed
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction={"column"}
          paddingX={"40px"}
          paddingY={"23px"}
          boxShadow={
            "0px 0px 1px rgba(12, 14, 25, 0.24), 0px 2px 12px rgba(12, 14, 25, 0.12)"
          }
          borderRadius={"6px"}
          bgcolor={mode === "dark" ? "#1A1A1A" : "white"}
          width={"50%"}
          gap={4}
        >
          <Stack
            direction={"row"}
            gap={2}
            justifyContent={"flex-start"}
            alignItems={"flex-end"}
          >
            <Typography
              fontSize={"20px"}
              fontWeight={500}
              color={mode === "dark" ? "#DCDCDC" : "#232323"}
              lineHeight={1}
            >
              Annual Report Statistics {"  "}{" "}
            </Typography>
            <Typography
              fontSize={"10px"}
              fontWeight={400}
              color={mode === "dark" ? "#DCDCDC" : "#232323"}
            >
              (No of Locations)
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            gap={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <DoughnutChart
              width="240px"
              height="240px"
              data={annualReportData}
              loading={AnnualReportFetching}
            />
            <Stack justifyContent={"center"} gap={2}>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <div className="tw-w-5 tw-h-5 tw-rounded tw-bg-[#43A5FF]"></div>
                <Typography
                  fontSize={"12px"}
                  fontWeight={400}
                  color={mode === "dark" ? "#DCDCDC" : "#4B4B4B"}
                >
                  Pending
                </Typography>
              </Stack>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <div className="tw-w-5 tw-h-5 tw-rounded tw-bg-[#00FE93]"></div>
                <Typography
                  fontSize={"12px"}
                  fontWeight={400}
                  color={mode === "dark" ? "#DCDCDC" : "#4B4B4B"}
                >
                  Completed
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack> */}

      <Stack
        width={"100%"}
        borderRadius={"6px"}
        gap={4}
        padding={"40px"}
        bgcolor={mode === "dark" ? "#1A1A1A" : "white"}
        boxShadow={
          "0px 0px 1px rgba(12, 14, 25, 0.24), 0px 2px 12px rgba(12, 14, 25, 0.12)"
        }
      >
        <Typography
          fontSize={"20px"}
          fontWeight={500}
          color={mode === "dark" ? "#DCDCDC" : "#232323"}
          lineHeight={1}
        >
          Complaint Statistics
        </Typography>
        <Box width={"100%"} height={"260px"}>
          <LineGraph
            data={data}
            style={{
              backgroundColor: `${mode === "dark" ? "#4B4B4B" : "#FFFFFF"}`,
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
            }}
          />
        </Box>
      </Stack>
    </ContainerLayout>
  );
}

export default Dashboard;
/* eslint-enable no-unused-vars */
