import { createSlice } from "@reduxjs/toolkit";
import { trainingAPI } from "./TrainingAPI";

const initialState = {
  loading: false,
  trainingInfo: {},
  error: null,
  success: false,
};

const trainingSlice = createSlice({
  name: "training",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      trainingAPI.endpoints.createTraining.matchFulfilled,
      (state, { payload }) => {
        console.log("create training --->", payload);
      },
    );
    builder.addMatcher(
      trainingAPI.endpoints.updateTraining.matchFulfilled,
      (state, { payload }) => {
        console.log("update training --->", payload);
      },
    );
    builder.addMatcher(
      trainingAPI.endpoints.getAllTraining.matchFulfilled,
      (state, { payload }) => {
        console.log("get all training --->", payload);
      },
    );
    builder.addMatcher(
      trainingAPI.endpoints.deleteTraining.matchFulfilled,
      (state, { payload }) => {
        console.log("delete training --->", payload);
      },
    );
  },
});

export default trainingSlice.reducer;
