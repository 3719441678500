import React, { useEffect } from "react";
import InputField from "../../components/Form/InputField";
import { Button, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BackdropLoader } from "../../components/General";
import {
  useLoginMutation,
  useSuperAdminLoginMutation,
} from "../../store/auth/authApi";
import LoginAuthLayout from "../../components/Layouts/LoginAuthLayout";

function Login({ tab }) {
  const navigate = useNavigate();
  const [login, { isLoading: loading, isError, isSuccess: success, error }] =
    useLoginMutation();

  const [
    superadminLogin,
    {
      isLoading: superadminLoading,
      isError: superadminError,
      isSuccess: superadminSuccess,
      error: superadminErrorData,
    },
  ] = useSuperAdminLoginMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    if (superadminSuccess) {
      navigate("/superadmin/dashboard");
    }
    if (superadminError) {
      console.log("error -->", error);
      alert(superadminErrorData?.data?.msg || "Something went wrong");
    }
    if (superadminLoading) {
      console.log(superadminLoading);
    }
  }, [superadminSuccess, superadminLoading, superadminError]);

  useEffect(() => {
    if (success) {
      navigate("/home/dashboard");
    }
    if (isError) {
      console.log("error -->", error);
      alert(error?.data?.msg || "Something went wrong");
    }
    if (loading) {
      console.log(loading);
    }
  }, [success, loading, error]);

  const onSubmit = (data) => {
    console.log(data);
    if (tab === "superadmin") {
      superadminLogin(data);
    } else {
      login(data);
    }
  };

  const emailValidations = {
    required: "This field is required",
    validate: (value) => {
      let re =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return re.test(value) || "Email invalid";
    },
  };

  return (
    <LoginAuthLayout>
      <BackdropLoader open={loading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          sx={{ textAlign: "center", fontWeight: 700, marginBottom: 2 }}
        >
          {tab === "superadmin" ? "Super Admin Login" : "Admin Login"}
        </Typography>

        <Stack gap={3}>
          <InputField
            placeholder={"Enter Email"}
            label={"Email"}
            name={"email"}
            register={register}
            validations={emailValidations}
            error={errors?.email?.message}
            inputHeight={40}
          />
          <InputField
            placeholder={"Enter Password"}
            label={"Password"}
            type="password"
            name={"password"}
            register={register}
            validations={{ required: "This field is required" }}
            error={errors?.password?.message}
            inputHeight={40}
          />
          <Button
            variant="contained"
            type="submit"
            className="tw-text-white"
            sx={{
              borderRadius: "4px",
              backgroundColor: "#4B4B4B",
              marginTop: 2,
            }}
          >
            Login
          </Button>
        </Stack>
      </form>
    </LoginAuthLayout>
  );
}

export default Login;
