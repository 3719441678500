/* eslint-disable indent */
import React from "react";
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import { StatusBar } from "../../../components/General";
import { ColorModeContext } from "../../../constants/theme";
import CompanyPolicyLogo from "../../../assets/logos/CompanyPolicyLogo.svg";
import {
  Clause,
  ClauseLayout1,
  ClauseNestedLayout1,
  DownloadButton,
  ShortWomenOnly,
  ShortGenderNeutral,
} from "../../../components";
import {
  Amendments,
  Clause1,
  Confidentiality,
  FilingOfComplaintWithPolice,
  NonRetaliation,
  ProcedureForFilingAnAppeal,
  ProcedureForRedressal,
  backgroundClause,
  keyDefinitionsClauses,
  scopeClause,
} from "../../../utils/clauses/ShortWomenOnly";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { keyDefinitionsClausesSGN } from "../../../utils/clauses/ShortGenderNeutral";

function SelectShortClauseBank({ source = "short_women_only" }) {
  const { mode } = React.useContext(ColorModeContext);
  const [confirm, setConfirm] = React.useState(false);
  const clauses = {
    background:
      source === "short_women_only"
        ? {
            para1: true,
            para2: true,
          }
        : true,
    scope: true,
    keyDefinitions: true,
    procedureForRedressal: true,
    procedureForFilingAnAppeal: true,
    confidentiality: true,
    nonRetaliation: true,
    filingOfComplaintWithPolice: true,
    amendments: true,
    clause1: false,
    clause2: false,
  };
  const handleClauseChange = (state, action) => {
    /*eslint indent: ["error", 2, { "SwitchCase": 1 }]*/
    switch (action.type) {
      case "background":
        return source === "short_women_only"
          ? {
              ...state,
              background: { ...state.background, ...action.payload },
            }
          : { ...state, background: !state.background };
      case "scope":
        return { ...state, scope: !state.scope };
      case "keyDefinitions":
        return { ...state, keyDefinitions: !state.keyDefinitions };
      case "procedureForRedressal":
        return {
          ...state,
          procedureForRedressal: !state.procedureForRedressal,
        };
      case "procedureForFilingAnAppeal":
        return {
          ...state,
          procedureForFilingAnAppeal: !state.procedureForFilingAnAppeal,
        };
      case "confidentiality":
        return { ...state, confidentiality: !state.confidentiality };
      case "nonRetaliation":
        return { ...state, nonRetaliation: !state.nonRetaliation };
      case "filingOfComplaintWithPolice":
        return {
          ...state,
          filingOfComplaintWithPolice: !state.filingOfComplaintWithPolice,
        };
      case "amendments":
        return { ...state, amendments: !state.amendments };
      case "clause1":
        return { ...state, clause1: !state.clause1 };
      case "clause2":
        return { ...state, clause2: !state.clause2 };
      default:
        return state;
    }
  };
  const [state, dispatch] = React.useReducer(handleClauseChange, clauses);

  return (
    <Box
      sx={{
        background: `${mode === "dark" ? "#000000" : " #FFF"}`,
        minHeight: "100vh",
        width: "100%",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        gap: "50px",
        padding: "40px",
      }}
    >
      <Stack
        direction={"row"}
        columnGap={4}
        justifyContent={"flex-start"}
        alignItems={"center"}
        marginBottom={2}
        marginTop={10}
      >
        <img
          src={CompanyPolicyLogo}
          height={"36px"}
          alt="Comapny Policy Logo"
        />
        <Typography
          className="tw-font-semibold tw-text-4xl"
          sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
        >
          Clause bank
        </Typography>
        <StatusBar label={"PENDING"} status={"PENDING"} />
      </Stack>
      <Typography
        sx={{ fontSize: "20px", fontWeight: 500, marginTop: -5 }}
        className="inter-font"
      >
        Select Clause to build your company policy
      </Typography>
      <Stack direction={"column"} gap={2}>
        {source === "short_women_only" && (
          <Clause
            heading={"1. Background"}
            value={state.background.para1 && state.background.para2}
            handleChange={() =>
              dispatch({
                type: "background",
                payload: {
                  para1: !(state.background.para1 && state.background.para2),
                  para2: !(state.background.para1 && state.background.para2),
                },
              })
            }
          >
            <Stack direction={"column"} gap={1}>
              <Stack direction={"row"} gap={2} alignItems={"flex-start"}>
                <Checkbox
                  checked={state.background.para1}
                  onChange={() =>
                    dispatch({
                      type: "background",
                      payload: {
                        para1: !state.background.para1,
                      },
                    })
                  }
                  sx={{ padding: 0 }}
                />
                <Typography paragraph>{backgroundClause[0]}</Typography>
              </Stack>
              <Stack direction={"row"} gap={2} alignItems={"flex-start"}>
                <Checkbox
                  checked={state.background.para2}
                  onChange={() =>
                    dispatch({
                      type: "background",
                      payload: {
                        para2: !state.background.para2,
                      },
                    })
                  }
                  sx={{ padding: 0 }}
                />
                <Typography paragraph>{backgroundClause[1]}</Typography>
              </Stack>
            </Stack>
          </Clause>
        )}
        {source === "short_gender_neutral" && (
          <Clause
            heading={"1. Background"}
            value={state.background}
            handleChange={() => dispatch({ type: "background" })}
          >
            <ClauseLayout1 body={backgroundClause} />
          </Clause>
        )}
        <Clause
          heading={"2. Scope"}
          value={state.scope}
          handleChange={() => dispatch({ type: "scope" })}
        >
          <ClauseLayout1 body={scopeClause} />
        </Clause>
        <Clause
          heading={"3. Key Definitions"}
          value={state.keyDefinitions}
          handleChange={() => dispatch({ type: "keyDefinitions" })}
        >
          <ClauseNestedLayout1
            body={
              source === "short_women_only"
                ? keyDefinitionsClauses
                : keyDefinitionsClausesSGN
            }
          />
        </Clause>
        <Clause
          heading={"4. Procedure for Redressal"}
          value={state.procedureForRedressal}
          handleChange={() => dispatch({ type: "procedureForRedressal" })}
        >
          <ClauseNestedLayout1 body={ProcedureForRedressal} />
        </Clause>
        <Clause
          heading={"5. PROCEDURE FOR FILING AN APPEAL"}
          value={state.procedureForFilingAnAppeal}
          handleChange={() => dispatch({ type: "procedureForFilingAnAppeal" })}
        >
          <ClauseLayout1 body={ProcedureForFilingAnAppeal} />
        </Clause>
        <Clause
          heading={"6. Confidentiality"}
          value={state.confidentiality}
          handleChange={() => dispatch({ type: "confidentiality" })}
        >
          <ClauseLayout1 body={Confidentiality} />
        </Clause>
        <Clause
          heading={"7. Non-retaliation"}
          value={state.nonRetaliation}
          handleChange={() => dispatch({ type: "nonRetaliation" })}
        >
          <ClauseLayout1 body={NonRetaliation} />
        </Clause>
        <Clause
          heading={"8. FILING OF COMPLAINT WITH POLICE"}
          value={state.filingOfComplaintWithPolice}
          handleChange={() => dispatch({ type: "filingOfComplaintWithPolice" })}
        >
          <ClauseLayout1 body={FilingOfComplaintWithPolice} />
        </Clause>
        <Clause
          heading={"9. AMENDMENTS"}
          value={state.amendments}
          handleChange={() => dispatch({ type: "amendments" })}
        >
          <ClauseLayout1 body={Amendments} />
        </Clause>
        <Clause
          heading={"10. Clause 1"}
          value={state.clause1}
          handleChange={() => dispatch({ type: "clause1" })}
        >
          <ClauseLayout1 body={Clause1} />
        </Clause>
        <Clause
          heading={"11. Clause 2"}
          value={state.clause2}
          handleChange={() => dispatch({ type: "clause2" })}
        >
          <ClauseLayout1 body={Clause1} />
        </Clause>
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        gap={3}
      >
        <Checkbox onChange={() => setConfirm(!confirm)} />
        <Typography>
          I Confirm that i had read and understood the clauses
        </Typography>
      </Stack>
      {confirm ? (
        <PDFDownloadLink
          document={
            source === "short_women_only" ? (
              <ShortWomenOnly clauses={state} />
            ) : (
              <ShortGenderNeutral clauses={state} />
            )
          }
          style={{ margin: "auto" }}
          fileName={
            source === "short_women_only"
              ? "ShortWomenOnlyPolicy"
              : "ShortGenderNeutralPolicy"
          }
        >
          <DownloadButton
            label="Download Company Policy"
            onClick={() => console.log(state)}
          />
        </PDFDownloadLink>
      ) : (
        <DownloadButton
          label="Download Company Policy"
          disabledValue={true}
          styles={{ margin: "auto" }}
          onClick={() => console.log(state)}
        />
      )}
    </Box>
  );
}

export default SelectShortClauseBank;
