import React from "react";
import { Document } from "@react-pdf/renderer";
import { longPolicyClauses } from "../../../constants/initialValues";
import { policyStyles } from "../PDFStyles";
import { getPolicyIndices } from "../../../utils/policyHelpers";
import LongPolicyView from "./LongPolicyView";

const initialClauses = longPolicyClauses;

function WomenOnly({ clauses = initialClauses }) {
  const Indices = getPolicyIndices(clauses);

  return (
    <Document style={policyStyles.body}>
      <LongPolicyView
        styles={policyStyles}
        Indices={Indices}
        clauses={clauses}
      />
    </Document>
  );
}

export default WomenOnly;
