import React, { useEffect, useState } from "react";
import {
  useGetContactQuery,
  useUpdateContactMutation,
} from "../../../store/org/orgApi";
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  useGetAllPocTypesQuery,
  useGetEmployementLevelsQuery,
  useGetServiceRulesQuery,
  useGetWorkLocationsQuery,
} from "../../../store/dropdown/dropdownApi";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { FromHeading } from "../../../components/OrgDetails";
import { FileUploadOutlined } from "@mui/icons-material";
import { Genders } from "../../../utils/dropdowns";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import {
  CheckboxField2,
  InputField2,
  SelectField2,
} from "../../../components/Form";
import { ContainerLayout } from "../../../components/Layouts";
import { NavigationBar } from "../../../components/General";
import Swal from "sweetalert2";

function EditContact() {
  const navigate = useNavigate();
  const url = window.location.href;
  const ID = url.substring(url.lastIndexOf("/") + 1);
  const { data, isSuccess } = useGetContactQuery(ID);
  const TabScreen = useMediaQuery("(min-width: 640px)");
  useGetEmployementLevelsQuery();
  useGetWorkLocationsQuery();
  useGetServiceRulesQuery();
  useGetAllPocTypesQuery();
  const { dropdowns } = useSelector((state) => state.dropdown);
  const [InitialData, setInitialData] = useState({});

  const [updateContact, { isSuccess: updateSuccess }] =
    useUpdateContactMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    if (isSuccess) {
      setInitialData(data?.employee);
      console.log("employee ---->", data?.employee);
      const obj = { ...data?.employee };
      obj.date_of_birth = new Date(data?.employee?.date_of_birth);
      setValue("gender", obj.gender);
      for (let key in obj) {
        if (obj[key] === null) {
          obj[key] = "";
        }
      }
      if (!obj.employment_level_id) {
        obj.employment_level_id = " ";
      }
      if (!obj.location_id) {
        obj.location_id = " ";
      }
      if (!obj.services_rules_id) {
        obj.services_rules_id = " ";
      }
      if (!obj.poc_id) {
        obj.poc_id = " ";
      }
      console.log("obj ---->", obj);

      reset(obj);
    }
    if (updateSuccess) {
      Swal.fire({
        title: "Updated",
        text: "Contacts has been updated",
        icon: "success",
        timer: 1500,
      });
      navigate("/home/company-profile");
    }
  }, [isSuccess, InitialData, updateSuccess]);

  const onSubmit = (data) => {
    const obj = {};
    obj.firstName = data.firstName;
    obj.lastName = data.lastName;
    obj.email = data.email;
    obj.date_of_birth = data.date_of_birth;
    obj.phone_number = data.phone_number;
    obj.alternateEmail = data.alternateEmail;
    obj.gender = data.gender;
    obj.designation = data.designation;
    obj.employment_level_id = data.employment_level_id;
    obj.is_poc = data.is_poc;
    obj.poc_id = data.poc_id;
    obj.image = data.image;
    obj.services_rules_id = data.services_rules_id;
    updateContact({ body: obj, id: ID });
    console.log("obj --->", obj);
  };

  const validations = {
    required: "This field is required",
  };

  const emailValidations = {
    required: "This field is required",
    validate: (value) => {
      let re =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return re.test(value) || "Email invalid";
    },
  };

  const phonevalidations = {
    required: "This field is required",
    validate: (value) => {
      let re = /(7|8|9)\d{9}/;
      return re.test(value) || "Please match the format";
    },
  };

  const breadcrumbs = [
    <Typography className="tw-text-black tw-text-[11px]" key="100">
      company profile
    </Typography>,
    <Typography className="tw-text-dark-blue tw-text-[11px]" key="200">
      Edit Contacts
    </Typography>,
  ];

  return (
    <ContainerLayout paddingTop="0">
      <NavigationBar breadcrumbs={breadcrumbs} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-w-full tw-mb-4 sm:tw-mb-6 lg:tw-mb-12">
          <FromHeading text="Edit Contact" />
        </div>
        <div className="tw-bg-light-blue tw-p-3 sm:tw-p-6 lg:tw-p-12 tw-rounded-3 tw-mb-4 sm:tw-mb-8">
          <Grid container spacing={TabScreen ? 4 : 2}>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField2
                label={"First Name"}
                placeholder={"Enter name"}
                name={`firstName`}
                control={control}
                validations={validations}
                defaultValue=""
                error={errors?.firstName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField2
                label={"Last Name"}
                placeholder={"Enter name"}
                name={`lastName`}
                control={control}
                validations={validations}
                defaultValue=""
                error={errors?.lastName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField2
                label={"Email"}
                placeholder={"Enter"}
                name={`email`}
                control={control}
                validations={emailValidations}
                defaultValue=""
                error={errors?.email?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <div className="tw-gap-2 tw-flex tw-flex-col">
                <InputLabel
                  className="tw-text-xs sm:tw-text-sm  tw-w-min tw-px-2 tw-py-1 tw-rounded-md tw-bg-label-bg"
                  htmlFor="orgName"
                >
                  Birth Date
                </InputLabel>
                <Controller
                  control={control}
                  name="date_of_birth"
                  defaultValue={new Date()}
                  rules={{
                    required: "This field is required",
                    validate: (value) => {
                      console.log("validation ---->", value, typeof value);
                      const check = new Date(value) > new Date();
                      return !check || "Please select a valid date";
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      onChange={onChange}
                      format="DD/MM/YYYY"
                      className="tw-bg-input-bg"
                      value={moment(value)}
                    />
                  )}
                />

                {errors?.date_of_birth?.message && (
                  <span className="tw-text-red-700">
                    {errors?.date_of_birth?.message}
                  </span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField2
                label={"Phone"}
                placeholder={"Enter"}
                name={`phone_number`}
                control={control}
                validations={phonevalidations}
                defaultValue=""
                error={errors?.phone_number?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField2
                label={"Alternate Email"}
                placeholder={"Enter"}
                name={`alternateEmail`}
                control={control}
                defaultValue=""
                error={errors?.alternateEmail?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <div className="tw-gap-2 tw-flex tw-flex-col">
                <InputLabel
                  className="tw-text-xs sm:tw-text-sm  tw-w-min tw-px-2 tw-py-1 tw-rounded-md tw-bg-label-bg"
                  htmlFor="orgName"
                >
                  Gender
                </InputLabel>

                <Controller
                  name="gender"
                  rules={{ ...validations }}
                  control={control}
                  defaultValue={""}
                  render={({ field: { value, name, onChange } }) => (
                    <Select
                      onChange={onChange}
                      name={name}
                      value={value || ""}
                      className="tw-border-black tw-bg-input-bg"
                    >
                      <MenuItem hidden disabled value="">
                        Select
                      </MenuItem>
                      {Genders.map((m) => {
                        return (
                          <MenuItem key={m.id} value={m.name}>
                            {m.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
                {errors?.gender?.message && (
                  <span className="tw-text-red-700">
                    {errors?.gender?.message}
                  </span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField2
                label="Designation in Organization"
                placeholder={"Enter"}
                name={`designation`}
                defaultValue=""
                control={control}
                validations={validations}
                error={errors?.designation?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField2
                label="Department"
                placeholder={"Enter"}
                name={`department_id`}
                control={control}
                validations={validations}
                defaultValue=""
                error={errors?.department_id?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectField2
                label={"Level of Employement"}
                placeholder={"Enter"}
                menus={
                  dropdowns?.EmploymentLevels?.length > 0
                    ? dropdowns?.EmploymentLevels
                    : []
                }
                control={control}
                name={`employment_level_id`}
                validations={validations}
                error={errors?.employment_level_id?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectField2
                label={"Location of work"}
                placeholder={"Enter"}
                menus={
                  dropdowns?.WorkLocations?.length > 0
                    ? dropdowns?.WorkLocations
                    : []
                }
                name={`location_id`}
                control={control}
                validations={validations}
                error={errors?.location_id?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectField2
                name="services_rules_id"
                label={"Service Rules"}
                placeholder={"Enter"}
                menus={
                  dropdowns?.ServiceRules?.length > 0
                    ? dropdowns?.ServiceRules
                    : []
                }
                control={control}
                validations={validations}
                error={errors?.services_rules_id?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField2
                name={`is_poc`}
                control={control}
                label={"POC"}
                defaultValue={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectField2
                label={"POC Type"}
                placeholder={"Enter"}
                menus={
                  dropdowns?.POCTypes?.length > 0 ? dropdowns?.POCTypes : []
                }
                name={`poc_id`}
                control={control}
                error={errors?.poc_id?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
              <div className="tw-gap-4 tw-flex max-[400px]:tw-flex-col tw-items-center tw-h-[100%] tw-justify-center">
                <InputLabel className="tw-text-xs sm:tw-text-sm tw-text-center tw-whitespace-normal">
                  {getValues(`image`) ? getValues(`image`) : "Upload Image"}
                </InputLabel>
                <Button
                  sx={{ backgroundColor: "white" }}
                  component="label"
                  variant="outlined"
                >
                  Choose
                  <input
                    hidden
                    onChange={() => {
                      setValue(`image`, "https://picsum.photos/200");
                    }}
                    accept="image/*"
                    type="file"
                  />
                </Button>
                <Button
                  component="label"
                  variant="contained"
                  className="tw-bg-gray sm:tw-gap-2"
                >
                  <FileUploadOutlined />
                  Upload
                  <input
                    hidden
                    onChange={() => {
                      setValue(`image`, "https://picsum.photos/200");
                    }}
                    accept="image/*"
                    type="file"
                  />
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>

        {/* Submit Buttons */}
        <div className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-justify-end tw-space-y-5 sm:tw-space-x-8 sm:tw-space-y-0 tw-mt-6 md:tw-mt-8 lg:tw-mt-16">
          <Button
            variant="contained"
            type="submit"
            className="tw-bg-black tw-text-white sm:tw-min-w-[30%]  sm:tw-h-10"
          >
            Save Changes
          </Button>
        </div>
      </form>
    </ContainerLayout>
  );
}

export default EditContact;
