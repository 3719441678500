import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function Scope({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{serialNo}. Scope</Text>
      <Text style={styles.text}>
        This Policy extends to all employees of the Organization and to
        individuals coming to the workplace for employment or for any other
        purpose whatsoever including, but not limited to visitors, vendors,
        contractual resources, secondees. It also applies to employees working
        virtually or from any other location as may be necessary for the
        employees to perform their duties. All employees of the Organization
        have a personal responsibility to ensure that their conduct is not
        contrary to this Policy.
      </Text>
      <Text style={styles.text}>
        The purpse of the Policy isto provide protection against sexual
        harassment of women at workplace and the prevention and redressal of
        complaints of sexual harassment and matters related to it.
      </Text>
    </View>
  );
}

export default Scope;
