import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function ProcedureForFilingAppeal({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {serialNo}. PROCEDURE FOR FILING AN APPEAL
      </Text>
      <Text style={styles.text}>
        Any party aggrieved (Complainant or Respondent) by the recommendations
        of the IC and / or non-implementation of recommendations may file an
        appeal to the Appellate Authority as per service rules and/or as per
        other provisions of Law within 90 (ninety) days of the recommendations.
      </Text>
      <Text style={styles.text}>
        In case the inquiry process is conducted by the IC and the Complainant
        is not a woman, any party aggrieved (Complainant or Respondent) by the
        recommendations of the IC and / or non-implementation of recommendations
        can get in touch with [ ], who will review the appeal.
      </Text>
    </View>
  );
}

export default ProcedureForFilingAppeal;
