import React, { useContext } from "react";
import PageHeading from "../../../components/General/PageHeading";
import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import ArchiveLogo from "../../../assets/logos/ArchivesLogo.svg";
import { ColorModeContext } from "../../../constants/theme";

import {
  IcArchivesCom,
  OiwArchivesCom,
  PolicyArchivesCom,
} from "../../../components";
import { useGetAllOrgsQuery } from "../../../store/org/orgApi";
import { Compliances } from "../../../utils/dropdowns";

const Archives = () => {
  const { mode } = useContext(ColorModeContext);
  const [orgs, setOrgs] = React.useState([]);
  const [selectCompliances, setSelectCompliances] = React.useState("select");
  const [orgId, setOrgId] = React.useState("select");

  const handleChangeOrg = (e) => {
    setOrgId(e.target.value);
  };

  const handleCompliances = (e) => {
    setSelectCompliances(e.target.value);
  };

  const { data: orgData } = useGetAllOrgsQuery({
    source: "ic",
  });

  React.useEffect(() => {
    let temp = [];
    if (orgData?.org) {
      temp = orgData?.org?.map((org) => {
        return {
          id: org?.id,
          name: org?.name || "",
        };
      });
    }
    setOrgs(temp || []);
  }, [orgData, setOrgs]);

  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      paddingX={"80px"}
      rowGap={"26px"}
      sx={{ background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}` }}
    >
      <PageHeading label={"Archives"} logo={ArchiveLogo} />

      <Box
        paddingY={"26px"}
        paddingX={"46px"}
        bgcolor={mode === "dark" ? "#151515" : "#F9EFFF"}
        width={"100%"}
        className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 tw-gap-5"
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={2}
        >
          <InputLabel>Company</InputLabel>
          <Select
            sx={{ height: "40px", flex: 1 }}
            className="tw-border-black  tw-rounded-lg"
            onChange={handleChangeOrg}
            value={orgId}
          >
            <MenuItem value={"select"}>Select</MenuItem>
            {orgs.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={2}
        >
          <InputLabel>Compliance</InputLabel>
          <Select
            sx={{ height: "40px", flex: 1 }}
            value={selectCompliances}
            onChange={handleCompliances}
            className="tw-border-black  tw-rounded-lg"
          >
            <MenuItem value={"select"}>Select</MenuItem>
            {Compliances.map((s) => (
              <MenuItem key={s.id} value={s.name}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      {selectCompliances === "Internal Commitee" && (
        <IcArchivesCom
          orgId={orgId}
          styles="superadmin-data-grid-div"
          action={true}
        />
      )}
      {selectCompliances === "Order In Writing" && (
        <OiwArchivesCom orgId={orgId} styles="superadmin-data-grid-div" />
      )}
      {selectCompliances === "Company Policy" && (
        <PolicyArchivesCom orgId={orgId} styles="superadmin-data-grid-div" />
      )}
    </Box>
  );
};

export default Archives;
