import React from "react";
import {
  Box,
  Button,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { ColorModeContext } from "../../../constants/theme";
import CompanyPolicyLogo from "../../../assets/logos/CompanyPolicyLogo.svg";
import { StatusBar } from "../../../components/General";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {
  DownloadButton,
  GenderNeutral,
  ShortGenderNeutral,
  ShortWomenOnly,
  WomenOnly,
} from "../../../components";
import { useNavigate } from "react-router-dom";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useGetOrgDetailsQuery } from "../../../store/org/orgApi";
import { useSelector } from "react-redux";
import { Status } from "../../../utils/options";

function SelectPolicyPage() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth?.user);
  const { data: org } = useGetOrgDetailsQuery(user?.org_id);
  const { mode } = React.useContext(ColorModeContext);
  const [selectedPdf, setSelectedPdf] = React.useState("select");
  const [confirm, setConfirm] = React.useState(false);
  const [withoutClauses, setWithoutClauses] = React.useState(false);

  const handleProceed = () => {
    if (selectedPdf === "women_only") {
      navigate("/home/clause-bank-wo");
    }
    if (selectedPdf === "gender_neutral") {
      navigate("/home/clause-bank-gn");
    }
    if (selectedPdf === "short_women_only") {
      navigate("/home/clause-bank-swo");
    }
    if (selectedPdf === "short_gender_neutral") {
      navigate("/home/clause-bank-sgn");
    }
  };

  React.useEffect(() => {
    console.log(confirm);
    console.log(withoutClauses);
  }, [confirm, withoutClauses]);

  return (
    <Box
      className="tw-p-10"
      sx={{
        background: `${mode === "dark" ? "#1A1A1A" : " #FFF"}`,
        height: `${selectedPdf !== "select" ? "100%" : "100vh"}`,
        width: "100%",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        gap: "50px",
        padding: "40px",
      }}
    >
      <Stack
        direction={"row"}
        columnGap={4}
        justifyContent={"flex-start"}
        alignItems={"center"}
        marginBottom={2}
        marginTop={10}
      >
        <img
          src={CompanyPolicyLogo}
          height={"36px"}
          alt="Comapny Policy Logo"
        />
        <Typography
          className="tw-font-semibold tw-text-4xl"
          sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
        >
          Company Policy
        </Typography>
        <StatusBar
          label={
            org?.org?.policy_status === Status.APPROVED
              ? "COMPLETED"
              : org?.org?.policy_status
          }
          status={org?.org?.policy_status}
        />
      </Stack>
      <Box className="tw-w-[70%] lg:tw-w-[646px] tw-mx-auto tw-py-10 tw-flex tw-flex-col tw-space-y-5">
        <Stack direction={"column"} alignItems={"flex-start"} gap={2}>
          <InputLabel sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}>
            Select Template
          </InputLabel>
          <Select
            disabled={org?.org?.policy_status === Status.APPROVAL}
            sx={{ height: "35px", width: "200px" }}
            className="tw-border-black"
            value={selectedPdf}
            onChange={(e) => setSelectedPdf(e.target.value)}
          >
            <MenuItem value={"select"}>Select</MenuItem>
            <MenuItem value={"gender_neutral"}>Gender Neutral</MenuItem>
            <MenuItem value={"women_only"}>Women Only</MenuItem>
            <MenuItem value={"short_gender_neutral"}>
              Short Gender Neutral
            </MenuItem>
            <MenuItem value={"short_women_only"}>Short Women Only</MenuItem>
          </Select>
        </Stack>
        {selectedPdf !== "select" && (
          <PDFViewer
            showToolbar={false}
            style={{
              height: "600px",
              width: "100%",
            }}
          >
            {selectedPdf === "gender_neutral" && <GenderNeutral />}
            {selectedPdf === "women_only" && <WomenOnly />}
            {selectedPdf === "short_gender_neutral" && <ShortGenderNeutral />}
            {selectedPdf === "short_women_only" && <ShortWomenOnly />}
          </PDFViewer>
        )}
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={3}
        >
          <Checkbox
            disabled={selectedPdf === "select"}
            onChange={() => setConfirm(!confirm)}
          />
          <Typography sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}>
            I CONFIRM THAT I HAVE READ THE INSTRUCTIONS Of the this template
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={3}
        >
          <Checkbox
            disabled={selectedPdf === "select"}
            onChange={() => setWithoutClauses(!withoutClauses)}
          />
          <Typography sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}>
            Move without clauses
          </Typography>
        </Stack>
        <Stack direction={"row"} justifyContent={"center"}>
          {withoutClauses ? (
            <PDFDownloadLink
              document={<ShortWomenOnly />}
              fileName="ShortWomenOnlyPolicy"
            >
              <DownloadButton
                disabledValue={!confirm}
                styles={{
                  height: "35px",
                  fontSize: "14px",
                  fontWeight: 400,
                  width: "120px",
                }}
              />
            </PDFDownloadLink>
          ) : (
            <Button
              variant="contained"
              disabled={!confirm}
              onClick={handleProceed}
              sx={{
                backgroundColor: "#4B4B4B",
                fontSize: "14px",
                fontWeight: 400,
                height: "35px",
                width: "80px",
                padding: 0,
              }}
            >
              Next
            </Button>
          )}
        </Stack>
      </Box>
    </Box>
  );
}

export default SelectPolicyPage;
