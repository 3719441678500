import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../constants";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
      console.log("building headers");
      return headers;
    },
  }),
  endpoints: (build) => ({
    registerProfile: build.mutation({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
    }),
    registerOrg: build.mutation({
      query: (body) => ({
        url: "v1/auth/register",
        method: "POST",
        body,
      }),
    }),
    login: build.mutation({
      query: (body) => ({
        url: "v1/auth/user-login",
        method: "POST",
        body,
      }),
    }),
    changePassword: build.mutation({
      query: (body) => ({
        url: "v1/auth/change-password",
        method: "PUT",
        body,
      }),
    }),
    superAdminLogin: build.mutation({
      query: (body) => ({
        url: "v1/super-admin/login",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useRegisterProfileMutation,
  useRegisterOrgMutation,
  useLoginMutation,
  useChangePasswordMutation,
  useSuperAdminLoginMutation,
} = authApi;
