import React, { useContext } from "react";
import Logo from "../../assets/LogoWithText.svg";
import DarkLogo from "../../assets/DarkLogoWithText.svg";
import { Box, Stack } from "@mui/material";
import { ColorModeContext } from "../../constants/theme";

const LoginAuthLayout = ({ children }) => {
  const { mode } = useContext(ColorModeContext);

  return (
    <Box
      sx={{
        background: `${mode === "dark" ? "#0D0D0D" : "#EEF8FF"}`,
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: 2,
      }}
    >
      <Stack
        gap={3}
        boxShadow={
          "0px 0px 2px rgba(12, 14, 25, 0.12), 0px 8px 20px rgba(12, 14, 25, 0.12)"
        }
        borderRadius={"24px"}
        alignItems={"center"}
        paddingX={10}
        paddingY={5}
        sx={{ background: `${mode === "dark" ? "#000" : "#FFF"}` }}
        className="tw-w-[90%] sm:tw-w-[500px]"
      >
        <img
          src={mode === "dark" ? DarkLogo : Logo}
          style={{ width: "150px", height: "200px" }}
          alt="Logo"
        />
        <Box className="lg:tw-h-full tw-w-full">{children}</Box>
      </Stack>
    </Box>
  );
};

export default LoginAuthLayout;
