export const ResovlePoint1 = [
  {
    id: "sdfdfgdg",
    point: `To formulate and disseminate the Policy on Prevention of Sexual Harassment at Workplace for the Company to inform employees about the IC members and the Company’s policy. The Policy shall be subject to adoption by the Company and any necessary amendments made to the Policy (other than the amendments that are necessary to carry out any change in the Act) from time to time shall not take effect unless adopted by the Company. `,
  },
  {
    id: "erteryrtyt",
    point: `To organize workshops and awareness programs at regular intervals for sensitizing employees with the provisions of the Act and orientation programs, capacity building and skill building programs for members of IC in accordance with Section 19 (c) of the Act read with Rules 13 (b), (c) and (d) of the Sexual Harassment of Women at Workplace (Prevention, Prohibition & Redressal) Rules, 2013 (“Rules”). `,
  },
  {
    id: "erteryrtytgjgh",
    point: `To appoint, remove or replace IC members in accordance with the Law and also implement actions recommended by IC.`,
  },
  {
    id: "erteryrtytgfdhghfgfggjgh",
    point: `To do all such things necessary for effective compliance of the Act, Rules and any other notices / amendments / circulars / notifications / case laws etc. including filling up vacancies in IC and sign and/or submit all the necessary papers, letters, forms, documents etc., on behalf of the Company in order to comply with various requirements under the Act and the Rules. All acts done pursuant to this resolution shall be binding on the Company, until the same is withdrawn/ rescinded.`,
  },
];

export const ResovlePoint = [
  {
    id: "sdfdfgdg",
    point: `To assist the Company/authorized person in organizing workshops and awareness programs at regular intervals for sensitizing employees with the provisions of the Act and orientation programs, capacity building and skill building programs for members of IC in accordance with Section 19 (c) of the Act read with Rules 13 (b), (c) and (d) of the Sexual Harassment of Women at Workplace (Prevention, Prohibition & Redressal) Rules, 2013 (“Rules”).`,
  },
  {
    id: "erteryrtyt",
    point: `To assist the Company/authorized person in doing all such things necessary for effective compliance with various provisions of the Act, Rules and any other notices / amendments / circulars / notifications / case laws etc. that may become applicable from time to time. `,
  },
];
