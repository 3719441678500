import React from "react";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Font,
} from "@react-pdf/renderer";
import Roboto from "../../../../../src/assets/font/Roboto-Bold.ttf";
import RobotoRegular from "../../../../../src/assets/font/Roboto-Regular.ttf";
import RegistrationTable from "../RegistrationTable";

Font.register({
  family: "MyFont",
  fonts: [{ src: Roboto, fontWeight: "bold" }],
});
Font.register({
  family: "RegularFont",
  fonts: [{ src: RobotoRegular, fontWeight: "normal" }],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 100,
    fontSize: 8,
  },
  heading: {
    marginTop: 20,
    textAlign: "center",
    fontFamily: "MyFont",
    display: "flex",
    lineHeight: 1.5,
  },
  title: {
    marginTop: 10,
    display: "flex",
    fontFamily: "RegularFont",
    textAlign: "left",
  },
  text: {
    paddingTop: 20,
    display: "flex",
    fontFamily: "RegularFont",
    textAlign: "left",
  },
  bottomText: {
    display: "flex",
    fontFamily: "RegularFont",
    textAlign: "left",
  },
});

const KarnatakaEmailDoc = ({ ICEmployee, representativeName, orgData }) => {
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.heading}>
          Subject: Details regarding the Internal Committee “IC” constituted by
          as per the Order dated 25th November 2020 issued by the Karnataka
          State Commission for Women
        </Text>
        <Text style={styles.title}>Dear Madam/Sir</Text>
        <Text style={styles.text}>
          Please find below the details of the members of the IC constituted by
          {orgData.name}, to be autofilled for its workplace situated in
          Karnataka having address at {orgData.address}”
        </Text>
        <View style={{ marginTop: 15 }}>
          <RegistrationTable ICEmployee={ICEmployee} />
        </View>
        <View style={{ paddingTop: 20 }}>
          <Text style={styles.bottomText}>Yours faithfully</Text>
          <Text style={styles.bottomText}>{representativeName}</Text>
          {/* <Text style={styles.bottomText}>Designation</Text> */}
        </View>
      </Page>
    </Document>
  );
};

export default KarnatakaEmailDoc;
