import { InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

function SelectYear({ value, handleChange }) {
  return (
    <>
      <InputLabel>Select Year</InputLabel>
      <Select
        sx={{ height: "35px", width: "160px" }}
        className="tw-border-black  tw-rounded-lg"
        value={value}
        onChange={handleChange}
      >
        <MenuItem value={"2023"}>2023</MenuItem>
        <MenuItem value={"2022"}>2022</MenuItem>
        <MenuItem value={"2021"}>2021</MenuItem>
        <MenuItem value={"2020"}>2020</MenuItem>
        <MenuItem value={"2019"}>2019</MenuItem>
        <MenuItem value={"2018"}>2018</MenuItem>
      </Select>
    </>
  );
}

export default SelectYear;
