import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import AdditionICMembers from "./Annexure/AdditionICMembers";
import RemovalICMembers from "./Annexure/RemovalICMembers";
import { ResovlePoint, ResovlePoint1 } from "./RelovePoints";
import { styles } from "../PDFStyling/OIW/OiwStyle";

const CheckedClauses = ({ newIcMembers, authority }) => {
  return (
    <Document>
      <Page size={"A4"} style={styles.body}>
        <Text style={styles.title}>
          CONSTITUTION OF INTERNAL COMMITTEE UNDER LAW AGAINST SEXUAL HARASSMENT
          AT WORKPLACE
        </Text>
        <View style={styles.container}>
          <View style={styles.container}>
            <Text style={styles.title}>(Order in Writing)</Text>
            <View style={styles.textContainer}>
              <Text style={styles.text}>
                CERTIFIED TRUE COPY OF THE RESOLUTION PASSED AT THE MEETING OF
                THE BOARD OF DIRECTORS OF (Company Name)
                ___________________________________ HELD ON (Date)
                _______________ AT (Address)
                ____________________________________________________
              </Text>
              <View style={styles.textContainer}>
                <Text style={styles.text}>
                  RESOLVED THAT pursuant to Section 4 (1) of the Sexual
                  Harassment of Women at Workplace (Prevention, Prohibition &
                  Redressal) Act, 2013 (“Act”), the Board of the Company be and
                  hereby constitutes the Internal Committee (“IC”) for redressal
                  of complaints of sexual harassment at workplace.
                </Text>
              </View>
              <View style={styles.textContainer}>
                <Text style={styles.text}>
                  RESOLVED FURTHER THAT the IC for the Company shall be
                  constituted, including addition and removal of the members (as
                  the case may be), as provided in Annexure A of this Board
                  Resolution.
                </Text>
              </View>
              <View style={styles.textContainer}>
                <Text style={styles.text}>
                  RESOLVED FURTHER THAT{" "}
                  {`${authority.compliance} and ${authority.signingAuthority}`}{" "}
                  be and is hereby authorized to do and undertake the following
                  activities and actions:
                </Text>
              </View>
              <View style={styles.textContainer}>
                {ResovlePoint1.map((resolveText, indx) => (
                  <View
                    key={resolveText.id}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 5,
                    }}
                  >
                    <Text style={styles.text}>{indx + 1}.</Text>
                    <Text style={styles.relovePointText}>
                      {resolveText.point}
                    </Text>
                  </View>
                ))}
              </View>

              <View style={styles.textContainer}>
                <Text style={styles.text}>
                  RESOLVED FURTHER THAT the IC members are authorized, jointly
                  or severally, to and undertake the following activities and
                  actions:
                </Text>
              </View>
              <View style={styles.textContainer}>
                {ResovlePoint.map((resolveText, indx) => (
                  <View
                    key={resolveText.id}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 20,
                    }}
                  >
                    <Text style={styles.text}>{indx + 1}.</Text>
                    <Text style={styles.relovePointText}>
                      {resolveText.point}
                    </Text>
                  </View>
                ))}
              </View>
              <View style={styles.textContainer}>
                <Text style={styles.text}>
                  REESOLVED FURTHER THAT, a copy of the above resolution duly
                  certified as true by authorized signatory of the Company be
                  furnished to parties as may be required from time to time in
                  connection with the above matter..
                </Text>
              </View>
            </View>
            <View>
              <Text style={styles.signText}>For the Organization, </Text>
              <Text style={styles.signText}>(Seal & Signature) </Text>
              <Text style={styles.signText}>
                Name: __________________________________
              </Text>
              <Text style={styles.signText}>
                Designation: _____________________________
              </Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size={"A4"} style={styles.body}>
        <Text style={styles.title}>ANNEXURE A</Text>
        <AdditionICMembers newIcMembers={newIcMembers} />
        <RemovalICMembers />
      </Page>
    </Document>
  );
};

export default CheckedClauses;
