import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function WhatIsInquiryReport({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {serialNo}. WHAT IS AN INQUIRY REPORT AND WHAT ACTIONS CAN BE SUGGESTED
        UNDER IT?
      </Text>
      <Text style={styles.text}>
        On completion of inquiry, the IC shall provide a written report of its
        findings and recommendations to Organization within 10 (ten) days from
        date of completion of the inquiry. Such report shall also be made
        available to Complainant and Respondent. The inquiry report shall
        specify details of the allegations against the Respondent, the
        statements made and evidence presented by the Complainant, Respondent
        and / or witnesses, IC’s findings along with a statement giving reasons
        for the findings arrived at by the IC and IC’s recommendations.
      </Text>

      {/* In case allegations against Respondent have not been proven: */}
      <Text style={styles.subHeading}>
        a) In case allegations against Respondent have not been proven:
      </Text>
      <Text style={styles.text}>
        In the event that the IC arrives at the conclusion that the allegation
        against the Respondent has not been proved, it shall recommend to
        Organization that no action is required to be taken in the matter.
      </Text>

      {/* In case allegations against Respondent have been proven: */}
      <Text style={styles.subHeading}>
        b) In case allegations against Respondent have been proven:
      </Text>
      <Text style={styles.text}>
        Where the IC determines that sexual harassment has taken place, to
        whatever extent, immediate corrective and preventive measures will be
        recommended. Severity of recommendation will be determined by the first
        time/ misunderstanding/ habitual offender etc.{" "}
      </Text>

      {/* Where Respondent is an Employee, IC may recommend any one or more of the following:*/}
      <Text style={styles.subHeading}>
        i) Where Respondent is an Employee, IC may recommend any one or more of
        the following:
      </Text>
      <Text style={styles.text}>
        Where the Committee arrives at the conclusion that the allegation
        against the Respondent has been proved, where Respondent is an Employee
        it will recommend to the Company:
      </Text>

      <View style={styles.subContainer}>
        <Text style={styles.point}>
          1) To take action for sexual harassment as a misconduct as per
          Organization Policy.
        </Text>
        <Text style={styles.point}>
          2) To take action as per the service rules applicable to the
          Respondent and where no service rules are applicable to take any
          action including:
        </Text>
        <Text style={styles.subPoint}>
          a) A written apology from the Respondent
        </Text>
        <Text style={styles.subPoint}>
          b) A letter of warning may be given to the Respondent that will be
          placed in the personnel file
        </Text>
        <Text style={styles.subPoint}>
          c) Reprimand or censure the Respondent
        </Text>
        <Text style={styles.subPoint}>
          d) Withholding the promotion or reduction in rank
        </Text>
        <Text style={styles.subPoint}>
          e) Withholding of pay rise or increments
        </Text>
        <Text style={styles.subPoint}>
          f) Immediate transfer or suspension without pay
        </Text>
        <Text style={styles.subPoint}>
          g) Termination from service / dismissal from the services of
          Organization
        </Text>
        <Text style={styles.subPoint}>h) Undergoing a counselling session</Text>
        <Text style={styles.subPoint}>i) Carrying out community service</Text>
        <Text style={styles.subPoint}>
          j) deduction from salary or wages of the Respondent such sum as it may
          consider appropriate to be paid to the Complainant or to their legal
          heirs. In case, Organization is unable to make deductions from salary
          of the Respondent due to them being absent from duty or cessation of
          employment, it may direct the Respondent to pay such sum to the
          Complainant. In case the Respondent fails to pay the sum, the IC may
          forward the order for recovery of the sum as an arrear of land revenue
          to the concerned district officer.
        </Text>
      </View>
      <Text style={styles.text}>
        IC may consider various factors as required under Law for the purpose of
        determining the sums to be paid to the Complainant and may take the
        following into consideration:
      </Text>
      <View style={styles.subContainer}>
        <Text style={styles.subPoint}>
          a) the mental trauma, pain, suffering and emotional distress caused to
          the Complainant
        </Text>
        <Text style={styles.subPoint}>
          b) the loss in the career opportunity due to the incident of sexual
          harassment
        </Text>
        <Text style={styles.subPoint}>
          c) medical expenses incurred by the victim for physical or psychiatric
          treatment
        </Text>
        <Text style={styles.subPoint}>
          d) the income and financial status of the Respondent
        </Text>
        <Text style={styles.subPoint}>
          e) feasibility of such payment in lump sum or in instalments
        </Text>
      </View>

      {/* Where the Respondent is a Third Party, IC may recommend any one or more of the
following:*/}
      <Text style={styles.subHeading}>
        ii) Where Respondent is an Employee, IC may recommend any one or more of
        the following:
      </Text>
      <View style={styles.subContainer}>
        <Text style={styles.subPoint}>
          a) Warning to employing Organization/association/firm (if any)
        </Text>
        <Text style={styles.subPoint}>
          b) Declaring the Workplace/Organization out of bounds to the
          Respondent
        </Text>
        <Text style={styles.subPoint}>
          c) Withdrawal of the right to provide services to Organization for a
          certain period (if applicable)
        </Text>
        <Text style={styles.subPoint}>
          d) Helping the Complainant in filing an FIR, should they desire
        </Text>
        <Text style={styles.subPoint}>
          e) Debarring/blacklisting such person from Organization
        </Text>
        <Text style={styles.subPoint}>
          f) Other similar or appropriate punishment
        </Text>
      </View>
      <Text style={styles.text}>
        Organization shall act upon the recommendation given by IC within 60
        (sixty) days of receipt of the recommendation.
      </Text>

      <Text style={styles.subHeading}>
        c) In case it is proven that the Complaint is malicious:
      </Text>
      <Text style={styles.point}>
        i) If on inquiry, it is revealed that the complaint was made with a
        malicious intent and with the motive of maligning the Respondent /
        tarnishing their image in Organization or otherwise and/or to settle
        personal / professional scores, strict action will be recommended by IC
        against the Complainant. Hence, in case IC arrives at a conclusion that
        allegation against the Respondent is malicious or Complainant has made
        the complaint knowing it to be false or has produced a forged or
        misleading document, IC may recommend to Organization to take an action
        applicable to Respondent if the complaint was genuine. In this regard,
        IC may recommend any of the actions specified above (As applicable).
      </Text>
      <Text style={styles.point}>
        ii) A mere inability to substantiate a complaint or provide adequate
        proof need not attract action against the Complainant as the malicious
        intent on the part of the Complainant will have to be established
        through the inquiry process before an action against such Complainant is
        recommended.
      </Text>
    </View>
  );
}

export default WhatIsInquiryReport;
