import React from "react";
import { Stack } from "@mui/material";

function HeaderCell({ text, ...rest }) {
  return (
    <Stack direction={"row"} width={"100%"} justifyContent={"center"} {...rest}>
      {text}
    </Stack>
  );
}

export default HeaderCell;
