import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../constants";

export const icApi = createApi({
  reducerPath: "icApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
      console.log("building ic headers");
      headers.set(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
      );
      return headers;
    },
  }),

  endpoints: (build) => ({
    getICByLocation: build.query({
      query: (params) => ({
        url: `/v1/ic/get-ic-by-loc`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 0,
    }),
    addICMember: build.mutation({
      query: (body) => ({
        url: `/v1/ic/create`,
        method: "POST",
        body,
      }),
    }),
    editICMember: build.mutation({
      query: ({ empId, body }) => ({
        url: `/v1/ic/update?id=${empId}`,
        method: "PUT",
        body,
      }),
    }),
    deleteICMember: build.mutation({
      query: (body) => ({
        url: `/v1/ic/delete-member`,
        method: "DELETE",
        body,
      }),
    }),
    getAllOrgICStatus: build.query({
      query: () => ({
        url: `/v1/ic/org-status`,
        method: "GET",
      }),
      keepUnusedDataFor: 1800,
    }),
    getOrgICMapped: build.query({
      query: (params) => ({
        url: `/v1/ic/org-ic-mapped`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 600,
    }),
    getLocationICMapped: build.query({
      query: (params) => ({
        url: `/v1/ic/loc-ic-mapped`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 300,
    }),
    getLocationICStatus: build.query({
      query: (params) => ({
        url: `/v1/ic/loc-ic-status`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 300,
    }),
    getIcArchives: build.query({
      query: (params) => ({
        url: `/v1/ic-logs/get`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 1800,
    }),
    deleteIcArchives: build.mutation({
      query: (id) => ({
        url: `/v1/ic-logs/delete/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetICByLocationQuery,
  useAddICMemberMutation,
  useEditICMemberMutation,
  useDeleteICMemberMutation,
  useGetAllOrgICStatusQuery,
  useGetOrgICMappedQuery,
  useGetLocationICMappedQuery,
  useGetLocationICStatusQuery,
  useGetIcArchivesQuery,
  useDeleteIcArchivesMutation,
} = icApi;
