import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function Overview() {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        [ ] (“Organization”) believes that everyone must get equal opportunity
        to work, regardless of race, caste, religion, colour, ancestry, marital
        status, gender, sexual orientation, age, nationality, ethnic origin or
        disability. It believes that a safe and conducive work environment that
        enables all to work without fear of prejudice, gender bias and sexual
        harassment, where employer and employees respect each other’s integrity
        and dignity, their privacy, their right to equality and right to
        opportunity in the workplace, is created. It understands that equality
        in employment can be seriously impaired when individuals are subjected
        to discrimination by way of sexual harassment at workplace. Each such
        incident results not only in the violation of fundamental rights of
        “Gender Equality” and the “Right to Life and Liberty” under Articles 14,
        15 and 21 of the Constitution of India but also in the violation of
        Article 19 (1) (g).
      </Text>
      <Text style={styles.text}>
        The Indian Parliament has enacted the Sexual Harassment of Women at
        Workplace (Prevention, Prohibition and Redressal) Act and Rules, 2013
        along with all amendments from time to time (&quotthe Law&quot) with the
        objective to provide protection against sexual harassment to women at a
        workplace. Organization recognizes that the Law has been passed as a
        measure to provide equal opportunity to work for its women population
        and grant them equality under the Constitution of India. However,
        Organization also recognizes that other genders aren’t immune to sexual
        harassment and can experience such behaviours at the workplace.
      </Text>
      <Text style={styles.text}>
        Organization is committed to work and provide support towards provision
        of a safe and conducive environment at workplace for all its employees,
        regardless of gender. Additionally, it is committed to create an ethos
        of acceptance and inclusivity such that individuals regardless of their
        sexual orientation or sexual preferences, individuals belonging to
        LGBTQIA community and individuals choosing to not be in any of the
        socially constructed communities and adopting a fluid/non-binary
        approach feel respected and accepted at the workplace.
      </Text>
      <Text style={styles.text}>
        In light of the above, Organization has formulated this policy against
        sexual harassment at workplace (“Policy”) as per the Law and as a
        healthy practice and as part of internal policies is also extending the
        benefit of the law to not only its women employees, but also to its
        other employees who may belong to any gender. Thus, this Policy shall be
        applicable to all complaints of sexual harassment made by anyone against
        an employee of the Organization. This Policy also extends to employees
        working virtually or any other location as may be necessary for the
        employees to perform their duties.
      </Text>
      <Text style={styles.text}>
        Our success is based on our people. We treat each other with respect and
        dignity and expect everyone to promote a sense of personal
        responsibility. We recruit competent and motivated people who respect
        our values, provide equal opportunities for their development and
        advancement; protect their privacy and do not tolerate any form of
        harassment or discrimination. Organization shall take strict
        disciplinary action as provided under this Policy against any persons
        found guilty of sexual harassment, regardless of the gender.
      </Text>
    </View>
  );
}

export default Overview;
