import React, { useContext, useEffect } from "react";
import {
  Typography,
  Button,
  Grid,
  useMediaQuery,
  InputLabel,
  Container,
  Box,
} from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import {
  NewCheckboxField,
  NewDatePickerField,
  NewInputField,
  NewSelectField,
} from "../../../components/Form";
import { FileUploadOutlined } from "@mui/icons-material";
import {
  EmployementLevels,
  Genders,
  LocationsOfWork,
  POCTypes,
  ServiceRules,
} from "../../../utils/dropdowns";
import {
  useGetEmployementLevelsQuery,
  useGetWorkLocationsQuery,
  useGetServiceRulesQuery,
  useGetAllPocTypesQuery,
} from "../../../store/dropdown/dropdownApi";
import { useSelector } from "react-redux";
import { useCreateContactsMutation } from "../../../store/org/orgApi";
import { ColorModeContext } from "../../../constants/theme";
import { CloseButton, SaveAndCancelBtn } from "../../../components";
import { BackdropLoader, NavigationBar } from "../../../components/General";
import Swal from "sweetalert2";

function AddContacts() {
  const { mode } = useContext(ColorModeContext);
  useGetEmployementLevelsQuery();
  useGetWorkLocationsQuery();
  useGetServiceRulesQuery();
  useGetAllPocTypesQuery();
  const { dropdowns } = useSelector((state) => state.dropdown);
  const orgInfo = useSelector((state) => state.org?.orgInfo);
  const TabScreen = useMediaQuery("(min-width: 640px)");

  const [createContacts, { isLoading, isSuccess, isError, error }] =
    useCreateContactsMutation();
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (isLoading) {
      setOpen(true);
    }
    if (isSuccess) {
      setOpen(false);
      Swal.fire({
        title: "Added",
        text: "Contact has been added",
        icon: "success",
        timer: 1500,
      });
    }
    if (isError) {
      setOpen(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error}`,
      });
    }
  }, [isLoading, isSuccess, isError, error]);

  useEffect(() => {
    console.log("orgInfo -------->", orgInfo);
  }, [orgInfo]);

  const initialValues = {
    employees: [],
  };

  const InputField = NewInputField;
  const SelectField = NewSelectField;
  const CheckboxField = NewCheckboxField;
  const DatePickerField = NewDatePickerField;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
    defaultValues: initialValues,
  });

  const onSubmit = (data) => {
    console.log(data);
    if (orgInfo?.id) {
      createContacts({ id: orgInfo?.id, body: data });
    } else {
      console.log("Org Id not available");
    }
  };

  const validations = {
    required: "This field is required",
  };

  const emailValidations = {
    required: "This field is required",
    validate: (value) => {
      let re =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return re.test(value) || "Email invalid";
    },
  };

  const phonevalidations = {
    required: "This field is required",
    validate: (value) => {
      let re = /(7|8|9)\d{9}/;
      return re.test(value) || "Please match the format";
    },
  };

  const {
    fields: EmployeeFields,
    append: EmployeeAppend,
    remove,
  } = useFieldArray({
    name: "employees",
    control,
  });

  const breadcrumbs = [
    <Typography className="tw-text-[11px]" key="100">
      company profile
    </Typography>,
    <Typography className="tw-text-dark-blue tw-text-[11px]" key="200">
      Add Contacts
    </Typography>,
  ];

  return (
    <Box
      className="tw-w-full tw-p-4 sm:tw-p-7 lg:tw-p-12 tw-rounded-lg tw-border tw-border-[#EDEDED]"
      sx={{
        background: `${mode === "dark" ? "#0D0D0D" : " #fff"}`,
        height: "100%",
        width: "100%",
      }}
    >
      <BackdropLoader open={open} />
      <Container
        className="tw-p-10"
        sx={{
          height: `${EmployeeFields.length === 0 ? "100vh" : "100%"}`,
          width: "100%",
          margin: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <NavigationBar breadcrumbs={breadcrumbs} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="tw-w-full tw-mb-4 sm:tw-mb-6 lg:tw-my-12">
            <Typography
              className="tw-font-semibold heading1"
              sx={{ color: `${mode === "dark" ? "#FFF" : "#000"}` }}
            >
              Contacts
            </Typography>
          </Box>

          {/* Each Contact */}
          {EmployeeFields.map((c, idx) => {
            return (
              <Box
                key={c.id}
                position={"relative"}
                className="tw-p-3 sm:tw-p-6 lg:tw-p-12 tw-rounded-3 tw-mb-4 sm:tw-mb-8"
                sx={{
                  background: `${mode === "dark" ? "#1A1A1A" : "#DDF1FF"}`,
                }}
              >
                <CloseButton
                  styles={{
                    position: "absolute",
                    top: "15px",
                    right: "15px",
                  }}
                  handleClick={() => remove(idx)}
                />
                <Grid container spacing={TabScreen ? 4 : 2}>
                  <Grid item xs={12} sm={6} lg={4}>
                    <InputField
                      label={"First Name"}
                      placeholder={"Enter name"}
                      name={`employees.${idx}.firstName`}
                      register={register}
                      validations={validations}
                      error={
                        errors?.employees?.length > idx &&
                        errors?.employees[idx]?.firstName?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <InputField
                      label={"Last Name"}
                      placeholder={"Enter name"}
                      name={`employees.${idx}.lastName`}
                      register={register}
                      validations={validations}
                      error={
                        errors?.employees?.length > idx &&
                        errors?.employees[idx]?.lastName?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <InputField
                      label={"Email"}
                      placeholder={"Enter"}
                      name={`employees.${idx}.email`}
                      register={register}
                      validations={emailValidations}
                      error={
                        errors?.employees?.length > idx &&
                        errors?.employees[idx]?.email?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <DatePickerField
                      label={"Birth Date"}
                      setFieldValue={setValue}
                      name={`employees.${idx}.date_of_birth`}
                      control={control}
                      error={
                        errors?.employees?.length > idx &&
                        errors?.employees[idx]?.date_of_birth?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <InputField
                      label={"Phone"}
                      placeholder={"Enter"}
                      name={`employees.${idx}.phone_number`}
                      register={register}
                      validations={phonevalidations}
                      error={
                        errors?.employees?.length > idx &&
                        errors?.employees[idx]?.phone_number?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <InputField
                      label={"Alternate Email"}
                      placeholder={"Enter"}
                      name={`employees.${idx}.alternate_email`}
                      register={register}
                      error={
                        errors?.employees?.length > idx &&
                        errors?.employees[idx]?.alternate_email?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <SelectField
                      label={"Gender"}
                      placeholder={"Enter"}
                      menus={Genders}
                      name={`employees.${idx}.gender`}
                      register={register}
                      validations={validations}
                      error={
                        errors?.employees?.length > idx &&
                        errors?.employees[idx]?.gender?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <InputField
                      label="Designation in Organization"
                      placeholder={"Enter"}
                      name={`employees.${idx}.designation`}
                      register={register}
                      validations={validations}
                      error={
                        errors?.employees?.length > idx &&
                        errors?.employees[idx]?.designation?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <InputField
                      label="Department"
                      placeholder={"Enter"}
                      name={`employees.${idx}.department`}
                      register={register}
                      validations={validations}
                      error={
                        errors?.employees?.length > idx &&
                        errors?.employees[idx]?.department?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <SelectField
                      label={"Level of Employement"}
                      placeholder={"Enter"}
                      menus={
                        dropdowns?.EmploymentLevels?.length > 0
                          ? dropdowns?.EmploymentLevels
                          : EmployementLevels
                      }
                      name={`employees.${idx}.employment_level`}
                      register={register}
                      validations={validations}
                      error={
                        errors?.employees?.length > idx &&
                        errors?.employees[idx]?.employment_level?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <SelectField
                      label={"Location of work"}
                      placeholder={"Enter"}
                      menus={
                        dropdowns?.WorkLocations?.length > 0
                          ? dropdowns?.WorkLocations
                          : LocationsOfWork
                      }
                      name={`employees.${idx}.locations_of_work`}
                      register={register}
                      validations={validations}
                      error={
                        errors?.employees?.length > idx &&
                        errors?.employees[idx]?.locations_of_work?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <SelectField
                      label={"Service Rules"}
                      placeholder={"Enter"}
                      menus={
                        dropdowns?.ServiceRules?.length > 0
                          ? dropdowns?.ServiceRules
                          : ServiceRules
                      }
                      name={`employees.${idx}.service_rules`}
                      register={register}
                      validations={validations}
                      error={
                        errors?.employees?.length > idx &&
                        errors?.employees[idx]?.service_rules?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxField
                      name={`employees.${idx}.is_poc`}
                      register={register}
                      label={"POC"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <SelectField
                      label={"POC Type"}
                      placeholder={"Enter"}
                      menus={
                        dropdowns?.POCTypes?.length > 0
                          ? dropdowns?.POCTypes
                          : POCTypes
                      }
                      name={`employees.${idx}.poc_type`}
                      register={register}
                      // validations={validations}
                      error={
                        errors?.employees?.length > idx &&
                        errors?.employees[idx]?.poc_type?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={8}>
                    <Box className="tw-gap-4 tw-flex max-[400px]:tw-flex-col tw-items-center tw-h-[100%] tw-justify-center">
                      <InputLabel className="tw-text-xs sm:tw-text-sm tw-text-center tw-whitespace-normal">
                        {getValues(`employees.${idx}.image`)
                          ? getValues(`employees.${idx}.image`)
                          : "Upload Image"}
                      </InputLabel>
                      <Button
                        sx={{ backgroundColor: "white" }}
                        component="label"
                        variant="outlined"
                      >
                        Choose
                        <input
                          hidden
                          // name={`employees.${idx}.image`}
                          // ref={...register()}
                          // {...register(`employees.${idx}.image`)}
                          onChange={(e) => {
                            setValue(
                              `employees.${idx}.image`,
                              "https://picsum.photos/200",
                            );
                            console.log(e.target);
                            console.log(e.target.files);
                          }}
                          accept="image/*"
                          type="file"
                        />
                      </Button>
                      <Button
                        component="label"
                        variant="contained"
                        className="sm:tw-gap-2"
                      >
                        <FileUploadOutlined />
                        Upload
                        <input
                          hidden
                          // {...register(`employees.${idx}.image`)}
                          onChange={(e) => {
                            console.log(e.target);
                            console.log(e.target.value);
                            console.log(e.target.files);
                            setValue(
                              `employees.${idx}.image`,
                              "https://picsum.photos/200",
                            );
                          }}
                          accept="image/*"
                          type="file"
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            );
          })}

          <Box className="tw-w-full tw-mb-8 sm:tw-mb-12">
            <Button
              variant="contained"
              onClick={() =>
                EmployeeAppend({
                  firstName: "",
                  lastName: "",
                  email: "",
                  phone_number: "",
                  date_of_birth: "",
                  gender: "",
                  designation: "",
                  employment_level: "",
                  alternate_email: "",
                  department: "",
                  locationsofWork: "",
                  serviceRules: "",
                  image: null,
                  is_poc: false,
                  pocType: "",
                })
              }
              sx={{ border: "1px solid white" }}
            >
              Add Contacts
            </Button>
          </Box>

          {/* Submit Buttons */}
          <Box className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-justify-end tw-space-y-5 sm:tw-space-x-8 sm:tw-space-y-0 tw-mt-6 md:tw-mt-8 lg:tw-mt-16">
            <SaveAndCancelBtn
              handleCancle={() => remove()}
              saveBtnProps={{ disabled: getValues()?.employees?.length === 0 }}
            />
          </Box>
        </form>
      </Container>
    </Box>
  );
}

export default AddContacts;
