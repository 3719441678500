import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function WhoCanFileComplaint({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{serialNo}. WHO CAN FILE A COMPLAINT?</Text>
      <Text style={styles.text}>
        Any woman of any age whether employed or not, who alleges to have been
        subjected to any act of sexual harassment by the respondent (“Aggrieved
        Person”) and the term “Respondent” shall refer to the person (i.e. the
        Employee of the Organization as defined above) against whom the
        complaint of sexual harassment has been filed.
      </Text>
    </View>
  );
}

export default WhoCanFileComplaint;
