import React from "react";
import { IconButton } from "@mui/material";
import EditIcon from "../../assets/icons/EditIcon.svg";
import DarkEditIcon from "../../assets/icons/DarkEditIcon.svg";
import { ColorModeContext } from "../../constants/theme";

function EditButton({ onClick = () => null, buttonProps = {}, styles = {} }) {
  const { mode } = React.useContext(ColorModeContext);

  return (
    <IconButton
      onClick={onClick}
      sx={{
        ...styles,
      }}
      {...buttonProps}
    >
      {mode === "dark" ? <img src={DarkEditIcon} /> : <img src={EditIcon} />}
    </IconButton>
  );
}

export default EditButton;
