import React from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ColorModeContext } from "../../constants/theme";
import { AddNewButton, EditButton, GridActionsLayout } from "../../components";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { useGetLocationByOrgQuery } from "../../store/org/orgApi";
import useDebouncedSearch from "../../utils/hooks/useDebounceSearch";
import { GridColumns } from "../../utils/gridColumns";

function AllLocations() {
  const navigate = useNavigate();
  const { mode } = React.useContext(ColorModeContext);
  const { user } = useSelector((state) => state.auth?.user);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const { searchText, debouncedResults } = useDebouncedSearch();
  const { data: locations, isFetching } = useGetLocationByOrgQuery({
    id: user?.org_id,
    params: { ...paginationModel, ...searchText },
  });
  const [rows, setRows] = React.useState([]);
  const [rowCountState, setRowCountState] = React.useState(
    locations?.count || 0,
  );

  React.useEffect(() => {
    let temp = [];
    console.log("locations ---->", locations);
    if (locations?.location) {
      console.log(locations?.location);
      temp = locations?.location?.map((loc) => {
        return {
          id: loc?.id,
          Name: loc?.location_name || "N/A",
          ID: loc?.loc_id || "",
          "No Of Employees": loc?.no_of_employee || "N/A",
          Type: loc?.location_type?.name || "N/A",
        };
      });
    }
    setRows(temp || []);
  }, [locations]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      locations?.count ? locations?.count : prevRowCountState,
    );
  }, [locations?.count, setRowCountState]);

  const columns = [
    ...new GridColumns()
      .name()
      .id()
      .noOfEmp()
      .type()
      .actions({
        renderCell: (params) => {
          return (
            <GridActionsLayout>
              <EditButton
                onClick={() =>
                  navigate(
                    `/home/company-profile/location/edit/${params.row.id}`,
                  )
                }
              />
            </GridActionsLayout>
          );
        },
      })
      .getCols(),
  ];

  return (
    <Box
      className="tw-p-10"
      sx={{
        background: `${mode === "dark" ? "#000000" : " #FFF"}`,
        height: "100vh",
        width: "100%",
        margin: 0,

        display: "flex",
        flexDirection: "column",
        gap: "50px",
        padding: "40px",
      }}
    >
      <Stack
        direction={"row"}
        columnGap={4}
        justifyContent={"flex-start"}
        alignItems={"center"}
        marginBottom={2}
        marginTop={10}
      >
        <LocationCityIcon fontSize="medium" />
        <Typography
          className="tw-font-semibold tw-text-4xl"
          sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
        >
          Locations
        </Typography>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"} gap={2} pr={2}>
        <TextField
          type="text"
          name="search"
          onChange={debouncedResults}
          sx={{
            width: "250px",
            "& .MuiInputBase-root": {
              height: "32px",
            },
          }}
          placeholder="Search By Location Name"
        />
        <AddNewButton
          onClick={() => navigate("/home/company-profile/location/add")}
        />
      </Stack>
      <Box
        sx={{
          boxShadow:
            "0px 0px 1px rgba(12, 14, 25, 0.24), 0px 2px 12px rgba(12, 14, 25, 0.12)",
          borderRadius: "6px",
          width: "100%",
          height: "100%",
          padding: 2,
          backgroundColor: `${mode === "dark" ? " #181818" : "transparent"}`,
        }}
        className="data-grid-div"
      >
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isFetching}
          disableRowSelectionOnClick
          pageSizeOptions={[5]}
          rowCount={rowCountState}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: `${mode === "dark" ? " #181818" : "#FFDEDE"}`,
            },
            "& .MuiDataGrid-toolbarContainer": {
              paddingY: 2,
              paddingX: 1,
            },

            "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
              backgroundColor: "transparent",
              color: "#4B4B4B",
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default AllLocations;
