import React from "react";
import RegistrationLayout from "../../../components/Layouts/RegistrationLayout";
import { Box, Stack, Typography, styled } from "@mui/material";
import UploadLogo from "../../../assets/logos/UploadLogo.svg";
import { ColorModeContext } from "../../../constants/theme";
import { useNavigate } from "react-router-dom";
import { SingleUploadLayout } from "../../../components";
import AWS from "aws-sdk";
import { useUploadPolicyMutation } from "../../../store/policy/policyApi";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
  region: "ap-south-1",
});

const UploadCompanyPolicyStyle = styled(Box)(({ theme }) => ({
  ".heading": {
    fontWeight: 700,
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
    },
  },
}));

const UploadCompanyPolicy = () => {
  const { mode } = React.useContext(ColorModeContext);
  const [policy, setPolicy] = React.useState({ file: null, name: "" });
  const [s3Url, setS3Url] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const [uploadPolicy, { isLoading, isSuccess, data }] =
    useUploadPolicyMutation();
  const { user } = useSelector((state) => state.auth?.user);

  const navigate = useNavigate();

  const handleChange = (e) => {
    console.log(e.target?.files);
    if (e.target?.files[0]?.type?.match(/(pdf)$/)) {
      setPolicy({ file: e.target.files[0], name: e.target.files[0].name });
    } else {
      setAlert({ severity: "error", message: "Please select PDF" });
    }
  };

  React.useEffect(() => {
    console.log("result --->", data);
    if (isSuccess) {
      Swal.fire({
        title: "Uploaded",
        text: "Policy has been uploded",
        icon: "success",
        timer: 1500,
      });
      navigate("/home/upload");
    }
  }, [isSuccess]);

  const handleUpload = () => {
    if (policy.file) {
      try {
        const s3 = new AWS.S3();
        const params = {
          Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
          Key: "CompanyPolicyFiles/" + policy.name,
          Body: policy.file,
          ContentType: "application/pdf",
        };

        s3.upload(params, (err, data) => {
          if (err) {
            console.log(err);
          } else {
            console.log("File uploaded Successfully!", data);
            setS3Url(data.Location);
          }
        });
      } catch (error) {
        console.log("Error uploading file:", error);
        setAlert({ severity: "error", message: "Error in uploading file" });
      }
    } else {
      setAlert({ severity: "error", message: "Please select file" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (s3Url) {
      uploadPolicy({
        org_id: user?.org_id,
        file: s3Url,
      });
    } else {
      setAlert({ severity: "error", message: "Please select PDF" });
    }
  };

  return (
    <RegistrationLayout>
      <UploadCompanyPolicyStyle>
        <Stack
          direction={"row"}
          columnGap={4}
          justifyContent={"flex-start"}
          alignItems={"center"}
          marginBottom={2}
          marginTop={10}
        >
          <img src={UploadLogo} alt="" />
          <Typography
            className="heading"
            sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
          >
            Upload Company Policy
          </Typography>
        </Stack>
        <form action="" onSubmit={handleSubmit}>
          <SingleUploadLayout
            handleChange={handleChange}
            handleUpload={handleUpload}
            label={"Upload Company Policy"}
            done={s3Url}
            isLoading={isLoading}
            fileName={policy.name || ""}
            alert={alert}
            handleAlertClose={() => setAlert(null)}
          />
        </form>
      </UploadCompanyPolicyStyle>
    </RegistrationLayout>
  );
};

export default UploadCompanyPolicy;
