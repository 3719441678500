import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function ResponsibilitiesOfEmployees({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {serialNo}. RESPONSIBILITIES OF EMPLOYEES
      </Text>
      <View style={styles.subContainer}>
        <Text style={styles.point}>
          a) Everyone has a personal responsibility to ensure that their
          behaviour is not contrary to this Policy.
        </Text>
        <Text style={styles.point}>
          b) Set an example of dignified workplace behaviour and ethical
          standards in line with Organization’s values and code of conduct.
        </Text>
        <Text style={styles.point}>
          c) To be mindful of professional and personal boundaries while
          performing your duties for the organization and representing the
          organization.
        </Text>
        <Text style={styles.point}>
          d) Create and sustain a healthy work environment.
        </Text>
        <Text style={styles.point}>
          e) Do not ignore or allow an inappropriate behaviour to continue
          regardless of who is creating that situation.
        </Text>
        <Text style={styles.point}>
          f) Support and cooperate during any inquiry of a complaint, including
          testifying as witness and giving full and truthful disclosure of all
          factual information when asked by the IC, while ensuring that complete
          confidentiality is maintained throughout, about any complaint of
          sexual harassment within Organization.
        </Text>
        <Text style={styles.point}>
          g) Be aware that Organization will take allegations seriously and will
          ask their cooperation in an inquiry if they bring a complaint forward.
        </Text>
        <Text style={styles.point}>
          h) Handle information related to known or suspected violations of this
          Policy in a discreet and confidential manner and not attempt to
          investigate / inquire into the information or suspected violations of
          this Policy on your own i.e. without involving the IC.
        </Text>
        <Text style={styles.point}>
          i) Report any inappropriate behavior to IC immediately.
        </Text>
        <Text style={styles.point}>
          j) Do not misuse the policy or the law by filing malicious complaints
          for settling personal scores, seeking revenge or for personal gains
          and benefits etc.
        </Text>
        <Text style={styles.point}>
          k) To remember that on social media, the employees represent the
          Organization and therefore to ensure that they post
          information/comment/opinion that is responsible, professional and
          protect the interests of the Organization.
        </Text>
        <Text style={styles.point}>l) With respect to virtual working:</Text>

        {/* sub points */}
        <Text style={styles.subPoint}>
          i) Ensure that video/audio calls are scheduled during working hours as
          much as possible.
        </Text>
        <Text style={styles.subPoint}>
          ii) State the preferred modality (video or audio) while scheduling the
          meeting/ blocking other person’s calendar & allow for flexibility for
          the individual to confirm
        </Text>
        <Text style={styles.subPoint}>
          iii) Seek consent before requesting for a video call for a one on one
          conversation
        </Text>
        <Text style={styles.subPoint}>
          iv) Do not insist on video calls where not necessary, and/or if your
          colleague/s expresses difficulty in joining via video call.
        </Text>
        <Text style={styles.subPoint}>
          v) While on video/audio calls, be mindful of the professional
          boundaries, and do not initiate conversations/jokes that could make
          others uncomfortable.
        </Text>
        <Text style={styles.subPoint}>
          vi) While on video calls, ensure that a professional dress code is
          followed, and the background does not have anything inappropriate.
        </Text>
        <Text style={styles.subPoint}>
          vii) Do not share/forward inappropriate or offensive posts or images
          on team chat or social media, etc
        </Text>
        <Text style={styles.subPoint}>
          viii) Do not forward any personal message, image, or video sent to you
          without sender’s consent
        </Text>
        <Text style={styles.subPoint}>
          ix) Do not repeatedly send messages to engage in personal discussions,
          comment, like on posts, pictures etc. in absence of any positive,
          enthusiastic response
        </Text>
        <Text style={styles.subPoint}>
          x) Do not repetitively ask any employee to accept your friend/follow
          request on any social media platform
        </Text>
        <Text style={styles.subPoint}>
          xi) Do not engage in online bullying, stalking or harassment.
        </Text>
      </View>
    </View>
  );
}

export default ResponsibilitiesOfEmployees;
