import React from "react";
import { Document } from "@react-pdf/renderer";
import { shortPolicyClauses } from "../../../constants/initialValues";
import { policyStyles } from "../PDFStyles";
import { getPolicyIndices } from "../../../utils/policyHelpers";
import ShortPolicyView from "./ShortPolicyView";

const initialClauses = shortPolicyClauses;

function ShortGenderNeutral({ clauses = initialClauses }) {
  const Indices = getPolicyIndices(clauses);

  return (
    <Document style={policyStyles.body}>
      <ShortPolicyView
        heading="POLICY ON PREVENTION OF SEXUAL HARASSMENT"
        source="ShortGenderNeutral"
        styles={policyStyles}
        clauses={clauses}
        Indices={Indices}
      />
    </Document>
  );
}

export default ShortGenderNeutral;
