import { Box, Button, Stack, TextField } from "@mui/material";
import React, { useContext } from "react";
import { ColorModeContext } from "../../../constants/theme";
import { NavigationBar } from "../../../components/General";
import { NumericalOverview } from "../../../components/Layouts";
import BreadcrumbFont from "../../../components/Typography/BreadcrumbFont";
import { ApproveButton, CloseButton, FilterButton } from "../../../components";
import StatusFilters from "../../../components/Filters/StatusFilters";
import {
  useGetOiwStatusQuery,
  useGetOrgOiwMappedQuery,
  useUpdateOiwStatusMutation,
} from "../../../store/oiw/OiwApi";
import { Status } from "../../../utils/options";
import { DataGrid } from "@mui/x-data-grid";
import ReasonRejection from "../../../components/Dialog/ReasonRejection";
import OiwApproveFile from "../../../components/Dialog/OiwApproveFile";
import useDebouncedSearch from "../../../utils/hooks/useDebounceSearch";
import { GridColumns } from "../../../utils/gridColumns";

const breadcrumbs = [
  <BreadcrumbFont key={1} color="#005D9E" text={"ORDER IN WRITING"} last />,
];

const AllOrgOIW = () => {
  const { mode } = useContext(ColorModeContext);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const { searchText, debouncedResults } = useDebouncedSearch();
  const [selectedStatus, setSelectedStatus] = React.useState("all");
  const { data: orgData, isFetching } = useGetOrgOiwMappedQuery({
    ...paginationModel,
    ...searchText,
    status: selectedStatus,
  });
  const { data: totalStatus } = useGetOiwStatusQuery();

  const [rowCountState, setRowCountState] = React.useState(orgData?.count || 0);
  const [currentOrgId, setCurrentOrgId] = React.useState(null);
  const [currentOiwId, setCurrentOiwId] = React.useState(null);
  const [openApproveALert, setOpenApproveAlert] = React.useState(false);
  const [openRejectAlert, setOpenRejectAlert] = React.useState(false);
  const [updateOiwStatus, { isLoading: updateOiwLoading }] =
    useUpdateOiwStatusMutation();

  const getOrgStatus = (org) => {
    if (org?.oiw_status === "PENDING" || org?.oiw_status === "REJECTED") {
      return "PENDING";
    } else if (
      org?.oiw_status === "APPROVAL" ||
      org?.oiw_status === "COMPLETED"
    ) {
      return org?.oiw_status;
    } else {
      return "";
    }
  };

  React.useEffect(() => {
    let temp = [];
    console.log(orgData?.orgs);
    if (orgData?.orgs) {
      temp = orgData?.orgs?.map((org) => {
        return {
          id: org?.id,
          Organization: org?.name || "",
          Status: getOrgStatus(org),
          oiw_id: org.oiw?.length !== 0 ? org.oiw[org.oiw.length - 1]?.id : "",
          file: org.oiw?.length !== 0 ? org.oiw[org.oiw.length - 1]?.file : "",
        };
      });
    }
    setRows(temp || []);
  }, [orgData]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      orgData?.count ? orgData?.count : prevRowCountState,
    );
  }, [orgData?.count, setRowCountState]);

  const numericalData = [
    {
      id: 1,
      text: "NO. OF COMPANIES ORDER IN WRITING PENDING",
      number: totalStatus?.pending,
    },
    {
      id: 2,
      text: "NO. OF COMPANIES ORDER IN WRITING COMPLETED",
      number: totalStatus?.completed,
    },
  ];

  const columns = [
    ...new GridColumns().org().status().getCols(),
    {
      field: "Approval",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            direction={"row"}
            columnGap={2}
            flex={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {params.row.Status === "APPROVAL" ? (
              <>
                <CloseButton
                  handleClick={() => {
                    setCurrentOrgId(params.row.id);
                    setCurrentOiwId(params.row.oiw_id);
                    setOpenRejectAlert(!openRejectAlert);
                  }}
                />
                <ApproveButton
                  handleClick={() => {
                    setCurrentOrgId(params.row.id);
                    setCurrentOiwId(params.row.oiw_id);
                    setOpenApproveAlert(!openApproveALert);
                  }}
                />
              </>
            ) : (
              `"`
            )}
          </Stack>
        );
      },
    },
    {
      field: "Actions",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            direction={"row"}
            columnGap={2}
            flex={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {params.row.Status === "PENDING" ? (
              <Button
                sx={{
                  backgroundColor: "#4B4B4B",
                  fontSize: "12px",
                  height: "25px",
                  width: "80px",
                  paddingX: 1,
                  paddingY: 1,
                }}
                variant="contained"
              >
                Take Action
              </Button>
            ) : (
              <Button
                sx={{
                  backgroundColor: "#4B4B4B",
                  fontSize: "12px",
                  height: "25px",
                  width: "80px",
                  paddingX: 1,
                  paddingY: 1,
                }}
                variant="contained"
                onClick={() => window.open(params.row.file, "_blank")}
              >
                View
              </Button>
            )}
          </Stack>
        );
      },
    },
  ];

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      paddingX={"60px"}
      paddingY={"35px"}
      rowGap={"46px"}
      sx={{ background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}` }}
    >
      <NavigationBar breadcrumbs={breadcrumbs} />
      <NumericalOverview data={numericalData} />
      {openRejectAlert && (
        <ReasonRejection
          open={openRejectAlert}
          id={currentOiwId}
          orgId={currentOrgId}
          updateApi={updateOiwStatus}
          loading={updateOiwLoading}
          onClose={() => {
            setOpenRejectAlert(!openRejectAlert);
            setCurrentOrgId(null);
            setCurrentOiwId(null);
          }}
          name="rejection_reason"
        />
      )}
      {openApproveALert && (
        <OiwApproveFile
          open={openApproveALert}
          id={currentOiwId}
          updateApi={updateOiwStatus}
          orgId={currentOrgId}
          loading={updateOiwLoading}
          onClose={() => {
            setOpenApproveAlert(!openApproveALert);
            setCurrentOrgId(null);
            setCurrentOiwId(null);
          }}
        />
      )}

      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={5}
        className="superadmin-data-grid-div"
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <TextField
            type="text"
            name="search"
            onChange={debouncedResults}
            sx={{
              width: "302px",
              "& .MuiInputBase-root": {
                height: "32px",
              },
            }}
            placeholder="Search By Company Name"
          />
          <FilterButton
            buttonStyles={{ height: "32px" }}
            onClick={() => setOpen(!open)}
          />
        </Stack>
        <StatusFilters
          open={open}
          selectedStatus={selectedStatus}
          handleStatusChange={handleStatusChange}
          status={[Status.APPROVAL, Status.COMPLETE, Status.PENDING]}
        />
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isFetching}
          disableRowSelectionOnClick
          pageSizeOptions={[5]}
          rowCount={rowCountState}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: `${mode === "dark" ? " #181818" : "#EEF8FF"}`,
            },
            "& .MuiDataGrid-toolbarContainer": {
              paddingY: 2,
              paddingX: 1,
            },

            "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
              backgroundColor: "transparent",
              color: "#4B4B4B",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default AllOrgOIW;
