import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function WhatIsWorkPlace({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{serialNo}. WHAT IS WORKPLACE?</Text>
      <View style={styles.subContainer}>
        <Text style={styles.text}>
          “Workplace” shall mean all places where Organization’s work is carried
          out. It does not only include physical workspaces but also spaces from
          where work is carried out remotely or virtually. For example:
        </Text>
        <Text style={styles.point}>
          a. All offices or other premises where Organization’s business is
          conducted
        </Text>
        <Text style={styles.point}>
          b. All Organization related activities performed at any physical or
          virtual site
        </Text>
        <Text style={styles.point}>
          c. Any social, business or other functions taking place physically or
          virtually where the conduct or comments may have an adverse impact on
          the workplace or workplace relations
        </Text>
        <Text style={styles.point}>
          d. Any alleged act of sexual harassment committed during or outside of
          office hours using any mode of communication including video
          calls/audio calls, phone calls, text, e-mails, , on any social media
          platform or through any other electronic communication etc..
        </Text>
        <Text style={styles.point}>
          e. Any sexual harassment on any social networking website during or
          outside of office hours
        </Text>
        <Text style={styles.point}>
          f. Any place visited by Employee or arising out of or during the
          course of employment with Organization, including transport services
          provided by Organization for undertaking such journey, audio/video
          conferencing applications or any other communication related
          tools/applications.. For example, Employees who are on overseas
          programs and trainings in course of their employment with the
          Organization.
        </Text>
      </View>
    </View>
  );
}

export default WhatIsWorkPlace;
