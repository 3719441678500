import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Appbar, MobileDrawer, Sidebar } from "../components/General";
import { Outlet } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Toolbar } from "@mui/material";

const drawerWidth = 300;

export default function PrivateScreen() {
  const TabScreen = useMediaQuery("(min-width: 640px)");
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      <Appbar setOpen={setOpen} />
      <MobileDrawer open={open} setOpen={setOpen} />
      {TabScreen && (
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            // flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto" }}>
            <Sidebar />
          </Box>
        </Drawer>
      )}
      <Outlet />
    </Box>
  );
}
