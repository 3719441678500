import React from "react";
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import Logo from "../../assets/AppBarLightLogo.svg";
import DarkLogo from "../../assets/AppBarDarkLogo.svg";
import { ColorModeContext } from "../../constants/theme";
import { useSelector } from "react-redux";

function Header() {
  const { mode } = React.useContext(ColorModeContext);
  const { user } = useSelector((state) => state?.auth?.user);

  return (
    <AppBar position="sticky" color="inherit" elevation={1}>
      <Toolbar className="tw-px-10 sm:tw-px-20">
        <Box className="tw-flex-grow tw-flex tw-items-center tw-justify-start tw-space-x-2">
          <Box className="tw-w-min tw-py-1 tw-px-6 ">
            <img
              src={mode === "dark" ? DarkLogo : Logo}
              alt="Complimate logo"
              width={"30px"}
            />
          </Box>
        </Box>
        <IconButton>
          <Avatar
            sx={{
              backgroundColor: "white",
              color: "#4B4B4B",
              border: "2px solid #4B4B4B",
              width: "30px",
              height: "30px",
            }}
          >
            {user?.email?.slice(0, 1).toUpperCase()}
          </Avatar>
        </IconButton>
        {user?.email && <Typography>{user?.email}</Typography>}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
