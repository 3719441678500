import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { TableStyle } from "../../PDFStyling/Registration/RegistrationStyle";

const MumbaiRegistrationTable = ({ ICEmployee }) => {
  return (
    <View style={TableStyle.table}>
      <View style={TableStyle.tableRow}>
        <Text style={TableStyle.headerCell}>Sr. No</Text>
        <Text style={TableStyle.headerCell}>
          Designation on the Internal Committee (IC)
        </Text>
        <Text style={TableStyle.headerCell}>Name of the Employee </Text>
        <Text style={TableStyle.headerCell}>Designation</Text>
        <Text style={TableStyle.headerCell}>Mobile Number</Text>
        <Text style={TableStyle.headerCell}>Email ID</Text>
      </View>
      {ICEmployee?.map((row, indx) => (
        <View key={row?.id} style={TableStyle.tableRow}>
          <Text style={TableStyle.cell}>{indx + 1}</Text>
          <Text style={TableStyle.cell}>{row?.designation_in_ic?.name}</Text>
          <Text style={TableStyle.cell}>
            {row?.firstName}
            {row?.lastName}
          </Text>
          <Text style={TableStyle.cell}>{row?.designation}</Text>
          <Text style={TableStyle.cell}>{row?.phone_number}</Text>
          <Text style={TableStyle.cell}>{row?.email}</Text>
        </View>
      ))}
    </View>
  );
};

export default MumbaiRegistrationTable;
