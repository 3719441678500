import React, { useContext } from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  useDeleteTrainingMutation,
  useGetTrainingByNameQuery,
} from "../../../store/training/TrainingAPI";
import ClearIcon from "@mui/icons-material/Clear";

import { useNavigate } from "react-router-dom";
import EditIcon from "../../../assets/icons/EditIcon.svg";
import DarkEditIcon from "../../../assets/icons/DarkEditIcon.svg";
import { ColorModeContext } from "../../../constants/theme";
import { getIdFromUrl } from "../../../utils/getIdFromUrl";
import { StatusBar } from "../../../components/General";
import StatusFilters from "../../../components/Filters/StatusFilters";
import FilterButton from "../../../components/Buttons/FilterButton";

const OtherInitiatives = ({ superadmin = false, params = {} }) => {
  const ID = getIdFromUrl();
  const { mode } = useContext(ColorModeContext);
  const [deleteTraining] = useDeleteTrainingMutation();
  const [open, setOpen] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState("all");

  const { data, refetch } = useGetTrainingByNameQuery({
    training_type_name: "Any Other Initiatives",
    location_id: ID,
    status: selectedStatus,
    ...params,
  });

  const navigate = useNavigate();

  return (
    <>
      <Paper
        className="tw-w-full tw-p-4 sm:tw-p-7 lg:tw-p-12 tw-rounded-lg tw-border tw-border-[#EDEDED]"
        sx={{
          background: `${mode === "dark" ? "#1A1A1A" : " #fff"}`,
          height: "100%",
          width: "100%",
        }}
      >
        <Box className="tw-flex tw-flex-row tw-justify-between  tw-mb-3 sm:tw-mb-8">
          <Stack direction={"row"} justifyContent={"flex-start"} gap={2}>
            <Typography className="tw-font-medium heading4">
              Other Initiatives
            </Typography>
            <StatusBar label={data?.status} status={data?.status} />
          </Stack>
          <Box className="tw-flex">
            {" "}
            <FilterButton
              buttonStyles={{ height: "32px" }}
              onClick={() => setOpen(!open)}
            />
            {!superadmin && (
              <Button
                variant="contained"
                onClick={() => navigate("/home/training/add-initative")}
                sx={{ backgroundColor: "#4B4B4B" }}
              >
                Add New
              </Button>
            )}
          </Box>
        </Box>
        <StatusFilters
          open={open}
          selectedStatus={selectedStatus}
          handleStatusChange={(e) => setSelectedStatus(e.target.value)}
          marginBottom={2}
        />
        <Box className="tw-flex tw-flex-col tw-space-y-3">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    background: `${mode === "dark" ? "#131313" : " #FFF6F2"}`,
                  }}
                >
                  <TableCell align="center">SI No.</TableCell>
                  <TableCell align="center">Name of Initiative</TableCell>
                  <TableCell align="center"> Initiative description</TableCell>
                  <TableCell align="center"> Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.training &&
                  data?.training?.map((data, index) => (
                    <TableRow key={data.id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {data?.inititative_name}
                      </TableCell>
                      <TableCell align="center">{data?.description}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() =>
                            navigate(
                              `/home/training/edit-initative/${data?.id}`,
                            )
                          }
                        >
                          {mode === "dark" ? (
                            <img src={DarkEditIcon} />
                          ) : (
                            <img src={EditIcon} />
                          )}
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            deleteTraining(data?.id);
                            refetch();
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </>
  );
};

export default OtherInitiatives;
