import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function NonRetaliation({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{serialNo}. Non-retaliation</Text>
      <Text style={styles.text}>
        Organization will not tolerate any form of retaliation against any
        individual who, in good faith, files a Complaint regarding actual or
        potential violation of this Policy or participates in any way in the
        inquiry. Individuals engaging in retaliatory conduct shall be subject to
        strict disciplinary action.
      </Text>
      <Text style={styles.text}>
        However, anyone who abuses the procedure (for example, by maliciously
        putting an allegation knowing it to be untrue and/or produces any forged
        or misleading document) will be subject to appropriate disciplinary
        action.
      </Text>
    </View>
  );
}

export default NonRetaliation;
