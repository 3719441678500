export const backgroundClause = [
  "Organization is an equal opportunity employer and is committed to creating a healthy and safe working environment that enables employees to work without fear of prejudice, gender bias and in a harassment free workplace to all without regard to race, caste, religion, colour, ancestry, marital status, gender, age, nationality, ethnic origin or disability. Organization strivesto establish and maintain a work environment in which all individuals are respected and treated with dignity and does not tolerate any form of discrimination or harassment. This ‘Policy on Prevention of Sexual Harassment of women at workplace’ (“Policy”) reinforces that commitment.",
  "This Policy has been framed specifically in line with the provisions of “The Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013” and rules framed thereunder along with all amendments from time to time (“Act/Law”). As such, reference shall always be made to the Act for any aspect relating to sexual harassment not explicitly covered herein. In the event of any conflict between this Policy and the Act, the Act will prevail.",
];

export const scopeClause = [
  "This Policy extends to all employees of the Organization and to individuals coming to the workplace for employment or for any other purpose whatsoever including, but not limited to visitors, vendors, contractual resources, secondees. It also applies to employees working virtually or from any other location as may be necessary for the employees to perform their duties. All employees of the Organization have a personal responsibility to ensure that their conduct is not contrary to this Policy.",
  "The purpse of the Policy isto provide protection against sexual harassment of women at workplace and the prevention and redressal of complaints of sexual harassment and matters related to it. ",
];

export const keyDefinitionsClauses = {
  objective:
    "For the purposes of this Policy, the following terms shall have the meaning indicated below:",
  sexualharassment: {
    heading: "Sexual Harassment",
    acts: {
      objective:
        "“Sexual Harassment” includes any one or more of the following unwelcome acts or behavior (whether directly or by implication) namely:",
      points: [
        "physical contact and advances; or",
        "a demand or request for sexual favours; or",
        "making sexually coloured remarks; or",
        "showing pornography; or",
        "any other unwelcome physical, verbal or non-verbal conduct of sexual nature;",
      ],
    },
    circumstances: {
      objective:
        "These circumstances, among other circumstances, if it occurs or if it is present in relation to or connected with any act of sexual harassment, may amount to sexual harassment:",
      points: [
        "When either implicitly or explicitly it becomes a condition of the individuals present or future employment",
        "Implied or explicit promise of preferential treatment in employment",
        "Implied or explicit threat of detrimental treatment in employment.",
        "The harassment has a purpose or effect of unreasonably interfering with the work performance or creating an environment, which is intimidating, hostile or offensive",
        "Humiliating treatment likely to affect health or safety",
      ],
    },
    conclusion:
      "In all the above cases, everyone must exercise their own good judgment to avoid engaging in conduct that may be perceived by others as Sexual Harassment. In all the above cases, it is not the intention of the person behaving but the impact on the person who is subjected to such behavior that will matter.",
  },
  Employee:
    "A person employed at a workplace for any work on regular, temporary, ad hoc or daily wage basis, either directly or through an agent, including a contractor, with or, without the knowledge of the principal employer, whether for remuneration or not, or working on a voluntary basis or otherwise, whether the terms of employment are express or implied and includes a co-worker, a contract worker, probationer, trainee, apprentice or called by any other such name. This definition of ‘Employee’ shall be used only for the purposes of this policy and cannot be used to claim rights of an employee conferred under any other law for the time being in force.",
  Workplace:
    "In addition to the place of work (office, branch, yard etc.), it covers any other Organization premises (including transit houses and guest houses) and any place visited by the employee in connection with work during the course of employment or arising out of employment with the Organization, including travel/transportation provided by the employer for undertaking such a journey.",
  "Aggrieved Woman Or Complaint": [
    "In relation to a workplace, a woman, of any age, whether employed or not, who alleges to have been subjected to any act of sexual harassment by the respondent and includes contractual, temporary, visitors.",
    "In case, anyone else has to file a complaint on behalf of the Aggrieved Woman as per Law, such person shall be referred to as the “Complainant.” Any reference to the term ‘Complainant’ throughout this Policy shall mean reference to ‘Aggrieved Woman’ and / or the ‘Complainant’ as applicable.",
  ],
  Respondent:
    "Employees against whom a complaint has been made by the Complainant.",
};

export const ProcedureForRedressal = {
  objective:
    "The Organization is committed to providing a supportive environment to resolve concerns of sexual harassment as under:",
  "Redressal Mechanism and Internal Committee":
    "Organization has constituted an Internal Committee (“IC”) for receiving and redressing complaints related to Sexual Harassment at Workplace, pursuant to the relevant provisions of the Act. The details of the members of the IC, including their names, contact information and other details is attached as Annexure- 1. The details of the members of the IC will also be displayed at the office notice board and be published in the “Legal & Compliance” intranet.",
  "Filing a complaint": [
    "A complaint must be reported in writing within a period of 3 (three) months of occurrence of an act of Sexual Harassment and in case of a series of incidents, within a period of 3 (three) months from the date of last incident to any member of the IC as per details provided in Annexure I. The IC can extend the timeline for filing the complaint, for reasons to be recorded in writing, by a period of 3 (three) months. A complaint can also be reported electronically at ________ as per the details mentioned in Annexure ‘1’ below.Complainant should submit the complaint along with supporting documents and the names of the witnesses.",
    "For details regarding complaints that can be filed on behalf of an aggrieved woman on account of her physical incapacity, mental incapacity or any other reason, please refer to Anneure ‘2’ below",
  ],
  ProcedureForHandlingComplaints: {
    heading: "Procedure for Handling Complaints:",
    "Process for Conciliation":
      "Prior to initiating an inquiry, the IC may, at the written request of the Complainant, take steps to conciliate the matter between the Complainant and the Respondent. In case a settlement is arrived at, the IC will record & report the same to the management for taking appropriate action. The IC will also provide copies of the settlement, as recorded, to the Complainant and the Respondent. If conciliation has been reached and the action implemented, the IC will not be required to conduct any further inquiry. Monetary settlement cannot be made the basis of such conciliation.",
    ProcedureForInquiry: {
      heading: "Procedure for Inquiry",
      objective:
        "If the Complainant does not wish to go for conciliation or where a settlement could not be arrived at through conciliation or if the Complainant informs the IC that conciliation reached between the parties is not complied with, the IC shall proceed to make inquiry into the complaint as per the process prescribed under this Policy.",
      points: [
        "The IC shall send copy of the complaint to the Respondent within a period of 7 (seven) working days.",
        "The Respondent shall file the reply to the complaint with all supporting documents, and names and addresses of witnesses (if any), within a period of 10 (ten) working days.",
        "The Complainant and Respondent shall not be allowed to bring in any legal practitioner to represent them in their case at any stage of the proceedings before the IC.",
        "During the pendency of an inquiry, IC shall have the power to issue interim directions to / with regard to any person participating in the proceedings before it as more particularly stated in Annexure ‘2‘ below.",
        "The IC shall have the right to terminate the inquiry proceedings or to give an ex-parte decision on the complaint, if the Complainant or Respondent fails, without sufficient cause, to present themselves, for 3 (three) consecutive hearings convened by the Presiding Officer. Such termination or ex-parte order may not be passed without giving a notice in writing, 15 (fifteen) days in advance, to the Complainant or Respondent as the case maybe.",
        "In conducting the inquiry, at any point of time, the Complaint shall be heard by a minimum of 3 (three) members including the Presiding Officer.",
        "The IC shall make an inquiry into the complaint in accordance with the principles of natural justice.",
      ],
    },
    "Submission of Report": [
      "The IC shall conduct its inquiry in a timely manner and shall submit a written report to the management as soon as practically possible and in any case, not later than 90 (Ninety) days from the date of receipt of the complaint. The inquiry report shall specify details of the allegations against the Respondent, the statements made and evidence presented by the Complainant, Respondent and / or witnesses, IC’s findings along with a statement giving reasons for the findings arrived at by the IC and IC’s recommendations. Details are provided in Annexure ‘2’ below.",
      "The Organization is required to act upon the recommendations within 60 days and confirm to the IC.",
    ],
  },
};

export const ProcedureForFilingAnAppeal = [
  "Any party aggrieved (Complainant or Respondent) by the recommendations of the IC and / or nonimplementation of recommendations may file an appeal to the Appellate Authority as per service rules and/or as per other provisions of Law within 90 (ninety) days of the recommendations.",
];

export const Confidentiality = [
  "All discussions / decisions pertaining to an incident of sexual harassment must be kept confidential. The contents of the complaint made, the identity and addresses of the Complainant, Respondent and witnesses, any information relating to conciliation and inquiry proceedings, inquiry report including recommendations of the IC and action taken on the Respondent shall not be published, communicated or made known to the public, press and media in any manner. Any violation thereto shall subject to disciplinary action and further dealt with in accordance with the Act",
];

export const NonRetaliation = [
  "Organization will not tolerate any form of retaliation against any individual who, in good faith, files a Complaint regarding actual or potential violation of this Policy or participates in any way in the inquiry. Individuals engaging in retaliatory conduct shall be subject to strict disciplinary action.",
  "However, anyone who abuses the procedure (for example, by maliciously putting an allegation knowing it to be untrue and/or produces any forged or misleading document) will be subject to appropriate disciplinary action.",
];

export const FilingOfComplaintWithPolice = [
  "Nothing in these rules and procedures shall preclude anybody from simultaneously lodging a complaint with the police in respect of any act amounting to an offence under the law. Organization will provide assistance in this regard, if assistance is sought. This Policy is not intended to impair or limit the right of any Employee seeking a remedy available under any law. Proceedings under this Policy shall continue notwithstanding any proceedings initiated by a Complainant (as defined hereinafter) against Respondent under any law in force.",
];

export const Amendments = [
  "Organization reserves the right to amend the Policy from time to time in order to comply with any laws / rules / regulations that come into effect from time to time, related to sexual harassment at workplace. This Policy shall be reviewed bi-annually or as and when key issues get raised to incorporate amendments.",
  "This Policy does not intend to create any contractual obligation for or with Organization, and in case of dispute with the Law and the Policy, the Law shall prevail.",
];

export const Clause1 = [
  "Rorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.",
];

export const Clause2 = [
  "Doloremque sapiente enim, eveniet odit nostrum nam. Unde dolores ipsa culpa, similique neque quia beatae aliquam consequatur nisi facilis. Nisi cum officiis doloribus, aliquid voluptates velit enim adipisci provident corrupti ratione magni eligendi dignissimos repellendus amet nostrum ab tempora sed? Est inventore unde veritatis nesciunt!",
];
