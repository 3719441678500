export const getPolicyIndices = (clauses) => {
  let Indices = [];
  Object.keys(clauses).forEach((c) => {
    if (clauses[c] === true) {
      Indices.push(Indices.length + 1);
    }
  });
  Indices = Indices.reverse();
  return Indices;
};
