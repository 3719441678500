import React from "react";
import { Grid } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useGetAllZonesQuery } from "../../store/dropdown/dropdownApi";
import { CheckboxField2, SelectField2 } from "../Form";

function ZoneField({
  control,
  checkboxName,
  zoneName,
  zoneError,
  validations,
  lg = 4,
}) {
  const watchedCheckbox = useWatch({ control, name: checkboxName });
  useGetAllZonesQuery();
  const { dropdowns } = useSelector((state) => state.dropdown);

  return (
    <>
      <Grid item xs={12} sm={6} lg={lg}>
        <CheckboxField2
          label={"Assign zone to this location"}
          name={checkboxName}
          control={control}
          defaultValue={false}
        />
      </Grid>
      {watchedCheckbox && (
        <Grid item xs={12} sm={6} lg={4}>
          <SelectField2
            label={"Zones"}
            placeholder={"Select"}
            menus={dropdowns?.AllZones?.length > 0 ? dropdowns?.AllZones : []}
            name={zoneName}
            control={control}
            validations={validations}
            error={zoneError}
          />
        </Grid>
      )}
    </>
  );
}

export default ZoneField;
