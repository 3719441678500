import React from "react";
import { ColorModeContext } from "../../constants/theme";
import { Stack, Typography } from "@mui/material";
import { StatusBar } from "../../components";

function ComplianceStatusLayout({
  imgSrc = "",
  text = "",
  label = "",
  status = "",
}) {
  const { mode } = React.useContext(ColorModeContext);

  return (
    <Stack
      direction={"row"}
      paddingX={"40px"}
      paddingY={"23px"}
      boxShadow={
        "0px 0px 1px rgba(12, 14, 25, 0.24), 0px 2px 12px rgba(12, 14, 25, 0.12)"
      }
      borderRadius={"6px"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      bgcolor={mode === "dark" ? "#1A1A1A" : "white"}
      width={"50%"}
      gap={2}
    >
      <Stack direction={"row"} flex={1} gap={2} justifyContent={"flex-start"}>
        <img width={"25px"} src={imgSrc} alt="" />
        <Typography
          fontSize={"20px"}
          fontWeight={500}
          color={mode === "dark" ? "#DCDCDC" : "#4B4B4B"}
          marginRight={4}
        >
          {text}
        </Typography>
      </Stack>
      <StatusBar label={label} status={status} />
    </Stack>
  );
}

export default ComplianceStatusLayout;
