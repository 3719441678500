import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  IconButton,
  InputLabel,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import InputField from "../../../components/Form/InputField";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  useGetContactQuery,
  useUpdateContactMutation,
} from "../../../store/org/orgApi";

import { BackdropLoader } from "../../../components/General";
import Swal from "sweetalert2";
import { ColorModeContext } from "../../../constants/theme";
import { ContainerLayout } from "../../../components/Layouts";
import { SaveAndCancelBtn } from "../../../components";

const breadcrumbs = [
  <Typography className="tw-text-[11px]" key="100">
    EMPLOYEE MANAGER
  </Typography>,
  <Typography className="tw-text-dark-blue tw-text-[11px]" key="200">
    UPDATE EMPLOYEE
  </Typography>,
];

const EditEmployeeManager = () => {
  const { mode } = useContext(ColorModeContext);
  const url = window.location.href;
  const ID = url.substring(url.lastIndexOf("/") + 1);
  const { data, isSuccess, isLoading } = useGetContactQuery(ID);
  const [
    updateEmployee,
    { isSuccess: updateSuccess, isLoading: updateLoading },
  ] = useUpdateContactMutation(ID);
  const navigate = useNavigate();
  const TabScreen = useMediaQuery("(min-width: 640px)");

  const { register, handleSubmit, control, reset } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    console.log(isLoading);
    if (isSuccess) {
      console.log("Employee data------->", data?.employee);
      const obj = { ...data.employee };
      reset(obj);
    }
    if (updateSuccess) {
      Swal.fire({
        icon: "success",
        title: "Employee updated Successfully!",
        showConfirmButton: false,
        timer: 1000,
      });
      navigate("/home/employee-manager-list");
    }
  }, [updateSuccess, isLoading]);
  const onSubmit = (data) => {
    console.log("OnSubmit data", data);
    const obj = {};
    obj.firstName = data.firstName;
    obj.lastName = data.lastName;
    obj.email = data.email;
    obj.phone_number = data.phone_number;
    obj.employment_level_id = data.employment_level_id;
    obj.designation = data.designation;
    obj.date_of_birth = data.date_of_birth;
    console.log("object", obj);
    updateEmployee({ body: obj, id: ID });
  };
  return (
    <ContainerLayout>
      {updateLoading ? (
        <>{updateLoading && <BackdropLoader open={updateLoading} />}</>
      ) : (
        <Container
          className="tw-p-10"
          sx={{
            height: "auto",
            width: "100%",
            maxWidth: "1300px",
            margin: 0,
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <Toolbar />
          <Box className="tw-flex tw-justify-start tw-space-x-3 lg:tw-px-10">
            <IconButton
              className="tw-rounded-lg tw-shadow-lg tw-w-14 tw-h-14"
              sx={{ background: `${mode === "dark" ? "#1A1A1A" : "#FFFFFF"}` }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <Box
              className="tw-flex-1 tw-rounded-lg tw-shadow-lg tw-py-3 tw-px-8"
              sx={{ background: `${mode === "dark" ? "#1A1A1A" : "#FFFFFF"}` }}
            >
              <Breadcrumbs
                sx={{
                  display: "flex",
                  height: "100%",
                }}
                separator={<NavigateNextIcon fontSize="small" />}
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Box>
          </Box>
          <Box className="tw-w-full tw-mb-4 sm:tw-mb-6 lg:tw-mb-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography
                className="tw-text-heading-black tw-font-medium lg:tw-px-10 heading1"
                sx={{ color: `${mode === "dark" ? "#FFFFFF" : "#4B4B4B"}` }}
              >
                Edit Employee
              </Typography>
              <Box className=" tw-p-3 sm:tw-p-6 lg:tw-p-10 tw-rounded-3 tw-mb-4 sm:tw-mb-8">
                <Grid container spacing={TabScreen ? 4 : 2}>
                  <Grid item xs={12} sm={6} lg={6}>
                    <InputField
                      label={"First Name"}
                      placeholder="Enter first name"
                      name="firstName"
                      register={register}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <InputField
                      label={"Last Name"}
                      placeholder="Enter last name"
                      name="lastName"
                      register={register}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <InputLabel
                      className="tw-text-xs sm:tw-text-sm  tw-w-min tw-px-2 tw-py-1 tw-rounded-md"
                      htmlFor="orgName"
                    >
                      <Typography>Date of Birth</Typography>
                    </InputLabel>
                    <Controller
                      control={control}
                      name="date_of_birth"
                      rules={{
                        required: "This field is required",
                        validate: (value) => {
                          console.log("validation ---->", value, typeof value);
                          const check = new Date(value) > new Date();
                          return !check || "Please select a valid date";
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          onChange={onChange}
                          format="DD/MM/YYYY"
                          // className="tw-bg-input-bg"
                          value={moment(value)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <InputField
                      label={"Email"}
                      placeholder="Enter email"
                      name="email"
                      register={register}
                      control={control}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={6}>
                    <InputField
                      label={"Phone Number"}
                      placeholder="Enter phone number"
                      name="phone_number"
                      register={register}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <InputField
                      label={"Designation in company"}
                      placeholder="Enter Designation"
                      name="designation"
                      register={register}
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-justify-end tw-space-y-4 sm:tw-space-x-8 sm:tw-space-y-0 tw-mt-4 md:tw-mt-4 lg:tw-mt-6 lg: tw-mr-10">
                <SaveAndCancelBtn
                  handleCancle={() => {
                    navigate("/home/employee-manager-list");
                  }}
                />
              </Box>
            </form>
          </Box>
        </Container>
      )}
    </ContainerLayout>
  );
};

export default EditEmployeeManager;
