import React, { useContext } from "react";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "./BreadCrumb";
import { ColorModeContext } from "../../constants/theme";

function NavigationBar({ breadcrumbs }) {
  const { mode } = useContext(ColorModeContext);
  const navigate = useNavigate();
  return (
    <div className="tw-flex tw-justify-start tw-space-x-3">
      <IconButton
        className="tw-rounded-lg tw-w-14 tw-h-14"
        sx={{
          background: `${mode === "dark" ? "#1A1A1A" : "#FFFFFF"}`,
          boxShadow: "0px 5px 20px rgba(85, 85, 85, 0.14)",
        }}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIcon />
      </IconButton>
      <BreadCrumb breadcrumbs={breadcrumbs} />
    </div>
  );
}

export default NavigationBar;
