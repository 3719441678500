import React from "react";
import { InputLabel, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";

function DatePickerField({ label, name, error, control }) {
  return (
    <div className="tw-gap-2 tw-flex tw-flex-col">
      <InputLabel
        className="tw-text-xs sm:tw-text-sm  tw-w-min tw-py-1"
        htmlFor="orgName"
      >
        <Typography>{label}</Typography>
      </InputLabel>
      <Controller
        name={name}
        control={control}
        rules={{
          required: "This field is required",
          validate: (value) => {
            console.log("validation ---->", value, typeof value);
            const check = new Date(value) > new Date();
            return !check || "Please select a valid date";
          },
        }}
        render={({ field: { name, onChange, defaultValue, value } }) => (
          <DatePicker
            name={name}
            defaultValue={defaultValue}
            value={value}
            format="DD/MM/YYYY"
            onChange={onChange}
          />
        )}
      />
      {error && <span className="tw-text-red-700 tw-text-xs">{error}</span>}
    </div>
  );
}

export default DatePickerField;
