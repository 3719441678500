import React, { useEffect } from "react";
import RegistrationLayout from "../../../components/Layouts/RegistrationLayout";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import {
  useGetEmployeeByRoleQuery,
  useGetRegionLocationByNameQuery,
} from "../../../store/dropdown/dropdownApi";
import { useGetICByLocationQuery } from "../../../store/ic/icApi";
import {
  DownloadButton,
  RegistrationCard,
  RegistrationForm,
} from "../../../components";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useNavigate } from "react-router-dom";
import RegistrationLogo from "../../../assets/logos/RegistrationLogo.svg";
import PageHeading from "../../../components/General/PageHeading";
import { MumbaiLetterDoc } from "../../../components/PDF/Registration";
import RegistrationList from "../../../utils/RegistrationList";

const Mode = [{ id: "wrwer", name: "Courier" }];
const MumbaiCityRegistration = () => {
  const [agree, setAgree] = React.useState(false);
  const [preview, setPreview] = React.useState(false);
  const [locationValue, setLocationValue] = React.useState([]);
  const [complianceDetails, setComplianceDetails] = React.useState([]);
  const [icData, setIcData] = React.useState();
  const [letterMode, setLetterMode] = React.useState("");
  const [locationId, setLocationId] = React.useState("");
  const [representativeName, setRepresentativeName] = React.useState("");
  const [pdfPreivewLoader, setPdfPreviewLoader] = React.useState(false);

  const navigate = useNavigate();

  // Get Org-ID
  const OrganizationDetails = useSelector((state) => state.auth);
  const { user } = OrganizationDetails;

  const ID = user?.user?.org_id;

  // Get List of Mumbai City's compliance manager
  const { data: mumbaiComplianceManager } = useGetEmployeeByRoleQuery({
    role: "COMPLIANCE_MANAGER",
    org_id: ID,
  });
  // Get List of Mumbai City's Locations
  const { data: MumbaiLocation } = useGetRegionLocationByNameQuery({
    region: "Mumbai City",
    org_id: ID,
  });

  const { data: IcData } = useGetICByLocationQuery({
    location_id: locationId,
  });

  useEffect(() => {
    let temp = [];
    if (MumbaiLocation?.location) {
      temp = MumbaiLocation?.location?.map((loc) => {
        return {
          id: loc?.id,
          name: loc?.location_name,
        };
      });
    }
    setComplianceDetails(mumbaiComplianceManager?.employees);
    setIcData(IcData?.ic?.employees);
    setLocationValue(temp || []);
  }, [mumbaiComplianceManager, MumbaiLocation, IcData, icData]);

  const checkboxHandler = () => {
    setAgree(!agree);
    setPreview(false);
  };

  const onSubmit = (data) => {
    console.log("data", data);
    setLetterMode(data.mode);
    setLocationId(data.location);
    setRepresentativeName(data.compliance);
    setPreview(true);
    setPdfPreviewLoader(true);
    setTimeout(() => {
      setPdfPreviewLoader(false);
    }, 2000);
  };

  return (
    <RegistrationLayout>
      <PageHeading label="Mumbai City Registration" logo={RegistrationLogo} />
      <RegistrationCard
        checkboxHandler={checkboxHandler}
        order={RegistrationList[0].mumbaiCity.order}
        translatedOrder={RegistrationList[0].mumbaiCity.translatedOrder}
        orderUrl={RegistrationList[0].mumbaiCity.orderUrl}
        translatedOrderUrl={RegistrationList[0].mumbaiCity.translatedOrder}
        confirmation={RegistrationList[0].mumbaiCity.confirmation}
      />
      <Box className="tw-px-60">
        <RegistrationForm
          onSubmit={onSubmit}
          complianceDetails={complianceDetails}
          locationValue={locationValue}
          Mode={Mode}
          agree={agree}
          pdfPreivewLoader={pdfPreivewLoader}
        />
        {preview && !pdfPreivewLoader && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginY: "20px",
            }}
          >
            <PDFViewer
              style={{
                height: "100vh",
                width: "100%",
              }}
              onLoad={() => setPdfPreviewLoader(false)}
            >
              {letterMode === "Courier" && (
                <MumbaiLetterDoc
                  ICEmployee={icData}
                  representativeName={representativeName}
                />
              )}
            </PDFViewer>
          </Box>
        )}

        {preview && !pdfPreivewLoader && (
          <>
            <PDFDownloadLink
              style={{ marginTop: "20px" }}
              document={
                letterMode === "Courier" && (
                  <MumbaiLetterDoc
                    ICEmployee={icData}
                    representativeName={representativeName}
                  />
                )
              }
              fileName="MumbaiCityLetterDoc"
            >
              {({ loading }) =>
                loading ? (
                  <DownloadButton
                    loading={loading}
                    styles={{ width: "100%", marginTop: "10rem" }}
                  />
                ) : (
                  <DownloadButton
                    styles={{ width: "100%", marginTop: "10rem" }}
                  />
                )
              }
            </PDFDownloadLink>
            <DownloadButton
              label="Upload Registration"
              styles={{ width: "100%", marginTop: "2rem" }}
              onClick={() => {
                navigate("/home/upload-registration");
              }}
            />
          </>
        )}
      </Box>
    </RegistrationLayout>
  );
};

export default MumbaiCityRegistration;
