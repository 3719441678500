import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  useDeleteTrainingMutation,
  useGetTrainingByNameQuery,
} from "../../../store/training/TrainingAPI";
import ClearIcon from "@mui/icons-material/Clear";
import TrainingLogo from "../../../assets/logos/TrainingLogo.svg";
import EditICon from "../../../assets/icons/EditIcon.svg";

import { useNavigate } from "react-router-dom";
import Loader from "../../../components/General/Loader";
import { ColorModeContext } from "../../../constants/theme";
import { getIdFromUrl } from "../../../utils/getIdFromUrl";
import { StatusBar } from "../../../components/General";
import FilterButton from "../../../components/Buttons/FilterButton";
import StatusFilters from "../../../components/Filters/StatusFilters";

const EmployeeTraining = ({ superadmin = false, params = {} }) => {
  const ID = getIdFromUrl();
  const { mode } = useContext(ColorModeContext);
  const [open, setOpen] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState("all");

  const { data, isLoading, refetch } = useGetTrainingByNameQuery({
    training_type_name: "Employee Awarness Sessions",
    location_id: ID,
    status: selectedStatus,
    ...params,
  });

  useEffect(() => {
    console.log("training type", data);
  }, [data]);

  const [deleteTraining] = useDeleteTrainingMutation();

  const navigate = useNavigate();

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <Paper
          className="tw-w-full tw-p-4 sm:tw-p-7 lg:tw-p-12 tw-rounded-lg tw-border tw-border-[#EDEDED]"
          sx={{
            background: `${mode === "dark" ? "#1A1A1A" : " #fff"}`,
            height: "100%",
            width: "100%",
          }}
        >
          <Typography className="tw-font-medium heading3 tw-mb-3 sm:tw-mb-8">
            <img
              src={TrainingLogo}
              style={{ width: "20px", marginRight: "40px" }}
            />{" "}
            Trainings
          </Typography>
          <Box className="tw-flex tw-flex-row tw-justify-between  tw-mb-3 sm:tw-mb-8">
            <Stack direction={"row"} justifyContent={"flex-start"} gap={2}>
              <Typography className="tw-font-medium heading4">
                Employ Awareness Trainings
              </Typography>
              <StatusBar label={data?.status} status={data?.status} />
            </Stack>
            <Box className="tw-flex">
              {" "}
              <FilterButton
                buttonStyles={{ height: "32px" }}
                onClick={() => setOpen(!open)}
              />
              {!superadmin && (
                <Button
                  variant="contained"
                  onClick={() => navigate("/home/training/add")}
                  sx={{ backgroundColor: "#4B4B4B" }}
                >
                  Add New
                </Button>
              )}
            </Box>
          </Box>
          <StatusFilters
            open={open}
            selectedStatus={selectedStatus}
            handleStatusChange={(e) => setSelectedStatus(e.target.value)}
            marginBottom={2}
          />
          <Box className="tw-flex tw-flex-col tw-space-y-3">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      background: `${mode === "dark" ? "#131313" : " #FFF6F2"}`,
                    }}
                  >
                    <TableCell align="center">SI No.</TableCell>
                    <TableCell align="center">Date of Training</TableCell>
                    <TableCell align="center"> Mode of training</TableCell>
                    <TableCell align="center"> Training description</TableCell>
                    <TableCell align="center"> Status</TableCell>
                    <TableCell align="center"> Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.training?.map((data, index) => (
                    <TableRow
                      key={data?.id}
                      sx={{
                        background: `${mode === "dark" ? "#0D0D0D" : " #FFF"}`,
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{data?.date}</TableCell>
                      <TableCell align="center">
                        {data?.mode_of_training}
                      </TableCell>
                      <TableCell align="center">{data?.description}</TableCell>
                      <TableCell align="center">{data?.status}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            navigate(`/home/training/edit/${data?.id}`)
                          }
                        >
                          <img src={EditICon} />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            deleteTraining(data?.id);
                            refetch();
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      )}
    </>
  );
};

export default EmployeeTraining;
