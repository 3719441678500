import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function WhatIsVisitor({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {serialNo}. WHAT IS A VISITOR / THIRD PARTY?
      </Text>
      <Text style={styles.text}>
        “Visitor” or “Third Party” includes any person who is visiting the
        Workplace and is not covered by any of the other categories defined by
        this Policy. For example, guests coming for physical/online interviews,
        meetings, events, programs or people walking in for meeting existing
        Employees, etc.
      </Text>
    </View>
  );
}

export default WhatIsVisitor;
