import React from "react";
import { Doughnut } from "react-chartjs-2";
// eslint-disable-next-line no-unused-vars
import { Chart as ChartJS, Tooltip } from "chart.js/auto";
import { Box, CircularProgress } from "@mui/material";
ChartJS.register(Tooltip);

function DoughnutChart({
  data = {},
  options = {},
  width = "100%",
  height = "100%",
  loading = false,
}) {
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Box width={width} height={height}>
      {Object.keys(data)?.length === 0 ? null : (
        <Doughnut
          options={{ maintainAspectRatio: false, ...options }}
          data={data}
        />
      )}
    </Box>
  );
}

export default DoughnutChart;
