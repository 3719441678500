import Roboto from "../../assets/font/Roboto-Bold.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";

export const myFont = {
  family: "MyFont",
  fonts: [{ src: Roboto, fontWeight: "bold" }],
};

export const regularFont = {
  family: "RegularFont",
  fonts: [{ src: RobotoRegular, fontWeight: "normal" }],
};
