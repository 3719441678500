import { Typography, Button, Box, Container } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useGetAllLanguagesQuery,
  useGetAllZonesQuery,
  useGetAverageAgeBracketsQuery,
  useGetRegionQuery,
  useGetTypesOfLocationsQuery,
} from "../../../store/dropdown/dropdownApi";
import { useCreateLocationsMutation } from "../../../store/org/orgApi";
import { ColorModeContext } from "../../../constants/theme";
import { BackdropLoader, NavigationBar } from "../../../components/General";
import AddLocationSection from "../../../components/AddLocationSection";
import { SaveAndCancelBtn } from "../../../components";
import Swal from "sweetalert2";

function LocationEdit() {
  const { mode } = useContext(ColorModeContext);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth?.user);
  const [open, setOpen] = React.useState(false);

  useGetTypesOfLocationsQuery();
  useGetAllLanguagesQuery();
  useGetAllZonesQuery();
  useGetAverageAgeBracketsQuery();
  useGetRegionQuery();
  const { dropdowns } = useSelector((state) => state.dropdown);

  const [createLocations, { isLoading, isSuccess, error }] =
    useCreateLocationsMutation();

  useEffect(() => {
    if (isLoading) {
      setOpen(true);
    }
    if (isSuccess) {
      setOpen(false);
      Swal.fire({
        title: "Added",
        text: "Location has been added",
        icon: "success",
        timer: 1500,
      });
      navigate(-1);
    }
    if (error) {
      setOpen(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error}`,
      });
    }
  }, [isLoading, isSuccess, error, dropdowns]);

  const initialLocationObj = {
    location_name: "",
    address_line_1: "",
    address_line_2: "",
    location_type_id: " ",
    country: "",
    postalcode: "",
    zone_id: " ",
    is_zone_assigned: false,
    no_of_employee: "",
    avg_age_bracket_id: " ",
    gender_women_percentage: "",
    gender_men_percentage: "",
    gender_other_percentage: "",
    preferred_language_of_communication: 0,
    preferNoToSay: false,
  };

  const initialValues = {
    locations: [initialLocationObj],
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    resetField,
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
    defaultValues: initialValues,
  });

  const onSubmit = (data) => {
    console.log(data);
    for (let i = 0; i < data.locations.length; i++) {
      if (data.locations[i].preferNoToSay) {
        data.locations[i].gender_men_percentage = null;
        data.locations[i].gender_other_percentage = null;
        data.locations[i].gender_women_percentage = null;
      }
      for (let key in data.locations[i]) {
        console.log("pair -->", key, data.locations[i][key]);
        if (data.locations[i][key] === " ") {
          console.log("yes", key);
          data.locations[i][key] = null;
        }
      }
    }
    console.log(data);
    if (user?.org_id) {
      createLocations({ id: user?.org_id, body: data });
    } else {
      console.log("org id not found");
    }
  };

  const {
    fields: LocationFields,
    append: LocationAppend,
    remove,
  } = useFieldArray({
    name: "locations",
    control,
  });

  const breadcrumbs = [
    <Typography className=" tw-text-[11px]" key="1000">
      company profile
    </Typography>,
    <Typography className="tw-text-dark-blue tw-text-[11px]" key="2000">
      Add Locations
    </Typography>,
  ];

  return (
    <Box
      className="tw-w-full tw-p-4 sm:tw-p-7 lg:tw-p-12 tw-rounded-lg tw-border tw-border-[#EDEDED]"
      sx={{
        background: `${mode === "dark" ? "#0D0D0D" : " #fff"}`,
        height: "100%",
        width: "100%",
      }}
    >
      <BackdropLoader open={open} />
      <Container
        className="tw-p-10"
        sx={{
          height: `${LocationFields.length === 0 ? "100vh" : "100%"}`,
          width: "100%",
          margin: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <NavigationBar breadcrumbs={breadcrumbs} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="tw-w-full tw-mb-4 sm:tw-mb-6 lg:tw-my-12">
            <Typography
              className="tw-font-semibold heading1"
              sx={{ color: `${mode === "dark" ? "#FFF" : "#000"}` }}
            >
              Locations
            </Typography>
          </Box>

          {/* Each Location */}
          {LocationFields.map((loc, idx) => {
            return (
              <AddLocationSection
                key={loc.id}
                idx={idx}
                remove={remove}
                register={register}
                control={control}
                errors={errors}
                resetField={resetField}
              />
            );
          })}

          <Box className="tw-w-full tw-mb-8 sm:tw-mb-12">
            <Button
              variant="contained"
              onClick={() => {
                LocationAppend(initialLocationObj);
              }}
              className="tw-text-white"
              sx={{ border: "1px solid white" }}
            >
              {LocationFields.length ? "Add More" : "Add Location"}
            </Button>
          </Box>

          {/* Submit Buttons */}
          <Box className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-justify-end tw-space-y-5 sm:tw-space-x-8 sm:tw-space-y-0 tw-mt-6 md:tw-mt-8 lg:tw-mt-16">
            <SaveAndCancelBtn
              handleCancle={() => remove()}
              saveBtnProps={{ disabled: getValues()?.locations?.length === 0 }}
            />
          </Box>
        </form>
      </Container>
    </Box>
  );
}

export default LocationEdit;
