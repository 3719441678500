import React, { useEffect } from "react";
import {
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { InputField2, SelectField2 } from "../../../components/Form";
import TextAreaField from "../../../components/Form/TextAreaField";
import { FromHeading } from "../../../components/OrgDetails";
import useMediaQuery from "@mui/material/useMediaQuery";
import { EmployeesSize } from "../../../utils/dropdowns";
import { useSelector } from "react-redux";
import { useGetAllRegistrationTypesQuery } from "../../../store/dropdown/dropdownApi";
import moment from "moment";
import {
  useGetLocationByOrgQuery,
  useUpdateOrgMutation,
} from "../../../store/org/orgApi";
import { DatePicker } from "@mui/x-date-pickers";
import SnackBar from "../../../components/General/Snackbar";
import { ContainerLayout } from "../../../components/Layouts";
import StateCityFields from "../../OrganizationDetails/StateCityFields";
import { NavigationBar } from "../../../components/General";
import { SaveAndCancelBtn } from "../../../components";

function CompanyEdit() {
  const navigate = useNavigate();
  const TabScreen = useMediaQuery("(min-width: 640px)");
  const { dropdowns } = useSelector((state) => state.dropdown);
  const { user } = useSelector((state) => state.auth?.user);
  const [updateOrg, { isSuccess: updateSuccess }] = useUpdateOrgMutation();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const { data: locationData } = useGetLocationByOrgQuery({
    id: user?.org_id,
  });
  const [selectedLocation, setSelectedLocation] = React.useState("select");
  useGetAllRegistrationTypesQuery();
  const { state } = useLocation();
  console.log("state --->", state);

  useEffect(() => {
    if (updateSuccess) {
      setOpenSnackbar(true);
      navigate("/home/company-profile");
    }
  }, [updateSuccess, setOpenSnackbar]);

  const breadcrumbs = [
    <Typography className="tw-text-black tw-text-[11px]" key="100">
      company profile
    </Typography>,
    <Typography className="tw-text-dark-blue tw-text-[11px]" key="200">
      Edit Company
    </Typography>,
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    resetField,
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
    defaultValues: {
      ...state,
      establishment_date: new Date(state.establishment_date),
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    const obj = {};
    obj.name = data.name;
    obj.registration_id = data.registration_id;
    obj.website = data.website;
    obj.establishment_date = data.establishment_date;
    obj.description = data.description;
    obj.no_of_employee = data.no_of_employee;
    obj.gender_ratio = data.gender_ratio;
    obj.city = data.city;
    obj.state = data.state;
    obj.country = data.country;
    if (selectedLocation && selectedLocation !== "select") {
      obj.address_line_1 = selectedLocation.address_line_1;
      obj.address_line_2 = selectedLocation.address_line_2;
      obj.city = selectedLocation.city;
      obj.state = selectedLocation.state;
      obj.country = selectedLocation.country;
      obj.postal_code = selectedLocation.postalcode;
    } else {
      obj.address_line_1 = data.address_line_1;
      obj.address_line_2 = data.address_line_2;
      obj.city = data.city;
      obj.state = data.state;
      obj.country = data.country;
      obj.postal_code = data.postal_code;
    }
    updateOrg({ id: user?.org_id, body: obj });
  };

  const validations = {
    required: "This field is required",
  };

  return (
    <ContainerLayout paddingTop={"0"}>
      <NavigationBar breadcrumbs={breadcrumbs} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-flex tw-flex-col">
          <div className="tw-w-full tw-mb-4 sm:tw-mb-6 lg:tw-mb-12">
            <FromHeading text="Organization details" />
          </div>

          {/* General Details */}
          <Grid container spacing={TabScreen ? 4 : 2}>
            <Grid item xs={12} sm={6}>
              <InputField2
                label={"Organization Name"}
                placeholder={"Enter Company Name"}
                name={"name"}
                control={control}
                validations={validations}
                error={errors?.name?.message}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField2
                label={"Client Code"}
                placeholder={"Enter Client Code"}
                name="client_code"
                register={register}
                validations={validations}
                error={errors?.client_code?.message}
                control={control}
                inputProps={{ disabled: true }}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectField2
                label={"Registration Type"}
                placeholder={"Select"}
                menus={
                  dropdowns?.RegistrationTypes?.length > 0
                    ? dropdowns?.RegistrationTypes
                    : []
                }
                name={"registration_id"}
                validations={validations}
                error={errors?.registration_id?.message}
                control={control}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="tw-gap-2 tw-flex tw-flex-col">
                <InputLabel
                  className="tw-text-xs sm:tw-text-sm  tw-w-min tw-px-2 tw-py-1 tw-rounded-md"
                  htmlFor="orgName"
                >
                  <Typography>Date of Incorporation</Typography>
                </InputLabel>
                <Controller
                  name="establishment_date"
                  control={control}
                  defaultValue={new Date()}
                  rules={{
                    required: "This field is required",
                    validate: (value) => {
                      console.log("validation ---->", value, typeof value);
                      const check = new Date(value) > new Date();
                      return !check || "Please select a valid date";
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      onChange={onChange}
                      format="DD/MM/YYYY"
                      className="tw-bg-input-bg"
                      value={moment(value)}
                    />
                  )}
                />
                {errors?.establishmentDate?.message && (
                  <span className="tw-text-red-700">
                    {errors?.establishmentDate?.message}
                  </span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField2
                label={"Organization Website"}
                placeholder="Enter"
                name={"website"}
                control={control}
                validations={validations}
                error={errors?.website?.message}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12}>
              <TextAreaField
                label={"About Company"}
                placeholder={"Enter Text"}
                name={"description"}
                register={register}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectField2
                label={"No of Employees"}
                placeholder={"Select"}
                menus={EmployeesSize}
                name={"no_of_employee"}
                control={control}
                is_id="name"
                validations={validations}
                error={errors?.no_of_employee?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField2
                label={"Gender Ratio"}
                placeholder="Enter ratio"
                name={"gender_ratio"}
                type="number"
                control={control}
                validations={{
                  ...validations,
                  valueAsNumber: "Value should be number",
                }}
                defaultValue=""
                error={errors?.gender_ratio?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider textAlign="left">
                Choose the address of a location
              </Divider>
            </Grid>
            <Grid item xs={6}>
              <div className="tw-gap-2 tw-flex tw-flex-col">
                <InputLabel
                  className="tw-text-xs sm:tw-text-sm tw-w-min tw-py-1"
                  htmlFor="orgName"
                >
                  <Typography>Location</Typography>
                </InputLabel>
                <Select
                  value={selectedLocation}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setSelectedLocation(e.target.value);
                  }}
                >
                  <MenuItem value={"select"}>Select</MenuItem>
                  {locationData?.location?.map((loc) => (
                    <MenuItem key={loc.id} value={loc}>
                      {loc.location_name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>

            {selectedLocation === "select" && (
              <>
                <Grid item xs={12}>
                  <Divider textAlign="left">Or Enter manually</Divider>
                </Grid>
                <Grid item xs={12}>
                  <InputField2
                    label={"Registered address 1"}
                    placeholder={"Enter Registered address"}
                    name={"address_line_1"}
                    control={control}
                    validations={validations}
                    error={errors?.address_line_1?.message}
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField2
                    label={"Registered address 2"}
                    placeholder={"Enter Registered address"}
                    name={"address_line_2"}
                    control={control}
                    validations={validations}
                    error={errors?.address_line_2?.message}
                    defaultValue=""
                  />
                </Grid>
                <StateCityFields
                  control={control}
                  stateName={"state"}
                  cityName={"city"}
                  resetField={resetField}
                  lg={6}
                />
                <Grid item xs={12} sm={6}>
                  <InputField2
                    label={"Country"}
                    placeholder={"Enter"}
                    name={"country"}
                    control={control}
                    validations={validations}
                    error={errors?.country?.message}
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField2
                    label={"Postal Code"}
                    placeholder={"Enter"}
                    name={"postal_code"}
                    control={control}
                    validations={validations}
                    error={errors?.postal_code?.message}
                    defaultValue=""
                  />
                </Grid>
              </>
            )}
          </Grid>
          <div className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-justify-end tw-space-y-5 sm:tw-space-x-8 sm:tw-space-y-0 tw-mt-6 md:tw-mt-8 lg:tw-mt-16">
            <SaveAndCancelBtn handleCancle={() => navigate(-1)} />
          </div>
        </div>
        <SnackBar
          open={openSnackbar}
          handleClose={() => setOpenSnackbar(false)}
          message={"Edited Successfully"}
        />
      </form>
    </ContainerLayout>
  );
}

export default CompanyEdit;
