import { Box, Tab, Tabs } from "@mui/material";
import React, { useContext } from "react";
import Login from "./Login";
import { ColorModeContext } from "../../constants/theme";

const LoginDashboard = () => {
  const [value, setValue] = React.useState(0);
  const { mode } = useContext(ColorModeContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: `${mode === "dark" ? "#0D0D0D" : "#EEF8FF"}`,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        gap: 5,
      }}
    >
      <Tabs
        variant="scrollable"
        value={value}
        onChange={handleChange}
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        <Tab label="Super Admin" />
        <Tab label="Admin" />
      </Tabs>
      <Box>
        {value === 0 && <Login tab={"superadmin"} />}
        {value === 1 && <Login tab={"admin"} />}
      </Box>
    </Box>
  );
};

export default LoginDashboard;
