import { Stack, Typography } from "@mui/material";
import React from "react";
import ClauseLayout1 from "./ClauseLayout1";
import { getKey } from "../../../utils/getKey";

function ClauseNestedLayout1({ body = {}, styles = {} }) {
  const { heading, objective, conclusion, points = [], image, ...rest } = body;
  return (
    <Stack direction={"column"} gap={1} sx={{ ...styles }}>
      {heading && <Typography fontWeight={700}>{heading}</Typography>}
      {objective && <Typography paragraph>{objective}</Typography>}
      {points.map((point, i) => {
        if (typeof point === "string") {
          return (
            <Typography key={getKey(i)} marginLeft={4}>
              {i + 1} {"  "}
              {point}
            </Typography>
          );
        } else if (Array.isArray(point)) {
          return (
            <ClauseNestedLayout1
              key={getKey(i)}
              body={{ points: point }}
              styles={{ marginLeft: 4 }}
            />
          );
        } else {
          return null;
        }
      })}
      {Object.keys(rest).map((key, i) => {
        console.log("key ---->", key, typeof rest[key]);
        if (typeof rest[key] === "object" && !Array.isArray(rest[key])) {
          return <ClauseNestedLayout1 key={getKey(i)} body={rest[key]} />;
        } else if (Array.isArray(rest[key])) {
          return (
            <ClauseLayout1 key={getKey(i)} body={rest[key]} heading={key} />
          );
        } else if (typeof rest[key] === "string") {
          return (
            <React.Fragment key={1}>
              <Typography fontWeight={700}>{key}</Typography>
              <Typography paragraph>{rest[key]}</Typography>
            </React.Fragment>
          );
        } else {
          return null;
        }
      })}
      {image && <img src={image} width={"60%"} />}
      {conclusion && <Typography paragraph>{conclusion}</Typography>}
    </Stack>
  );
}

export default ClauseNestedLayout1;
