import { configureStore } from "@reduxjs/toolkit";
import orgReducer from "./org/orgSlice";
import authReducer from "./auth/authSlice";
import dropdownReducer from "./dropdown/dropdownSlice";
import trainingReducer from "./training/trainingSlice";
import { orgApi } from "./org/orgApi";
import { authApi } from "./auth/authApi";
import { trainingAPI } from "./training/TrainingAPI";
import dropdownApi from "./dropdown/dropdownApi";
import { icApi } from "./ic/icApi";
import { annualReportApi } from "./annualReport/annualReportApi";
import { uploadRegistrationApi } from "./uploadRegistration/uploadRegistrationApi";
import { policyApi } from "./policy/policyApi";
import { oiwApi } from "./oiw/OiwApi";
import { reminderApi } from "./reminders/reminderApi";

export const store = configureStore({
  reducer: {
    org: orgReducer,
    auth: authReducer,
    dropdown: dropdownReducer,
    training: trainingReducer,
    [orgApi.reducerPath]: orgApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [dropdownApi.reducerPath]: dropdownApi.reducer,
    [trainingAPI.reducerPath]: trainingAPI.reducer,
    [icApi.reducerPath]: icApi.reducer,
    [annualReportApi.reducerPath]: annualReportApi.reducer,
    [policyApi.reducerPath]: policyApi.reducer,
    [uploadRegistrationApi.reducerPath]: uploadRegistrationApi.reducer,
    [oiwApi.reducerPath]: oiwApi.reducer,
    [reminderApi.reducerPath]: reminderApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      orgApi.middleware,
      authApi.middleware,
      dropdownApi.middleware,
      trainingAPI.middleware,
      icApi.middleware,
      annualReportApi.middleware,
      uploadRegistrationApi.middleware,
      policyApi.middleware,
      oiwApi.middleware,
      reminderApi.middleware,
    ]),
});
