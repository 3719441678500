import React from "react";
import { Image, Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function ExternalComplainant({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {serialNo}. WHAT IF COMPLAINT IS AGAINST SOMEONE WHO IS NOT AN EMPLOYEE
        OF THE ORGANIZATION?
      </Text>
      <Text style={styles.text}>
        If any Employee/Third Party is sexually harassed by any external party
        who is not an Employee of the Organization, either in Organization
        premises or in that external party’s premises, then IC shall contact
        either the internal committee of that external party and / or the Police
        (as the case may be), on the written complaint of such Employee/Third
        Party to the Organization’s IC and on their request that such complaint
        be transferred to other IC and address the matter to satisfactory
        closure. Alternatively, Employee/Third Party may choose to reach out
        directly to IC of that external party and / or Police (as the case may
        be) and Organization will offer assistance on written request by
        Employee/Third Party.
      </Text>
      <Image
        src={
          "https://turnkey-equihawk.s3.ap-south-1.amazonaws.com/PolicyImages/Report.png"
        }
      />
    </View>
  );
}

export default ExternalComplainant;
