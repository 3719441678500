import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function Clause2({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{serialNo}. Clause 2</Text>
      <Text style={styles.text}>
        Doloremque sapiente enim, eveniet odit nostrum nam. Unde dolores ipsa
        culpa, similique neque quia beatae aliquam consequatur nisi facilis.
        Nisi cum officiis doloribus, aliquid voluptates velit enim adipisci
        provident corrupti ratione magni eligendi dignissimos repellendus amet
        nostrum ab tempora sed? Est inventore unde veritatis nesciunt!
      </Text>
    </View>
  );
}

export default Clause2;
