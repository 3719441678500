import { Grid, Typography } from "@mui/material";
import React from "react";
import { ColorModeContext } from "../../constants/theme";

function GridLayout({ text = "", children }) {
  const { mode } = React.useContext(ColorModeContext);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={9} display={"flex"} alignItems={"center"}>
        <Typography
          color={mode === "dark" ? "white" : "#4B4B4B"}
          fontSize={"14px"}
          fontWeight={400}
        >
          {text}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={3} display={"flex"} justifyContent={"flex-end"}>
        {children}
      </Grid>
    </Grid>
  );
}

export default React.memo(GridLayout);
