import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext } from "react";
import styled from "@emotion/styled";
import { SectionHeading } from "../Superadmin";
import { useGetAllOrgsQuery } from "../../store/org/orgApi";
import { useNavigate } from "react-router-dom";
import { ColorModeContext } from "../../constants/theme";
import useDebouncedSearch from "../../utils/hooks/useDebounceSearch";

function DynamicRowTable({ params = {} }) {
  const { mode } = useContext(ColorModeContext);
  const navigate = useNavigate();
  /* eslint-disable no-unused-vars */
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  /* eslint-enable no-unused-vars */

  const [page] = React.useState(0);
  const { searchText, debouncedResults } = useDebouncedSearch();
  const { data } = useGetAllOrgsQuery({
    page: 0,
    pageSize: 10,
    ...searchText,
    ...params,
  });

  return (
    <Box
      display={"flex"}
      boxSizing="border-box"
      sx={{
        background: `${mode === "dark" ? "#272727" : "white"}`,
        borderRadius: "1rem",
      }}
      // padding={2}
      rowGap={2}
      flexDirection={"column"}
    >
      <SectionHeading
        heading="List of Organizations"
        linkText="View full list"
        url="/superadmin/aggregate-org"
      />
      <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
        <TextField
          sx={{
            "& .MuiInputBase-root": {
              height: "32px",
              padding: 0,
              width: "300px",
            },
            padding: 0,
            flex: 1,
          }}
          onChange={debouncedResults}
          placeholder="Search by company name"
        />
      </Stack>
      <TableContainer sx={{ height: "530px" }}>
        <Table stickyHeaderp>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  background: `${mode === "dark" ? "#151515" : "#EEF8FF"}`,
                }}
                align="left"
              >
                ID
              </TableCell>
              <TableCell
                sx={{
                  background: `${mode === "dark" ? "#151515" : "#EEF8FF"}`,
                }}
                align="left"
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  background: `${mode === "dark" ? "#151515" : "#EEF8FF"}`,
                }}
                align="right"
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.org?.slice(page * 10, page * 10 + 10).map((row) => {
              return (
                <StyledTableRow
                  key={row.id}
                  hover
                  sx={{
                    background: `${mode === "dark" ? "#0D0D0D" : " #FFFFFF"}`,
                  }}
                >
                  <TableCell align="left">{`${row.id?.slice(
                    0,
                    8,
                  )}...`}</TableCell>
                  <TableCell align="left">{`${row.name}`}</TableCell>
                  <TableCell align="right">
                    <Button
                      sx={{
                        background: `${
                          mode === "dark" ? "#1A1A1A" : " #4B4B4B"
                        }`,
                        fontSize: "12px",
                        height: "25px",
                        paddingX: 1,
                        paddingY: 1,
                      }}
                      variant="contained"
                      onClick={() => navigate(`/superadmin/company/${row.id}`)}
                    >
                      View details
                    </Button>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default DynamicRowTable;
