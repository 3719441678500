import React, { createContext, useMemo } from "react";
import { ThemeProvider, createTheme } from "@mui/material";

export const ColorModeContext = createContext({
  toggleMode: () => { },
  mode: localStorage.getItem("mode") || "light",
});

export const primary = {
  main: "#000000",
  secondary: " #4B4B4B",
  white: "#FBFEF9",
};

const fontFamilyInfo = `'Rubik', sans-serif`;

export const ColorContextProvider = ({ children }) => {
  const [mode, setMode] = React.useState(localStorage.getItem("mode") || "light");

  const colorMode = useMemo(
    () => ({
      toggleMode: () =>
        setMode((prevMode) => {
          const newMode = prevMode === "dark" ? "light" : "dark";
          localStorage.setItem("mode", newMode);
          return newMode;
        }),
      mode,
    }),
    [mode],
  );

  let theme = createTheme({
    typography: {
      fontFamily: fontFamilyInfo,
      color: primary.secondary,
      allVariants: {},
    },
    palette: {
      primary: primary,
      mode: mode,
    },
    components: {
      MuiButton: {
        defaultProps: {
        },
        styleOverrides: {
          root: {
            fontFamily: fontFamilyInfo,
            borderRadius: "3px",
            textTransform: "none",
            fontWeight: "400",
            height: "36px",
          },
          sizeMedium: {
            fontSize: "14px",
            padding: "10px 12px",
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          MenuProps: {
            style: {
              maxHeight: 400,
              fontFamily: fontFamilyInfo,
            },
          },
        },
        styleOverrides: {
          border: `1px solid rgba(33, 33, 33, 0.14)`,
          borderRadius: "3px",
        },
      },

      MuiList: {
        styleOverrides: {
          root: {
            fontFamily: fontFamilyInfo,
            border: `1px solid #DFDFDF`,
          },
        },
      },

      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&:hover": {
              borderRadius: "8px",
              background: "#EFEFEE",
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            fontFamily: fontFamilyInfo,
            color: "#7E7E7D",
            fontSize: "18px",
            fontWeight: 400,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 100px #000 inset !important',
            '-webkit-text-fill-color': '#fff !important'
          }
        }
      }
    },
  });

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};
