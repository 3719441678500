import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../constants";

export const dropdownApi = createApi({
  reducerPath: "dropdownApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    getPriorityLevels: build.query({
      query: () => ({
        url: "v1/prioritylevel/getallprioritylevels",
        method: "GET",
      }),
    }),
    getEmployementLevels: build.query({
      query: () => ({
        url: "v1/employementlevel/getallemploymentlevels",
        method: "GET",
      }),
    }),
    getWorkLocations: build.query({
      query: () => ({
        url: "v1/locationofwork/getalllocations",
        method: "GET",
      }),
    }),
    getServiceRules: build.query({
      query: () => ({
        url: "v1/servicerule/getallservicerules",
        method: "GET",
      }),
    }),
    getAllPocTypes: build.query({
      query: () => ({
        url: "v1/poctype/getallpocs",
        method: "GET",
      }),
    }),
    getDepartments: build.query({
      query: () => ({
        url: "v1/department/getalldepartment",
        method: "GET",
      }),
    }),
    getAllRegistrationTypes: build.query({
      query: () => ({
        url: "v1/registration/getallregistrations",
        method: "GET",
      }),
    }),
    getAverageAgeBrackets: build.query({
      query: () => ({
        url: "v1/agebracket/getallagebracket",
        method: "GET",
      }),
    }),
    getTypesOfLocations: build.query({
      query: () => ({
        url: "v1/locationtype/getalllocationtypes",
        method: "GET",
      }),
    }),
    getAllLanguages: build.query({
      query: () => ({
        url: "v1/language/getalllanguages",
        method: "GET",
      }),
    }),
    getAllZones: build.query({
      query: () => ({
        url: "v1/zone/getallzones",
        method: "GET",
      }),
    }),
    getTrainingTypes: build.query({
      query: () => ({
        url: "v1/trainingtype/get/all",
        method: "GET",
      }),
    }),
    getICDesignation: build.query({
      query: () => ({
        url: "v1/ic-designation/get-all",
        method: "GET",
      }),
    }),
    getRegionLocationByName: build.query({
      query: (params) => ({
        url: "v1/region_registration/get/region-location",
        method: "GET",
        params,
      }),
    }),
    getRegion: build.query({
      query: (params) => ({
        url: "v1/region/get-all-region",
        method: "GET",
        params,
      }),
    }),
    getLocByRegion: build.query({
      query: (params) => ({
        url: "v1/region_registration/get/loc-by-region",
        method: "GET",
        params,
      }),
    }),
    getEmployeeByRole: build.query({
      query: (params) => ({
        url: "v1/employee/get/employee-by-role",
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useGetPriorityLevelsQuery,
  useGetEmployementLevelsQuery,
  useGetWorkLocationsQuery,
  useGetServiceRulesQuery,
  useGetAllPocTypesQuery,
  useGetDepartmentsQuery,
  useGetAllRegistrationTypesQuery,
  useGetAverageAgeBracketsQuery,
  useGetTypesOfLocationsQuery,
  useGetAllLanguagesQuery,
  useGetTrainingTypesQuery,
  useGetAllZonesQuery,
  useGetICDesignationQuery,
  useGetRegionLocationByNameQuery,
  useGetRegionQuery,
  useGetLocByRegionQuery,
  useGetEmployeeByRoleQuery,
} = dropdownApi;
export default dropdownApi;
