import React from "react";
import { ColorModeContext } from "../../../constants/theme";
import AWS from "aws-sdk";
import UploadLogo from "../../../assets/logos/UploadLogo.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography, styled } from "@mui/material";
import RegistrationLayout from "../../../components/Layouts/RegistrationLayout";
import { SingleUploadLayout } from "../../../components";
import { useUploadOiwMutation } from "../../../store/oiw/OiwApi";
import { useGetOrgDetailsQuery } from "../../../store/org/orgApi";
import { StatusBar } from "../../../components/General";
import { Status } from "../../../utils/options";
import Swal from "sweetalert2";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
  region: "ap-south-1",
});

const UploadOrderInWritingStyle = styled(Box)(({ theme }) => ({
  ".heading": {
    fontWeight: 700,
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
    },
  },
}));

const UploadOrderInWriting = () => {
  const { mode } = React.useContext(ColorModeContext);
  const [oiw, setOiw] = React.useState({ file: null, name: "" });
  const [s3Url, setS3Url] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const [uploadOiw, { isLoading, isSuccess, data }] = useUploadOiwMutation();

  const { user } = useSelector((state) => state.auth?.user);
  const { data: org } = useGetOrgDetailsQuery(user?.org_id);
  const navigate = useNavigate();

  const handleChange = (e) => {
    console.log(e.target?.files);
    if (e.target?.files[0]?.type?.match(/(pdf)$/)) {
      setOiw({ file: e.target.files[0], name: e.target.files[0].name });
    } else {
      setAlert({ severity: "error", message: "Please select PDF" });
    }
  };

  React.useEffect(() => {
    console.log(org);
    console.log("result --->", data);
    if (isSuccess) {
      Swal.fire({
        title: "Uploaded",
        text: "Order in writing has been uploded",
        icon: "success",
        timer: 1500,
      });
      navigate("/home/upload");
    }
  }, [isSuccess, org]);

  const handleUpload = () => {
    if (oiw.file) {
      try {
        const s3 = new AWS.S3();
        const params = {
          Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
          Key: "Order-In-Writing-Files/" + oiw.name,
          Body: oiw.file,
          ContentType: "application/pdf",
        };

        s3.upload(params, (err, data) => {
          if (err) {
            console.log(err);
          } else {
            console.log("File uploaded Successfully!", data);
            setS3Url(data.Location);
          }
        });
      } catch (error) {
        console.log("Error uploading file:", error);
        setAlert({ severity: "error", message: "Error in uploading file" });
      }
    } else {
      setAlert({ severity: "error", message: "Please select file" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (s3Url) {
      uploadOiw({
        org_id: user?.org_id,
        file: s3Url,
      });
    } else {
      setAlert({ severity: "error", message: "Please select PDF" });
    }
  };

  return (
    <RegistrationLayout>
      <UploadOrderInWritingStyle>
        <Stack
          direction={"row"}
          columnGap={4}
          justifyContent={"flex-start"}
          alignItems={"center"}
          marginBottom={2}
          marginTop={10}
        >
          <img src={UploadLogo} alt="" />
          <Typography
            className="heading"
            sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
          >
            Upload Order in writing
          </Typography>
          <StatusBar
            label={
              org?.org?.oiw_status === Status.APPROVED
                ? "COMPLETED"
                : org?.org?.oiw_status
            }
            status={org?.org?.oiw_status}
          />
        </Stack>
        <form action="" onSubmit={handleSubmit}>
          <SingleUploadLayout
            handleChange={handleChange}
            handleUpload={handleUpload}
            label={"Upload Order In Writing"}
            done={s3Url}
            isLoading={isLoading}
            fileName={oiw.name || ""}
            alert={alert}
            handleAlertClose={() => setAlert(null)}
          />
        </form>
      </UploadOrderInWritingStyle>
    </RegistrationLayout>
  );
};

export default UploadOrderInWriting;
