export const procedureForFilingAppeal = [
  `Any party aggrieved (Complainant or Respondent) by the recommendations of the IC and /
  or non-implementation of recommendations may file an appeal to the Appellate Authority
  as per service rules and/or as per other provisions of Law within 90 (ninety) days of the
  recommendations.`,
  `In case the inquiry process is conducted by the IC and the Complainant is not a woman, any 
  party aggrieved (Complainant or Respondent) by the recommendations of the IC and / or 
  non-implementation of recommendations can get in touch with [ ], who will review the 
  appeal.`,
];
