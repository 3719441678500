import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function RestrictedVictim({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {serialNo}. WHAT IF AGGRIEVED PERSON IS UNABLE TO MAKE A COMPLAINT?
      </Text>
      <Text style={styles.text}>
        If the Aggrieved Person is unable to make a complaint on account of
        their physical incapacity, a complaint may be filed, with the written
        consent of the Aggrieved Person by
      </Text>
      <View style={styles.subContainer}>
        <Text style={styles.point}>a) their relative or friend; or</Text>
        <Text style={styles.point}>b) their co-worker; or</Text>
        <Text style={styles.point}>
          c) an officer of the National Commission for Women or State Women’s
          Commission; or
        </Text>
        <Text style={styles.point}>
          d) any person who has knowledge of the incident
        </Text>
      </View>

      <Text style={styles.text}>
        If the Aggrieved Person is unable to make a complaint on account of
        mental incapacity, a complaint may be filed by:
      </Text>
      <View style={styles.subContainer}>
        <Text style={styles.point}>a) their relative or friend; or</Text>
        <Text style={styles.point}>b) a special educator; or</Text>
        <Text style={styles.point}>
          c) a qualified psychiatrist or psychologist; or
        </Text>
        <Text style={styles.point}>
          d) the guardian or authority under whose care they are receiving
          treatment or care; or
        </Text>
        <Text style={styles.point}>
          e) any person who has knowledge of the incident jointly with the
          Aggrieved Person’s relative or friend or a special educator or
          qualified psychiatrist or psychologist, or guardian or authority under
          whose care they are receiving treatment or care.
        </Text>
      </View>

      <Text style={styles.text}>
        If the Aggrieved Person for any other reason is unable to make a
        complaint, a complaint may be filed by any person who has knowledge of
        the incident, with their written consent. If the Aggrieved Person is
        deceased, a complaint may be filed by any person who has knowledge of
        the incident, with the written consent of their legal heir.
      </Text>
      <Text style={styles.text}>
        Any of the persons referred to above, who may file a complaint on behalf
        of the Aggrieved Person shall be referred to as the “Complainant.” Any
        reference to the term ‘Complainant’ throughout this Policy shall mean
        reference to ‘Aggrieved Person’ and / or the ‘Complainant’ as
        applicable.{" "}
      </Text>
    </View>
  );
}

export default RestrictedVictim;
