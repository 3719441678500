import React from "react";
import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ChangePassword({ open = false }) {
  const navigate = useNavigate();

  const logoutHandler = () => {
    console.log("sfds");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("mode");
    navigate("/");
    window.location.reload();
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: { borderRadius: 24 },
      }}
    >
      <Box
        display="flex"
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        rowGap={4}
        padding={6}
      >
        <Typography maxWidth={300} textAlign={"center"}>
          Please Change your password for security reasons{" "}
        </Typography>
        <Stack direction={"row"} justifyContent={"space-between"} gap={2}>
          <Button
            variant="contained"
            onClick={() => navigate("/change-password")}
          >
            Change password
          </Button>
          <Button variant="text" onClick={logoutHandler}>
            Logout
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}

export default ChangePassword;
