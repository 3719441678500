import React from "react";
import { Button, Stack } from "@mui/material";
import { useWatch } from "react-hook-form";
import { FileUploadOutlined } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";

function UploadRegistrationInput({
  register,
  control,
  name,
  error,
  success = false,
  validations = {},
}) {
  const file = useWatch({ control, name: name });
  React.useEffect(() => {
    console.log(file);
  }, [file]);
  return (
    <Stack gap={1} alignItems={"flex-start"}>
      <Stack direction={"row"} justifyContent={"flex-end"} gap={2}>
        <Button
          type="button"
          sx={{
            backgroundColor: "white",
            height: "35px",
            width: "120px",
          }}
          component="label"
          variant="outlined"
        >
          Choose File
          <input
            {...register(name, { ...validations })}
            hidden
            type="file"
            accept={".pdf"}
          />
        </Button>
        <Button
          variant="contained"
          className="sm:tw-gap-2"
          type="submit"
          sx={{
            backgroundColor: "#4B4B4B",
            height: "35px",
            width: "120px",
          }}
        >
          {success ? <DoneIcon /> : <FileUploadOutlined />}
          Upload
        </Button>
      </Stack>
      {error && <span className="tw-text-red-700 tw-text-xs">{error}</span>}
      {!!file?.length && <span className=" tw-text-xs">{file[0]?.name}</span>}
    </Stack>
  );
}

export default UploadRegistrationInput;
