/* eslint-disable indent */
import React from "react";
import { Text, View, Page } from "@react-pdf/renderer";
import Background from "./Clauses/SHO/Background";
import Scope from "./Clauses/SHO/Scope";
import KeyDefinitions from "./Clauses/SGN/KeyDefinitions";
import ProcedureForRedressal from "./Clauses/SHO/ProcedureForRedressal";
import ProcedureForFilingAnAppeal from "./Clauses/SHO/ProcedureForFilingAppeal";
import Confedentiality from "./Clauses/SHO/Confedentiality";
import NonRetaliation from "./Clauses/SHO/NonRetaliation";
import FilingOfComplaintWithPolice from "./Clauses/SHO/FilingOfCompaintWithPolice";
import Amendments from "./Clauses/SHO/Amendments";
import Clause1 from "./Clauses/SHO/Clause1";
import Clause2 from "./Clauses/SHO/Clause2";

function ShortPolicyView({
  heading = "",
  source,
  bgText = [],
  styles,
  clauses,
  Indices,
}) {
  return (
    <Page size={"A4"} style={styles.body}>
      <Text style={styles.title}>{heading}</Text>
      <View style={styles.main}>
        {source === "ShortGenderNeutral" && clauses.background && (
          <Background serialNo={Indices.pop()} />
        )}

        {source === "ShortWomenOnly" &&
          (clauses.background.para1 || clauses.background.para2) && (
            <Background serialNo={Indices.pop()} text={bgText} />
          )}
        {clauses.scope && <Scope serialNo={Indices.pop()} />}
        {clauses.keyDefinitions && <KeyDefinitions serialNo={Indices.pop()} />}
        {clauses.procedureForRedressal && (
          <ProcedureForRedressal serialNo={Indices.pop()} />
        )}
        {clauses.procedureForFilingAnAppeal && (
          <ProcedureForFilingAnAppeal serialNo={Indices.pop()} />
        )}
        {clauses.confidentiality && (
          <Confedentiality serialNo={Indices.pop()} />
        )}
        {clauses.nonRetaliation && <NonRetaliation serialNo={Indices.pop()} />}
        {clauses.filingOfComplaintWithPolice && (
          <FilingOfComplaintWithPolice serialNo={Indices.pop()} />
        )}
        {clauses.amendments && <Amendments serialNo={Indices.pop()} />}
        {clauses.clause1 && <Clause1 serialNo={Indices.pop()} />}
        {clauses.clause2 && <Clause2 serialNo={Indices.pop()} />}
      </View>
    </Page>
  );
}

export default ShortPolicyView;
