const labels = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const complaintData = {
  labels,
  datasets: [
    {
      label: "Complaints",
      data: [10, 20, 15, 20, 10, 20, 15, 20, 15, 20, 10, 15],
      borderColor: "gray",
      fill: true,
      backgroundColor: "#EDF8FF",
    },
  ],
};
