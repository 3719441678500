import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { ColorModeContext } from "../../../constants/theme";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { EditButton, GridActionsLayout } from "../../../components";
import { GridColumns } from "../../../utils/gridColumns";

function ICMembersTable({ isFetching, rows = [], handleDelete = () => null }) {
  const { mode } = React.useContext(ColorModeContext);
  const navigate = useNavigate();

  const columns = [
    ...new GridColumns()
      .name()
      .gender()
      .email()
      .designationInOrg()
      .designationInIC()
      .actions({
        renderCell: (params) => {
          return (
            <GridActionsLayout>
              <EditButton
                onClick={() => navigate(`/superadmin/ic/edit/${params.row.id}`)}
              />
              <IconButton onClick={() => handleDelete(params)}>
                <CloseOutlinedIcon />
              </IconButton>
            </GridActionsLayout>
          );
        },
      })
      .getCols(),
  ];

  return (
    <DataGrid
      loading={isFetching}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      pageSizeOptions={[5]}
      sx={{
        "& .MuiDataGrid-columnHeaders": {
          background: `${mode === "dark" ? " #181818" : "#EEF8FF"}`,
        },
        "& .MuiDataGrid-toolbarContainer": {
          paddingY: 2,
          paddingX: 1,
        },

        "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
          backgroundColor: "transparent",
          color: "#4B4B4B",
        },
      }}
    />
  );
}

export default ICMembersTable;
