import React from "react";
import { Button } from "@mui/material";
import { ColorModeContext } from "../../constants/theme";

function SaveAndCancelBtn({
  handleCancle = () => null,
  cancelBtnProps = {},
  saveBtnProps = {},
  cancelPlaceholder = "Cancel Changes",
  savePlaceholder = "Save Changes",
}) {
  const { mode } = React.useContext(ColorModeContext);

  return (
    <>
      <Button
        variant="outlined"
        className=" sm:tw-min-w-[30%]  sm:tw-h-10"
        sx={{
          border: `${mode === "dark" ? "1px solid white" : "1px solid black"}`,
          color: `${mode === "dark" ? "white" : "black"}`,
        }}
        onClick={handleCancle}
        {...cancelBtnProps}
      >
        {cancelPlaceholder}
      </Button>
      <Button
        variant="contained"
        className="sm:tw-min-w-[30%]  sm:tw-h-10"
        sx={{
          border: "1px solid white",
          background: `${mode === "dark" ? "white" : "black"}`,
          color: `${mode === "light" ? "white" : "black"}`,
        }}
        type="submit"
        {...saveBtnProps}
      >
        {savePlaceholder}
      </Button>
    </>
  );
}

export default SaveAndCancelBtn;
