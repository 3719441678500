import React, { useContext } from "react";
import { Box } from "@mui/material";
import { NavigationBar } from "../../../components/General";
import { ColorModeContext } from "../../../constants/theme";
import BreadcrumbFont from "../../../components/Typography/BreadcrumbFont";
import { SingleOrgARTable } from "../../../components/tables";

const breadcrumbs = [
  <BreadcrumbFont key={1} color="#4B4B4B" text="AGGREGATE ANNUAL REPORT" />,
  <BreadcrumbFont key={2} color="#005D9E" text="ORGANIZATION LOCATION" last />,
];

function SingleOrgAR() {
  const url = window.location.href;
  const ID = url.substring(url.lastIndexOf("/") + 1);
  const { mode } = useContext(ColorModeContext);

  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      paddingX={"60px"}
      paddingY={"35px"}
      rowGap={"46px"}
      sx={{ background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}` }}
    >
      <NavigationBar breadcrumbs={breadcrumbs} />
      <SingleOrgARTable ID={ID} />
    </Box>
  );
}

export default SingleOrgAR;
