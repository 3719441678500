import { Box, Typography } from "@mui/material";
import React from "react";
import { DownloadButton } from "../Buttons";

const RegistrationCard = ({
  checkboxHandler = () => null,
  order = null,
  translatedOrder = null,
  orderUrl = null,
  translatedOrderUrl = null,
  confirmation,
}) => {
  return (
    <Box className="tw-p-14 tw-bg-[#DBFFFF] ">
      <Box className="tw-flex tw-flex-row">
        <Box className="tw-m-6 tw-flex tw-flex-col">
          <Typography className="tw-text-sm">{order}</Typography>
          {translatedOrder && (
            <Typography className="tw-text-sm tw-mt-10">
              {" "}
              {translatedOrder}{" "}
            </Typography>
          )}
        </Box>
        <Box className="tw-flex tw-flex-col">
          {orderUrl && (
            <DownloadButton
              label="Download"
              styles={{ width: "112px", marginTop: "0.5rem" }}
              onClick={() => {
                window.open(`${orderUrl}`, "_blank");
              }}
            />
          )}
          {translatedOrderUrl && (
            <DownloadButton
              label="Download"
              styles={{ width: "112px", marginTop: "2rem" }}
              onClick={() => {
                window.open(`${translatedOrderUrl}`, "_blank");
              }}
            />
          )}
        </Box>
      </Box>
      <Box>
        <Box className="tw-my-6 tw-ml-6 tw-flex">
          <input type="checkbox" id="agree" onChange={checkboxHandler} />
          <label htmlFor="agree">
            {" "}
            <Typography className="tw-mt-2 tw-ml-4  tw-text-sm">
              {confirmation}
            </Typography>
          </label>
        </Box>
      </Box>
    </Box>
  );
};

export default RegistrationCard;
