import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import LineGraph from "../../components/Graphs/LineGraph";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import { ContainerLayout, ReminderLayout } from "../../components/Layouts";
import { ColorModeContext } from "../../constants/theme";
import { useGetRemindersByOrgQuery } from "../../store/reminders/reminderApi";
import { useSelector } from "react-redux";

function Dashboard() {
  const { mode } = useContext(ColorModeContext);
  const { user } = useSelector((state) => state.auth?.user);
  const { data: reminders, isFetching: remindersFetching } =
    useGetRemindersByOrgQuery({
      org_id: user?.org_id,
      limit: 6,
    });

  const labels = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const data = {
    labels,
    datasets: [
      {
        label: "Complaints",
        data: [10, 20, 15, 30, 10, 20, 15],
        borderColor: "gray",
        fill: true,
        backgroundColor: "#EDF8FF",
      },
    ],
  };

  return (
    <ContainerLayout>
      <Typography
        className="tw-text-3xl tw-font-bold"
        sx={{ color: `${mode === "dark" ? "#FFFFFF" : " #000000"}` }}
      >
        <AutoAwesomeMosaicIcon sx={{ color: "#0060A4", marginRight: "40px" }} />
        Dashboard
      </Typography>
      <ReminderLayout
        remindersFetching={remindersFetching}
        reminders={reminders?.reminders}
        count={reminders?.count}
      />

      <Box className="tw-w-full tw-flex tw-flex-col">
        <Typography className="tw-text-[#484A48] tw-text-xl tw-font-semibold tw-mb-7">
          Complaints Statistics
        </Typography>
        <Box width={"100%"} height={"260px"}>
          <LineGraph
            data={data}
            style={{
              backgroundColor: `${mode === "dark" ? "#4B4B4B" : "#FFFFFF"}`,
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
            }}
          />
        </Box>
      </Box>
    </ContainerLayout>
  );
}

export default Dashboard;
