import { Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ColorModeContext } from "../../constants/theme";

function SectionHeading({ heading, linkText, url = "" }) {
  const { mode } = useContext(ColorModeContext);
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography
        fontSize={"20px"}
        fontWeight="600"
        sx={{ color: `${mode === "dark" ? "#fff" : " #000"}` }}
      >
        {heading}
      </Typography>
      <Link to={url} className="tw-text-[#6AC2FF] tw-text-sm tw-font-normal">
        {linkText}
      </Link>
    </Stack>
  );
}

export default SectionHeading;
