import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function AvailabilityOfMentalHealthProfessionals({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {serialNo}. AVAILABILITY OF MENTAL HEALTH PROFESSIONALS
      </Text>
      <Text style={styles.text}>
        The Organization shall provide assistance, such as availing services of
        a mental health professional, to any of the concerned parties on request
        as may be required from time to time.
      </Text>
    </View>
  );
}

export default AvailabilityOfMentalHealthProfessionals;
