import React from "react";
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function DownArrowIconButton({
  handleClick = () => null,
  buttonProps = {},
  styles = {},
  iconStyles = {},
}) {
  return (
    <IconButton
      onClick={handleClick}
      sx={{ fontSize: "24px", fontWeight: 500, ...styles }}
      {...buttonProps}
    >
      <KeyboardArrowDownIcon
        color="#4B4B4B"
        fontSize="inherit"
        sx={{ fontSize: "24px", fontWeight: 500, ...iconStyles }}
      />
    </IconButton>
  );
}

export default DownArrowIconButton;
