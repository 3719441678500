import React from "react";
import { IconButton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

function CloseButton({
  handleClick = () => null,
  buttonProps = {},
  styles = {},
}) {
  return (
    <IconButton sx={{ ...styles }} {...buttonProps} onClick={handleClick}>
      <CloseOutlinedIcon />
    </IconButton>
  );
}

export default CloseButton;
