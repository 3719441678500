import React from "react";
import { Button, CircularProgress } from "@mui/material";

function DownloadButton({
  label = "Download",
  onClick = () => null,
  loading = false,
  disabledValue = false,
  buttonProps = {},
  styles = {},
}) {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      type="submit"
      disabled={loading || disabledValue}
      sx={{
        backgroundColor: "#4B4B4B",
        width: "300px",
        height: "40px",
        borderRadius: "4px",
        color: "#FBFEF9",
        fontSize: "16px",
        fontWeight: "600",
        ...styles,
      }}
      {...buttonProps}
    >
      {loading ? <CircularProgress size={20} sx={{ color: "white" }} /> : label}
    </Button>
  );
}

export default DownloadButton;
