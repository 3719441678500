import React from "react";
import { FileUploadOutlined } from "@mui/icons-material";
import { Box, Button, InputLabel, Stack, Typography } from "@mui/material";

function UploadButton({
  onUpload = () => null,
  label = "Upload Your Annual Report",
  accept,
  onChange,
  previewName,
  fileTypeError,
}) {
  return (
    <Box>
      <Stack
        direction={"row"}
        gap={2}
        alignItems={"center"}
        display={"flex"}
        justifyContent={"space-between"}
        width={"80%"}
      >
        <InputLabel>{label}</InputLabel>
        <Box>
          <Button
            sx={{
              backgroundColor: "white",
              height: "40px",
              marginRight: "1rem",
            }}
            component="label"
            variant="outlined"
          >
            Choose File
            <input
              hidden
              type="file"
              accept={accept}
              onChange={onChange}
              required
            />
          </Button>
          <Button
            component="label"
            variant="contained"
            className="sm:tw-gap-2"
            sx={{
              backgroundColor: "#4B4B4B",
              height: "40px",
            }}
            onClick={onUpload}
            type="submit"
          >
            <FileUploadOutlined />
            Upload
          </Button>
        </Box>
      </Stack>
      <Box display={"flex"} justifyContent={"center"}>
        {fileTypeError && (
          <Typography className="tw-text-red-400  tw-text-center tw-mt-4 sm:tw-gap-2 tw-text-xs tw-font-thin">
            Please select a valid file (PDF, JPEG, JPG).
          </Typography>
        )}
        {previewName && (
          <Typography className="tw-text-[#4B4B4B]  tw-text-center tw-mt-2 sm:tw-gap-2 tw-text-xs tw-font-thin">
            {previewName}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default UploadButton;
