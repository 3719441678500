import React from "react";
import { Box, Stack } from "@mui/material";
import { ColorModeContext } from "../../../constants/theme";
import { NavigationBar } from "../../../components/General";
import BreadcrumbFont from "../../../components/Typography/BreadcrumbFont";
import EmployeeTraining from "../../privatescreens/trainings/EmployeeTraining";
import IcTrainings from "../../privatescreens/trainings/IcTrainings";
import OtherTraining from "../../privatescreens/trainings/OtherTraining";
import OtherInitiatives from "../../privatescreens/trainings/OtherInitiatives";
import { SelectYear } from "../../../components/OtherFormFields";

const breadcrumbs = [
  <BreadcrumbFont key={1} color="#4B4B4B" text="AGGREGATE TRAININGS" />,
  <BreadcrumbFont key={2} color="#005D9E" text="ORGANIZATION LOCATIONs" last />,
];

function SingleLocTraining() {
  const { mode } = React.useContext(ColorModeContext);
  const [year, setYear] = React.useState(new Date().getFullYear());

  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      paddingX={"60px"}
      paddingY={"35px"}
      rowGap={"46px"}
      sx={{ background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}` }}
    >
      <NavigationBar breadcrumbs={breadcrumbs} />
      <Stack
        direction={"row"}
        gap={2}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <SelectYear
          value={year}
          handleChange={(e) => setYear(e.target.value)}
        />
      </Stack>
      <EmployeeTraining superadmin params={{ year: year }} />
      <IcTrainings superadmin params={{ year: year }} />
      <OtherTraining superadmin params={{ year: year }} />
      <OtherInitiatives superadmin params={{ year: year }} />
    </Box>
  );
}

export default SingleLocTraining;
