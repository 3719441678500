import { Typography } from "@mui/material";
import React, { useContext } from "react";
import { ColorModeContext } from "../../constants/theme";

function FromHeading({ text }) {
  const { mode } = useContext(ColorModeContext);
  return (
    <Typography
      className="tw-font-semibold heading1"
      sx={{ color: `${mode === "dark" ? "#FFFFFF" : "#4B4B4B"}` }}
    >
      {text}
    </Typography>
  );
}

export default FromHeading;
