import { Font, StyleSheet } from "@react-pdf/renderer";
import Roboto from "../../../../assets/font/Roboto-Bold.ttf";
import RobotoRegular from "../../../../assets/font/Roboto-Regular.ttf";

Font.register({
  family: "MyFont",
  fonts: [{ src: Roboto, fontWeight: "bold" }],
});
Font.register({
  family: "RegularFont",
  fonts: [{ src: RobotoRegular, fontWeight: "normal" }],
});

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    textAlign: "center",
    fontFamily: "MyFont",
    fontSize: "17px",
    lineHeight: 1.5,
  },
  container: {
    marginTop: 10,
  },
  textContainer: {
    marginTop: 25,
  },
  text: {
    fontSize: "14px",
  },
  relovePointText: {
    fontFamily: "RegularFont",
    fontSize: "13px",
    marginLeft: 5,
    lineHeight: 1,
  },
  signText: {
    fontFamily: "RegularFont",
    fontSize: "14px",
    marginTop: 5,
  },
  date: {
    textAlign: "right",
    fontFamily: "MyFont",
    fontSize: "14px",
    lineHeight: 1.5,
  },
});

export const tableStyles = StyleSheet.create({
  title: {
    textAlign: "center",
    fontFamily: "MyFont",
    fontSize: "14px",
    lineHeight: 1.5,
    textDecoration: "underline",
  },
  table: {
    // margin: 30,
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "1px solid black",
    fontSize: 10,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: 12,
  },
  hhhhhhhhhhhhhhhhhhhhhfdhfghgfhfghfdhfghfghfghfghfghfghfghfghfdhfghfgggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggghfghfghgfhgfhfghfghfghfghgfhfggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggghgfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffhgfhfghgfhfghffffffffffffggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggfhfghgfhgfhfghgfhfghfghfhfhfhfffffffffffhfhfghhhhhhhhhhhhhhhhhhhfghgfhgffffffffffffffffffffffffffffffffffffffffffffffffhfghgfhgfhhhhhhhhhhhhhhhhgggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggfdhfgggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggghgfhfghfghfggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggfghfghfgh:
    {
      borderLeft: "1px solid black",
      borderBottom: "1px solid black",
      fontFamily: "MyFont",
      padding: 5,
      flex: 1,
      textAlign: "center",
      fontSize: 12,
      fontWeight: "bold",
      backgroundColor: "#D9D9D9",
    },
  cell: {
    borderLeft: "1px solid black",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    fontFamily: "RegularFont",
    padding: 5,
    flex: 1,
    textAlign: "center",
    fontSize: 12,
  },
  locationTextStyle: {
    textAlign: "center",
    fontFamily: "MyFont",
    fontSize: 12,
    lineHeight: 1.5,
  },
});
