import { Button } from "@mui/material";
import React from "react";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

const MiniDownloadButton = ({
  label = "",
  disabledValue = false,
  onClick = () => null,
  ...rest
}) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      disabled={disabledValue}
      sx={{
        backgroundColor: "#4B4B4B",
        width: "112px",
        height: "40px",
        borderRadius: "4px",
        color: "#FBFEF9",
        fontSize: "14px",
        fontWeight: "400",
        ...rest,
      }}
    >
      <ArrowDownwardRoundedIcon /> {label}
    </Button>
  );
};

export default MiniDownloadButton;
