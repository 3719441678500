import {
  Box,
  Container,
  Grid,
  InputLabel,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useCreateContactsMutation,
  useGetLocationByOrgQuery,
} from "../../../store/org/orgApi";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../../components/Form/InputField";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { Genders, Roles } from "../../../utils/dropdowns";
import { BackdropLoader, NavigationBar } from "../../../components/General";
import Swal from "sweetalert2";
import { ColorModeContext } from "../../../constants/theme";
import { useSelector } from "react-redux";
import SelectField2 from "../../../components/Form/SelectField2";
import {
  useGetAllPocTypesQuery,
  useGetDepartmentsQuery,
  useGetEmployementLevelsQuery,
  useGetServiceRulesQuery,
} from "../../../store/dropdown/dropdownApi";
import SaveAndCancelBtn from "../../../components/Buttons/SaveAndCancelBtn";

const breadcrumbs = [
  <Typography className="tw-text-[11px]" key="100">
    EMPLOYEE MANAGER
  </Typography>,
  <Typography className="tw-text-dark-blue tw-text-[11px]" key="200">
    ADD NEW EMPLOYEE
  </Typography>,
];

const AddEmployee = () => {
  const OrganizationDetails = useSelector((state) => state?.auth);
  const { user } = OrganizationDetails;
  const orgId = user?.user?.org_id;
  const { data: locationData } = useGetLocationByOrgQuery({
    id: orgId,
  });
  const { mode } = useContext(ColorModeContext);
  const TabScreen = useMediaQuery("(min-width: 640px)");
  const navigate = useNavigate();
  useGetAllPocTypesQuery();
  useGetDepartmentsQuery();
  useGetServiceRulesQuery();
  useGetEmployementLevelsQuery();

  const [locations, setLocations] = React.useState([]);

  const [createContacts, { isLoading, isSuccess, isError, data, error }] =
    useCreateContactsMutation();
  const { dropdowns } = useSelector((state) => state.dropdown);

  React.useEffect(() => {
    console.log(orgId);
    let temp = [];
    if (locationData?.location) {
      temp = locationData.location.map((loc) => {
        return { name: loc.location_name, id: loc.id };
      });
    }
    setLocations(temp || []);
  }, [locationData]);

  useEffect(() => {
    if (isLoading) {
      console.log("loading", isLoading);
    }
    if (isSuccess) {
      console.log("success ----> ", data);
      Swal.fire({
        icon: "success",
        title: "Employee added Successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/home/employee-manager-list");
    }
    if (isError) {
      console.log(isError);
      console.log("Error -----> ", error);
    }
  });

  const validations = {
    required: "This field is required",
  };

  const onSubmit = (data) => {
    for (let key in data) {
      if (data[key] === " " || data[key] === "") {
        data[key] = null;
      }
    }
    createContacts({
      id: orgId,
      body: { employees: [data] },
    });
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
    defaultValues: "initialValues",
  });

  return (
    <Box
      className="tw-w-full tw-p-4 sm:tw-p-7 lg:tw-p-12 tw-rounded-lg tw-border tw-border-[#EDEDED]"
      sx={{
        background: `${mode === "dark" ? "#0D0D0D" : " #fff"}`,
        height: "100%",
        width: "100%",
      }}
    >
      <Container
        className="tw-p-10"
        sx={{
          height: "100%",
          width: "100%",
          margin: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <NavigationBar breadcrumbs={breadcrumbs} />
        {isLoading && <BackdropLoader open={isLoading} />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack my={5} gap={5}>
            <Typography
              className="tw-text-heading-black tw-font-medium heading1"
              sx={{ color: `${mode === "dark" ? "#FFFFFF" : "#4B4B4B"}` }}
            >
              Add New Employee
            </Typography>
            <Box className=" tw-rounded-3 tw-mb-4 sm:tw-mb-8">
              <Grid container spacing={TabScreen ? 4 : 2}>
                <Grid item xs={12} sm={6} lg={6}>
                  <InputField
                    label={"First Name"}
                    placeholder="Enter first name"
                    name="firstName"
                    register={register}
                    validations={validations}
                    error={errors?.firstName?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <InputField
                    label={"Last Name"}
                    placeholder="Enter last name"
                    name="lastName"
                    register={register}
                    validations={validations}
                    error={errors?.lastName?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <InputField
                    label={"Employee Code"}
                    placeholder="Enter employee code"
                    name="code"
                    register={register}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <InputField
                    label={"Email"}
                    placeholder="Enter email"
                    name="email"
                    register={register}
                    validations={validations}
                    error={errors?.email?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <InputField
                    label={"Alternate Email"}
                    placeholder="Enter email"
                    name="alternateEmail"
                    register={register}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <InputField
                    label={"Password"}
                    placeholder="Enter password"
                    name="password"
                    register={register}
                    validations={validations}
                    error={errors?.password?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <Box className="tw-gap-2 tw-flex tw-flex-col">
                    <InputLabel className="tw-text-xs sm:tw-text-sm tw-w-min tw-mb-1">
                      <Typography>Date of Birth</Typography>
                    </InputLabel>
                    <Controller
                      control={control}
                      name="date_of_birth"
                      rules={{
                        required: "This field is required",
                        validate: (value) => {
                          const check = new Date(value) > new Date();
                          return !check || "Please select a valid date";
                        },
                      }}
                      render={({ field: { value, onChange, ref } }) => (
                        <DatePicker
                          onChange={onChange}
                          format="DD/MM/YYYY"
                          inputRef={ref}
                          value={value ? moment(value) : null}
                        />
                      )}
                    />
                    {errors?.date_of_birth?.message && (
                      <span className="tw-text-red-700 tw-text-xs">
                        {errors?.date_of_birth?.message}
                      </span>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <SelectField2
                    label={"Gender"}
                    placeholder={"Select"}
                    name={"gender"}
                    control={control}
                    is_id="name"
                    menus={Genders}
                    validations={{
                      validate: (value) => {
                        return value !== " " || "This field is required";
                      },
                    }}
                    defaultValue={" "}
                    error={errors?.gender?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <InputField
                    label={"Phone Number"}
                    placeholder="Enter phone number"
                    name="phone_number"
                    register={register}
                    validations={validations}
                    error={errors?.phone_number?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <InputField
                    label={"Designation in company"}
                    placeholder="Enter Designation"
                    name="designation"
                    register={register}
                    validations={validations}
                    error={errors?.designation?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <SelectField2
                    control={control}
                    label={"Employement Level"}
                    placeholder={"Select"}
                    defaultValue=" "
                    name={"employment_level_id"}
                    register={register}
                    menus={
                      dropdowns?.EmploymentLevels?.length > 0
                        ? dropdowns?.EmploymentLevels
                        : []
                    }
                    validations={{
                      validate: (value) => {
                        return value !== " " || "This field is required";
                      },
                    }}
                    error={errors?.employment_level_id?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <SelectField2
                    control={control}
                    label={"Department"}
                    placeholder={"Select"}
                    defaultValue=" "
                    name={"department_id"}
                    register={register}
                    menus={
                      dropdowns?.Departments?.length > 0
                        ? dropdowns?.Departments
                        : []
                    }
                    validations={{
                      validate: (value) => {
                        return value !== " " || "This field is required";
                      },
                    }}
                    error={errors?.department_id?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <SelectField2
                    control={control}
                    label={"Service Rules"}
                    placeholder={"Select"}
                    defaultValue=" "
                    name={"services_rules_id"}
                    register={register}
                    menus={
                      dropdowns?.ServiceRules?.length > 0
                        ? dropdowns?.ServiceRules
                        : []
                    }
                    validations={{
                      validate: (value) => {
                        return value !== " " || "This field is required";
                      },
                    }}
                    error={errors?.services_rules_id?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <SelectField2
                    label={"Role"}
                    placeholder={"Select a role"}
                    name={"role"}
                    control={control}
                    is_id="name"
                    menus={Roles}
                    defaultValue={" "}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <SelectField2
                    control={control}
                    label={"Location"}
                    placeholder={"Select"}
                    defaultValue=" "
                    name={"location_id"}
                    register={register}
                    menus={locations}
                    validations={{
                      validate: (value) => {
                        return value !== " " || "This field is required";
                      },
                    }}
                    error={errors?.location_id?.message}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-justify-end tw-space-y-4 sm:tw-space-x-8 sm:tw-space-y-0 tw-mt-4 md:tw-mt-4 lg:tw-mt-6 lg: tw-mr-10">
              <SaveAndCancelBtn
                handleCancle={() => {
                  navigate("/home/employee-manager-list");
                }}
              />
            </Box>
          </Stack>
        </form>
      </Container>
    </Box>
  );
};

export default AddEmployee;
