import React, { useEffect } from "react";
import { Appbar, BackdropLoader } from "../../components/General";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useChangePasswordMutation } from "../../store/auth/authApi";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/Form/InputField";
import { useSelector } from "react-redux";

function ChangePassword() {
  const navigate = useNavigate();
  const [changePassword, { isLoading, isError, isSuccess, error, data }] =
    useChangePasswordMutation();
  const { user } = useSelector((state) => state.auth?.user);

  const logoutHandler = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("mode");
    navigate("/");
    window.location.reload();
  };

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
  });
  const onSubmit = (data) => {
    const body = {
      email: user?.email,
      password: data.oldPassword,
      newPassword: data.password,
    };
    console.log(body);
    changePassword(body);
  };

  useEffect(() => {
    if (isSuccess) {
      console.log(data);
      logoutHandler();
    }
    if (isError) {
      console.log("Error ------->", error);
    }
    if (isLoading) {
      console.log(isLoading);
    }
  }, [isSuccess, isLoading, isError]);

  const required = {
    required: "Required",
  };
  const ConfirmPassword = {
    validate: (value) => {
      return value === getValues("password") || "Passwords must match";
    },
  };

  return (
    <div className="tw-min-h-screen tw-flex tw-flex-col">
      <Appbar setOpen={() => null} />
      <BackdropLoader open={isLoading} />
      <Box
        flex={1}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        bgcolor={"#FBFBFA"}
        className="tw-p-4 sm:tw-p-0"
      >
        <Stack
          bgcolor={"white"}
          spacing={6}
          paddingX={14}
          paddingY={10}
          borderRadius={"24px"}
          className="tw-w-full sm:tw-w-[80%] md:tw-w-[60%] lg:tw-w-[45%]"
          sx={{ boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.13)" }}
        >
          <Typography
            color="black"
            fontSize={32}
            fontWeight={600}
            textAlign={"center"}
            className="inter-font"
          >
            Change Password
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            {console.log("Akash")}
            <Stack spacing={2}>
              <InputField
                name={"oldPassword"}
                control={control}
                label={"Old Password"}
                register={register}
                sm={"35px"}
                md={"35px"}
                lg={"35px"}
                placeholder={"Enter Old Password"}
                validations={{ ...required }}
                error={errors?.oldPassword?.message}
              />

              <InputField
                name={"password"}
                control={control}
                label={"New Password"}
                register={register}
                sm={"35px"}
                md={"35px"}
                lg={"35px"}
                placeholder={"Enter New Password"}
                validations={{ ...required }}
                error={errors?.password?.message}
              />
              <InputField
                name={"cPassword"}
                control={control}
                label={"Confirm Password"}
                register={register}
                sm={"35px"}
                md={"35px"}
                lg={"35px"}
                placeholder={"Enter Confirm Password"}
                validations={{ ...required, ...ConfirmPassword }}
                error={errors?.cPassword?.message}
              />
            </Stack>
            <Stack direction={"row"} justifyContent={"center"} marginTop={4}>
              <Button type="submit" variant="contained" sx={{ height: "40px" }}>
                Update Password
              </Button>
            </Stack>
          </form>
        </Stack>
      </Box>
    </div>
  );
}

export default ChangePassword;
