import React from "react";
import debounce from "lodash.debounce";

const useDebouncedSearch = () => {
  const [searchText, setSearchText] = React.useState({});

  const handleChange = React.useCallback((e) => {
    setSearchText({ like: e.target.value });
  }, []);

  const debouncedResults = React.useMemo(() => {
    return debounce(handleChange, 600);
  }, [handleChange]);

  React.useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, [debouncedResults]);

  return { searchText, debouncedResults };
};

export default useDebouncedSearch;
