import React, { useContext, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  InputLabel,
  Box,
  Checkbox,
} from "@mui/material";
import { FormLayout } from "../../components/Layouts";
import TextAreaField from "../../components/Form/TextAreaField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NewInputField, SelectField2 } from "../../components/Form";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  AvgAgeBrackets,
  EmployeesSize,
  PriorityLevels,
} from "../../utils/dropdowns";
import { useSelector } from "react-redux";
import {
  useGetAllLanguagesQuery,
  useGetAllPocTypesQuery,
  useGetAllRegistrationTypesQuery,
  useGetAllZonesQuery,
  useGetAverageAgeBracketsQuery,
  useGetDepartmentsQuery,
  useGetEmployementLevelsQuery,
  useGetPriorityLevelsQuery,
  useGetRegionQuery,
  useGetServiceRulesQuery,
  useGetTypesOfLocationsQuery,
  useGetWorkLocationsQuery,
} from "../../store/dropdown/dropdownApi";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useCreateOrgMutation } from "../../store/org/orgApi";
import SnackBar from "../../components/General/Snackbar";
import StateCityFields from "./StateCityFields";
import { ColorModeContext } from "../../constants/theme";
import { useNavigate } from "react-router-dom";
import AddLocationSection from "../../components/AddLocationSection";
import { SaveAndCancelBtn } from "../../components";

function DatePicker2({ control, label, name, errors }) {
  return (
    <Box className="tw-gap-2 tw-flex tw-flex-col">
      <InputLabel
        className="tw-text-xs sm:tw-text-sm  tw-w-min tw-py-1 tw-rounded-md"
        htmlFor="orgName"
      >
        <Typography>{label}</Typography>
      </InputLabel>
      <Controller
        control={control}
        name={name}
        rules={{
          required: "This field is required",
          validate: (value) => {
            const check = new Date(value) > new Date();
            return !check || "Please select a valid date";
          },
        }}
        render={({ field: { value, onChange, ref } }) => (
          <DatePicker
            onChange={onChange}
            format="DD/MM/YYYY"
            inputRef={ref}
            value={value ? moment(value) : null}
          />
        )}
      />

      {errors?.establishment_date?.message && (
        <span className="tw-text-red-700">
          {errors?.establishment_date?.message}
        </span>
      )}
    </Box>
  );
}

function OrganizationDetails() {
  const { mode } = useContext(ColorModeContext);
  const TabScreen = useMediaQuery("(min-width: 640px)");
  const navigate = useNavigate();

  useGetTypesOfLocationsQuery();
  useGetAllLanguagesQuery();
  useGetAllZonesQuery();
  useGetAllRegistrationTypesQuery();
  useGetAverageAgeBracketsQuery();
  useGetEmployementLevelsQuery();
  useGetWorkLocationsQuery();
  useGetServiceRulesQuery();
  useGetAllPocTypesQuery();
  useGetPriorityLevelsQuery();
  useGetDepartmentsQuery();
  useGetRegionQuery();

  const [createOrg, { isSuccess }] = useCreateOrgMutation();

  const { dropdowns } = useSelector((state) => state.dropdown);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [selectedLocation, setSelectedLocation] = React.useState("select");

  const initialLocationObj = {
    location_name: "",
    address_line_1: "",
    address_line_2: "",
    location_type_id: " ",
    country: "",
    postalcode: "",
    zone_id: " ",
    is_zone_assigned: false,
    no_of_employee: "",
    avg_age_bracket_id: " ",
    gender_women_percentage: "",
    gender_men_percentage: "",
    gender_other_percentage: "",
    preferred_language_of_communication: 0,
    preferNoToSay: false,
  };

  useEffect(() => {
    console.log("dropdowns --->", dropdowns);
    if (isSuccess) {
      setOpenSnackbar(true);
      navigate("/superadmin/dashboard");
    }
  }, [dropdowns, isSuccess, setOpenSnackbar]);

  const InputField = NewInputField;
  const {
    register,
    handleSubmit,
    control,
    resetField,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
    defaultValues: {
      locations: [initialLocationObj],
    },
  });

  const {
    fields: LocationFields,
    append: LocationAppend,
    remove,
  } = useFieldArray({
    name: "locations",
    control,
  });

  const onSubmit = (data) => {
    console.log("data ---->", data);
    const obj = { ...data };
    delete obj.contacts;
    obj.employees = [];
    for (let i = 0; i < obj.locations.length; i++) {
      if (obj.locations[i].preferNoToSay) {
        obj.locations[i].gender_men_percentage = null;
        obj.locations[i].gender_other_percentage = null;
        obj.locations[i].gender_women_percentage = null;
      }
      for (let key in obj.locations[i]) {
        if (obj.locations[i][key] === " ") {
          obj.locations[i][key] = null;
        }
      }
    }
    if (selectedLocation !== "select" && typeof selectedLocation === "number") {
      obj.address_line_1 = obj.locations[selectedLocation].address_line_1;
      obj.address_line_2 = obj.locations[selectedLocation].address_line_2;
      obj.city = obj.locations[selectedLocation].city;
      obj.state = obj.locations[selectedLocation].state;
      obj.country = obj.locations[selectedLocation].country;
      obj.postal_code = obj.locations[selectedLocation].postalcode;
    }
    createOrg(obj);
  };

  const validations = {
    required: "This field is required",
  };

  const emailValidations = {
    required: "This field is required",
    validate: (value) => {
      let re =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return re.test(value) || "Email invalid";
    },
  };
  const passwordValidations = {
    required: "This field is required",
    validate: (value) => {
      let re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
      return (
        re.test(value) ||
        "Password must be at-least 8 char,contains numbers and special chars"
      );
    },
  };

  const handleReset = () => {
    remove();
    reset({});
  };

  return (
    <FormLayout>
      <Box
        className="tw-p-20"
        sx={{
          height: "auto",
          // width: "100vw",
          margin: 0,
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}`,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="tw-flex tw-flex-col">
            <Box className="tw-w-full tw-mb-10 sm:tw-mb-14 lg:tw-mb-20">
              <Typography
                className="tw-text-heading-black tw-font-medium  heading1"
                sx={{ color: `${mode === "dark" ? "#FFFFFF" : "#4B4B4B"}` }}
              >
                Organization details
              </Typography>
            </Box>

            {/* General details */}
            <Grid
              container
              spacing={TabScreen ? 4 : 2}
              className="tw-mb-10 sm:tw-mb-14 lg:tw-mb-20"
            >
              <Grid item xs={12} sm={6}>
                <InputField
                  label={"Organization Name"}
                  placeholder={"Enter Company Name"}
                  name={"org_name"}
                  register={register}
                  validations={validations}
                  error={errors?.org_name?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  label={"Client Code"}
                  placeholder={"Enter Client Code"}
                  name="client_code"
                  register={register}
                  validations={validations}
                  error={errors?.client_code?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectField2
                  label={"Registration Type"}
                  placeholder="Select"
                  control={control}
                  menus={
                    dropdowns?.RegistrationTypes?.length > 0
                      ? dropdowns?.RegistrationTypes
                      : []
                  }
                  validations={{
                    validate: (value) => {
                      return value !== " " || "This field is required";
                    },
                  }}
                  name={"registration_id"}
                  defaultValue={" "}
                  error={errors?.registration_id?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  label={"Email"}
                  placeholder={"Enter email"}
                  name="email"
                  register={register}
                  validations={emailValidations}
                  error={errors?.email?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  label={"Password"}
                  placeholder={"Enter password"}
                  name="password"
                  type="password"
                  validations={passwordValidations}
                  error={errors?.password?.message}
                  register={register}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectField2
                  label={"Average age bracket"}
                  placeholder="Select"
                  control={control}
                  menus={
                    dropdowns?.AverageAgeBrackets?.length > 0
                      ? dropdowns?.AverageAgeBrackets
                      : AvgAgeBrackets
                  }
                  validations={{
                    validate: (value) => {
                      return value !== " " || "This field is required";
                    },
                  }}
                  name={`avg_age_bracket_id`}
                  defaultValue={" "}
                  error={errors?.avg_age_bracket_id?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker2
                  label={"Date of Incorporation"}
                  control={control}
                  name="establishment_date"
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  label={"Organization Website"}
                  placeholder="Enter"
                  name={"website"}
                  register={register}
                  validations={validations}
                  error={errors?.website?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <TextAreaField
                  label={"About Company"}
                  placeholder={"Enter Text"}
                  name={"description"}
                  register={register}
                  // validations={validations}
                  error={errors?.description?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectField2
                  control={control}
                  label={"No of Employees"}
                  placeholder={"Select"}
                  menus={EmployeesSize}
                  defaultValue=" "
                  name={"no_of_employee"}
                  validations={{
                    validate: (value) => {
                      return value !== " " || "This field is required";
                    },
                  }}
                  is_id="name"
                  error={errors?.no_of_employee?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  label={"Gender Ratio"}
                  placeholder="Enter ratio"
                  type="number"
                  name={"gender_ratio"}
                  register={register}
                  validations={{
                    ...validations,
                    valueAsNumber: "Value should be number",
                  }}
                  error={errors?.gender_ratio?.message}
                />
              </Grid>
              {selectedLocation === "select" && (
                <>
                  <Grid item xs={12}>
                    <InputField
                      label={"Registered address 1"}
                      placeholder={"Enter Registered address 1"}
                      name={"address_line_1"}
                      register={register}
                      validations={validations}
                      error={errors?.address_line_1?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      label={"Registered address 2"}
                      placeholder={"Enter Registered address 2"}
                      name={"address_line_2"}
                      register={register}
                      validations={{}}
                      error={errors?.address_line_2?.message}
                    />
                  </Grid>
                  <StateCityFields
                    control={control}
                    stateName={"state"}
                    cityName={"city"}
                    resetField={resetField}
                    validations={{
                      validate: (value) => {
                        return value !== "select" || "Thie field is required";
                      },
                    }}
                    stateError={errors?.state?.message}
                    cityError={errors?.city?.message}
                    lg={6}
                  />
                  <Grid item xs={12} sm={6}>
                    <InputField
                      label={"Country"}
                      placeholder={"Enter Country"}
                      name={"country"}
                      register={register}
                      validations={validations}
                      error={errors?.country?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      label={"Postal Code"}
                      placeholder={"Enter Postal Code"}
                      name={"postal_code"}
                      register={register}
                      validations={validations}
                      error={errors?.postal_code?.message}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            {/* Add Location */}
            <Box className="tw-w-full tw-mb-4 sm:tw-mb-8">
              <Typography
                className=" tw-font-semibold tw-text-sm lg:tw-text-base xl:tw-text-lg"
                sx={{ color: `${mode === "dark" ? "#FFFFFF" : "#4B4B4B"}` }}
              >
                Add Location
              </Typography>
            </Box>

            {/* Each Location */}
            {LocationFields.map((loc, idx) => {
              return (
                <AddLocationSection
                  key={loc.id}
                  idx={idx}
                  remove={remove}
                  register={register}
                  control={control}
                  errors={errors}
                  resetField={resetField}
                >
                  <Grid item xs={12} sm={6} lg={4}>
                    <div className="tw-gap-2 tw-flex tw-flex-col tw-items-start">
                      <InputLabel className="tw-text-xs sm:tw-text-sm tw-w-min tw-mb-1">
                        <Typography>
                          Use this address for organization
                        </Typography>
                      </InputLabel>
                      <Checkbox
                        value={idx}
                        checked={selectedLocation === idx}
                        onChange={() => {
                          if (selectedLocation === idx) {
                            setSelectedLocation("select");
                          } else {
                            setSelectedLocation(idx);
                          }
                          console.log("loc ---->", selectedLocation);
                        }}
                      />
                    </div>
                  </Grid>
                </AddLocationSection>
              );
            })}

            <Box className="tw-w-full tw-mb-8 sm:tw-mb-12">
              <Button
                variant="contained"
                onClick={() => LocationAppend(initialLocationObj)}
                sx={{
                  border: "1px solid white",
                  background: `${mode === "dark" ? "white" : "black"}`,
                  color: `${mode === "light" ? "white" : "black"}`,
                }}
                // className="tw-text-white"
              >
                {LocationFields.length ? "Add More" : "Add Location"}
              </Button>
            </Box>

            {/* Equilibrio internal data  */}
            <Box className="tw-w-full tw-mb-4 sm:tw-mb-8">
              <Typography
                className=" tw-font-semibold tw-text-sm lg:tw-text-base xl:tw-text-lg"
                sx={{
                  color: `${mode === "dark" ? "white" : "black"}`,
                }}
              >
                Complimate internal data
              </Typography>
            </Box>
            <Grid
              container
              spacing={TabScreen ? 4 : 2}
              className="tw-mb-4 sm:tw-mb-8"
            >
              <Grid item xs={12} sm={6} sx={{ marginTop: 0 }}>
                <SelectField2
                  label={"Priority Level"}
                  placeholder="Select"
                  control={control}
                  menus={
                    dropdowns?.PriorityLevels?.length > 0
                      ? dropdowns?.PriorityLevels
                      : PriorityLevels
                  }
                  validations={{
                    validate: (value) => {
                      return value !== " " || "This field is required";
                    },
                  }}
                  name={`Priority_level_id`}
                  defaultValue={" "}
                  error={errors?.Priority_level_id?.message}
                />
              </Grid>
            </Grid>

            {/* Submit Buttons */}
            <Box className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-justify-end tw-space-y-5 sm:tw-space-x-8 sm:tw-space-y-0 tw-mt-12 sm:tw-mt-24">
              <SaveAndCancelBtn
                handleCancle={handleReset}
                cancelBtnProps={{ type: "reset" }}
                savePlaceholder="Register"
              />
            </Box>
          </Box>
        </form>
        <SnackBar
          open={openSnackbar}
          handleClose={() => setOpenSnackbar(false)}
          message={"Added Successfully"}
        />
      </Box>
    </FormLayout>
  );
}

export default OrganizationDetails;
