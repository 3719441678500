import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function ResponsibilitiesOfIC({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{serialNo}. RESPONSIBILITIES OF THE IC</Text>
      <View style={styles.subContainer}>
        <Text style={styles.point}>
          a) Receive and address complaints of sexual harassment and inquire
          into complaints in detail
        </Text>
        <Text style={styles.point}>
          b) Assist Complainant in filing the complaint, in case the Complainant
          is unable to do so.
        </Text>
        <Text style={styles.point}>
          c) Inquiry shall be a priority for an IC member.
        </Text>
        <Text style={styles.point}>
          d) Ensure quorum of minimum three (3) members is met during all
          Inquiry meetings, with mandatory presence of the Presiding officer at
          all inquiry meetings.
        </Text>
        <Text style={styles.point}>
          e) Seeking consent from involved parties and witnesses for audio/video
          recording of the inquiry proceedings.
        </Text>
        <Text style={styles.point}>
          f) Follow the principles of natural justice and treat the Complainant,
          Respondent, witnesses and related persons to the inquiry with dignity
          and respect and give both the Complainant and the Respondent a fair
          opportunity to make their submissions. All members of the IC shall be
          acting without any prejudice or bias while conducting inquiries or
          dealing with complaints of sexual harassment in any other manner.
        </Text>
        <Text style={styles.point}>
          g) Make efforts to ensure that the Complainants and the witnesses are
          not further victimized or discriminated against while the complaint is
          pending.
        </Text>
        <Text style={styles.point}>
          h) Recuse themselves from their assigned job for the purpose of
          conducting an Inquiry if there is conflict of interest or a bias
          coming in the way of conducting a fair Inquiry.
        </Text>
        <Text style={styles.point}>
          i) Take appropriate measures to ensure confidentiality of the inquiry
          process while conducting inquiry physically or virtually.
        </Text>
        <Text style={styles.point}>
          j) Document in detail all the inquiry proceedings and the inquiry
          report.
        </Text>
        <Text style={styles.point}>
          k) Refrain from talking about a potential, pending or completed case
          of sexual harassment to any member directly or indirectly connected to
          any form of media, including print, Television, online media etc.
        </Text>
        <Text style={styles.point}>
          l) Review the status of all complaints received.
        </Text>
        <Text style={styles.point}>
          m) Recommend action against anyone who threatens or intimidates the
          Complainant or members of the IC.
        </Text>
        <Text style={styles.point}>
          n) Read and acquaint themselves with the Policy and attend trainings
          on this subject matter.
        </Text>
        <Text style={styles.point}>
          o) Compile annual reports and submit to the concerned authorities.
        </Text>
        <Text style={styles.point}>
          p) In the event, the complaint does not fall under the purview of
          sexual harassment or the complaint does not mean an offence of sexual
          harassment, forward the same to the concerned person / committee.{" "}
        </Text>
      </View>
    </View>
  );
}

export default ResponsibilitiesOfIC;
