import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function ResponsibilitiesOfOrganization({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {serialNo}. ORGANIZATION’S RESPONSIBILITIES
      </Text>
      <View style={styles.subContainer}>
        <Text style={styles.point}>
          a) Organization will not tolerate any act of sexual harassment. It
          shall treat sexual harassment as ‘Misconduct’ and take prompt,
          appropriate action for the same.
        </Text>
        <Text style={styles.point}>
          b) It will organize gender-sensitization workshops and awareness
          programs at regular intervals covering all locations.
        </Text>
        <Text style={styles.point}>
          c) Shall raise awareness about the prevention of sexual harassment
          through posters, calendars, mugs and other mediums
        </Text>
        <Text style={styles.point}>
          d) To sensitize managers to conduct calls/meetings during working
          hours as much as possible, and to not insist on any employee/team
          member to attend video calls, where not necessary and/or if they
          express difficulty in joining via video call.
        </Text>
        <Text style={styles.point}>
          e) It will ensure that necessary facilities and information are
          provided to the IC including technical support for dealing with the
          complaint and conducting an inquiry.
        </Text>
        <Text style={styles.point}>
          f) Assist in securing the attendance of Respondent and witnesses
          before the IC and make available such information to the IC as it may
          require with regards to the complaint.
        </Text>
        <Text style={styles.point}>
          g) Provide assistance to the IC in any manner feasible to conduct a
          fair and expeditious inquiry and implement recommendations made by IC.
        </Text>
        <Text style={styles.point}>
          h) Provide assistance to the Complainant if the Complainant chooses to
          file a complaint in relation to the offence under the Indian Penal
          Code. Cause to initiate action, under the Indian Penal Code, against
          Respondent or if the Complainant so desires, where the Respondent is
          not an Employee, in the workplace at which the incident of sexual
          harassment took place.
        </Text>
        <Text style={styles.point}>
          i) Cause to initiate action, under the Indian Penal Code, against the
          Respondent, or if the Complainant so desires, where the Respondent is
          not an employee, in the workplace at which the incident of sexual
          harassment took place.
        </Text>
        <Text style={styles.point}>
          j) Offer counselling sessions to anyone impacted by incident of sexual
          harassment
        </Text>
        <Text style={styles.point}>
          k) Do all other things it may deem fit for successful implementation
          of this Policy.
        </Text>
      </View>
    </View>
  );
}

export default ResponsibilitiesOfOrganization;
