import { Document, Font, Page, Text, View } from "@react-pdf/renderer";
import React from "react";

import Roboto from "../../../assets/font/Roboto-Bold.ttf";
import AdditionICMembers from "./Annexure/AdditionICMembers";
import RemovalICMembers from "./Annexure/RemovalICMembers";
import { styles } from "../PDFStyling/OIW/OiwStyle";
import { currentDate } from "../../../utils/currentDate";

Font.register({
  family: "MyFont",
  fonts: [{ src: Roboto, fontWeight: "bold" }],
});

const AppointedAuthorized = ({ newIcMembers, orgName }) => {
  return (
    <Document>
      <Page size={"A4"} style={styles.body}>
        <Text style={styles.title}>ORDER IN WRITING</Text>
        <Text style={styles.date}>DATE: {currentDate}</Text>
        <View style={styles.textContainer}>
          <Text style={styles.text}>
            Pursuant to the requirements under Section 4 (1) of the Sexual
            Harassment of Women at Workplace (Prevention, Prohibition &
            Redressal) Act, 2013 (
            <Text style={{ fontFamily: "MyFont" }}>“Act”</Text> ), by the
            authority vested through Board resolution dated [to be filled
            automatically], I hereby authorise the constitution of IC and the
            resultant addition and removal (as the case may be) of the such
            members to the IC of {`${orgName}`} for such locations as more
            specifically detailed in{" "}
            <Text style={{ fontFamily: "MyFont" }}> Annexure A</Text>.
          </Text>
        </View>
        <View style={{ marginTop: 30 }}>
          <Text style={styles.signText}>SIGNATURE </Text>
        </View>

        <Text style={styles.title}>ANNEXURE A</Text>
        <AdditionICMembers newIcMembers={newIcMembers} />
        <RemovalICMembers />
      </Page>
    </Document>
  );
};

export default AppointedAuthorized;
