import { Box, Stack, TextField } from "@mui/material";
import React, { useContext } from "react";
import { ColorModeContext } from "../../../constants/theme";
import { useNavigate } from "react-router-dom";
import { NavigationBar } from "../../../components/General";
import {
  GridActionsLayout,
  NumericalOverview,
} from "../../../components/Layouts";
import BreadcrumbFont from "../../../components/Typography/BreadcrumbFont";
import StatusFilters from "../../../components/Filters/StatusFilters";
import { FilterButton, TakeActionButton } from "../../../components";
import { DataGrid } from "@mui/x-data-grid";
import {
  useGetAllOrgRegistrationStatusQuery,
  useGetOrgRegistrationMappedQuery,
} from "../../../store/uploadRegistration/uploadRegistrationApi";
import useDebouncedSearch from "../../../utils/hooks/useDebounceSearch";
import { GridColumns } from "../../../utils/gridColumns";

const breadcrumbs = [
  <BreadcrumbFont
    key={1}
    color="#005D9E"
    text={"Aggregate Registration "}
    last
  />,
];

export const Rows = [
  {
    id: "wert",
    Organization: "Company1",
    Status: "Pending",
    Actions: "Take Action",
    Region: "Karnataka",
  },
  {
    id: "wertyuy",
    Organization: "Company2",
    Status: "Completed",
    Actions: "Take Action",
    Region: "Mumbai City",
  },
  {
    id: "wertyuyuio",
    Organization: "Company3",
    Status: "Completed",
    Actions: "Take Action",
    Region: "Karnataka",
  },
  {
    id: "wertyuyuiwero",
    Organization: "Company4",
    Status: "Pending",
    Actions: "Take Action",
    Region: "Pune",
  },
  {
    id: "wertyuyuiweriopo",
    Organization: "Company5",
    Status: "Pending",
    Actions: "Take Action",
    Region: "Mumbai Suburban",
  },
  {
    id: "wertyuyuiweriopwero",
    Organization: "Company6",
    Status: "Pending",
    Actions: "Take Action",
    Region: "Mumbai Suburban",
  },
  {
    id: "wertyuyuiw3345eriopwero",
    Organization: "Company7",
    Status: "Pending",
    Actions: "Take Action",
    Region: "Mumbai City",
  },
  {
    id: "werty4564yuyuiw3345eriopwero",
    Organization: "Company8",
    Status: "Pending",
    Actions: "Take Action",
    Region: "Pune",
  },
  {
    id: "werty4564yuy567uiw3345eriopwero",
    Organization: "Company9",
    Status: "Pending",
    Actions: "Take Action",
    Region: "Karnataka",
  },
  {
    id: "werty4564yuy567uiw3345eriopwero",
    Organization: "Company10",
    Status: "Completed",
    Actions: "Take Action",
    Region: "Telangana",
  },
];

const AllOrgReg = () => {
  const navigate = useNavigate();
  const { mode } = useContext(ColorModeContext);
  const [selectedStatus, setSelectedStatus] = React.useState("all");
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const { searchText, debouncedResults } = useDebouncedSearch();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const { data: orgRegistrationStatus } = useGetAllOrgRegistrationStatusQuery();

  const { data: orgData, isFetching } = useGetOrgRegistrationMappedQuery({
    ...paginationModel,
    ...searchText,
    status: selectedStatus,
  });

  const [rowCountState, setRowCountState] = React.useState(orgData?.count || 0);

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  React.useEffect(() => {
    let temp = [];
    console.log(orgData?.org);
    if (orgData?.org) {
      temp = orgData?.org?.map((org) => {
        return {
          id: org?.id,
          Organization: org?.name || "",
          Status: org?.registration_status || "",
        };
      });
    }
    setRows(temp || []);
  }, [orgData]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      orgData?.count ? orgData?.count : prevRowCountState,
    );
  }, [orgData?.count, setRowCountState]);

  const numericalData = [
    {
      id: 1,
      text: "NO. OF COMPANIES REGISTRATION PENDING",
      number: orgRegistrationStatus?.pending,
    },
    {
      id: 2,
      text: "NO. OF COMPANIES Registration COMPLETED",
      number: orgRegistrationStatus?.completed,
    },
  ];

  const columns = [
    ...new GridColumns()
      .org()
      .status()
      .actions({
        renderCell: (params) => {
          return (
            <GridActionsLayout>
              <TakeActionButton
                handleClick={() =>
                  navigate(`/superadmin/registration/${params.row.id}`)
                }
              />
            </GridActionsLayout>
          );
        },
      })
      .getCols(),
  ];
  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      paddingX={"60px"}
      paddingY={"35px"}
      rowGap={"46px"}
      sx={{ background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}` }}
    >
      <NavigationBar breadcrumbs={breadcrumbs} />
      <NumericalOverview data={numericalData} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={5}
        className="superadmin-data-grid-div"
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <TextField
            type="text"
            name="search"
            onChange={debouncedResults}
            sx={{
              width: "302px",
              "& .MuiInputBase-root": {
                height: "32px",
              },
            }}
            placeholder="Search By Company Name"
          />
          <FilterButton
            buttonStyles={{ height: "32px" }}
            onClick={() => setOpen(!open)}
          />
        </Stack>
        <StatusFilters
          open={open}
          selectedStatus={selectedStatus}
          handleStatusChange={handleStatusChange}
        />
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isFetching}
          disableRowSelectionOnClick
          pageSizeOptions={[5]}
          rowCount={rowCountState}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: `${mode === "dark" ? " #181818" : "#EEF8FF"}`,
            },
            "& .MuiDataGrid-toolbarContainer": {
              paddingY: 2,
              paddingX: 1,
            },

            "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
              backgroundColor: "transparent",
              color: "#4B4B4B",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default AllOrgReg;
