import React from "react";
import { Stack, Typography } from "@mui/material";
import { ColorModeContext } from "../../constants/theme";

function ReminderCard({
  heading = "",
  text = "",
  timeSince = "",
  handleClick = () => null,
  ...rest
}) {
  const { mode } = React.useContext(ColorModeContext);

  return (
    <Stack
      alignItems={"flex-start"}
      bgcolor={mode === "dark" ? "#0D0D0D" : "white"}
      gap={2}
      padding={"24px"}
      borderRadius={"8px"}
      onClick={handleClick}
      {...rest}
      className="hover:tw-cursor-pointer"
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        gap={2}
        width={"100%"}
      >
        <Typography
          color={mode === "dark" ? "#DCDCDC" : "black"}
          fontSize={"16px"}
          fontWeight={500}
        >
          {heading}
        </Typography>
        <Typography
          color={mode === "dark" ? "#DCDCDC" : "#989898"}
          fontSize={"14px"}
          fontWeight={400}
        >
          {timeSince}
        </Typography>
      </Stack>
      <Typography
        color={mode === "dark" ? "#DCDCDC" : "#989898"}
        fontSize={"14px"}
        fontWeight={400}
      >
        {text}
      </Typography>
    </Stack>
  );
}

export default ReminderCard;
