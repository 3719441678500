import { Text, View } from "@react-pdf/renderer";
import React from "react";
import { tableStyles } from "../../PDFStyling/OIW/OiwStyle";

const LocationTable = ({ newIcMembers }) => {
  return (
    <View>
      {newIcMembers?.map((row, indx) => (
        <View key={row?.id}>
          {row?.internal_committee?.employees && (
            <View>
              <Text style={tableStyles.locationTextStyle}>
                Location: {row?.location_name}
              </Text>
              {row?.internal_committee?.employees?.map((emp) => (
                <View key={emp?.id} style={tableStyles.tableRow}>
                  <Text style={tableStyles.cell}>{indx + 1}</Text>
                  <Text style={tableStyles.cell}>
                    {emp.firstName} {emp.lastName}
                  </Text>
                  <Text style={tableStyles.cell}>
                    {emp?.designation_in_ic?.name}
                  </Text>
                  <Text style={tableStyles.cell}>{emp?.email}</Text>
                  <Text style={tableStyles.cell}>
                    {new Date(emp?.ic_effective_date).toLocaleDateString(
                      "en-GB",
                    )}
                  </Text>
                </View>
              ))}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

export default LocationTable;
