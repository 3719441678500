import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../constants";

export const reminderApi = createApi({
  reducerPath: "reminderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
      console.log("building ic headers");
      headers.set(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
      );
      return headers;
    },
  }),

  endpoints: (build) => ({
    getRemindersByOrg: build.query({
      query: (params) => ({
        url: `/v1/reminder/get-by-org`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 3600,
    }),
    getSuperadminReminders: build.query({
      query: (params) => ({
        url: `/v1/reminder/superadmin-reminders`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const { useGetRemindersByOrgQuery, useGetSuperadminRemindersQuery } =
  reminderApi;
