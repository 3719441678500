import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../constants";

export const uploadRegistrationApi = createApi({
  reducerPath: "uploadRegistrationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
      console.log("building uploadRegistration headers");
      return headers;
    },
  }),
  tagTypes: ["OrgRegMapping", "RegStatus", "LocRegMapping"],

  endpoints: (build) => ({
    uploadRegistration: build.mutation({
      query: (body) => ({
        url: `/v1/upload-registration/create`,
        method: "POST",
        body,
      }),
    }),
    getUploadedRegistration: build.query({
      query: (body) => ({
        url: `/v1/upload-registration/get-all`,
        method: "GET",
        body,
      }),
    }),
    editUploadedRegistration: build.mutation({
      query: ({ id, body }) => ({
        url: `/v1/upload-registration/update-registration/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["OrgRegMapping", "RegStatus", "LocRegMapping"],
    }),
    updateRegistrationStatus: build.mutation({
      query: (body) => ({
        url: `/v1/upload-registration/update-status`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["OrgRegMapping", "RegStatus", "LocRegMapping"],
    }),
    getAllOrgRegistrationStatus: build.query({
      query: () => ({
        url: `v1/upload-registration/org-status`,
        method: "GET",
      }),
      keepUnusedDataFor: 1800,
      providesTags: ["RegStatus"],
    }),
    getOrgRegistrationMapped: build.query({
      query: (params) => ({
        url: `v1/upload-registration/org-registration-mapped`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 600,
      providesTags: ["OrgRegMapping"],
    }),
    getLocationRegistrationMapped: build.query({
      query: (params) => ({
        url: `v1/upload-registration/loc-registration-mapped`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 300,
      providesTags: ["LocRegMapping"],
    }),
  }),
});

export const {
  useGetUploadedRegistrationQuery,
  useUploadRegistrationMutation,
  useGetAllOrgRegistrationStatusQuery,
  useGetOrgRegistrationMappedQuery,
  useGetLocationRegistrationMappedQuery,
  useEditUploadedRegistrationMutation,
  useUpdateRegistrationStatusMutation,
} = uploadRegistrationApi;
