import { Box, InputLabel, TextareaAutosize, Typography } from "@mui/material";
import React from "react";

function TextAreaField({
  label,
  placeholder,
  name,
  register,
  validations,
  error,
}) {
  return (
    <Box className="tw-gap-2 tw-flex tw-flex-col">
      <InputLabel
        className="tw-text-xs sm:tw-text-sm tw-w-min tw-py-1"
        htmlFor="orgName"
      >
        <Typography>{label}</Typography>
      </InputLabel>
      <TextareaAutosize
        placeholder={placeholder}
        className={`tw-border-slate-300 tw-p-3 tw-rounded `}
        {...register(name, { ...validations })}
        minRows={2}
      />
      {error && <span className="tw-text-red-700 tw-text-xs">{error}</span>}
    </Box>
  );
}

export default TextAreaField;
