import React from "react";
import { Typography } from "@mui/material";
import { ColorModeContext } from "../../constants/theme";

function BreadcrumbFont({ color = "#4B4B4B", last = false, text }) {
  const { mode } = React.useContext(ColorModeContext);
  return (
    <Typography
      color={mode === "dark" && !last ? "white" : color}
      className="tw-text-[11px] tw-font-semibold tw-tracking-[1px] tw-leading-6 tw-font inter-font"
      key="100"
    >
      {text}
    </Typography>
  );
}

export default BreadcrumbFont;
