import React from "react";
import RegistrationLogo from "../../assets/logos/RegistrationLogo.svg";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { ColorModeContext } from "../../constants/theme";
import { StatusBar } from "../../components/General";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { FilterButton } from "../../components";
import RegistrationLayout from "../../components/Layouts/RegistrationLayout";
import { useGetLocationRegistrationMappedQuery } from "../../store/uploadRegistration/uploadRegistrationApi";
import StatusFilters from "../../components/Filters/StatusFilters";
import { useSelector } from "react-redux";
import { Status } from "../../utils/options";
import useDebouncedSearch from "../../utils/hooks/useDebounceSearch";
import { GridColumns } from "../../utils/gridColumns";

const Registration = () => {
  const { mode } = React.useContext(ColorModeContext);

  const RegistrationStyled = styled(Box)(({ theme }) => ({
    ".heading": {
      fontWeight: 700,
      fontSize: "2rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.4rem",
      },
    },
    ".buttonContainer": {
      display: "flex",
      flexWrap: "wrap",
    },
    ".regionButton": {
      width: "16%",
      padding: "50px",
      border: "2px solid #007E7E",
      borderRadius: "20px",
      margin: "10px",
      color: `${mode === "dark" ? "#FFF" : "#000"}`,
    },
    ".textStyle": {
      fontSize: "1rem",
      fontWeight: 600,
      color: "#4B4B4B",
      marginTop: "24px",
      marginBottom: "24px",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.8rem",
      },
    },
  }));

  const OrganizationDetails = useSelector((state) => state.auth);
  const { user } = OrganizationDetails;
  const orgId = user?.user?.org_id;

  const [rows, setRows] = React.useState([]);
  const { searchText, debouncedResults } = useDebouncedSearch();
  const [selectedStatus, setSelectedStatus] = React.useState("all");
  const [params] = React.useState({});
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [open, setOpen] = React.useState(false);
  const [orgStatus, setOrgStatus] = React.useState("PENDING");

  const { data: locData, isFetching } = useGetLocationRegistrationMappedQuery({
    ...paginationModel,
    ...searchText,
    ...params,
    status: selectedStatus,
    orgId: orgId,
  });

  const [rowCountState, setRowCountState] = React.useState(locData?.count || 0);

  React.useEffect(() => {
    let temp = [];
    console.log(locData?.locations);
    if (locData?.locations) {
      temp = locData?.locations?.map((loc) => {
        return {
          id: loc?.id,
          Location: loc?.location_name || "",
          Region: loc?.region?.region,
        };
      });
      setOrgStatus(locData?.locations[0]?.org?.registration_status);
    }

    setRows(temp || []);
  }, [locData]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      locData?.count ? locData?.count : prevRowCountState,
    );
  }, [locData?.count, setRowCountState]);

  const location = [
    {
      locationName: "Karnataka",
      link: "/home/karnataka-registration",
    },
    { locationName: "Pune", link: "/home/pune-registration" },
    {
      locationName: "Mumbai Suburban",
      link: "/home/mumbai-suburban-registration",
    },
    {
      locationName: "Mumbai City",
      link: "/home/mumbai-city-registration",
    },
    {
      locationName: "Telangana",
      link: "/home/telangana-registration",
    },
  ];
  const navigate = useNavigate();

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  return (
    <RegistrationLayout>
      <RegistrationStyled>
        <Stack
          direction={"row"}
          columnGap={4}
          justifyContent={"flex-start"}
          alignItems={"center"}
          marginBottom={2}
          marginTop={10}
        >
          <img src={RegistrationLogo} alt="" width={"30px"} />
          <Typography
            className="heading"
            sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
          >
            Registration
          </Typography>
          <StatusBar label={orgStatus} status={orgStatus} />
        </Stack>

        <Box className="buttonContainer">
          {location.map((loc, indx) => (
            <Button
              key={indx}
              variant="outlined"
              className="regionButton"
              onClick={() => navigate(loc.link)}
            >
              {loc.locationName}
            </Button>
          ))}
        </Box>

        <Box>
          <Typography className="textStyle">
            Locations where registration is required
          </Typography>

          <Paper
            elevation={3}
            className="tw-p-8"
            width={"100%"}
            overflow={"hidden"}
            display={"flex"}
            flexDirection={"column"}
            rowGap={4}
            height={"100%"}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: `${mode === "dark" ? "#151515" : "#DBFFFF"}`,
              },
              "& .MuiDataGrid-toolbarContainer": {
                paddingY: 2,
                paddingX: 1,
              },

              "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
                backgroundColor: "transparent",
                color: "#4B4B4B",
              },
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              marginBottom={4}
            >
              <TextField
                type="text"
                name="search"
                onChange={debouncedResults}
                sx={{
                  width: "302px",
                  "& .MuiInputBase-root": {
                    height: "32px",
                  },
                }}
                placeholder="Search By Location"
              />
              <FilterButton
                buttonStyles={{ height: "32px" }}
                onClick={() => setOpen(!open)}
              />
            </Stack>
            <StatusFilters
              open={open}
              selectedStatus={selectedStatus}
              handleStatusChange={handleStatusChange}
              status={[Status.PENDING, Status.COMPLETE]}
            />
            {/* Data Grid Table */}
            <DataGrid
              rows={rows}
              columns={new GridColumns()
                .location({ backgroundColor: "#DBFFFF" })
                .region()
                .getCols()}
              loading={isFetching}
              disableRowSelectionOnClick
              pageSizeOptions={[5]}
              rowCount={rowCountState}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
              sx={{
                "& .MuiDataGrid-virtualScroller": {
                  overflow: "hidden",
                  overflowX: "scroll",
                },
              }}
            />
          </Paper>
        </Box>

        <Box className="tw-mt-4">
          <Typography className="tw-text-center tw-p-20 tw-bg-[#DBFFFF]">
            How to register your Internal committee location
          </Typography>
        </Box>
      </RegistrationStyled>
    </RegistrationLayout>
  );
};

export default Registration;
