import React from "react";
import { Pie } from "react-chartjs-2";
// eslint-disable-next-line no-unused-vars
import { Chart as ChartJS, Tooltip } from "chart.js";
import { Box, CircularProgress } from "@mui/material";
ChartJS.register(Tooltip);

const PieChart = ({
  data = {},
  options = {},
  width = "100%",
  height = "100%",
  loading = false,
}) => {
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Box width={width} height={height}>
      {Object.keys(data)?.length === 0 ? null : (
        <Pie data={data} options={{ maintainAspectRatio: false, ...options }} />
      )}
    </Box>
  );
};

export default PieChart;
