import { Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { Controller, useWatch } from "react-hook-form";
import { states } from "../../utils/states";
import { getKey } from "../../utils/getKey";

function StateCityFields({
  control,
  stateName,
  cityName,
  resetField,
  validations = {},
  stateError,
  cityError,
  lg = 4,
}) {
  const watchedState = useWatch({ control, name: stateName });
  React.useEffect(() => {
    resetField(cityName, { defaultValue: "select" });
  }, [watchedState]);

  return (
    <>
      <Grid item xs={12} sm={6} lg={lg}>
        <div className="tw-gap-2 tw-flex tw-flex-col">
          <InputLabel className="tw-text-xs sm:tw-text-sm  tw-w-min  tw-py-1 tw-rounded-md">
            <Typography>State</Typography>
          </InputLabel>
          <Controller
            name={stateName}
            control={control}
            defaultValue={"select"}
            rules={validations}
            render={({ field: { value, onChange, ref } }) => (
              <Select
                value={value}
                onChange={onChange}
                inputRef={ref}
                // className="tw-border-black tw-bg-input-bg"
              >
                <MenuItem value={"select"}>Select</MenuItem>
                {Object.keys(states).map((s, i) => (
                  <MenuItem key={getKey(i)} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {stateError && (
            <span className="tw-text-red-700 tw-text-xs">{stateError}</span>
          )}
        </div>
      </Grid>
      <Grid item xs={12} sm={6} lg={lg}>
        <div className="tw-gap-2 tw-flex tw-flex-col">
          <InputLabel
            className="tw-text-xs sm:tw-text-sm  tw-w-min  tw-py-1 tw-rounded-md"
            htmlFor="orgName"
          >
            <Typography>City</Typography>
          </InputLabel>
          <Controller
            name={cityName}
            control={control}
            defaultValue={"select"}
            rules={validations}
            render={({ field: { value, onChange, ref } }) => (
              <Select value={value} onChange={onChange} inputRef={ref}>
                <MenuItem value={"select"}>Select</MenuItem>
                {states[watchedState]?.map((s, i) => (
                  <MenuItem key={getKey(i)} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {cityError && (
            <span className="tw-text-red-700 tw-text-xs">{cityError}</span>
          )}
        </div>
      </Grid>
    </>
  );
}

export default StateCityFields;
