import React from "react";
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { StatusBar } from "../../../components/General";
import { ColorModeContext } from "../../../constants/theme";
import AnnualReportLogo from "../../../assets/logos/AnnulaReportLogo.svg";
import ReportCard from "../../../components/AnnualReport/ReportCard";
import { DownloadButton } from "../../../components/Buttons";
import {
  useGetAnnualReportQuery,
  useUploadAnnualReportPDFMutation,
  useGetAnnualReportDataQuery,
} from "../../../store/annualReport/annualReportApi";
import { getIdFromUrl } from "../../../utils/getIdFromUrl";
import { useForm } from "react-hook-form";
import UploadButton from "../../../components/Buttons/UploadButton";
import { useSelector } from "react-redux";
import { PDFDownloadLink, usePDF } from "@react-pdf/renderer";
import AnnualReportPdf from "../../../components/PDF/AnnualReport/AnnualReportPdf";
import GridLayout from "../../../components/AnnualReport/GridLayout";
import AWS from "aws-sdk";

const data1 = [
  {
    text: "Number of complaints of sexual harassment received in the year",
    name: "sexual_harrasment",
    value: "2",
  },
  {
    text: "Number of complaints disposed off during the year",
    name: "disposed_off",
    value: "3",
  },
  {
    text: "Number of cases pending for more than 90 days",
    name: "cases_pending_more_than_90_days",
    value: "3",
  },
];

const data2 = [
  { name: "conciliation", text: "Conciliation", value: "3" },
  { name: "written_apology", text: "Written Apology", value: "3" },
  { name: "warning", text: "Warning", value: "3" },
  { name: "Reprimand or Censure", text: "Reprimand or Censure", value: "3" },
  { name: "warning", text: "Withholding of Promotion", value: "3" },
  { name: "warning", text: "Withholding of Increment", value: "3" },
  { name: "termination", text: "Termination", value: "3" },
  { name: "warning", text: "Transfer", value: "3" },
  { name: "warning", text: "Undergo Counselling", value: "3" },
  { name: "warning", text: "Carrying Out Community Service", value: "3" },
  { name: "warning", text: "Withdrawal Of Complaint", value: "3" },
];

function AnnualReportPage() {
  const data3 = [
    {
      name: "emp_awareness_trainings",
      text: "Employee awareness training",
      value: "3",
    },
    {
      name: "ic_trainings",
      text: "Internal Committee awareness training",
      value: "3",
    },
    { name: "other_trainings", text: "Other trainings", value: "3" },
    { name: "other_initiatives", text: "Other Initiatives", value: "3" },
  ];
  const ID = getIdFromUrl();
  const { mode } = React.useContext(ColorModeContext);
  const [loading] = React.useState(false);
  const { user } = useSelector((state) => state.auth.user);
  const [year, setYear] = React.useState(new Date().getFullYear() - 1);
  const { data } = useGetAnnualReportQuery({ location_id: ID, year: year });
  const [uploadAnnualReportPDF] = useUploadAnnualReportPDFMutation();
  const [instance, updateInstance] = usePDF({ document: <AnnualReportPdf /> });
  const [annualReport, setAnnualReport] = React.useState({
    file: null,
    name: "",
  });

  const [formData, setFormData] = React.useState({
    complaintsReceivedInYear: "",
    complaintsDisposedInYear: "",
    casesPending: "",
    conciliation: "",
    writtenApology: "",
    warning: "",
    reprimandOrCensure: "",
    withholdingOfPromotion: "",
    withholdingOfIncrement: "",
    termination: "",
    transfer: "",
    undergoCounselling: "",
    carryingOutCommunityService: "",
    withdrawalOfComplaint: "",
    emp_awareness_trainings: "",
    ic_trainings: "",
    other_trainings: "",
    other_initiatives: "",
  });

  const handleChange = (data) => {
    setFormData((prev) => {
      return { ...prev, ...data };
    });
  };

  const { data: tData } = useGetAnnualReportDataQuery({
    location_id: ID,
    year: year,
  });

  const { handleSubmit, reset } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
  });

  React.useEffect(() => {
    console.log("running...", tData);

    setFormData((prev) => {
      return {
        ...prev,
        ...{
          emp_awareness_trainings: tData?.emp_awareness_trainings || "0",
          ic_trainings: tData?.ic_trainings_sessions || "0",
          other_trainings: tData?.other_trainings || "0",
          other_initiatives: tData?.other_initiatives || "0",
        },
      };
    });
    reset({
      emp_awareness_trainings: tData?.emp_awareness_trainings || "0",
      ic_trainings: tData?.ic_trainings_sessions || "0",
      other_trainings: tData?.other_trainings || "0",
      other_initiatives: tData?.other_initiatives || "0",
    });
  }, [tData]);

  const onUpload = () => {
    try {
      const s3 = new AWS.S3();
      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: "CompanyPolicyFiles/" + annualReport.name,
        Body: annualReport.file,
        ContentType: "application/pdf",
      };

      s3.upload(params, (err, data) => {
        if (err) {
          console.log(err);
        } else {
          console.log("File uploaded Successfully!", data);
          const body = {
            file: data.Location,
            year: year,
            location_id: ID,
            org_id: user.org_id,
          };
          uploadAnnualReportPDF(body);
        }
      });
    } catch (error) {
      console.log("Error uploading file:", error);
      alert("Error uploading file:", error);
    }
  };

  const handleFileChange = (e) => {
    console.log(e.target?.files);
    if (e.target?.files[0]?.type?.match(/(pdf)$/)) {
      setAnnualReport({
        file: e.target.files[0],
        name: e.target.files[0].name,
      });
    } else {
      alert("Please select PDF");
    }
  };

  const onSubmit = (data) => {
    console.log("form data ---->", data);
    updateInstance({
      document: AnnualReportPdf({ body: data }),
    });
    console.log(instance);
    console.log(instance.url);
  };

  return (
    <Box
      className="tw-p-10"
      sx={{
        background: `${mode === "dark" ? "#000000" : " #FFF"}`,
        width: "100%",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        gap: "50px",
        padding: "40px",
      }}
    >
      <Stack
        direction={"row"}
        columnGap={4}
        justifyContent={"flex-start"}
        alignItems={"center"}
        marginBottom={2}
        marginTop={user?.role === "SUPER_ADMIN" ? 0 : 10}
      >
        <img src={AnnualReportLogo} alt="" />
        <Typography
          className="tw-font-semibold tw-text-4xl"
          sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
        >
          Annual Report
        </Typography>
        {data && (
          <StatusBar
            label={data?.annualReport ? "COMPLETED" : "PENDING"}
            status={data?.annualReport ? "COMPLETED" : "PENDING"}
          />
        )}
      </Stack>

      {/* <AnnualReport /> */}
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <Box display={"flex"} flexDirection={"column"} gap={4}>
          <Stack
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={2}
            marginBottom={4}
          >
            <InputLabel>Select Year</InputLabel>
            <Select
              sx={{ height: "35px", width: "160px" }}
              className="tw-border-black  tw-rounded-lg"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              <MenuItem value={"2022"}>2022</MenuItem>
              <MenuItem value={"2021"}>2021</MenuItem>
              <MenuItem value={"2020"}>2020</MenuItem>
              <MenuItem value={"2019"}>2019</MenuItem>
              <MenuItem value={"2018"}>2018</MenuItem>
            </Select>
          </Stack>

          <ReportCard>
            <GridLayout text={data1[0].text}>
              <TextField
                value={formData.complaintsDisposedInYear}
                onChange={(e) =>
                  handleChange({ complaintsDisposedInYear: e.target.value })
                }
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
            <GridLayout text={data1[1].text}>
              <TextField
                value={formData.complaintsReceivedInYear}
                onChange={(e) =>
                  handleChange({ complaintsReceivedInYear: e.target.value })
                }
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
            <GridLayout text={data1[2].text}>
              <TextField
                value={formData.casesPending}
                onChange={(e) => handleChange({ casesPending: e.target.value })}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
          </ReportCard>

          <ReportCard heading="Nature of action taken by the Employer">
            <GridLayout text={data2[0].text}>
              <TextField
                value={formData.conciliation}
                onChange={(e) => handleChange({ conciliation: e.target.value })}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
            <GridLayout text={data2[1].text}>
              <TextField
                value={formData.writtenApology}
                onChange={(e) =>
                  handleChange({ writtenApology: e.target.value })
                }
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
            <GridLayout text={data2[2].text}>
              <TextField
                value={formData.warning}
                onChange={(e) => handleChange({ warning: e.target.value })}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
            <GridLayout text={data2[3].text}>
              <TextField
                value={formData.reprimandOrCensure}
                onChange={(e) =>
                  handleChange({ reprimandOrCensure: e.target.value })
                }
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
            <GridLayout text={data2[4].text}>
              <TextField
                value={formData.withholdingOfPromotion}
                onChange={(e) =>
                  handleChange({ withholdingOfPromotion: e.target.value })
                }
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
            <GridLayout text={data2[5].text}>
              <TextField
                value={formData.withholdingOfIncrement}
                onChange={(e) =>
                  handleChange({ withholdingOfIncrement: e.target.value })
                }
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
            <GridLayout text={data2[6].text}>
              <TextField
                value={formData.termination}
                onChange={(e) => handleChange({ termination: e.target.value })}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
            <GridLayout text={data2[7].text}>
              <TextField
                value={formData.transfer}
                onChange={(e) => handleChange({ transfer: e.target.value })}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
            <GridLayout text={data2[8].text}>
              <TextField
                value={formData.undergoCounselling}
                onChange={(e) =>
                  handleChange({ undergoCounselling: e.target.value })
                }
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
            <GridLayout text={data2[9].text}>
              <TextField
                value={formData.carryingOutCommunityService}
                onChange={(e) =>
                  handleChange({ carryingOutCommunityService: e.target.value })
                }
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
            <GridLayout text={data2[10].text}>
              <TextField
                value={formData.withdrawalOfComplaint}
                onChange={(e) =>
                  handleChange({ withdrawalOfComplaint: e.target.value })
                }
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
          </ReportCard>

          <ReportCard heading="Number of workshops or awareness programs against sexual harassment carried out">
            <GridLayout text={data3[0].text}>
              <TextField
                value={formData.emp_awareness_trainings}
                disabled
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
            <GridLayout text={data3[1].text}>
              <TextField
                value={formData.ic_trainings}
                disabled
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
            <GridLayout text={data3[2].text}>
              <TextField
                value={formData.other_trainings}
                disabled
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
            <GridLayout text={data3[3].text}>
              <TextField
                value={formData.other_initiatives}
                disabled
                sx={{
                  "& .MuiInputBase-root": {
                    height: "35px",
                    backgroundColor: "white",
                    width: "100%",
                  },
                }}
              />
            </GridLayout>
          </ReportCard>

          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            gap={2}
            alignItems={"center"}
          >
            <PDFDownloadLink
              document={
                <AnnualReportPdf
                  body={{
                    date: new Date().toString(),
                    companyName: "Turnkey Tech",
                    calenderYear: year,
                    address: "Pune",
                    personName: user?.firstName || "Sir/Madam",
                    ...formData,
                  }}
                />
              }
              fileName="Annual Report"
            >
              <DownloadButton
                label="Download Annual Report"
                marginTop={2}
                loading={loading}
                buttonProps={{ type: "button" }}
              />
            </PDFDownloadLink>

            {!data?.annualReport && user.role !== "SUPER_ADMIN" && (
              <UploadButton
                onUpload={onUpload}
                accept=".pdf"
                onChange={handleFileChange}
                previewName={annualReport.name}
              />
            )}
          </Stack>
        </Box>
      </form>
    </Box>
  );
}

export default AnnualReportPage;
