import React from "react";
import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import InternalCommiteeLogo from "../../../assets/logos/InternalCommiteeLogo.svg";
import { ColorModeContext } from "../../../constants/theme";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useGetLocationICMappedQuery } from "../../../store/ic/icApi";
import { useSelector } from "react-redux";
import {
  FilterButton,
  GridActionsLayout,
  ViewDetailsButton,
} from "../../../components";
import StatusFilters from "../../../components/Filters/StatusFilters";
import { Status } from "../../../utils/options";
import { GridColumns } from "../../../utils/gridColumns";

function LocationsList() {
  const navigate = useNavigate();
  const { mode } = React.useContext(ColorModeContext);
  const { user } = useSelector((state) => state.auth?.user);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState("all");
  const [filters, setFilters] = React.useState({
    location_category: "ic_required",
  });

  const { data, isSuccess, isFetching } = useGetLocationICMappedQuery({
    orgId: user?.org_id || "",
    page: 0,
    pageSize: 500,
    status: selectedStatus,
    ...filters,
  });

  React.useEffect(() => {
    let tempRows = [];
    if (isSuccess) {
      tempRows = data?.locations?.map((loc) => {
        return {
          id: loc.id,
          Location: loc?.location_name || "",
          Status: loc?.internal_committee?.status || "PENDING",
          location_id: loc?.id,
        };
      });
    }
    setRows(tempRows || []);
  }, [data, isSuccess]);

  const columns = [
    ...new GridColumns()
      .location()
      .status()
      .actions({
        renderCell: (params) => {
          return (
            <GridActionsLayout>
              <ViewDetailsButton
                handleClick={() =>
                  navigate(
                    `/home/internal-committee/${params?.row?.location_id}`,
                  )
                }
              />
            </GridActionsLayout>
          );
        },
      })
      .getCols(),
  ];

  return (
    <Box
      className="tw-p-10"
      sx={{
        background: `${mode === "dark" ? "#000000" : " #FFF"}`,
        height: "100vh",
        width: "100%",
        margin: 0,

        display: "flex",
        flexDirection: "column",
        gap: "50px",
        padding: "40px",
      }}
    >
      <Stack
        direction={"row"}
        columnGap={4}
        justifyContent={"flex-start"}
        alignItems={"center"}
        marginBottom={2}
        marginTop={10}
      >
        <img src={InternalCommiteeLogo} alt="" />
        <Typography
          className="tw-font-semibold tw-text-4xl"
          sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
        >
          Internal Committee
        </Typography>
      </Stack>
      <Stack direction={"row"} gap={2} pr={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={2}
          flex={1}
        >
          <Typography sx={{ color: mode === "dark" ? "white" : "black" }}>
            Categories
          </Typography>
          <Select
            sx={{ height: "35px", width: "150px" }}
            className="tw-border-black  tw-rounded-lg"
            value={filters.location_category}
            onChange={(e) => {
              setFilters((prev) => {
                return { ...prev, location_category: e.target.value };
              });
            }}
          >
            <MenuItem value={"ic_required"}>IC Required</MenuItem>
            <MenuItem value={"ic_not_required"}>IC Not Required</MenuItem>
          </Select>
        </Stack>
        <FilterButton
          buttonStyles={{ height: "32px" }}
          onClick={() => setOpen(!open)}
        />
      </Stack>
      <StatusFilters
        open={open}
        selectedStatus={selectedStatus}
        handleStatusChange={(e) => setSelectedStatus(e.target.value)}
        status={[Status.COMPLETE, Status.PENDING]}
      />
      <Box
        sx={{
          boxShadow:
            "0px 0px 1px rgba(12, 14, 25, 0.24), 0px 2px 12px rgba(12, 14, 25, 0.12)",
          borderRadius: "6px",
          width: "100%",
          height: "100%",
          padding: 2,
          backgroundColor: `${mode === "dark" ? " #181818" : "transparent"}`,
        }}
        className="data-grid-div"
      >
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isFetching}
          disableRowSelectionOnClick
          pageSizeOptions={[5]}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: `${mode === "dark" ? " #181818" : "#FFE8F2"}`,
            },
            "& .MuiDataGrid-toolbarContainer": {
              paddingY: 2,
              paddingX: 1,
            },

            "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
              backgroundColor: "transparent",
              color: "#4B4B4B",
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default LocationsList;
