import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function WhatIsAnEmployee({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{serialNo}. WHAT IS AN EMPLOYEE?</Text>
      <Text style={styles.text}>
        “Employee” means a person employed at a workplace for any work on
        regular, temporary, ad hoc or daily wage basis, either directly or
        through an agent, including a contractor, with or, without the knowledge
        of the principal employer, whether for remuneration or not, or working
        on a voluntary basis or otherwise, whether the terms of employment are
        express or implied and includes a co-worker, a contract worker,
        probationer, trainee, apprentice or called by any other such name.
      </Text>
    </View>
  );
}

export default WhatIsAnEmployee;
