import React from "react";
import { IconButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function RightArrowButton({
  handleClick = () => null,
  buttonProps = {},
  styles = {},
}) {
  return (
    <IconButton
      sx={{
        border: "1px solid",
        boxShadow:
          "0px 0px 1px rgba(12, 14, 25, 0.24), 0px 2px 12px rgba(12, 14, 25, 0.12)",
        ...styles,
      }}
      {...buttonProps}
      onClick={handleClick}
    >
      <ChevronRightIcon />
    </IconButton>
  );
}

export default RightArrowButton;
