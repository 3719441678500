import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../constants";

export const trainingAPI = createApi({
  reducerPath: "trainingAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
      headers.set(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
      );
      console.log("building headers");
      return headers;
    },
  }),
  endpoints: (build) => ({
    createTraining: build.mutation({
      query: (body) => ({
        url: "v1/training/create",
        method: "POST",
        body,
      }),
    }),

    getAllTraining: build.query({
      query: (body) => ({
        url: "v1/training/get/all",
        method: "GET",
        body,
      }),
    }),
    getTrainingByName: build.query({
      query: (params) => ({
        url: `v1/training/get-by-training-type`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 0,
    }),

    getByIdTraining: build.query({
      query: (id) => ({
        url: `v1/training/get-by-id/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    updateTraining: build.mutation({
      query: ({ id, body }) => ({
        url: `v1/training/update/${id}`,
        method: "PUT",
        body,
      }),
      keepUnusedDataFor: 0,
    }),
    deleteTraining: build.mutation({
      query: (id) => ({
        url: `v1/training/delete/${id}`,
        method: "DELETE",
      }),
    }),
    getOrgTrainingMapped: build.query({
      query: (params) => ({
        url: `v1/training/org-training-mapped`,
        method: "GET",
        params,
      }),
    }),
    getLocationTrainingMapped: build.query({
      query: (params) => ({
        url: `v1/training/loc-training-mapped`,
        method: "GET",
        params,
      }),
    }),
    getTrainingStatus: build.query({
      query: () => ({
        url: `v1/training/status`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useCreateTrainingMutation,
  useGetAllTrainingQuery,
  useUpdateTrainingMutation,
  useDeleteTrainingMutation,
  useGetByIdTrainingQuery,
  useGetTrainingByNameQuery,
  useGetOrgTrainingMappedQuery,
  useGetTrainingStatusQuery,
  useGetLocationTrainingMappedQuery,
} = trainingAPI;
