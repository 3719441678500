import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function SHConcernsRedressal({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {serialNo}. HOW ARE CONCERNS RELATED TO SEXUAL HARASSMENT REDRESSED?
      </Text>
      <Text style={styles.text}>
        Organization has constituted an Internal Committee (“IC”) for receiving
        and redressing complaints related to Sexual Harassment at Workplace.{" "}
      </Text>
      <Text style={styles.subHeading}>a. Constitution of the IC:</Text>
      <View style={styles.subContainer}>
        <Text style={styles.text}>
          At all times, the IC shall comprise of the following:
        </Text>
        <Text style={styles.point}>
          i) A senior level woman Employee shall be appointed as Presiding
          Officer of the IC (“Presiding Officer”). The Presiding Officer shall
          automatically vacate her office upon ceasing to be Employee of
          Organization.
        </Text>
        <Text style={styles.point}>
          ii) Minimum two (2) members of the IC will be appointed from amongst
          Employees who are preferably committed to the cause of women or who
          have had experience in social work or have legal knowledge in this
          field. These members shall automatically vacate office upon ceasing to
          be Employee of Organization.
        </Text>
        <Text style={styles.point}>
          iii) Minimum one (1) member from amongst non-governmental
          organizations or associations committed to the cause of women or a
          person familiar with the issues relating to Sexual Harassment. This
          member shall vacate office upon termination of contract with
          Organization.
        </Text>
        <Text style={styles.point}>
          iv) At least one-half of the total members nominated to the IC shall
          be women.
        </Text>
        <Text style={styles.text}>
          All IC members are duty bound to handle all complaints without any
          bias or partiality towards any party. The term for the IC is a maximum
          of 3 years.
        </Text>
      </View>

      <Text style={styles.subHeading}>b. Removal of IC members:</Text>
      <View style={styles.subContainer}>
        <Text style={styles.text}>
          An IC member shall be removed / replaced in the event that the IC
          member:
        </Text>
        <Text style={styles.point}>i) breaches confidentiality</Text>
        <Text style={styles.point}>
          ii) has been convicted for an offence or an inquiry into an offence
          under any law for the time being in force is pending against them
        </Text>
        <Text style={styles.point}>
          iii) has been found guilty in any disciplinary proceedings or a
          disciplinary proceeding is pending against them
        </Text>
        <Text style={styles.point}>
          iv) has abused their position as to render their continuance in office
          prejudicial to the public interest
        </Text>
        <Text style={styles.point}>
          v) Any vacancy created in the IC due to cessation of employment,
          resignation, death, disability or removal, as applicable, shall be
          filled by a fresh nomination by Organization in accordance with its
          guidelines and in conjunction with provision of Law (where
          applicable).
        </Text>
      </View>

      <Text style={styles.boldText}>
        Details of IC are given in Annexure 1.
      </Text>
    </View>
  );
}

export default SHConcernsRedressal;
