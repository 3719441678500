import { createSlice } from "@reduxjs/toolkit";
import { registerOrg } from "./actions";
import { orgApi } from "./orgApi";

const initialState = {
  loading: false,
  orgInfo: {},
  error: null,
  success: false,
};

const orgSlice = createSlice({
  name: "org",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerOrg.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerOrg.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
        state.orgInfo = payload.org;
      })
      .addCase(registerOrg.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    builder.addMatcher(
      orgApi.endpoints.getOrgDetails.matchFulfilled,
      (state, { payload }) => {
        console.log("org details --->", payload);
        state.orgInfo = {
          ...payload?.org,
          contacts: payload?.org?.employees || [],
        };
      },
    );
    builder.addMatcher(
      orgApi.endpoints.createContacts.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
      },
    );
    builder.addMatcher(
      orgApi.endpoints.getContacts.matchFulfilled,
      (state, { payload }) => {
        console.log("contacts ---->", payload);
        state.orgInfo.contacts = payload.employee;
      },
    );
    builder.addMatcher(
      orgApi.endpoints.createLocations.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
      },
    );
    builder.addMatcher(
      orgApi.endpoints.getLocations.matchFulfilled,
      (state, { payload }) => {
        console.log("locations ---->", payload);
        state.orgInfo.locations = payload.location;
      },
    );
  },
});

export default orgSlice.reducer;
