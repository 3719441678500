import React from "react";
import { OverviewLayout } from "../../../components/Layouts";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { NavigationBar } from "../../../components/General";
import { useGetEmployeesByLocQuery } from "../../../store/org/orgApi";
import { Controller, useForm } from "react-hook-form";
import { SelectField2 } from "../../../components/Form";
import { useGetICDesignationQuery } from "../../../store/dropdown/dropdownApi";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useAddICMemberMutation } from "../../../store/ic/icApi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

function EffectiveDate({ control, label, name, errors }) {
  return (
    <Box className="tw-gap-2 tw-flex tw-flex-col">
      <InputLabel className="tw-text-xs sm:tw-text-sm  tw-w-min tw-px-2 tw-py-1 tw-rounded-md">
        <Typography>{label}</Typography>
      </InputLabel>
      <Controller
        control={control}
        name={name}
        rules={{
          required: "Required",
        }}
        render={({ field: { value, onChange, ref } }) => (
          <DatePicker
            onChange={onChange}
            format="DD/MM/YYYY"
            // className="tw-bg-input-bg"
            inputRef={ref}
            value={value ? moment(value) : null}
          />
        )}
      />

      {errors?.effective_date && (
        <span className="tw-text-red-700">
          {errors?.effective_date?.message}
        </span>
      )}
    </Box>
  );
}

function ExpiryDate({ control, label, name, errors }) {
  return (
    <Box className="tw-gap-2 tw-flex tw-flex-col">
      <InputLabel className="tw-text-xs sm:tw-text-sm  tw-w-min tw-px-2 tw-py-1 tw-rounded-md">
        <Typography>{label}</Typography>
      </InputLabel>
      <Controller
        control={control}
        name={name}
        rules={{
          required: "Required",
        }}
        render={({ field: { value, onChange, ref } }) => (
          <DatePicker
            onChange={onChange}
            format="DD/MM/YYYY"
            // className="tw-bg-input-bg"
            inputRef={ref}
            value={value ? moment(value) : null}
          />
        )}
      />

      {errors?.expiry_date && (
        <span className="tw-text-red-700">{errors?.expiry_date?.message}</span>
      )}
    </Box>
  );
}

function AddICMember() {
  const { user } = useSelector((state) => state?.auth?.user);
  const url = window.location.href;
  const ID = url.substring(url.lastIndexOf("/") + 1);
  const { data: EmpData } = useGetEmployeesByLocQuery({
    id: ID,
    params: { for_ic: true },
  });
  const { data: ICDesignations } = useGetICDesignationQuery();
  const [addICMember, { isSuccess: icAdded, isError }] =
    useAddICMemberMutation();
  const [employees, setEmployess] = React.useState([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    console.log("emp ---", EmpData);
    let temp = [];
    if (EmpData?.employees) {
      temp = EmpData.employees.map((emp) => {
        return emp.email;
      });
    }
    setEmployess(temp || []);
  }, [EmpData]);

  React.useEffect(() => {
    console.log("running");
    if (isError) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
    if (icAdded) {
      Swal.fire({
        title: "Added",
        text: "IC Memeber has been added",
        icon: "success",
        timer: 1500,
      });
      navigate(-1);
    }
  }, [icAdded, isError]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
  });

  const breadcrumbs = [
    <Typography
      className="tw-text-[11px] tw-font-semibold tw-tracking-[1px] tw-leading-6 tw-font inter-font"
      key="100"
    >
      INTERNAL COMMITTEE
    </Typography>,
    <Typography
      className="tw-text-dark-blue tw-text-[11px] tw-font-semibold tw-tracking-[1px] tw-leading-6 tw-font inter-font"
      key="100"
    >
      ADD MEMBER
    </Typography>,
  ];

  const onSubmit = (data) => {
    console.log(data);
    addICMember({
      ...data,
      location_id: ID,
      org_id: user?.org_id,
    });
  };

  return (
    <OverviewLayout>
      <Container
        className="tw-p-20"
        sx={{
          height: "auto",
          width: "100%",
          maxWidth: "1300px",
          margin: 0,
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <NavigationBar breadcrumbs={breadcrumbs} />

        <Stack marginTop={4} rowGap={3}>
          <Typography fontSize={"20px"} fontWeight={"600"}>
            Add Internal Committee member
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box className="tw-gap-2 tw-flex tw-flex-col">
                  <InputLabel
                    className="tw-text-xs sm:tw-text-sm tw-w-min tw-py-1"
                    htmlFor="orgName"
                  >
                    <Typography>Select</Typography>
                  </InputLabel>
                  <Controller
                    name="email"
                    rules={{ required: "Required" }}
                    control={control}
                    render={({ field: { onChange, ref } }) => (
                      <Autocomplete
                        disablePortal
                        onChange={(e, v) => onChange(v)}
                        options={employees}
                        renderInput={(params) => (
                          <TextField
                            inputRef={ref}
                            {...params}
                            placeholder="Select"
                          />
                        )}
                      />
                    )}
                  />
                  {errors?.email && (
                    <span className="tw-text-red-700">
                      {errors?.email?.message}
                    </span>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectField2
                  label={"Designation In IC"}
                  name={"designation_in_ic_id"}
                  control={control}
                  rules={{
                    required: "Required",
                    validate: (v) => v !== " " || "Required",
                  }}
                  menus={ICDesignations?.designations || []}
                  error={errors?.designation_in_ic_id?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <EffectiveDate
                  control={control}
                  label={"Effective Date"}
                  name="effective_date"
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ExpiryDate
                  control={control}
                  name={"expiry_date"}
                  errors={errors}
                  label={"Expiry Date"}
                />
              </Grid>
            </Grid>
            <Stack
              direction={"row"}
              gap={2}
              justifyContent={"flex-end"}
              marginTop={6}
            >
              <Button
                type="button"
                variant="outlined"
                sx={{ backgroundColor: "white", color: "black" }}
              >
                Discard changes
              </Button>
              <Button type="submit" variant="contained">
                Add IC member
              </Button>
            </Stack>
          </form>
        </Stack>
      </Container>
    </OverviewLayout>
  );
}

export default AddICMember;
