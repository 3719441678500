export const RegistrationTypes = [
  {
    id: "7a66c9c9-76b2-40e4-a8ff-1f2ae65027c6",
    name: "Private Limited Company",
  },
  {
    id: "d56f8bd8-8a72-4933-926e-128ff61225f8",
    name: "Public Limited Company",
  },
  { id: "05e2570a-7618-4cbf-93ef-a9ce2c98be57", name: "Limited Liability" },
  {
    id: "39fe8910-dc1a-0e49-4e63-2bda8b92387b",
    name: "Registered Partnership",
  },
  {
    id: "39fe89c3-ee64-147d-b8cc-69678fd6407d",
    name: "Un-Registered Partnership",
  },
  { id: "39fe9a11-8ceb-2fa3-ef39-a7c27150c18a", name: "Sole Proprietorship" },
  { id: "39fe9e99-2376-66fb-5098-ef3dce075959", name: "Trust" },
  {
    id: "39fea883-e59f-2d1b-5e49-1a0bbc31c618",
    name: "Educational Institution",
  },
];

export const EmployeesSize = [
  { id: "39ff7292-7d8a-1f32-dbf7-cbb44ed20870", name: "1 - 9" },
  { id: "39fe8fe8-1ddc-21f6-d3f7-f7e103fdc3c8", name: "10 - 50" },
  { id: "39fe940a-0103-147f-6b0f-1e2bacba2258", name: "51 - 100" },
  { id: "39feb43f-f988-0388-e0e0-856e9c163d61", name: "101 - 500" },
  { id: "39fec723-c3f6-0f5d-00a6-8d736e404790", name: "501 - 1000" },
  { id: "39fec75c-ee97-6c7e-2130-1aa35ee66996", name: "1001 - 5000" },
  { id: "39ff736c-1d0e-e2d7-0943-a3d13820b595", name: "5001 - 10000" },
  { id: "39ff8652-31dd-a21e-15ec-b11b879116fa", name: "10000+" },
];

export const Genders = [
  { id: "39fecc57-a55c-d7af-3520-f90e5b906263", name: "Woman" },
  { id: "39fecdc1-cd42-1aa6-663f-5da6ef67efe5", name: "Male" },
  { id: "39ff70d4-4a7b-b3d4-57e4-64639abb026b", name: "Transgender" },
  {
    id: "39ff71d2-af8a-8cae-eee2-62654ab8764a",
    name: "Non-binary/non-confirming",
  },
  { id: "39ff71dd-94b5-5e2c-bf64-f5f75ed7c736", name: "Prefer-not-to-respond" },
  { id: "39fecdc3-2459-2a24-5769-31cde0d27e77", name: "Other" },
];

export const EmployementLevels = [
  { id: "39fed126-b92d-db48-2e01-a9fcb47f155c", name: "Management(Employer)" },
  {
    id: "39fea884-962f-7b17-dcce-a372be2d39b7",
    name: "Manager(Senior Employee)",
  },
  {
    id: "39feae83-f16c-a972-c8d8-925938150f20",
    name: "Manager(Mid-level Employee)",
  },
  { id: "39fee83d-9dc9-b81f-891b-461a7009cd1b", name: "Employee" },
  { id: "39fef0a7-652a-b42c-5eb8-a3a1e56deed7", name: "External Consultant" },
];

export const LocationsOfWork = [
  { id: "39fef0a9-193c-d2f8-8e50-c031acda1f7e", name: "Branch 1" },
  { id: "39fef0bc-9342-8f50-85c8-b69a4a510f3c", name: "Branch 2" },
  { id: "39fef53a-4f0f-01d3-dcea-6128401fae15", name: "Branch 3" },
];

export const ServiceRules = [
  { id: "39fef778-ecb9-6dd1-887d-c1c3759e8de0", name: "Applicable" },
  { id: "39fefd1d-6683-4da2-8c96-f1deed5af46a", name: "Not Applicable" },
];

export const POCTypes = [
  { id: "39fefd30-c0d5-1a19-b3d6-6246b9b8a8dc", name: "Option 1" },
  { id: "39fefd31-641e-59e1-2b09-c2dbf8ce78c2", name: "Option 2" },
];

export const LocationTypes = [
  { id: "39ff0b3c-437b-7523-3557-3d0e7408d5f7", name: "HO" },
  { id: "39ff101d-4bc9-8a30-6954-42606a58e0d2", name: "Branch Office" },
  { id: "39ff1540-522b-3a20-dc2a-0ed004120a5c", name: "Plant" },
  { id: "39ff23f4-0fec-1e1b-3928-f8b33e885d10", name: "Other" },
];

export const LanguagesOfCommunication = [
  { id: "39ff2401-8201-2f86-95a5-b3e9eed37ba7", name: "Hindi" },
  { id: "39fed354-04b5-5ff7-1d30-f9ca54d7e944", name: "English" },
  { id: "39fee161-e43a-8472-6a8f-271889d72ed2", name: "Bangali" },
  { id: "39ff2ecd-fff5-ba56-4a94-75fa7c8613b7", name: "Gujarati" },
  { id: "39ff3eba-a9ee-7753-d160-31c8dc1b26a0", name: "Marathi" },
  { id: "39ff3ebc-2091-d16d-20d9-802dc52701e3", name: "Tamil" },
  { id: "39ff3ef2-b2e2-3220-4b2d-5f96b8ae454c", name: "Other" },
];

export const Zones = [
  { id: "39ff3ef3-b023-35ce-8dbd-fa557e7b43ba", name: "Zone 1" },
  { id: "39ff438a-2bcc-5c83-3d56-e013a19c2e8e", name: "Zone 2" },
];

export const PriorityLevels = [];

export const AvgAgeBrackets = [];

export const TrainingType = [
  { id: 1, name: "Employee Awarness Sessions" },
  { id: 2, name: "IC Training  Sessions" },
  { id: 3, name: "Any Other Training Details" },
  { id: 4, name: "Any Other Initiatives" },
];

export const TrainingMode = [
  { id: 10, name: "Webinar" },
  { id: 20, name: "In person" },
  { id: 30, name: "Workshop" },
  { id: 40, name: "E-module" },
];

export const TrainingGroup = [
  { id: 11, name: "Consent Training" },
  { id: 22, name: "Sensitization Training" },
];

export const Compliances = [
  { id: "39ff66e6-bdea-4abc-6b21-1727b6824abb", name: "Internal Commitee" },
  { id: "39ff66e7-c69a-b0e0-b593-dbe37523fd6a", name: "Order In Writing" },
  { id: "39ff66e8-7148-43a9-c05b-8b682e6b62f9", name: "Company Policy" },
];
export const Roles = [
  { id: "39ff66e6-bdea-4abc-6b21-1727b6824abb", name: "SUPER_ADMIN" },
  { id: "39ff66e7-c69a-b0e0-b593-dbe37523fd6a", name: "COMPANY_ADMIN" },
  { id: "39ff66e8-7148-43a9-c05b-8b682e6b62f9", name: "COMPLIANCE_MANAGER" },
  { id: "39ff66e9-43d7-8d4c-37a9-4b7d909ac639", name: "EMPLOYEE" },
  // { id: "39ff24b5-9253-a62b-e9c0-e5e5ec233f90", name: "IC_MEMBER" },
  { id: "39ff6798-3346-729c-cca3-445bac2a7c9d", name: "MANAGER" },
  { id: "39ff6799-1d4a-3f5a-8e3f-69211e30fad8", name: "SIGNING_AUTHORITY" },
];
