import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { BackdropLoader, NavigationBar } from "../../../components/General";
import {
  useGetContactQuery,
  useGetEmployeesByLocQuery,
} from "../../../store/org/orgApi";
import { Controller, useForm } from "react-hook-form";
import { SelectField2 } from "../../../components/Form";
import { useGetICDesignationQuery } from "../../../store/dropdown/dropdownApi";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useEditICMemberMutation } from "../../../store/ic/icApi";
import SnackBar from "../../../components/General/Snackbar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

function EffectiveDate({ control, label, name, errors, defaultValue = null }) {
  console.log("effective_date --->", defaultValue);
  return (
    <Box className="tw-gap-2 tw-flex tw-flex-col">
      <InputLabel className="tw-text-xs sm:tw-text-sm  tw-w-min tw-px-2 tw-py-1 tw-rounded-md">
        <Typography>{label}</Typography>
      </InputLabel>
      <Controller
        control={control}
        name={name}
        rules={{
          required: "Required",
        }}
        defaultValue={defaultValue}
        render={({ field: { value, onChange, ref } }) => (
          <DatePicker
            onChange={onChange}
            format="DD/MM/YYYY"
            // className="tw-bg-input-bg"
            inputRef={ref}
            value={value ? moment.utc(value) : null}
          />
        )}
      />

      {errors?.effective_date && (
        <span className="tw-text-red-700">
          {errors?.effective_date?.message}
        </span>
      )}
    </Box>
  );
}

function ExpiryDate({ control, label, name, errors }) {
  return (
    <Box className="tw-gap-2 tw-flex tw-flex-col">
      <InputLabel className="tw-text-xs sm:tw-text-sm  tw-w-min tw-px-2 tw-py-1 tw-rounded-md">
        <Typography>{label}</Typography>
      </InputLabel>
      <Controller
        control={control}
        name={name}
        rules={{
          required: "Required",
        }}
        render={({ field: { value, onChange, ref } }) => (
          <DatePicker
            onChange={onChange}
            format="DD/MM/YYYY"
            // className="tw-bg-input-bg"
            inputRef={ref}
            value={value ? moment.utc(value) : null}
          />
        )}
      />

      {errors?.expiry_date && (
        <span className="tw-text-red-700">{errors?.expiry_date?.message}</span>
      )}
    </Box>
  );
}

function EditICMember() {
  const OrganizationDetails = useSelector((state) => state.auth);
  const { user } = OrganizationDetails;
  const orgId = user?.user?.org_id;

  const initialValues = {
    email: "",
    designation_in_ic_id: " ",
    effective_date: null,
    expiry_date: null,
  };
  const url = window.location.href;
  const ID = url.substring(url.lastIndexOf("/") + 1);
  const { data: memberData } = useGetContactQuery(ID);
  const { data: EmpData, isFetching: empFetching } = useGetEmployeesByLocQuery({
    id: memberData?.employee?.location_id,
    params: { for_ic: true },
  });
  const { data: ICDesignations } = useGetICDesignationQuery();
  const [editICMember, { isSuccess: updateEmployee, isError }] =
    useEditICMemberMutation();
  const [employees, setEmployess] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
    defaultValues: initialValues,
  });

  React.useEffect(() => {
    console.log("member ---->", memberData?.employee?.email);
    if (memberData?.employee?.email) {
      reset({
        email: memberData?.employee?.email,
        designation_in_ic_id: memberData?.employee?.designation_in_ic_id,
        expiry_date: memberData?.employee?.ic_expiry_date,
        effective_date: memberData?.employee?.ic_effective_date,
      });
    }
  }, [memberData]);

  React.useEffect(() => {
    console.log("emp ---", EmpData);
    let temp = [];
    if (EmpData?.employees) {
      temp =
        EmpData.employees.map((emp) => {
          return emp.email;
        }) || [];
    }
    setEmployess((prev) => {
      return [...prev, ...temp];
    });
  }, [EmpData]);

  React.useEffect(() => {
    console.log("running");
    if (isError) {
      setOpen(true);
    }
    if (updateEmployee) {
      Swal.fire({
        title: "Update",
        text: "IC Member has been updated",
        icon: "success",
        timer: 1500,
      });
      navigate(-1);
    }
  }, [updateEmployee, isError]);

  const breadcrumbs = [
    <Typography
      className="tw-text-[11px] tw-font-semibold tw-tracking-[1px] tw-leading-6 tw-font inter-font"
      key="100"
    >
      INTERNAL COMMITTEE
    </Typography>,
    <Typography
      className="tw-text-dark-blue tw-text-[11px] tw-font-semibold tw-tracking-[1px] tw-leading-6 tw-font inter-font"
      key="100"
    >
      Edit MEMBER
    </Typography>,
  ];

  const onSubmit = (data) => {
    console.log(data);
    const body = {
      email: data.email,
      designation_in_ic_id: data.designation_in_ic_id,
      effective_date: data.effective_date,
      expiry_date: data.expiry_date,
    };
    editICMember({
      empId: ID,
      body: {
        ...body,
        org_id: orgId,
        ic_id: memberData?.employee?.ic_id,
        location_id: memberData?.employee?.location_id,
      },
    });
  };

  const handleReset = () => {
    reset({
      email: memberData?.employee?.email,
      designation_in_ic_id: memberData?.employee?.designation_in_ic_id,
      expiry_date: memberData?.employee?.ic_expiry_date,
      effective_date: memberData?.employee?.ic_effective_date,
    });
  };

  return (
    <Container
      className="tw-p-20"
      sx={{
        height: "auto",
        width: "100%",
        maxWidth: "1300px",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <BackdropLoader open={empFetching} />
      <NavigationBar breadcrumbs={breadcrumbs} />
      <SnackBar
        open={open}
        handleClose={() => setOpen(false)}
        message={"Please try again"}
      />
      <Stack marginTop={4} rowGap={3}>
        <Typography fontSize={"20px"} fontWeight={"600"}>
          Edit Internal Committee member
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box className="tw-gap-2 tw-flex tw-flex-col">
                <InputLabel
                  className="tw-text-xs sm:tw-text-sm tw-w-min tw-py-1"
                  htmlFor="orgName"
                >
                  <Typography>Select</Typography>
                </InputLabel>
                <Controller
                  name="email"
                  rules={{ required: "Required" }}
                  control={control}
                  render={({ field: { onChange, ref, value } }) => (
                    <Autocomplete
                      disablePortal
                      onChange={(e, v) => onChange(v)}
                      options={employees}
                      value={value}
                      renderInput={(params) => (
                        <TextField
                          inputRef={ref}
                          {...params}
                          placeholder="Select"
                        />
                      )}
                    />
                  )}
                />
                {errors?.email && (
                  <span className="tw-text-red-700">
                    {errors?.email?.message}
                  </span>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectField2
                label={"Designation In IC"}
                name={"designation_in_ic_id"}
                control={control}
                rules={{
                  required: "Required",
                  validate: (v) => v !== " " || "Required",
                }}
                menus={ICDesignations?.designations || []}
                error={errors?.designation_in_ic_id?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <EffectiveDate
                control={control}
                label={"Effective Date"}
                name="effective_date"
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ExpiryDate
                control={control}
                name={"expiry_date"}
                errors={errors}
                label={"Expiry Date"}
              />
            </Grid>
          </Grid>
          <Stack
            direction={"row"}
            gap={2}
            justifyContent={"flex-end"}
            marginTop={6}
          >
            <Button
              type="button"
              variant="outlined"
              onClick={handleReset}
              sx={{ backgroundColor: "white", color: "black" }}
            >
              Discard changes
            </Button>
            <Button type="submit" variant="contained">
              Edit
            </Button>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}

export default EditICMember;
