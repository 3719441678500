import React from "react";
import { NavigationBar } from "../../components/General";
import { Box, Typography } from "@mui/material";

function CompanyDetails() {
  const breadcrumbs = [
    <Typography
      className="tw-text-dark-blue tw-text-[11px] tw-font-semibold tw-tracking-[1px] tw-leading-6 tw-font inter-font"
      key="100"
    >
      DASHBOARD
    </Typography>,
    <Typography
      className="tw-text-dark-blue tw-text-[11px] tw-font-semibold tw-tracking-[1px] tw-leading-6 tw-font inter-font"
      key="100"
    >
      COMPANY NAME
    </Typography>,
  ];

  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      paddingX={"60px"}
      paddingY={"35px"}
      rowGap={"46px"}
    >
      <NavigationBar breadcrumbs={breadcrumbs} />
    </Box>
  );
}

export default CompanyDetails;
