import React, { useContext } from "react";
import { Box, Stack, TextField } from "@mui/material";
import { NavigationBar } from "../../../components/General";
import { ColorModeContext } from "../../../constants/theme";
import {
  GridActionsLayout,
  NumericalOverview,
} from "../../../components/Layouts";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import {
  useGetAllOrgICStatusQuery,
  useGetOrgICMappedQuery,
} from "../../../store/ic/icApi";
import BreadcrumbFont from "../../../components/Typography/BreadcrumbFont";
import { FilterButton, TakeActionButton } from "../../../components";
import StatusFilters from "../../../components/Filters/StatusFilters";
import { Status } from "../../../utils/options";
import useDebouncedSearch from "../../../utils/hooks/useDebounceSearch";
import { GridColumns } from "../../../utils/gridColumns";

const breadcrumbs = [
  <BreadcrumbFont key={1} color="#005D9E" text={"AGGREGATE IC"} />,
];

function AllOrgIC() {
  const navigate = useNavigate();
  const { mode } = useContext(ColorModeContext);
  const [rows, setRows] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const { searchText, debouncedResults } = useDebouncedSearch();
  const [open, setOpen] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState("all");
  const { data: orgData, isFetching } = useGetOrgICMappedQuery({
    ...paginationModel,
    ...searchText,
    status: selectedStatus,
    source: "ic",
  });
  const { data: OrgICStatus } = useGetAllOrgICStatusQuery();
  const [rowCountState, setRowCountState] = React.useState(orgData?.count || 0);

  React.useEffect(() => {
    let temp = [];
    if (orgData?.org) {
      temp = orgData?.org?.map((org) => {
        return {
          id: org?.id,
          Organization: org?.name || "",
          Status: org?.ic_status || "",
        };
      });
    }
    setRows(temp || []);
  }, [orgData]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      orgData?.count ? orgData?.count : prevRowCountState,
    );
  }, [orgData?.count, setRowCountState]);

  const numericalData = [
    {
      id: 1,
      text: "NO. OF COMPANIES INTERNAL COMMITTEEE PENDING",
      number: OrgICStatus?.pending,
    },
    {
      id: 2,
      text: "NO. OF COMPANIES INTERNAL COMMITTEEE COMPLETED",
      number: OrgICStatus?.completed,
    },
  ];

  const columns = [
    ...new GridColumns()
      .org()
      .status()
      .actions({
        renderCell: (params) => {
          return (
            <GridActionsLayout>
              <TakeActionButton
                handleClick={() => navigate(`/superadmin/ic/${params.row.id}`)}
              />
            </GridActionsLayout>
          );
        },
      })
      .getCols(),
  ];

  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      paddingX={"60px"}
      paddingY={"35px"}
      rowGap={"46px"}
      sx={{ background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}` }}
    >
      <NavigationBar breadcrumbs={breadcrumbs} />
      <NumericalOverview data={numericalData} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={5}
        className="superadmin-data-grid-div"
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <TextField
            type="text"
            name="search"
            onChange={debouncedResults}
            sx={{
              width: "302px",
              "& .MuiInputBase-root": {
                height: "32px",
              },
            }}
            placeholder="Search By Company Name"
          />
          <FilterButton
            buttonStyles={{ height: "32px" }}
            onClick={() => setOpen(!open)}
          />
        </Stack>
        <StatusFilters
          open={open}
          selectedStatus={selectedStatus}
          handleStatusChange={(e) => setSelectedStatus(e.target.value)}
          status={[Status.COMPLETE, Status.PENDING]}
        />
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isFetching}
          disableRowSelectionOnClick
          pageSizeOptions={[5]}
          rowCount={rowCountState}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: `${mode === "dark" ? " #181818" : "#EEF8FF"}`,
            },
            "& .MuiDataGrid-toolbarContainer": {
              paddingY: 2,
              paddingX: 1,
            },

            "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
              backgroundColor: "transparent",
              color: "#4B4B4B",
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default AllOrgIC;
