/* eslint-disable indent */
import {
  Box,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { StatusBar } from "../../../components/General";

import { ColorModeContext } from "../../../constants/theme";
import OrderInWritingLogo from "../../../assets/logos/OrderWritingLogo.svg";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import {
  AppointedAuthorized,
  CheckedClauses,
  NotCheckedClauses,
} from "../../../components/PDF/OrderInWriting";
import { DownloadButton } from "../../../components";
import { useGetEmployeeByRoleQuery } from "../../../store/dropdown/dropdownApi";
import { useSelector } from "react-redux";
import { useGetLocationICMappedQuery } from "../../../store/ic/icApi";
import {
  useGetOrgDetailsQuery,
  useGetSingleOrgQuery,
} from "../../../store/org/orgApi";
import { Authorization, Status } from "../../../utils/options";
import { Controller, useForm } from "react-hook-form";

const SelectAuthority = () => {
  const { user } = useSelector((state) => state.auth?.user);
  const { data: org } = useGetOrgDetailsQuery(user?.org_id);
  const ID = user?.org_id;

  const { mode } = React.useContext(ColorModeContext);
  const [confirm, setConfirm] = React.useState(false);
  const [isDownload, setIsDownload] = React.useState(false);
  const [authority, setAuthority] = React.useState({});
  const [pdfPreivewLoader, setPdfPreviewLoader] = React.useState(false);
  const { data: signingAuthorityData } = useGetEmployeeByRoleQuery({
    role: "SIGNING_AUTHORITY",
    org_id: ID,
  });
  const { data: complianceManagerData } = useGetEmployeeByRoleQuery({
    role: "COMPLIANCE_MANAGER",
    org_id: ID,
  });

  const { data: locData } = useGetLocationICMappedQuery({
    page: 0,
    pageSize: 5,
    orgId: ID,
    source: "OIW",
  });

  const { data: SingleOrgData } = useGetSingleOrgQuery({ id: ID });

  const { handleSubmit, control } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
    defaultValues: "initialValues",
  });

  const [preview, setPreview] = React.useState(false);

  React.useEffect(() => {
    console.log("loxation", locData?.locations);

    console.log(signingAuthorityData);
  }, [signingAuthorityData, locData]);

  const onSubmit = (data) => {
    console.log(data);
    setAuthority(data);
    setPreview(true);
    setPdfPreviewLoader(true);
    setTimeout(() => {
      setPdfPreviewLoader(false);
    }, 2000);
  };

  return (
    <Box
      className="tw-p-10"
      sx={{
        background: `${mode === "dark" ? "#1A1A1A" : " #FFF"}`,
        height: `${preview ? "100%" : "100vh"}`,
        width: "100%",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        gap: "50px",
        padding: "40px",
      }}
    >
      <Stack
        direction={"row"}
        columnGap={4}
        justifyContent={"flex-start"}
        alignItems={"center"}
        marginBottom={2}
        marginTop={10}
      >
        <img
          src={OrderInWritingLogo}
          height={"36px"}
          alt="Comapny Policy Logo"
        />
        <Typography
          className="tw-font-semibold tw-text-4xl"
          sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
        >
          Order in Writing
        </Typography>
        <StatusBar
          label={
            org?.org?.oiw_status === Status.APPROVED
              ? "COMPLETED"
              : org?.org?.oiw_status
          }
          status={org?.org?.oiw_status}
        />
      </Stack>
      <Box className="tw-w-[70%] lg:tw-w-[646px] tw-mx-auto tw-py-10 tw-flex tw-flex-col tw-space-y-5">
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={3}
        >
          <Checkbox
            onChange={() => setConfirm(!confirm)}
            disabled={
              org?.org?.oiw_status === Status.APPROVAL ||
              org?.org?.oiw_status === Status.COMPLETE
            }
          />
          <Typography sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}>
            Do you want an authorized Person ?
          </Typography>
        </Stack>

        <form onSubmit={handleSubmit(onSubmit)}>
          {confirm && (
            <>
              <Box className="tw-mt-4">
                <div className="tw-gap-2 tw-flex tw-flex-col">
                  <InputLabel
                    className="tw-text-xs sm:tw-text-sm  tw-w-min tw-px-2 tw-py-1 tw-rounded-md"
                    htmlFor="orgName"
                  >
                    <Typography>Select Compliance manager</Typography>
                  </InputLabel>
                  <Controller
                    name={"compliance"}
                    control={control}
                    defaultValue={" "}
                    // rules={validations}
                    render={({ field: { value, onChange, ref } }) => (
                      <Select value={value} onChange={onChange} inputRef={ref}>
                        <MenuItem hidden disabled value={" "}>
                          Select
                        </MenuItem>
                        {complianceManagerData?.employees?.map((auth) => (
                          <MenuItem key={auth?.id} value={auth?.fullName}>
                            {auth?.email}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </Box>

              <Box className="tw-mt-4">
                <div className="tw-gap-2 tw-flex tw-flex-col">
                  <InputLabel
                    className="tw-text-xs sm:tw-text-sm  tw-w-min tw-px-2 tw-py-1 tw-rounded-md"
                    htmlFor="orgName"
                  >
                    <Typography>Select Signing Authority</Typography>
                  </InputLabel>
                  <Controller
                    name={"signingAuthority"}
                    control={control}
                    required={true}
                    defaultValue={" "}
                    // rules={validations}
                    render={({ field: { value, onChange, ref } }) => (
                      <Select value={value} onChange={onChange} inputRef={ref}>
                        <MenuItem hidden disabled value={" "}>
                          Select
                        </MenuItem>
                        {signingAuthorityData?.employees?.map((auth) => (
                          <MenuItem key={auth?.id} value={auth?.fullName}>
                            {auth?.email}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </Box>
            </>
          )}
          <Box className="tw-mt-8">
            <DownloadButton
              disabledValue={org?.org?.oiw_status === Status.APPROVAL}
              type="submit"
              loading={pdfPreivewLoader}
              label="View Preview"
              styles={{ width: "100%", marginBottom: "10rem" }}
            />
          </Box>
        </form>
        {preview && org?.org?.oiw_status === Status.PENDING && (
          <>
            {!pdfPreivewLoader && (
              <PDFViewer
                showToolbar={false}
                style={{
                  height: "600px",
                  width: "100%",
                }}
                onLoad={() => setPdfPreviewLoader(false)}
              >
                {!confirm && (
                  <NotCheckedClauses newIcMembers={locData?.locations || []} />
                )}
                {confirm && (
                  <CheckedClauses
                    newIcMembers={locData?.locations || []}
                    authority={authority}
                  />
                )}
              </PDFViewer>
            )}

            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              marginTop={"1rem"}
              gap={3}
            >
              <Checkbox onChange={() => setIsDownload(!isDownload)} />
              <Typography
                sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
              >
                I CONFIRM THAT I HAVE READ AND UNDERSTOOD THE ABOVE DOCUMENT
              </Typography>
            </Stack>

            <PDFDownloadLink
              document={
                (!confirm && (
                  <NotCheckedClauses newIcMembers={locData?.locations || []} />
                )) ||
                (confirm && (
                  <CheckedClauses
                    newIcMembers={locData?.locations || []}
                    authority={authority}
                  />
                ))
              }
              fileName="Order In Writing"
            >
              {({ loading }) =>
                loading ? (
                  <DownloadButton
                    loading={loading}
                    styles={{ width: "100%", marginTop: "1rem" }}
                    disabledValue={!isDownload}
                  />
                ) : (
                  <DownloadButton
                    styles={{
                      width: "100%",
                      marginTop: "1rem",
                    }}
                    disabledValue={!isDownload}
                  />
                )
              }
            </PDFDownloadLink>
          </>
        )}
        {preview && org?.org?.oiw_status === Status.COMPLETE && (
          <>
            {!pdfPreivewLoader && (
              <PDFViewer
                showToolbar={false}
                style={{
                  height: "600px",
                  width: "100%",
                }}
                onLoad={() => setPdfPreviewLoader(false)}
              >
                {org?.org?.person_type_oiw === Authorization.UNAUTHORIZED && (
                  <NotCheckedClauses newIcMembers={locData?.locations || []} />
                )}
                {org?.org?.person_type_oiw === Authorization.AUTHORIZED && (
                  <AppointedAuthorized
                    newIcMembers={locData?.locations || []}
                    orgName={SingleOrgData?.org?.name}
                  />
                )}
              </PDFViewer>
            )}
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              marginTop={"1rem"}
              gap={3}
            >
              <Checkbox onChange={() => setIsDownload(!isDownload)} />
              <Typography>
                I CONFIRM THAT I HAVE READ AND UNDERSTOOD THE ABOVE DOCUMENT
              </Typography>
            </Stack>

            <PDFDownloadLink
              document={
                (org?.org?.person_type_oiw === Authorization.UNAUTHORIZED && (
                  <NotCheckedClauses newIcMembers={locData?.locations || []} />
                )) ||
                (org?.org?.person_type_oiw === Authorization.AUTHORIZED && (
                  <AppointedAuthorized
                    newIcMembers={locData?.locations || []}
                    orgName={SingleOrgData?.org?.name}
                  />
                ))
              }
              fileName="Order In Writing"
            >
              {({ loading }) =>
                loading ? (
                  <DownloadButton
                    loading={loading}
                    styles={{ marginTop: "1rem", width: "100%" }}
                    disabledValue={!isDownload}
                  />
                ) : (
                  <DownloadButton
                    styles={{
                      marginTop: "1rem",
                      width: "100%",
                    }}
                    disabledValue={!isDownload}
                  />
                )
              }
            </PDFDownloadLink>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SelectAuthority;
