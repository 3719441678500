import { Box, Stack, Typography, styled } from "@mui/material";
import React from "react";
import { ColorModeContext } from "../../constants/theme";

const PageHeadingStyle = styled(Box)(({ theme }) => ({
  ".headingStyle": {
    fontWeight: 700,
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
    },
  },
}));

const PageHeading = ({ label, logo }) => {
  const { mode } = React.useContext(ColorModeContext);
  return (
    <PageHeadingStyle>
      <Stack
        direction={"row"}
        columnGap={4}
        justifyContent={"flex-start"}
        alignItems={"center"}
        marginBottom={2}
        marginTop={10}
      >
        <img src={logo} alt="" width={"30px"} />
        <Typography
          className="headingStyle"
          sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
        >
          {label}
        </Typography>
      </Stack>
    </PageHeadingStyle>
  );
};

export default PageHeading;
