import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../constants";

export const policyApi = createApi({
  reducerPath: "policyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
      console.log("building policy headers");
      headers.set(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
      );
      return headers;
    },
  }),
  tagTypes: ["OrgPolicyMapping", "PolicyStatus"],
  endpoints: (build) => ({
    uploadPolicy: build.mutation({
      query: (body) => ({
        url: `/v1/policy/upload`,
        method: "POST",
        body,
      }),
    }),
    getOrgPolicyMapped: build.query({
      query: (params) => ({
        url: `/v1/policy/org-policy-mapped`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 1000,
      providesTags: ["OrgPolicyMapping"],
    }),
    getPolicyStatus: build.query({
      query: () => ({
        url: `/v1/policy/policy-status`,
        method: "GET",
      }),
      keepUnusedDataFor: 1800,
      providesTags: ["PolicyStatus"],
    }),
    getPolicyByOrg: build.query({
      query: (params) => ({
        url: `/v1/policy/get-by-org`,
        method: "GET",
        params,
      }),
    }),
    updatePolicyStatus: build.mutation({
      query: (body) => ({
        url: `/v1/policy/update-status`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["OrgPolicyMapping", "PolicyStatus"],
    }),
    updatePolicy: build.mutation({
      query: ({ id, body }) => ({
        url: `/v1/policy/update/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["OrgPolicyMapping", "PolicyStatus"],
    }),
  }),
});

export const {
  useUploadPolicyMutation,
  useGetOrgPolicyMappedQuery,
  useGetPolicyStatusQuery,
  useGetPolicyByOrgQuery,
  useUpdatePolicyStatusMutation,
  useUpdatePolicyMutation,
} = policyApi;
