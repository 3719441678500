import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./styles/normalize.css";
import { OrganizationDetails } from "./pages/OrganizationDetails";
import PrivateScreen from "./pages/PrivateScreen";
import {
  CompanyEdit,
  CompanyProfile,
  Dashboard,
  InternalCommitee,
  AddLocation,
  Trainings,
  EmployeeManager,
  SelectPolicyPage,
  AllLocations,
  AdminReminders,
  POC,
  IcArchives,
  OiwArchives,
  CompanyPolicyArchive,
} from "./pages/privatescreens";
import {
  AddContacts,
  EditContact,
  EditLocation,
} from "./pages/privatescreens/Edit";
import {
  AggregateOrg,
  Archives,
  CompanyDetails,
  Main,
  SuperAdminDashboard,
} from "./pages/SuperAdmin";
import TrainingEdit from "./pages/privatescreens/Edit/TrainingEdit";
import TrainingAdd from "./pages/privatescreens/Add/TrainingAdd";
import InitativeTrainingAdd from "./pages/privatescreens/Add/InitativeTrainingAdd";
import EditInitativeTraining from "./pages/privatescreens/Edit/EditInitativeTraining";
import ChangePassword from "./pages/auth/ChangePassword";
import EmployeeManagerList from "./pages/employeeManager/EmployeeManagerList";
import AddEmployee from "./pages/privatescreens/Add/AddEmployee";
import EditEmployeeManager from "./pages/privatescreens/Edit/EditEmployeeManager";
import AddICMember from "./pages/privatescreens/complainces/AddICMember";
import LocationsList from "./pages/privatescreens/complainces/LocationLists";
import EditICMember from "./pages/privatescreens/complainces/EditICMember";
import {
  AllOrgIC,
  SingleLocIC,
  SingleOrgIC,
} from "./pages/SuperAdmin/AggregateIC";
import {
  AllOrgTraining,
  SingleLocTraining,
  SingleOrgTraining,
} from "./pages/SuperAdmin/AggregateTraining";
import Protected from "./components/Routes/ProtectedRoute";
import PublicRoute from "./components/Routes/PublicRoute";
import LoginDashboard from "./pages/auth/LoginDashboard";
import {
  AllOrgAR,
  SingleOrgAR,
} from "./pages/SuperAdmin/AggregateAnnualReport";
import {
  AllLocAR,
  AnnualReportPage,
} from "./pages/privatescreens/AnnualReport";
import Registration from "./pages/registration/Registration";
import AllLocTraining from "./pages/privatescreens/trainings/AllLocTrainings";

import PuneRegistration from "./pages/registration/PuneRegistration/PuneRegistration";
import KarnatakaRegistration from "./pages/registration/KarnatakaRegistration/KarnatakaRegistration";
import MumbaiCityRegistration from "./pages/registration/mumbaiCity/MumbaiCityRegistration";
import MumbaiSuburbanRegistration from "./pages/registration/mumbaiSuburban/MumbaiSuburbanRegistration";
import TelanganaRegistration from "./pages/registration/telanganaRegistration/TelanganaRegistration";
import UploadRegistration from "./pages/registration/uploadRegistration/UploadRegistration";
import UploadRegistrationByCourier from "./pages/registration/uploadRegistration/UploadRegistrationByCourier";
import UploadRegistrationByHand from "./pages/registration/uploadRegistration/UploadRegistrationByHand";
import Upload from "./pages/upload/Upload";
import AllOrgReg from "./pages/SuperAdmin/AggregateRegistration/AllOrgReg";
import SingleOrgRegistration from "./pages/SuperAdmin/AggregateRegistration/SingleOrgRegistration";
import UploadCompanyPolicy from "./pages/privatescreens/CompanyPolicy/UploadCompanyPolicy";
import AllOrgPolicy from "./pages/SuperAdmin/AggregatePolicy/AllOrgPolicy";
import { SelectAuthority } from "./pages/privatescreens/OrderInWriting";
import { ComplianceManagerDashboard } from "./pages/ComplianceManager";
import UploadOrderInWriting from "./pages/privatescreens/OrderInWriting/UploadOrderInWriting";
import AllOrgOIW from "./pages/SuperAdmin/AggregateOIW/AllOrgOIW";
import SuperadminReminders from "./pages/SuperAdmin/SuperadminReminders";
import SelectLongClauseBank from "./pages/privatescreens/CompanyPolicy/SelectLongClauseBank";
import SelectShortClauseBank from "./pages/privatescreens/CompanyPolicy/SelectShortClauseBank";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoute>
            <LoginDashboard />
          </PublicRoute>
        }
      />

      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/home" element={<PrivateScreen />}>
        <Route
          path="/home/compliance-manager-dashboard"
          element={<ComplianceManagerDashboard />}
        />
        <Route path="/home/upload" element={<Upload />} />
        <Route path="/home/registration" element={<Registration />} />
        <Route path="/home/reminders" element={<AdminReminders />} />
        <Route
          path="/home/karnataka-registration"
          element={<KarnatakaRegistration />}
        />
        <Route path="/home/pune-registration" element={<PuneRegistration />} />

        <Route
          path="/home/mumbai-city-registration"
          element={<MumbaiCityRegistration />}
        />
        <Route
          path="/home/mumbai-suburban-registration"
          element={<MumbaiSuburbanRegistration />}
        />
        <Route
          path="/home/telangana-registration"
          element={<TelanganaRegistration />}
        />
        <Route
          path="/home/upload-registration"
          element={<UploadRegistration />}
        />
        <Route
          path="/home/upload-registration-by-courier"
          element={<UploadRegistrationByCourier />}
        />
        <Route
          path="/home/upload-registration-by-hand"
          element={<UploadRegistrationByHand />}
        />

        <Route
          path="/home/upload-company-policy"
          element={<UploadCompanyPolicy />}
        />
        <Route
          path="/home/upload-order-in-writing"
          element={<UploadOrderInWriting />}
        />

        <Route
          path="/home/dashboard"
          element={
            <Protected roles={["COMPANY_ADMIN", "SIGNING_AUTHORITY"]}>
              <Dashboard />
            </Protected>
          }
        />
        <Route
          path="/home/locations"
          element={
            <Protected roles={["COMPANY_ADMIN"]}>
              <AllLocations />
            </Protected>
          }
        />
        <Route
          path="/home/poc"
          element={
            <Protected roles={["COMPANY_ADMIN"]}>
              <POC />
            </Protected>
          }
        />
        <Route
          path="/home/internal-committee"
          element={
            <Protected
              roles={[
                "SUPER_ADMIN",
                "COMPANY_ADMIN",
                "COMPLIANCE_MANAGER",
                "SIGNING_AUTHORITY",
              ]}
            >
              <LocationsList />
            </Protected>
          }
        />
        <Route
          path="/home/internal-committee/:id"
          element={
            <Protected
              roles={[
                "SUPER_ADMIN",
                "COMPANY_ADMIN",
                "COMPLIANCE_MANAGER",
                "SIGNING_AUTHORITY",
              ]}
            >
              <InternalCommitee />
            </Protected>
          }
        />
        <Route
          path="/home/internal-commitee/add/:id"
          element={
            <Protected
              roles={[
                "SUPER_ADMIN",
                "COMPANY_ADMIN",
                "COMPLIANCE_MANAGER",
                "SIGNING_AUTHORITY",
              ]}
            >
              <AddICMember />{" "}
            </Protected>
          }
        />
        <Route
          path="/home/internal-commitee/edit/:id"
          element={
            <Protected
              roles={[
                "SUPER_ADMIN",
                "COMPANY_ADMIN",
                "COMPLIANCE_MANAGER",
                "SIGNING_AUTHORITY",
              ]}
            >
              <EditICMember />
            </Protected>
          }
        />
        <Route
          path="/home/company-profile"
          element={
            <Protected
              roles={[
                "SUPER_ADMIN",
                "COMPANY_ADMIN",
                "COMPLIANCE_MANAGER",
                "SIGNING_AUTHORITY",
              ]}
            >
              <CompanyProfile />
            </Protected>
          }
        />
        <Route
          path="/home/employee-manager"
          element={
            <Protected
              roles={["SUPER_ADMIN", "COMPANY_ADMIN", "SIGNING_AUTHORITY"]}
            >
              <EmployeeManager />
            </Protected>
          }
        />
        <Route
          path="/home/company-profile/company/edit"
          element={
            <Protected
              roles={["SUPER_ADMIN", "COMPANY_ADMIN", "SIGNING_AUTHORITY"]}
            >
              <CompanyEdit />
            </Protected>
          }
        />
        <Route
          path="/home/company-profile/contact/add"
          element={
            <Protected
              roles={["SUPER_ADMIN", "COMPANY_ADMIN", "SIGNING_AUTHORITY"]}
            >
              <AddContacts />
            </Protected>
          }
        />
        <Route
          path="/home/company-profile/location/add"
          element={
            <Protected
              roles={["SUPER_ADMIN", "COMPANY_ADMIN", "SIGNING_AUTHORITY"]}
            >
              <AddLocation />
            </Protected>
          }
        />
        <Route
          path="/home/company-profile/contact/edit/:id"
          element={
            <Protected
              roles={["SUPER_ADMIN", "COMPANY_ADMIN", "SIGNING_AUTHORITY"]}
            >
              <EditContact />
            </Protected>
          }
        />
        <Route
          path="/home/company-profile/location/edit/:id"
          element={
            <Protected
              roles={["SUPER_ADMIN", "COMPANY_ADMIN", "SIGNING_AUTHORITY"]}
            >
              <EditLocation />
            </Protected>
          }
        />
        <Route
          path="/home/training"
          element={
            <Protected
              roles={[
                "COMPANY_ADMIN",
                "COMPLIANCE_MANAGER",
                "SIGNING_AUTHORITY",
              ]}
            >
              <AllLocTraining />
            </Protected>
          }
        />
        <Route
          path="/home/training/:id"
          element={
            <Protected
              roles={[
                "SUPER_ADMIN",
                "COMPANY_ADMIN",
                "COMPLIANCE_MANAGER",
                "SIGNING_AUTHORITY",
              ]}
            >
              <Trainings />
            </Protected>
          }
        />
        <Route
          path="/home/training/edit/:id"
          element={
            <Protected
              roles={[
                "SUPER_ADMIN",
                "COMPANY_ADMIN",
                "COMPLIANCE_MANAGER",
                "SIGNING_AUTHORITY",
              ]}
            >
              <TrainingEdit />
            </Protected>
          }
        />
        <Route
          path="/home/training/add"
          element={
            <Protected
              roles={[
                "SUPER_ADMIN",
                "COMPANY_ADMIN",
                "COMPLIANCE_MANAGER",
                "SIGNING_AUTHORITY",
              ]}
            >
              <TrainingAdd />
            </Protected>
          }
        />
        <Route
          path="/home/training/add-initative"
          element={
            <Protected
              roles={[
                "SUPER_ADMIN",
                "COMPANY_ADMIN",
                "COMPLIANCE_MANAGER",
                "SIGNING_AUTHORITY",
              ]}
            >
              <InitativeTrainingAdd />
            </Protected>
          }
        />
        <Route
          path="/home/edit-employee-manager/:id"
          element={
            <Protected
              roles={[
                "SUPER_ADMIN",
                "COMPANY_ADMIN",
                "COMPLIANCE_MANAGER",
                "SIGNING_AUTHORITY",
              ]}
            >
              <EditEmployeeManager />
            </Protected>
          }
        />
        <Route
          path="/home/policy"
          element={
            <Protected roles={["COMPANY_ADMIN"]}>
              <SelectPolicyPage />
            </Protected>
          }
        />
        <Route
          path="/home/clause-bank-swo"
          element={
            <Protected roles={["COMPANY_ADMIN"]}>
              <SelectShortClauseBank />
            </Protected>
          }
        />
        <Route
          path="/home/clause-bank-wo"
          element={
            <Protected roles={["COMPANY_ADMIN"]}>
              <SelectLongClauseBank />
            </Protected>
          }
        />
        <Route
          path="/home/clause-bank-gn"
          element={
            <Protected roles={["COMPANY_ADMIN"]}>
              <SelectLongClauseBank source="gender_neutral" />
            </Protected>
          }
        />
        <Route
          path="/home/clause-bank-sgn"
          element={
            <Protected roles={["COMPANY_ADMIN"]}>
              <SelectShortClauseBank source="short_gender_neutral" />
            </Protected>
          }
        />
        <Route
          path="/home/training/edit-initative/:id"
          element={
            <Protected
              roles={[
                "SUPER_ADMIN",
                "COMPANY_ADMIN",
                "COMPLIANCE_MANAGER",
                "SIGNING_AUTHORITY",
              ]}
            >
              <EditInitativeTraining />
            </Protected>
          }
        />
        <Route
          path="/home/employee-manager-list"
          element={
            <Protected roles={["SUPER_ADMIN", "COMPANY_ADMIN"]}>
              <EmployeeManagerList />
            </Protected>
          }
        />
        <Route
          path="/home/add-employee-manager"
          element={
            <Protected roles={["SUPER_ADMIN", "COMPANY_ADMIN"]}>
              <AddEmployee />
            </Protected>
          }
        />
        <Route
          path="/home/annual-report"
          element={
            <Protected roles={["COMPANY_ADMIN"]}>
              <AllLocAR />
            </Protected>
          }
        />
        <Route
          path="/home/annual-report/:id"
          element={
            <Protected roles={["COMPANY_ADMIN"]}>
              <AnnualReportPage />
            </Protected>
          }
        />
        <Route
          path="/home/order-in-writing"
          element={
            <Protected roles={["COMPANY_ADMIN", "SIGNING_AUTHORITY"]}>
              <SelectAuthority />
            </Protected>
          }
        />
        <Route
          path="/home/archives/internal-committee"
          element={
            <Protected roles={["COMPANY_ADMIN", "SIGNING_AUTHORITY"]}>
              <IcArchives />
            </Protected>
          }
        />
        <Route
          path="/home/archives/order-in-writing"
          element={
            <Protected roles={["COMPANY_ADMIN", "SIGNING_AUTHORITY"]}>
              <OiwArchives />
            </Protected>
          }
        />
        <Route
          path="/home/archives/company-policy"
          element={
            <Protected roles={["COMPANY_ADMIN", "SIGNING_AUTHORITY"]}>
              <CompanyPolicyArchive />
            </Protected>
          }
        />
      </Route>

      <Route
        path="/superadmin"
        element={
          <Protected roles={["SUPER_ADMIN"]}>
            <Main />
          </Protected>
        }
      >
        <Route
          path="/superadmin/org-details"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <OrganizationDetails />
            </Protected>
          }
        />
        <Route
          path="/superadmin/reminders"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <SuperadminReminders />
            </Protected>
          }
        />
        <Route
          path="/superadmin/dashboard"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <SuperAdminDashboard />
            </Protected>
          }
        />
        <Route
          path="/superadmin/aggregate-org"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <AggregateOrg />
            </Protected>
          }
        />
        <Route
          path="/superadmin/company/:id"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <CompanyDetails />
            </Protected>
          }
        />
        <Route
          path="/superadmin/ic"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <AllOrgIC />
            </Protected>
          }
        />
        <Route
          path="/superadmin/ic/:id"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <SingleOrgIC />
            </Protected>
          }
        />
        <Route
          path="/superadmin/ic/location/:id"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <SingleLocIC />
            </Protected>
          }
        />
        <Route
          path="/superadmin/ic/edit/:id"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <EditICMember />
            </Protected>
          }
        />
        <Route
          path="/superadmin/training"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <AllOrgTraining />
            </Protected>
          }
        />
        <Route
          path="/superadmin/training/:id"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <SingleOrgTraining />
            </Protected>
          }
        />
        <Route
          path="/superadmin/training/locations/:id"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <SingleLocTraining />
            </Protected>
          }
        />
        <Route
          path="/superadmin/annual-report"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <AllOrgAR />
            </Protected>
          }
        />
        <Route
          path="/superadmin/annual-report/:id"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <SingleOrgAR />
            </Protected>
          }
        />
        <Route
          path="/superadmin/annual-report/location/:id"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <AnnualReportPage />
            </Protected>
          }
        />
        <Route
          path="/superadmin/registration"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <AllOrgReg />
            </Protected>
          }
        />
        <Route
          path="/superadmin/registration/:id"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <SingleOrgRegistration />
            </Protected>
          }
        />
        <Route
          path="/superadmin/company-policy"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <AllOrgPolicy />
            </Protected>
          }
        />
        <Route
          path="/superadmin/order-in-writing"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <AllOrgOIW />
            </Protected>
          }
        />
        <Route
          path="/superadmin/archives"
          element={
            <Protected roles={["SUPER_ADMIN"]}>
              <Archives />
            </Protected>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
