import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { InputField2 } from "../Form";
import { DownloadButton } from "../Buttons";

const ReasonRejection = ({
  open,
  name,
  onClose = () => null,
  id,
  updateApi,
  loading,
  orgId = null,
  locId = null,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    const obj = {};
    obj.rejection_reason = data.rejection_reason;
    obj.id = id;
    obj.status = "REJECT";
    obj.org_id = orgId;

    if (locId !== null) {
      obj.loc_id = locId;
    }
    updateApi(obj);
  };
  React.useEffect(() => {
    if (loading) {
      onClose();
    }
  }, [loading]);
  const validations = {
    required: "This field is required",
  };
  return (
    <Dialog open={open}>
      <Box>
        <DialogTitle>Do you want to reject?</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField2
              label="Reason for rejection"
              placeholder={"Reason for rejection"}
              name={name}
              control={control}
              validations={validations}
              defaultValue=""
              error={errors?.name?.message}
            />

            <DialogActions>
              <Stack direction={"row"} marginLeft={10} marginTop={2}>
                <DialogActions>
                  <DownloadButton
                    label="Submit"
                    loading={loading}
                    styles={{ width: "80px" }}
                  />
                  <Button onClick={onClose} variant="outlined">
                    Cancel
                  </Button>
                </DialogActions>
              </Stack>
            </DialogActions>
          </form>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ReasonRejection;
