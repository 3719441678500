import React, { useContext } from "react";
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { ColorModeContext } from "../../constants/theme";
import { states } from "../../utils/states";
import { FilterButton, SearchButton } from "../Buttons";
import { useGetLocAnnualReportMappedQuery } from "../../store/annualReport/annualReportApi";
import { TextCell, HeaderCell } from "../DataGrid";
import useDebouncedSearch from "../../utils/hooks/useDebounceSearch";
import { getKey } from "../../utils/getKey";

function SingleOrgARTable({
  color = "#EEF8FF",
  ID = "",
  boxClass = ".superadmin-data-grid-div",
  source = "superadmin",
}) {
  const navigate = useNavigate();
  const { mode } = useContext(ColorModeContext);
  const [rows, setRows] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [selectedStatus, setSelectedStatus] = React.useState("all");
  const [params, setParams] = React.useState({});
  const { searchText, debouncedResults } = useDebouncedSearch();
  const { data: locData, isFetching } = useGetLocAnnualReportMappedQuery({
    ...paginationModel,
    ...searchText,
    ...params,
    status: selectedStatus,
    orgId: ID,
  });
  const [rowCountState, setRowCountState] = React.useState(locData?.count || 0);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    let temp = [];
    if (locData?.locations) {
      temp = locData?.locations?.map((loc) => {
        return {
          id: loc?.id,
          Location: loc?.location_name || "",
          Status: loc?.status,
        };
      });
    }
    setRows(temp || []);
  }, [locData]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      locData?.count ? locData?.count : prevRowCountState,
    );
  }, [locData?.count, setRowCountState]);

  const { handleSubmit, control, watch, resetField } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
  });
  const selectedState = watch("state");

  React.useEffect(() => {
    resetField("city");
  }, [selectedState]);

  const columns = [
    {
      field: "Location",
      flex: 1,
      renderHeader: () => <HeaderCell text="Location" paddingLeft="16px" />,
      renderCell: (params) => (
        <TextCell
          text={params.row.Location}
          justifyContent="flex-start"
          paddingLeft="16px"
        />
      ),
    },
    {
      field: "Status",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => <TextCell text={params.row.Status} />,
    },
    {
      field: "Actions",
      headerAlign: "right",
      flex: 1,
      renderHeader: () => <HeaderCell text="Actions" paddingRight="16px" />,
      renderCell: (params) => {
        return (
          <Stack
            direction={"row"}
            columnGap={2}
            flex={1}
            justifyContent={"flex-end"}
            alignItems={"center"}
            paddingRight="16px"
          >
            <Button
              sx={{
                backgroundColor: "#4B4B4B",
                fontSize: "12px",
                height: "25px",
                paddingX: 1,
                paddingY: 1,
              }}
              variant="contained"
              onClick={() => {
                if (source === "superadmin") {
                  navigate(
                    `/superadmin/annual-report/location/${params.row.id}`,
                  );
                } else {
                  navigate(`/home/annual-report/${params.row.id}`);
                }
              }}
            >
              Take Action
            </Button>
          </Stack>
        );
      },
    },
  ];

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const onSubmit = (data) => {
    const params = {
      location_category: data.location_category,
    };
    if (data.state !== "select") {
      params.state = data.state;
    }
    if (data.city && data.city !== "select") {
      params.city = data.city;
    }
    setParams(params);
  };

  return (
    <Box
      display={"flex"}
      flex={1}
      flexDirection={"column"}
      gap={4}
      className={boxClass}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          padding={"26px"}
          bgcolor={mode === "dark" ? "#151515" : color}
          width={"100%"}
          className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 tw-gap-5"
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={2}
          >
            <InputLabel>State</InputLabel>
            <Controller
              name={"state"}
              control={control}
              defaultValue={"select"}
              render={({ field: { value, onChange, ref } }) => (
                <Select
                  sx={{ height: "40px", flex: 1 }}
                  className="tw-border-black  tw-rounded-lg"
                  value={value}
                  inputRef={ref}
                  onChange={onChange}
                >
                  <MenuItem value={"select"}>Select</MenuItem>
                  {Object.keys(states).map((s, i) => (
                    <MenuItem key={getKey(i)} value={s}>
                      {s}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={2}
          >
            <InputLabel>District</InputLabel>
            <Controller
              name={"city"}
              control={control}
              defaultValue={"select"}
              render={({ field: { value, onChange, ref } }) => (
                <Select
                  sx={{ height: "40px", flex: 1 }}
                  value={value}
                  inputRef={ref}
                  onChange={onChange}
                  className="tw-border-black  tw-rounded-lg"
                >
                  <MenuItem value={"select"}>Select</MenuItem>
                  {states[selectedState]?.map((c, i) => {
                    return (
                      <MenuItem key={getKey(i)} value={c}>
                        {c}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <SearchButton />
          </Box>
        </Box>
      </form>
      <Stack direction="column" gap={2}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <TextField
            type="text"
            name="search"
            onChange={debouncedResults}
            sx={{
              width: "302px",
              "& .MuiInputBase-root": {
                height: "32px",
              },
            }}
            placeholder="Search By Location Name"
          />
          <FilterButton
            buttonStyles={{ height: "32px" }}
            onClick={() => setOpen(!open)}
          />
        </Stack>
        <Collapse in={open} timeout="auto" unmountOnExit width="100%">
          <RadioGroup
            row
            name="status"
            value={selectedStatus}
            onChange={handleStatusChange}
            sx={{ justifyContent: "flex-end" }}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel
              value="pending"
              control={<Radio />}
              label="Pending"
            />
            <FormControlLabel
              value="completed"
              control={<Radio />}
              label="Completed"
            />
          </RadioGroup>
        </Collapse>
      </Stack>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isFetching}
        disableRowSelectionOnClick
        pageSizeOptions={[5]}
        rowCount={rowCountState}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            background: `${mode === "dark" ? " #181818" : color}`,
          },
          "& .MuiDataGrid-toolbarContainer": {
            paddingY: 2,
            paddingX: 1,
          },

          "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
            backgroundColor: "transparent",
            color: "#4B4B4B",
          },
        }}
      />
    </Box>
  );
}

export default SingleOrgARTable;
