import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function ProcedureForFilingAnAppeal({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {serialNo}. Procedure For Filing An Appeal
      </Text>
      <Text style={styles.text}>
        Any party aggrieved (Complainant or Respondent) by the recommendations
        of the IC and / or nonimplementation of recommendations may file an
        appeal to the Appellate Authority as per service rules and/or as per
        other provisions of Law within 90 (ninety) days of the recommendations.
      </Text>
    </View>
  );
}

export default ProcedureForFilingAnAppeal;
