import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { tableData } from "./AdditionICMembers";
import { tableStyles } from "../../PDFStyling/OIW/OiwStyle";

const RemovalICMembers = () => {
  return (
    <View>
      <Text style={tableStyles.title}>Removal of IC members</Text>
      <View style={tableStyles.table}>
        <View style={tableStyles.tableRow}>
          <Text style={tableStyles.headerCell}>Sr. No.</Text>
          <Text style={tableStyles.headerCell}>Name</Text>
          <Text style={tableStyles.headerCell}>Designation </Text>
          <Text style={tableStyles.headerCell}>Email ID</Text>
          <Text style={tableStyles.headerCell}>End date in the IC</Text>
        </View>
        {tableData.map((row, indx) => (
          <View key={row.id} style={tableStyles.tableRow}>
            <Text style={tableStyles.cell}>{indx + 1}</Text>
            <Text style={tableStyles.cell}>{row.name}</Text>
            <Text style={tableStyles.cell}>{row.designation}</Text>
            <Text style={tableStyles.cell}>{row.email}</Text>
            <Text style={tableStyles.cell}>{row.dateInIc}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

export default RemovalICMembers;
