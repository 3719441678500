import {
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

function InputField2({
  label,
  placeholder,
  name,
  sm,
  md,
  lg,
  validations,
  error,
  control,
  type = "text",
  classes = "",
  inputProps = {},
  ...rest
}) {
  const TabScreen = useMediaQuery("(min-width: 640px)");
  const BigScreen = useMediaQuery("(min-width: 1500px)");

  const height = () => {
    if (TabScreen) {
      if (md) {
        return md;
      } else {
        return "56px";
      }
    }
    if (BigScreen) {
      if (lg) {
        return lg;
      } else {
        return "56px";
      }
    }
    if (sm) {
      return sm;
    } else {
      return "56px";
    }
  };

  return (
    <div className="tw-gap-2 tw-flex tw-flex-col">
      <InputLabel
        className="tw-text-xs sm:tw-text-sm tw-w-min tw-py-1"
        htmlFor="orgName"
      >
        <Typography>{label}</Typography>
      </InputLabel>
      <Controller
        name={name}
        control={control}
        rules={validations}
        {...rest}
        render={({ field: { value, onChange, ref } }) => (
          <TextField
            className={`tw-border-black ${classes}`}
            value={value}
            onChange={onChange}
            inputRef={ref}
            type={type}
            sx={{
              "& .MuiInputBase-root": {
                height: height() || "56px",
              },
            }}
            placeholder={placeholder}
            {...inputProps}
          />
        )}
      />
      {error && <span className="tw-text-red-700 tw-text-xs">{error}</span>}
    </div>
  );
}

export default InputField2;
