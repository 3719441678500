import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function ProtectionAgainstRetaliation({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {serialNo}. PROTECTION AGAINST RETALIATION
      </Text>
      <Text style={styles.text}>
        There will be no retaliation against anyone who, in good faith, files a
        complaint or participates in any way in the inquiry of a complaint.
        Organization will not allow retaliation against that person. Individuals
        engaging in retaliatory conduct will be subject to strict disciplinary
        action and if necessary legal action and/or a police complaint. If you
        suspect that you or someone you know has been retaliated against for
        raising an issue, immediately contact the IC.
      </Text>
    </View>
  );
}

export default ProtectionAgainstRetaliation;
