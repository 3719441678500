import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Paper,
  IconButton,
  styled,
  Box,
  CircularProgress,
} from "@mui/material";

import {
  useGetContactsQuery,
  useGetLocationsQuery,
  useGetOrgDetailsQuery,
} from "../../store/org/orgApi";
import { useSelector } from "react-redux";
import CompanyProfileLogo from "../../assets/logos/CompanyProfileLogo.svg";
import EditIcon from "../../assets/icons/EditIcon.svg";
import DarkEditIcon from "../../assets/icons/DarkEditIcon.svg";
import { ContainerLayout } from "../../components/Layouts";
import { ColorModeContext } from "../../constants/theme";

/* eslint-disable no-unused-vars */
const CompanyProfileStyle = styled(Box)(({ theme }) => ({
  ".organizationDetailsContainer": {
    padding: "2rem",
  },
}));
/* eslint-enable no-unused-vars */

function CompanyProfile() {
  const { mode } = useContext(ColorModeContext);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth?.user);
  useGetContactsQuery();
  useGetLocationsQuery();
  const { isFetching } = useGetOrgDetailsQuery(user?.org_id);
  const orgInfo = useSelector((state) => state.org?.orgInfo);

  return (
    <ContainerLayout>
      <CompanyProfileStyle>
        <Container
          sx={{
            height: "auto",
            width: "100%",
            maxWidth: "1300px",
            margin: 0,
            display: "flex",
            flexDirection: "column",
            gap: "50px",
          }}
        >
          <Typography
            className="tw-text-2xl tw-font-semibold"
            sx={{
              color: `${mode === "dark" ? "#FFFFFF" : " #000000"}`,
            }}
          >
            <img
              src={CompanyProfileLogo}
              style={{ width: "20px", marginRight: "40px" }}
            />
            Company Profile
          </Typography>
          <Paper
            className="organizationDetailsContainer"
            sx={{
              background: `${mode === "dark" ? "#1A1A1A" : " #FFFFF6"}`,
            }}
          >
            <Box className="tw-flex tw-justify-between">
              <Typography className="tw-font-semibold heading3 tw-mb-3 sm:tw-mb-8">
                Organization details
              </Typography>
              <IconButton
                onClick={() =>
                  navigate("/home/company-profile/company/edit", {
                    state: orgInfo,
                  })
                }
              >
                {mode === "dark" ? (
                  <img src={DarkEditIcon} />
                ) : (
                  <img src={EditIcon} />
                )}
              </IconButton>
            </Box>
            {isFetching ? (
              <CircularProgress />
            ) : (
              <>
                <Grid container columnSpacing={4} rowSpacing={2}>
                  <Grid item xs={12} sm={6} className="">
                    <Box className="tw-flex tw-space-x-2 sm:tw-space-x-4 tw-items-center">
                      <Typography className="tw-text-slate-400 tw-flex-[0.5] tw-text-start">
                        Organization Name:
                      </Typography>
                      <Typography className="tw-flex-[0.5] tw-text-start">
                        {orgInfo?.name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} className="">
                    <Box className="tw-flex tw-space-x-2 sm:tw-space-x-4 tw-items-center">
                      <Typography className="tw-text-slate-400 tw-flex-[0.5] tw-text-start">
                        Registration Type:
                      </Typography>
                      <Typography className="tw-flex-[0.5] tw-text-start">
                        Reg type
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} className="">
                    <Box className="tw-flex tw-space-x-2 sm:tw-space-x-4 tw-items-center">
                      <Typography className="tw-text-slate-400 tw-flex-[0.5] tw-text-start">
                        Date of Incorporation:
                      </Typography>
                      <Typography className="tw-flex-[0.5] tw-text-start">
                        {new Date(
                          orgInfo?.establishment_date,
                        )?.toLocaleDateString()}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} className="">
                    <Box className=" tw-flex tw-space-x-2 sm:tw-space-x-4 tw-items-center">
                      <Typography className="tw-text-slate-400 tw-flex-[0.5] tw-text-start">
                        Website:
                      </Typography>
                      <Typography className="tw-flex-[0.5] tw-text-start">
                        {orgInfo?.website}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} className="">
                    <Box className="tw-flex tw-space-x-2 sm:tw-space-x-4 tw-items-center">
                      <Typography className="tw-text-slate-400 tw-flex-[0.5] tw-text-start">
                        No of employees:
                      </Typography>
                      <Typography className="tw-flex-[0.5] tw-text-start">
                        {orgInfo.no_of_employee}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} className="">
                    <Box className=" tw-flex tw-space-x-2 sm:tw-space-x-4 tw-items-center">
                      <Typography className="tw-text-slate-400 tw-flex-[0.5] tw-text-start">
                        Gender Ratio:
                      </Typography>
                      <Typography className="tw-flex-[0.5] tw-text-start">
                        {orgInfo.gender_ratio}&#37;
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box className="tw-flex tw-items-start  tw-mt-3 tw-space-x-2 sm:tw-space-x-0">
                  <Typography className="tw-text-slate-400 tw-flex-[0.5] sm:tw-flex-[0.25] tw-text-start">
                    Address:
                  </Typography>
                  <Typography className="tw-flex-[0.5] sm:tw-flex-[0.75] tw-text-start">
                    {orgInfo?.address_line_1 || orgInfo?.city}
                  </Typography>
                </Box>
              </>
            )}
          </Paper>
        </Container>
      </CompanyProfileStyle>
    </ContainerLayout>
  );
}

export default CompanyProfile;
