import React, { useContext } from "react";
import {
  Typography,
  IconButton,
  Box,
  Stack,
  InputLabel,
  Button,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import POCLogo from "../../assets/logos/POCLogo.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import { ColorModeContext } from "../../constants/theme";
import { Controller, useForm } from "react-hook-form";
import { DataGrid } from "@mui/x-data-grid";
import {
  useAddPOCMutation,
  useGetContactsQuery,
  useRemovePOCMutation,
} from "../../store/org/orgApi";
import { useGetAllPocTypesQuery } from "../../store/dropdown/dropdownApi";
import Swal from "sweetalert2";
import { GridColumns } from "../../utils/gridColumns";
import { GridActionsLayout } from "../../components";

function POC() {
  const { mode } = useContext(ColorModeContext);
  const { user } = useSelector((state) => state?.auth?.user);
  const [rows, setRows] = React.useState([]);
  useGetAllPocTypesQuery();
  const { dropdowns } = useSelector((state) => state.dropdown);
  const { data: employees } = useGetContactsQuery({
    org_id: user?.org_id,
    source: "not_poc",
  });
  const { data, isFetching, isSuccess } = useGetContactsQuery({
    org_id: user?.org_id,
    source: "poc",
  });
  const [addPOC, { isSuccess: pocAdded, isLoading: addingPOC }] =
    useAddPOCMutation();
  const [removePOC, { isSuccess: pocRemoved }] = useRemovePOCMutation();

  React.useEffect(() => {
    if (pocRemoved) {
      Swal.fire({
        title: "Removed",
        text: "POC has been removed",
        icon: "success",
        timer: 1500,
      });
    }
  }, [pocRemoved]);

  React.useEffect(() => {
    if (pocAdded) {
      Swal.fire({
        title: "Added",
        text: "POC has been added",
        icon: "success",
        timer: 1500,
      });
    }
  }, [pocAdded]);

  React.useEffect(() => {
    let tempRows = [];
    if (isSuccess) {
      tempRows = data?.employee?.map((emp) => {
        return {
          id: emp.id,
          "POC Type": emp.poc?.name,
          Name: emp.fullName || "N/A",
          Email: emp.email || "N/A",
          "Phone number": emp.phone_number || "N/A",
        };
      });
    }
    setRows(tempRows || []);
  }, [data, isSuccess]);

  const { handleSubmit, control } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
  });

  const handleRemovePOC = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove POC?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        removePOC({ employee_id: id });
      }
    });
  };

  const columns = [
    ...new GridColumns()
      .pocType()
      .name()
      .email()
      .phone()
      .actions({
        renderCell: (params) => {
          return (
            <GridActionsLayout>
              <IconButton
                onClick={() => {
                  handleRemovePOC(params.row.id);
                }}
              >
                <ClearIcon />
              </IconButton>
            </GridActionsLayout>
          );
        },
      })
      .getCols(),
  ];

  const onSubmit = (data) => {
    console.log(data);
    addPOC(data);
  };

  const validations = {
    required: "Required",
    validate: (val) => {
      return val !== "select" || "Required";
    },
  };

  return (
    <Box
      className="tw-p-10 data-grid-div"
      sx={{
        background: `${mode === "dark" ? "#000000" : " #FFF"}`,
        height: "100vh",
        width: "100%",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        gap: "50px",
        padding: "40px",
      }}
    >
      <Stack
        direction={"row"}
        columnGap={4}
        justifyContent={"flex-start"}
        alignItems={"center"}
        marginBottom={2}
        marginTop={10}
      >
        <img src={POCLogo} alt="POC logo" />
        <Typography
          className="tw-font-semibold tw-text-4xl"
          sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
        >
          POC
        </Typography>
      </Stack>

      {/* Form to add poc */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction={"row"} justifyContent={"space-between"} gap={4}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={2}
            flex={1}
          >
            <InputLabel>Select Employee</InputLabel>
            <Controller
              name={"employee_id"}
              control={control}
              defaultValue={"select"}
              rules={validations}
              render={({ field: { onChange, ref } }) => (
                <Autocomplete
                  sx={{
                    width: "70%",
                    "& .MuiOutlinedInput-root": {
                      padding: "0 9px 0 9px",
                      borderRadius: "8px",
                    },
                  }}
                  disablePortal
                  onChange={(e, v) => onChange(v?.id)}
                  options={
                    employees?.employee?.map((emp) => {
                      return { label: emp.fullName || emp.email, id: emp.id };
                    }) || []
                  }
                  renderInput={(params) => (
                    <TextField
                      inputRef={ref}
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "40px",
                          padding: 0,
                          boxSizing: "border-box",
                        },
                      }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              )}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={2}
            flex={1}
          >
            <InputLabel>POC type</InputLabel>
            <Controller
              name={"poc_id"}
              control={control}
              defaultValue={"select"}
              rules={validations}
              render={({ field: { onChange, ref } }) => (
                <Autocomplete
                  sx={{
                    width: "70%",
                    "& .MuiOutlinedInput-root": {
                      padding: "0 9px 0 9px",
                      borderRadius: "8px",
                    },
                  }}
                  disablePortal
                  onChange={(e, v) => onChange(v?.id)}
                  options={
                    dropdowns?.POCTypes?.map((poc) => {
                      return { label: poc.name, id: poc.id };
                    }) || []
                  }
                  renderInput={(params) => (
                    <TextField
                      inputRef={ref}
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "40px",
                          padding: 0,
                          boxSizing: "border-box",
                        },
                      }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              )}
            />
          </Box>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: "#4B4B4B",
              borderRadius: "8px",
              color: "white",
              paddingX: 4,
              width: 150,
            }}
          >
            {addingPOC ? (
              <CircularProgress size={16} sx={{ color: "white" }} />
            ) : (
              "ADD POC"
            )}
          </Button>
        </Stack>
      </form>

      {/* POC List */}
      <Box height={"50vh"}>
        <DataGrid
          loading={isFetching}
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          pageSizeOptions={[5]}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: `${mode === "dark" ? " #181818" : "#FFFFF6"}`,
            },
            "& .MuiDataGrid-toolbarContainer": {
              paddingY: 2,
              paddingX: 1,
            },

            "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
              backgroundColor: "transparent",
              color: "#4B4B4B",
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default POC;
