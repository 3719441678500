import React, { useContext } from "react";
import { Box, Container, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Header } from "../../components/Superadmin";
import { SuperadminDrawer } from "../../components/Drawers";
import { Outlet } from "react-router-dom";
import { ColorModeContext } from "../../constants/theme";

function Main() {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const { mode } = useContext(ColorModeContext);

  return (
    <Box className="tw-min-h-screen tw-flex tw-flex-col">
      <Header />
      <Container className="tw-p-0 tw-max-w-[1600px] tw-flex tw-min-h-screen">
        <Box
          className="tw-w-10 sm:tw-w-20 tw-flex tw-flex-col tw-pt-10"
          sx={{ background: `${mode === "dark" ? "#000000" : " #F0F0F0"}` }}
        >
          <IconButton onClick={() => setSidebarOpen(true)}>
            <MenuIcon />
          </IconButton>
        </Box>
        <SuperadminDrawer open={sidebarOpen} setOpen={setSidebarOpen} />
        <Outlet />
      </Container>
    </Box>
  );
}

export default Main;
