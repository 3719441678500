import React from "react";
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import { StatusBar } from "../../../components/General";
import { ColorModeContext } from "../../../constants/theme";
import CompanyPolicyLogo from "../../../assets/logos/CompanyPolicyLogo.svg";
import {
  Clause,
  ClauseLayout1,
  ClauseNestedLayout1,
  DownloadButton,
  GenderNeutral,
  WomenOnly,
} from "../../../components";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  SHConcernsRedressal,
  actionDuringPendencyOfInquiry,
  amendments,
  availabilityOfMentalHealthProfessionals,
  confidentiality,
  externalComplainant,
  filingOfComplaintAgainstPolice,
  howToFileComplaint,
  procedureForHandlingComplaints,
  protectionAgainstRetaliation,
  responsibilitiesOfEmployees,
  responsibilitiesOfIC,
  responsibilitiesOfOrganization,
  restrictedVictim,
  whatIsInquiryReport,
  whatIsSexualHarassment,
  whatIsWorkPlace,
  whoCanFileComplaint,
  whoIsAnEmployee,
  whoIsVisitor,
} from "../../../utils/clauses/WomenOnly";
import { procedureForFilingAppeal } from "../../../utils/clauses/GenderNeutral";

function SelectLongClauseBank({ source = "women_only" }) {
  const { mode } = React.useContext(ColorModeContext);
  const [confirm, setConfirm] = React.useState(false);
  const clauses = {
    whatIsSexualHarassment: true,
    whatIsWorkPlace: true,
    whatIsAnEmployee: true,
    whatIsVisitor: true,
    SHConcernsRedressal: true,
    whoCanFileComplaint: true,
    restrictedVictim: true,
    howToFileComplaint: true,
    externalComplainant: true,
    processToHandleComplaint: true,
    actionDuringPendencyOfInquiry: true,
    whatIsInquiryReport: true,
    procedureForFilingAppeal: true,
    confidentiality: true,
    protectionAgainstRetaliation: true,
    filingOfComplaintAgainstPolice: true,
    availabilityOfMentalHealthProfessionals: true,
    responsibilitiesOfEmployees: true,
    responsibilitiesOfIC: true,
    responsibilitiesOfOrganization: true,
    amendments: true,
    clause1: false,
    clause2: false,
  };
  const handleClauseChange = (state, action) => {
    /*eslint indent: ["error", 2, { "SwitchCase": 1 }]*/
    switch (action.type) {
      case "whatIsSexualHarassment":
        return {
          ...state,
          whatIsSexualHarassment: !state.whatIsSexualHarassment,
        };
      case "whatIsWorkPlace":
        return { ...state, whatIsWorkPlace: !state.whatIsWorkPlace };
      case "whatIsAnEmployee":
        return { ...state, whatIsAnEmployee: !state.whatIsAnEmployee };
      case "whatIsVisitor":
        return {
          ...state,
          whatIsVisitor: !state.whatIsVisitor,
        };
      case "SHConcernsRedressal":
        return {
          ...state,
          SHConcernsRedressal: !state.SHConcernsRedressal,
        };
      case "whoCanFileComplaint":
        return { ...state, whoCanFileComplaint: !state.whoCanFileComplaint };
      case "restrictedVictim":
        return { ...state, restrictedVictim: !state.restrictedVictim };
      case "howToFileComplaint":
        return {
          ...state,
          howToFileComplaint: !state.howToFileComplaint,
        };
      case "externalComplainant":
        return { ...state, externalComplainant: !state.externalComplainant };
      case "processToHandleComplaint":
        return {
          ...state,
          processToHandleComplaint: !state.processToHandleComplaint,
        };
      case "actionDuringPendencyOfInquiry":
        return {
          ...state,
          actionDuringPendencyOfInquiry: !state.actionDuringPendencyOfInquiry,
        };
      case "whatIsInquiryReport":
        return { ...state, whatIsInquiryReport: !state.whatIsInquiryReport };
      case "procedureForFilingAppeal":
        return {
          ...state,
          procedureForFilingAppeal: !state.procedureForFilingAppeal,
        };
      case "confidentiality":
        return { ...state, confidentiality: !state.confidentiality };
      case "protectionAgainstRetaliation":
        return {
          ...state,
          protectionAgainstRetaliation: !state.protectionAgainstRetaliation,
        };
      case "filingOfComplaintAgainstPolice":
        return {
          ...state,
          filingOfComplaintAgainstPolice: !state.filingOfComplaintAgainstPolice,
        };
      case "availabilityOfMentalHealthProfessionals":
        return {
          ...state,
          availabilityOfMentalHealthProfessionals:
            !state.availabilityOfMentalHealthProfessionals,
        };
      case "responsibilitiesOfEmployees":
        return {
          ...state,
          responsibilitiesOfEmployees: !state.responsibilitiesOfEmployees,
        };
      case "responsibilitiesOfIC":
        return { ...state, responsibilitiesOfIC: !state.responsibilitiesOfIC };
      case "responsibilitiesOfOrganization":
        return {
          ...state,
          responsibilitiesOfOrganization: !state.responsibilitiesOfOrganization,
        };
      case "amendments":
        return { ...state, amendments: !state.amendments };
      case "clause1":
        return { ...state, clause1: !state.clause1 };
      case "clause2":
        return { ...state, clause2: !state.clause2 };
      default:
        return state;
    }
  };
  const [state, dispatch] = React.useReducer(handleClauseChange, clauses);

  return (
    <Box
      sx={{
        background: `${mode === "dark" ? "#000000" : " #FFF"}`,
        minHeight: "100vh",
        width: "100%",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        gap: "50px",
        padding: "40px",
      }}
    >
      <Stack
        direction={"row"}
        columnGap={4}
        justifyContent={"flex-start"}
        alignItems={"center"}
        marginBottom={2}
        marginTop={10}
      >
        <img
          src={CompanyPolicyLogo}
          height={"36px"}
          alt="Comapny Policy Logo"
        />
        <Typography
          className="tw-font-semibold tw-text-4xl"
          sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
        >
          Clause bank
        </Typography>
        <StatusBar label={"PENDING"} status={"PENDING"} />
      </Stack>
      <Typography
        sx={{ fontSize: "20px", fontWeight: 500, marginTop: -5 }}
        className="inter-font"
      >
        Select Clause to build your company policy
      </Typography>
      <Stack direction={"column"} gap={2}>
        <Clause
          heading={"1. What Is Sexual Harassment"}
          value={state.whatIsSexualHarassment}
          handleChange={() => dispatch({ type: "whatIsSexualHarassment" })}
        >
          <ClauseNestedLayout1 body={whatIsSexualHarassment} />
        </Clause>

        <Clause
          heading={"2. What Is Work Place"}
          value={state.whatIsWorkPlace}
          handleChange={() => dispatch({ type: "whatIsWorkPlace" })}
        >
          <ClauseNestedLayout1 body={whatIsWorkPlace} />
        </Clause>
        <Clause
          heading={"3. Who Is An Employee"}
          value={state.whatIsAnEmployee}
          handleChange={() => dispatch({ type: "whatIsAnEmployee" })}
        >
          <ClauseLayout1 body={whoIsAnEmployee} />
        </Clause>
        <Clause
          heading={"4. Who Is Visitor"}
          value={state.whatIsVisitor}
          handleChange={() => dispatch({ type: "whatIsVisitor" })}
        >
          <ClauseLayout1 body={whoIsVisitor} />
        </Clause>
        <Clause
          heading={
            "5. HOW ARE CONCERNS RELATED TO SEXUAL HARASSMENT REDRESSED?"
          }
          value={state.SHConcernsRedressal}
          handleChange={() => dispatch({ type: "SHConcernsRedressal" })}
        >
          <ClauseNestedLayout1 body={SHConcernsRedressal} />
        </Clause>
        <Clause
          heading={"6. WHO CAN FILE A COMPLAINT?"}
          value={state.whoCanFileComplaint}
          handleChange={() => dispatch({ type: "whoCanFileComplaint" })}
        >
          <ClauseLayout1 body={whoCanFileComplaint} />
        </Clause>
        <Clause
          heading={"7. WHAT IF AGGRIEVED PERSON IS UNABLE TO MAKE A COMPLAINT?"}
          value={state.restrictedVictim}
          handleChange={() => dispatch({ type: "restrictedVictim" })}
        >
          <ClauseNestedLayout1 body={restrictedVictim} />
        </Clause>
        <Clause
          heading={"8. HOW TO FILE A COMPLAINT?"}
          value={state.howToFileComplaint}
          handleChange={() => dispatch({ type: "howToFileComplaint" })}
        >
          <ClauseNestedLayout1 body={howToFileComplaint} />
        </Clause>
        <Clause
          heading={
            "9. WHAT IF COMPLAINT IS AGAINST SOMEONE WHO IS NOT AN EMPLOYEE OF THE ORGANIZATION?"
          }
          value={state.externalComplainant}
          handleChange={() => dispatch({ type: "externalComplainant" })}
        >
          <ClauseNestedLayout1 body={externalComplainant} />
        </Clause>
        <Clause
          heading={"10. WHAT IS THE PROCESS FOR HANDLING COMPLAINTS BY IC?"}
          value={state.processToHandleComplaint}
          handleChange={() => dispatch({ type: "processToHandleComplaint" })}
        >
          <ClauseNestedLayout1 body={procedureForHandlingComplaints} />
        </Clause>
        <Clause
          heading={"11. CAN ANY ACTION BE TAKEN DURING PENDENCY OF INQUIRY?"}
          value={state.actionDuringPendencyOfInquiry}
          handleChange={() =>
            dispatch({ type: "actionDuringPendencyOfInquiry" })
          }
        >
          <ClauseNestedLayout1 body={actionDuringPendencyOfInquiry} />
        </Clause>
        <Clause
          heading={
            "12. WHAT IS AN INQUIRY REPORT AND WHAT ACTIONS CAN BE SUGGESTED UNDER IT?"
          }
          value={state.whatIsInquiryReport}
          handleChange={() => dispatch({ type: "whatIsInquiryReport" })}
        >
          <ClauseNestedLayout1 body={whatIsInquiryReport} />
        </Clause>
        <Clause
          heading={"13. PROCEDURE FOR FILING AN APPEAL"}
          value={state.procedureForFilingAppeal}
          handleChange={() => dispatch({ type: "procedureForFilingAppeal" })}
        >
          <ClauseLayout1 body={procedureForFilingAppeal} />
        </Clause>

        {/* ******************** Timeline ********************* */}

        <Clause
          heading={"14. CONFIDENTIALITY"}
          value={state.confidentiality}
          handleChange={() => dispatch({ type: "confidentiality" })}
        >
          <ClauseLayout1 body={confidentiality} />
        </Clause>
        <Clause
          heading={"15. PROTECTION AGAINST RETALIATION"}
          value={state.protectionAgainstRetaliation}
          handleChange={() =>
            dispatch({ type: "protectionAgainstRetaliation" })
          }
        >
          <ClauseLayout1 body={protectionAgainstRetaliation} />
        </Clause>
        <Clause
          heading={"16. FILING OF COMPLAINT WITH POLICE"}
          value={state.filingOfComplaintAgainstPolice}
          handleChange={() =>
            dispatch({ type: "filingOfComplaintAgainstPolice" })
          }
        >
          <ClauseLayout1 body={filingOfComplaintAgainstPolice} />
        </Clause>
        <Clause
          heading={"17. AVAILABILITY OF MENTAL HEALTH PROFESSIONALS"}
          value={state.availabilityOfMentalHealthProfessionals}
          handleChange={() =>
            dispatch({ type: "availabilityOfMentalHealthProfessionals" })
          }
        >
          <ClauseLayout1 body={availabilityOfMentalHealthProfessionals} />
        </Clause>
        <Clause
          heading={"18. RESPONSIBILITIES OF EMPLOYEES"}
          value={state.responsibilitiesOfEmployees}
          handleChange={() => dispatch({ type: "responsibilitiesOfEmployees" })}
        >
          <ClauseNestedLayout1 body={responsibilitiesOfEmployees} />
        </Clause>
        <Clause
          heading={"19. RESPONSIBILITIES OF IC"}
          value={state.responsibilitiesOfIC}
          handleChange={() => dispatch({ type: "responsibilitiesOfIC" })}
        >
          <ClauseNestedLayout1 body={responsibilitiesOfIC} />
        </Clause>
        <Clause
          heading={"20. RESPONSIBILITIES OF ORGANIZATION"}
          value={state.responsibilitiesOfOrganization}
          handleChange={() =>
            dispatch({ type: "responsibilitiesOfOrganization" })
          }
        >
          <ClauseNestedLayout1 body={responsibilitiesOfOrganization} />
        </Clause>
        <Clause
          heading={"21. AMENDMENTS"}
          value={state.amendments}
          handleChange={() => dispatch({ type: "amendments" })}
        >
          <ClauseLayout1 body={amendments} />
        </Clause>
        <Clause
          heading={"22. Clause 1"}
          value={state.clause1}
          handleChange={() => dispatch({ type: "clause1" })}
        >
          <ClauseLayout1
            body={[
              `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolor rem porro quasi praesentium nam laboriosam quidem! Repellendus tempore recusandae labore hic, modi sed commodi. Nisi eligendi accusantium distinctio illo voluptates.`,
            ]}
          />
        </Clause>
        <Clause
          heading={"23. Clause 2"}
          value={state.clause2}
          handleChange={() => dispatch({ type: "clause2" })}
        >
          <ClauseLayout1
            body={[
              `Veritatis ipsam possimus neque? Animi, impedit iste quasi voluptate doloribus eveniet at inventore id quis pariatur rem ullam. Fuga placeat veritatis molestiae recusandae. Quo et adipisci odio! Placeat, nemo, cupiditate accusantium quia necessitatibus dolores pariatur inventore repudiandae facilis libero recusandae eos, debitis consequuntur nobis itaque quas!`,
            ]}
          />
        </Clause>
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        gap={3}
      >
        <Checkbox onChange={() => setConfirm(!confirm)} />
        <Typography>
          I Confirm that i had read and understood the clauses
        </Typography>
      </Stack>
      {confirm ? (
        <PDFDownloadLink
          document={
            source === "women_only" ? (
              <WomenOnly clauses={state} />
            ) : (
              <GenderNeutral clauses={state} />
            )
          }
          style={{ margin: "auto" }}
          fileName={
            source === "women_only" ? "WomenOnlyPolicy" : "GenderNeutralPolicy"
          }
        >
          <DownloadButton
            label="Download Company Policy"
            onClick={() => console.log(state)}
          />
        </PDFDownloadLink>
      ) : (
        <DownloadButton
          label="Download Company Policy"
          disabledValue={true}
          styles={{ margin: "auto" }}
          onClick={() => console.log(state)}
        />
      )}
    </Box>
  );
}

export default SelectLongClauseBank;
