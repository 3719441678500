import React from "react";
import { Box, Typography } from "@mui/material";
import { ColorModeContext } from "../../constants/theme";

function ReportCard({ heading = "", children }) {
  const { mode } = React.useContext(ColorModeContext);
  return (
    <Box
      sx={{
        boxShadow:
          "0px 0px 1px rgba(12, 14, 25, 0.24), 0px 2px 12px rgba(12, 14, 25, 0.12)",
        backgroundColor: mode === "dark" ? "#1A1A1A" : "#EFFFE5",
        paddingY: 6,
        paddingX: 8,
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      {heading && (
        <Typography
          color={mode === "dark" ? "white" : "#4B4B4B"}
          fontSize={"20px"}
          fontWeight={500}
        >
          {heading}
        </Typography>
      )}
      {children}
    </Box>
  );
}

export default React.memo(ReportCard);
