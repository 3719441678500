import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import ComplaintsLogo from "../../assets/logos/complaintsLogo.svg";
import AnnualReportLogo from "../../assets/logos/AnnulaReportLogo.svg";
import TrainingLogo from "../../assets/logos/TrainingLogo.svg";
import CompanyProfileLogo from "../../assets/logos/CompanyProfileLogo.svg";
import EmployeeManagerLogo from "../../assets/logos/EmployManagerLogo.svg";
import ComapnyPolicyLogo from "../../assets/logos/CompanyPolicyLogo.svg";
import RegistrationLogo from "../../assets/logos/RegistrationLogo.svg";
import OrderWritingLogo from "../../assets/logos/OrderWritingLogo.svg";
import { Link, useNavigate } from "react-router-dom";

function MobileDrawer({ open, setOpen }) {
  const navigate = useNavigate();
  const logoutHandler = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("mode");
    navigate("/");
    window.location.reload();
  };
  return (
    <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
      <List sx={{ marginTop: "57px" }}>
        <ListItem>
          <Link to="/home/dashboard">
            <ListItemButton>
              <ListItemIcon>
                <AutoAwesomeMosaicIcon
                  sx={{
                    color: "#0060A4",
                  }}
                />{" "}
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/home/internal-commitee">
            <ListItemButton>
              <ListItemIcon>
                <PeopleIcon
                  sx={{
                    color: "#FF3A91",
                  }}
                />{" "}
              </ListItemIcon>
              <ListItemText primary={"Internal Commitee"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem>
          <Link>
            <ListItemButton>
              <ListItemIcon>
                <img src={ComplaintsLogo} />
              </ListItemIcon>
              <ListItemText primary={"Complaints"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem>
          <Link>
            <ListItemButton>
              <ListItemIcon>
                <img src={AnnualReportLogo} />
              </ListItemIcon>
              <ListItemText primary={"Annual Report"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/home/training">
            <ListItemButton>
              <ListItemIcon>
                <img src={TrainingLogo} />
              </ListItemIcon>

              <ListItemText primary={"Trainings"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/home/company-profile">
            <ListItemButton>
              <ListItemIcon>
                <img src={CompanyProfileLogo} />
              </ListItemIcon>
              <ListItemText primary={"Company Profile"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/home/employee-manager">
            <ListItemButton>
              <ListItemIcon>
                <img src={EmployeeManagerLogo} />
              </ListItemIcon>
              <ListItemText primary={"Employee manager"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem>
          <Link>
            <ListItemButton>
              <ListItemIcon>
                <img src={ComapnyPolicyLogo} />
              </ListItemIcon>
              <ListItemText primary={"Company Policy"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/home/registration">
            <ListItemButton>
              <ListItemIcon>
                <img src={RegistrationLogo} />
              </ListItemIcon>
              <ListItemText primary={"Registration"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem>
          <Link>
            <ListItemButton>
              <ListItemIcon>
                <img src={OrderWritingLogo} />
              </ListItemIcon>
              <ListItemText primary={"Order in writing "} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem>
          <Link>
            <ListItemButton
              onClick={() => {
                logoutHandler();
              }}
            >
              <ListItemText primary={"Logout "} />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
    </Drawer>
  );
}

export default MobileDrawer;
