import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function ProcessToHandleComplaint({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {serialNo}. WHAT IS THE PROCESS FOR HANDLING COMPLAINTS BY IC?
      </Text>
      <Text style={styles.subHeading}>a) Process for Conciliation:</Text>
      <View style={styles.subContainer}>
        <Text style={styles.text}>
          Prior to initiating an inquiry, the IC may, at the written request of
          the Complainant take steps to settle the matter between the
          Complainant and the Respondent through conciliation. The following
          need to be kept in mind with respect to conciliation:
        </Text>
        <Text style={styles.point}>
          i) It is not mandatory for Complainant to request for Conciliation.
        </Text>
        <Text style={styles.point}>
          ii) Conciliation cannot be done if inquiry has already started.
        </Text>
        <Text style={styles.point}>
          iii) In case a settlement has been arrived at, the IC shall record it
          and forward it to Organization to take action as specified in the
          recommendation of the IC.
        </Text>
        <Text style={styles.point}>
          iv) The IC will also provide copies of the settlement as recorded to
          the Complainant and the Respondent.
        </Text>
        <Text style={styles.point}>
          v) If conciliation has been reached, the IC will not be required to
          conduct any further inquiry.
        </Text>
        <Text style={styles.point}>
          vi) Monetary settlement cannot be made the basis of such conciliation.
        </Text>
        <Text style={styles.point}>
          vii) If Complainant feels that the terms of Settlement are not being
          complied with by the Respondent or action has not been taken by
          Organization, Complainant can make a written complaint to the IC to
          conduct an inquiry into the complaint.
        </Text>
      </View>

      {/* Process for Conciliation */}
      <Text style={styles.subHeading}>b) Procedure for Inquiry:</Text>
      <View style={styles.subContainer}>
        <Text style={styles.text}>
          If the Complainant does not wish to go for conciliation or if the
          conciliation reached between the parties is not complied with, the IC
          shall, upon a written request from the Complainant to do so, proceed
          to make inquiry into the complaint in the manner as provided under the
          Service Rules and Law, as may be applicable to the Respondent and if
          no service rules are applicable, then as per the process prescribed
          under this Policy and the Law. The following need to be kept in mind
          with respect to inquiry:
        </Text>
        <Text style={styles.point}>
          i) On receipt of the complaint, the IC shall send copy of the
          complaint received from the Complainant to the Respondent within a
          period of 7 (seven) working days. If the complaint has been received
          on email, the IC member shall forward the same to the Respondent, with
          clear instructions that the same is not to be shared with anyone and
          strict confidentiality has to be maintained.
        </Text>
        <Text style={styles.point}>
          ii) The Respondent shall file the reply to the complaint along with
          list of documents, and names and addresses of witnesses (if any),
          within a period of 10 (ten) working days from the date of receipt of
          the documents.
        </Text>
        <Text style={styles.point}>
          iii) If the Complainant or the Respondent desires any witness/es to be
          called, they shall communicate in writing to IC the names of
          witness/es whom they propose to call.
        </Text>
        <Text style={styles.point}>
          iv) If the Complainant desires to tender any documents by way of
          evidence before IC, they shall supply copies of such documents.
          Similarly, if Respondent desires to tender any documents in evidence
          before IC they shall supply copies of such documents. Both shall affix
          their signature on the respective documents to certify these to be
          authentic documents.
        </Text>
        <Text style={styles.point}>
          v) The IC shall have the powers to summon and enforce the attendance
          of any person and conduct an examination, request the discovery and
          production of documents and / or any other matter which may be
          necessary for the inquiry process. Any refusal by any Employee of
          Organization to attend any hearing before the IC when summoned or to
          provide to the IC, any documents and / or information within their
          power or possession shall constitute as misconduct, rendering such
          Employee for adverse action by Organization.
        </Text>
        <Text style={styles.point}>
          vi) The IC shall have the right to terminate the inquiry proceedings
          or to give an exparte decision on the complaint, if the Complainant or
          Respondent fails, without sufficient cause, to present themselves, for
          3 (three) consecutive hearings convened by the Presiding Officer. Such
          termination or ex-parte order may not be passed without giving a
          notice in writing, 15 (fifteen) days in advance, to the Complainant or
          Respondent as the case maybe.
        </Text>
        <Text style={styles.point}>
          vii) In conducting the inquiry, at any point of time, the Complaint
          shall be heard by a minimum of 3 (three) members including the
          Presiding Officer and the External Member and IC shall complete the
          inquiry within reasonable period but not beyond 90 (ninety) days.
        </Text>
        <Text style={styles.point}>
          viii) IC shall notify in writing, the time and dates of its meetings
          to the Complainant, the Respondent and witnesses.
        </Text>
        <Text style={styles.point}>
          ix) All inquiry proceedings will be documented in detail and treated
          as strictly confidential with restricted viewing only on need to know
          basis.
        </Text>
        <Text style={styles.point}>
          x) Inquiry proceedings shall be minuted / recorded in writing. They
          may be audio recorded as well (where possible). The minutes /
          transcribed record of the proceedings and the statement of witnesses
          shall be endorsed by the persons concerned and IC in token of
          authenticity thereof.
        </Text>
        <Text style={styles.point}>
          xi) All colleagues and witnesses who are part of the IC proceedings
          will need to sign a confidentiality declaration.
        </Text>
        <Text style={styles.point}>
          xii) The Complainant and Respondent shall not be allowed to bring in
          any legal practitioner to represent them in their case at any stage of
          the proceedings before the IC.
        </Text>
      </View>

      {/* The things IC shall keep in mind while inquiring into a complaint: */}
      <Text style={styles.subHeading}>
        c) The things IC shall keep in mind while inquiring into a complaint:
      </Text>
      <View style={styles.subContainer}>
        <Text style={styles.point}>
          i) The IC shall make an inquiry into the complaint in accordance with
          the principles of natural justice such that IC shall provide every
          reasonable and fair opportunity to the Complainant as well as to the
          Respondent, for putting forward and defending their respective side.
        </Text>
        <Text style={styles.point}>
          ii) The IC shall be sensitive to the often covert, private and
          insidious nature of sexual harassment and shall take into account that
          often the Complainant may not be able to submit or present direct or
          corroborative evidence.
        </Text>
        <Text style={styles.point}>
          iii) The IC shall take note of the respective socio-economic positions
          of the parties, their hierarchy in the Workplace, and other power
          differentials while giving recommendation.
        </Text>
        <Text style={styles.point}>
          iv) While inquiring into a complaint, IC shall take note that in cases
          of harassment via postings and messages through electronic and social
          media, the IC shall consider the public humiliation that this involves
          for the Complainant as well as the unwitting exposure of others who
          are using this media to circulate such messages and images.
        </Text>
      </View>
    </View>
  );
}

export default ProcessToHandleComplaint;
