import React, { useContext } from "react";
import { Box, IconButton, Stack, TextField } from "@mui/material";
import { useGetAllOrgsQuery } from "../../../store/org/orgApi";
import EditIcon from "../../../assets/icons/EditIcon.svg";
import DarkEditIcon from "../../../assets/icons/DarkEditIcon.svg";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { ColorModeContext } from "../../../constants/theme";
import useDebouncedSearch from "../../../utils/hooks/useDebounceSearch";
import { GridColumns } from "../../../utils/gridColumns";
import { GridActionsLayout, ViewDetailsButton } from "../../../components";

function OrgTable() {
  const { mode } = useContext(ColorModeContext);
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const { searchText, debouncedResults } = useDebouncedSearch();
  const { data: orgData, isFetching } = useGetAllOrgsQuery({
    source: "aggregate org",
    ...paginationModel,
    ...searchText,
  });
  const [rows, setRows] = React.useState([]);
  const [rowCountState, setRowCountState] = React.useState(orgData?.count || 0);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      orgData?.count ? orgData?.count : prevRowCountState,
    );
  }, [orgData?.count, setRowCountState]);

  React.useEffect(() => {
    console.log(orgData);
    const temp = orgData?.org?.map((row) => ({
      id: row.id,
      "Client Code": row.client_code,
      Organization: row.name,
      "Priority Level": row.priority_level?.name,
      Industry: "Type",
      "Registration Type": row.registration?.name,
      District: row.city,
      State: row.state,
    }));
    setRows(temp || []);
  }, [orgData]);

  const columns = [
    ...new GridColumns()
      .clientCode()
      .org()
      .priorityLevel()
      .industry()
      .registrationType()
      .district()
      .state()
      .actions({
        renderCell: (params) => {
          return (
            <GridActionsLayout>
              <ViewDetailsButton
                handleClick={() => navigate(`/superadmin/company/${params.id}`)}
              />
              <IconButton>
                {mode === "dark" ? (
                  <img src={DarkEditIcon} />
                ) : (
                  <img src={EditIcon} />
                )}
              </IconButton>
            </GridActionsLayout>
          );
        },
      })
      .getCols(),
  ];

  return (
    <Box
      width={"100%"}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
      rowGap={4}
      // height={"100%"}
      className="superadmin-data-grid-div"
      sx={{
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: `${mode === "dark" ? "#151515" : "#EEF8FF"}`,
        },
        "& .MuiDataGrid-toolbarContainer": {
          paddingY: 2,
          // paddingX: 1,
        },

        "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
          backgroundColor: "transparent",
          color: "#4B4B4B",
        },
      }}
    >
      {/* Search-box */}
      <Stack direction={"row"} justifyContent={"flex-start"}>
        <TextField
          type="text"
          name="search"
          sx={{
            width: "302px",
            "& .MuiInputBase-root": {
              height: "32px",
            },
          }}
          onChange={debouncedResults}
          placeholder="Search"
        />
      </Stack>

      {/* Data Grid Table */}
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isFetching}
        autoHeight
        disableRowSelectionOnClick
        pageSizeOptions={[5]}
        rowCount={rowCountState}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
      />
    </Box>
  );
}

export default OrgTable;
