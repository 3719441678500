import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { TableStyle } from "../PDFStyling/Registration/RegistrationStyle";

const RegistrationTable = ({ ICEmployee }) => {
  return (
    <View style={TableStyle.table}>
      <View style={TableStyle.tableRow}>
        <Text style={TableStyle.headerCell}>Name</Text>
        <Text style={TableStyle.headerCell}>Designation in IC </Text>
        <Text style={TableStyle.headerCell}>Gender</Text>
      </View>
      {ICEmployee?.map((row) => (
        <View key={row.id} style={TableStyle.tableRow}>
          <Text style={TableStyle.cell}>
            {row.firstName} {row.lastName}
          </Text>
          <Text style={TableStyle.cell}>{row.designation_in_ic?.name}</Text>
          <Text style={TableStyle.cell}>{row.gender}</Text>
        </View>
      ))}
    </View>
  );
};

export default RegistrationTable;
