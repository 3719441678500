import { CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import { ColorModeContext } from "../../constants/theme";
import { ReminderCard } from "../Cards";
import { useNavigate } from "react-router";
import { timeDifference } from "../../utils/timeDifference";

function ReminderScreenLayout({ reminders = [], loading }) {
  const { mode } = React.useContext(ColorModeContext);
  const navigate = useNavigate();

  return (
    <Stack
      gap={2}
      padding={"40px"}
      bgcolor={mode === "dark" ? "#1A1A1A" : "#EEF8FF"}
      border={mode === "dark" ? "none" : "1px solid #EDEDED"}
      boxShadow={"0px 2px 8px rgba(215, 215, 215, 0.27)"}
      borderRadius={"8px"}
    >
      {loading && <CircularProgress />}
      {!loading &&
        reminders?.map((rem) => {
          return (
            <ReminderCard
              key={rem.id}
              heading={rem.heading}
              text={
                <span>
                  {rem.content}
                  {rem?.org?.name && <strong>: {rem.org?.name}</strong>}
                </span>
              }
              timeSince={timeDifference(new Date(rem.createdAt))}
              handleClick={() =>
                rem.redirect_url ? navigate(rem.redirect_url) : null
              }
            />
          );
        })}
      {!reminders.length && !loading && <Typography>No Reminders</Typography>}
    </Stack>
  );
}

export default ReminderScreenLayout;
