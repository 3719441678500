import React from "react";
import { Document } from "@react-pdf/renderer";
import { backgroundClause } from "../../../utils/clauses/ShortWomenOnly";
import { shortPolicyClauses } from "../../../constants/initialValues";
import { getPolicyIndices } from "../../../utils/policyHelpers";
import { policyStyles } from "../PDFStyles";
import ShortPolicyView from "./ShortPolicyView";

const initialClauses = shortPolicyClauses;

function ShortWomenOnly({ clauses = initialClauses }) {
  const Indices = getPolicyIndices(clauses);

  console.log("background ---->", clauses);

  const bgText = [];
  if (clauses.background.para1) {
    bgText.push(backgroundClause[0]);
  }

  if (clauses.background.para2) {
    bgText.push(backgroundClause[1]);
  }

  return (
    <Document style={policyStyles.body}>
      <ShortPolicyView
        heading="ANTI SEXUAL HARASSMENT POLICY"
        source="ShortWomenOnly"
        styles={policyStyles}
        clauses={clauses}
        Indices={Indices}
        bgText={bgText}
      />
    </Document>
  );
}

export default ShortWomenOnly;
