import React, { useContext, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  InputLabel,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TrainingMode } from "../../../utils/dropdowns";
import { Controller, useForm } from "react-hook-form";
import TextAreaField from "../../../components/Form/TextAreaField";
import {
  useGetByIdTrainingQuery,
  useUpdateTrainingMutation,
} from "../../../store/training/TrainingAPI";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useGetTrainingTypesQuery } from "../../../store/dropdown/dropdownApi";
import { SelectField2 } from "../../../components/Form";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import Swal from "sweetalert2";
import { BackdropLoader } from "../../../components/General";
import { ColorModeContext } from "../../../constants/theme";
import SaveAndCancelBtn from "../../../components/Buttons/SaveAndCancelBtn";

const breadcrumbs = [
  <Typography className="tw-text-[11px]" key="100">
    Trainings
  </Typography>,
  <Typography className="tw-text-dark-blue tw-text-[11px]" key="200">
    Edit trainings
  </Typography>,
];

const trainingStatus = [
  { id: "101", name: "Completed" },
  { id: "102", name: "Pending" },
];

const TrainingEdit = () => {
  const { mode } = useContext(ColorModeContext);
  const TabScreen = useMediaQuery("(min-width: 640px)");
  const url = window.location.href;
  const ID = url.substring(url.lastIndexOf("/") + 1);
  const { data, isSuccess: locSuccess } = useGetByIdTrainingQuery(ID);
  const [
    updateTraining,
    { isSuccess: updateSuccess, isLoading: updateLoading },
  ] = useUpdateTrainingMutation();
  const { data: trainingTypeData } = useGetTrainingTypesQuery();
  const navigate = useNavigate();

  const { register, handleSubmit, control, reset } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    if (locSuccess) {
      console.log("training data ---->", data);
      const obj = { ...data.training };
      console.log(obj);
      reset(obj);
    }
    if (updateSuccess) {
      Swal.fire({
        icon: "success",
        title: "Training data updated Successfully!",
        showConfirmButton: false,
        timer: 1000,
      });
      navigate("/home/training");
    }
  }, [locSuccess, updateSuccess]);

  const onSubmit = (data) => {
    console.log("onnsuvmit data", data);
    const obj = {};
    obj.training_type = data.training_type;
    obj.status = data.status;
    obj.pending_reason = data.pending_reason;
    obj.date = data.date;
    obj.mode_of_training = data.mode_of_training;
    obj.training_group = data.training_group;
    obj.inititative_name = data.inititative_name;
    obj.inititative_description = data.inititative_description;

    console.log("object", obj);
    updateTraining({ id: ID, body: obj });
  };

  return (
    <Box
      className="tw-p-10"
      sx={{
        height: "auto",
        width: "100%",
        maxWidth: "1300px",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}`,
      }}
    >
      <Toolbar />
      <Box className="tw-flex tw-justify-start tw-space-x-3">
        <IconButton
          className="tw-rounded-lg tw-shadow-lg tw-w-14 tw-h-14"
          sx={{ background: `${mode === "dark" ? "#1A1A1A" : "#FFFFFF"}` }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Box
          className="tw-flex-1 tw-rounded-lg tw-shadow-lg tw-py-3 tw-px-8"
          sx={{ background: `${mode === "dark" ? "#1A1A1A" : "#FFFFFF"}` }}
        >
          <Breadcrumbs
            sx={{
              display: "flex",
              height: "100%",
            }}
            separator={<NavigateNextIcon fontSize="small" />}
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
      </Box>
      {updateLoading && <BackdropLoader open={updateLoading} />}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="tw-rounded-3 tw-mb-4 sm:tw-mb-8">
            <Box className="tw-w-full tw-mb-4 sm:tw-mb-6 lg:tw-mb-12">
              <Typography
                className="tw-font-semibold heading1"
                sx={{ color: `${mode === "dark" ? "#FFF" : "#000"}` }}
              >
                Edit Trainings
              </Typography>
            </Box>
            <Box className="tw-w-80 tw-my-10">
              <SelectField2
                label={"Training Type"}
                placeholder={"Select"}
                name={"training_type_id"}
                register={register}
                menus={trainingTypeData?.trainingType}
                control={control}
              />
            </Box>
            <Box
              className="tw-p-3 sm:tw-p-6 lg:tw-p-12 tw-rounded-3 tw-mb-4 sm:tw-mb-8"
              sx={{
                background: `${mode === "dark" ? "#1A1A1A" : "#FFF6F2"}`,
              }}
            >
              <Grid container spacing={TabScreen ? 4 : 2}>
                <Grid item xs={12} sm={6} lg={6}>
                  <InputLabel
                    className="tw-text-xs sm:tw-text-sm  tw-w-min tw-px-2 tw-py-1 tw-rounded-md"
                    htmlFor="orgName"
                  >
                    <Typography>Date</Typography>
                  </InputLabel>
                  <Controller
                    control={control}
                    name="date"
                    rules={{
                      required: "This field is required",
                      validate: (value) => {
                        console.log("validation ---->", value, typeof value);
                        const check = new Date(value) > new Date();
                        return !check || "Please select a valid date";
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        onChange={onChange}
                        format="DD/MM/YYYY"
                        // className="tw-bg-input-bg"
                        value={moment(value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <SelectField2
                    label={"Mode"}
                    placeholder={"Select"}
                    name={"mode_of_training"}
                    is_id="name"
                    register={register}
                    menus={TrainingMode}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <SelectField2
                    label={"Training Status"}
                    placeholder={"Select"}
                    name={"status"}
                    is_id="name"
                    register={register}
                    menus={trainingStatus}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <TextAreaField
                    register={register}
                    label={"Training Description "}
                    placeholder={""}
                    name={"description"}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-justify-end tw-space-y-4 sm:tw-space-x-8 sm:tw-space-y-0 tw-mt-4 md:tw-mt-4 lg:tw-mt-6">
              <SaveAndCancelBtn
                handleCancle={() => {
                  navigate("/home/training");
                }}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default TrainingEdit;
