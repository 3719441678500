import { Font, StyleSheet } from "@react-pdf/renderer";
import { myFont, regularFont } from "../Typography/pdfFonts";

Font.register(myFont);
Font.register(regularFont);

export const policyStyles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    textAlign: "center",
    textDecoration: "underline",
    fontFamily: "MyFont",
    fontWeight: "bold",
    fontSize: "16px",
    marginBottom: "30px",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  container2: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px",
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontSize: "11px",
  },

  boldText: {
    fontSize: "11px",
    fontWeight: 800,
  },
  heading: {
    fontSize: "13px",
    textAlign: "center",
    fontFamily: "MyFont",
    fontWeight: "bold",
  },
  subHeading: {
    fontWeight: "bold",
    fontFamily: "MyFont",
    fontSize: "14px",
    marginLeft: "10px",
  },
  point: {
    fontSize: "11px",
    marginLeft: "10px",
  },

  subPoint: {
    fontSize: "11px",
    marginLeft: "25px",
  },

  subText: {
    fontSize: "11px",
    marginLeft: "10px",
  },
  heading3: {
    fontSize: "11px",
    marginLeft: "20px",
    fontWeight: "bold",
    fontFamily: "MyFont",
  },
  text3: {
    fontSize: "11px",
    marginLeft: "20px",
  },
});
