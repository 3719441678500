import React, { useContext } from "react";
import { Collapse, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Status } from "../../utils/options";
import { ColorModeContext } from "../../constants/theme";

const ArchiveStatusFilters = ({
  open = false,
  selectedStatus = "all",
  handleStatusChange = () => null,
  status = [Status.PENDING, Status.COMPLETE],
  ...rest
}) => {
  const { mode } = useContext(ColorModeContext);
  return (
    <Collapse
      in={open}
      timeout="auto"
      unmountOnExit
      width="100%"
      sx={{ ...rest }}
    >
      <RadioGroup
        row
        name="status"
        value={selectedStatus}
        onChange={handleStatusChange}
        sx={{ justifyContent: "flex-end" }}
      >
        <FormControlLabel
          value="all"
          control={<Radio />}
          label="All"
          sx={{ color: `${mode === "dark" ? "#fff" : "#000"}` }}
        />
        {status.includes(Status.REJECTED) && (
          <FormControlLabel
            value="rejected"
            control={<Radio />}
            sx={{ color: `${mode === "dark" ? "#fff" : "#000"}` }}
            label="Rejected"
          />
        )}
        {status.includes(Status.APPROVED) && (
          <FormControlLabel
            value="approved"
            control={<Radio />}
            label="Approved"
            sx={{ color: `${mode === "dark" ? "#fff" : "#000"}` }}
          />
        )}
        {status.includes(Status.APPROVAL) && (
          <FormControlLabel
            value="approval"
            control={<Radio />}
            sx={{ color: `${mode === "dark" ? "#fff" : "#000"}` }}
            label="Approval"
          />
        )}
      </RadioGroup>
    </Collapse>
  );
};

export default ArchiveStatusFilters;
