import { Box, CircularProgress } from "@mui/material";
import React from "react";

const Loader = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
      <CircularProgress color="success" sx={{ margin: "auto" }} />
    </Box>
  );
};

export default Loader;
