import React from "react";
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import Roboto from "../../../../src/assets/font/Roboto-Bold.ttf";
import RobotoRegular from "../../../../src/assets/font/Roboto-Regular.ttf";

Font.register({
  family: "MyFont",
  fonts: [{ src: Roboto, fontWeight: "bold" }],
});
Font.register({
  family: "RegularFont",
  fonts: [{ src: RobotoRegular, fontWeight: "normal" }],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontFamily: "MyFont",
    fontSize: "11px",
    textAlign: "center",
    marginBottom: "15px",
  },
  boldText: {
    fontFamily: "MyFont",
    fontSize: "10px",
  },
  text: {
    fontFamily: "RegularFont",
    fontSize: "10px",
  },
  underline: {
    textDecoration: "underline",
  },
  tableBox: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid black",
    width: "100%",
    marginBottom: "20px",
    // height: "500px",
  },
  row: {
    borderBottom: "1px solid black",
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    borderRight: "1px solid black",
  },
  cellFirst: {
    borderRight: "1px solid black",
    flex: 1.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cellMid: {
    borderRight: "1px solid black",
    flex: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cellEnd: {
    flex: 2.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const Layout = ({ data = "" }) => {
  return (
    <View
      style={{
        ...styles.row,
        height: "40px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text style={styles.text}>{data}</Text>
    </View>
  );
};

const AnnualReportPdf = ({ body = {} }) => {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.container}>
          <Text style={styles.title}>COMPLIMATE</Text>

          {/* Date */}
          <Text style={styles.text}>
            <Text style={styles.boldText}>Date:{"  "}</Text>
            {/* May/15th/2023, 5:09:48 pm */}
            {body.date}
          </Text>

          {/* Subject */}
          <Text
            style={{
              ...styles.text,
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Text
              style={{
                ...styles.boldText,
                textDecoration: "underline",
              }}
            >
              Sub:
            </Text>
            {"  "}Annual Report of the Internal Committee of {body.companyName}{" "}
            for the calendar year {body.calenderYear}, the registered office of
            which is at {body.address} as per Section 21 of the Sexual
            Harassment of Women at Workplace (Prevention, Prohibition and
            Redressal) Act, 2013 and Rule 14 of the Rules, 2013
          </Text>

          {/* Report Body */}
          <Text style={{ ...styles.text, marginBottom: "10px" }}>
            Dear {body.personName}
          </Text>
          <Text style={{ ...styles.text, marginBottom: "20px" }}>
            Please find below Annual Report of the Internal Committee (“IC”) of
            {body.companyName} for the calendar year {body.calenderYear}, the
            registered office of which is at {`[${body.address}]`} as required
            to be prepared and submitted under Section 21 of the Sexual
            Harassment of Women at Workplace (Prevention, Prohibition and
            Redressal) Act, 2013 and Rule 14 of the Sexual Harassment of Women
            at Workplace (Prevention, Prohibition and Redressal) Rules, 2013:
          </Text>

          {/* Table */}
          <View style={styles.tableBox}>
            {/* complaints of sexual harassment */}
            <View style={{ ...styles.row, height: "30px" }}>
              <View style={styles.cellFirst}>
                <Text style={styles.text}>1</Text>
              </View>
              <View style={styles.cellMid}>
                <Text style={styles.text}>
                  Number of complaints of sexual harassment received in the year
                </Text>
              </View>
              <View style={styles.cellEnd}>
                <Text style={styles.text}>{body.complaintsReceivedInYear}</Text>
              </View>
            </View>

            {/* Number of complaints disposed off during the year  */}
            <View style={{ ...styles.row, height: "30px" }}>
              <View style={styles.cellFirst}>
                <Text style={styles.text}>2</Text>
              </View>
              <View style={styles.cellMid}>
                <Text style={styles.text}>
                  Number of complaints disposed off during the year
                </Text>
              </View>
              <View style={styles.cellEnd}>
                <Text style={styles.text}>{body.complaintsDisposedInYear}</Text>
              </View>
            </View>

            {/* Number of cases pending for more than 90 days  */}
            <View style={{ ...styles.row, height: "30px" }}>
              <View style={styles.cellFirst}>
                <Text style={styles.text}>3</Text>
              </View>
              <View style={styles.cellMid}>
                <Text style={styles.text}>
                  Number of cases pending for more than 90 days
                </Text>
              </View>
              <View style={styles.cellEnd}>
                <Text style={styles.text}>{body.casesPending}</Text>
              </View>
            </View>

            {/* workshops or awareness programs */}
            <View style={{ ...styles.row, height: "160px" }}>
              <View style={styles.cellFirst}>
                <Text style={styles.text}>4</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 6,
                  borderRight: "1px solid black",
                }}
              >
                <View style={{ ...styles.row, height: "40px" }}>
                  <Text style={styles.text}>
                    Number of workshops or awareness programs against sexual
                    harassment carried out
                  </Text>
                </View>
                <View style={{ ...styles.row, height: "40px" }}>
                  <View style={styles.cellFirst}>
                    <Text style={styles.text}>a.</Text>
                  </View>
                  <View style={{ ...styles.cellEnd, flex: 8.5 }}>
                    <Text style={styles.text}>
                      Number of IC Training/ Workshops/ Other Skill Building
                      Programmes done (In Person/ Webinar/ E-Learning Modules
                      etc)
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.row, height: "40px" }}>
                  <View style={styles.cellFirst}>
                    <Text style={styles.text}>b.</Text>
                  </View>
                  <View style={{ ...styles.cellEnd, flex: 8.5 }}>
                    <Text style={styles.text}>
                      Number of Employee (Including Managers) Awareness
                      Programmes done (In Person/ Webinar/ E-Learning Modules
                      etc)
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.row, height: "40px", border: "none" }}>
                  <View style={styles.cellFirst}>
                    <Text style={styles.text}>c.</Text>
                  </View>
                  <View style={{ ...styles.cellEnd, flex: 8.5 }}>
                    <Text style={styles.text}>
                      No. and details of any other activity done (If any):
                      [Brief details]
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{ flex: 2.5, display: "flex", flexDirection: "column" }}
              >
                <View
                  style={{
                    ...styles.row,
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Text style={styles.text}>{body.totalTrainings}</Text> */}
                </View>
                <View
                  style={{
                    ...styles.row,
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={styles.text}>{body.ic_trainings}</Text>
                </View>
                <View
                  style={{
                    ...styles.row,
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={styles.text}>
                    {body.emp_awareness_trainings}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.row,
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    border: "none",
                  }}
                >
                  <Text style={styles.text}>
                    {Number(body.other_trainings || 0) +
                      Number(body.other_initiatives || 0)}
                  </Text>
                </View>
              </View>
            </View>

            {/* Nature of action taken by the Employer */}
            <View style={{ ...styles.row }}>
              <View style={styles.cellFirst}>
                <Text style={styles.text}>5</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 6,
                  borderRight: "1px solid black",
                }}
              >
                <View style={{ ...styles.row, height: "40px" }}>
                  <Text style={styles.text}>
                    Nature of action taken by the Employer
                  </Text>
                </View>

                <View style={{ ...styles.row, height: "40px" }}>
                  <View style={styles.cellFirst}>
                    <Text style={styles.text}>a.</Text>
                  </View>
                  <View style={{ ...styles.cellEnd, flex: 8.5 }}>
                    <Text style={styles.text}>Concilation</Text>
                  </View>
                </View>
                <View style={{ ...styles.row, height: "40px" }}>
                  <View style={styles.cellFirst}>
                    <Text style={styles.text}>b.</Text>
                  </View>
                  <View style={{ ...styles.cellEnd, flex: 8.5 }}>
                    <Text style={styles.text}>Written apology</Text>
                  </View>
                </View>
                <View style={{ ...styles.row, height: "40px" }}>
                  <View style={styles.cellFirst}>
                    <Text style={styles.text}>c.</Text>
                  </View>
                  <View style={{ ...styles.cellEnd, flex: 8.5 }}>
                    <Text style={styles.text}>Warning</Text>
                  </View>
                </View>
                <View style={{ ...styles.row, height: "40px" }}>
                  <View style={styles.cellFirst}>
                    <Text style={styles.text}>d.</Text>
                  </View>
                  <View style={{ ...styles.cellEnd, flex: 8.5 }}>
                    <Text style={styles.text}>Reprimand or Censure</Text>
                  </View>
                </View>
                <View style={{ ...styles.row, height: "40px" }}>
                  <View style={styles.cellFirst}>
                    <Text style={styles.text}>e.</Text>
                  </View>
                  <View style={{ ...styles.cellEnd, flex: 8.5 }}>
                    <Text style={styles.text}>Withholding of promotion</Text>
                  </View>
                </View>
                <View style={{ ...styles.row, height: "40px" }}>
                  <View style={styles.cellFirst}>
                    <Text style={styles.text}>f.</Text>
                  </View>
                  <View style={{ ...styles.cellEnd, flex: 8.5 }}>
                    <Text style={styles.text}>
                      Withholding of Pay rise/Increment
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.row, height: "40px" }}>
                  <View style={styles.cellFirst}>
                    <Text style={styles.text}>g.</Text>
                  </View>
                  <View style={{ ...styles.cellEnd, flex: 8.5 }}>
                    <Text style={styles.text}>Termination</Text>
                  </View>
                </View>
                <View style={{ ...styles.row, height: "40px" }}>
                  <View style={styles.cellFirst}>
                    <Text style={styles.text}>h.</Text>
                  </View>
                  <View style={{ ...styles.cellEnd, flex: 8.5 }}>
                    <Text style={styles.text}>Transfer</Text>
                  </View>
                </View>
                <View style={{ ...styles.row, height: "40px" }}>
                  <View style={styles.cellFirst}>
                    <Text style={styles.text}>i.</Text>
                  </View>
                  <View style={{ ...styles.cellEnd, flex: 8.5 }}>
                    <Text style={styles.text}>Undergo Counselling</Text>
                  </View>
                </View>
                <View style={{ ...styles.row, height: "40px" }}>
                  <View style={styles.cellFirst}>
                    <Text style={styles.text}>j.</Text>
                  </View>
                  <View style={{ ...styles.cellEnd, flex: 8.5 }}>
                    <Text style={styles.text}>
                      Carrying out Community Service
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.row, height: "40px", border: "none" }}>
                  <View style={styles.cellFirst}>
                    <Text style={styles.text}>k.</Text>
                  </View>
                  <View style={{ ...styles.cellEnd, flex: 8.5 }}>
                    <Text style={styles.text}>Withdrawal of Complaint</Text>
                  </View>
                </View>
              </View>

              <View
                style={{ flex: 2.5, display: "flex", flexDirection: "column" }}
              >
                <Layout />
                <Layout data={body.conciliation} />
                <Layout data={body.writtenApology} />
                <Layout data={body.warning} />
                <Layout data={body.reprimandOrCensure} />
                <Layout data={body.withholdingOfPromotion} />
                <Layout data={body.withholdingOfIncrement} />
                <Layout data={body.termination} />
                <Layout data={body.transfer} />
                <Layout data={body.undergoCounselling} />
                <Layout data={body.carryingOutCommunityService} />
                <Layout data={body.withdrawalOfComplaint} />
              </View>
            </View>
          </View>

          {/* **************************** Table Ends Here *********************************************** */}

          {/* Regards */}
          <Text style={styles.text}>Yours Faithfully,</Text>
          <View
            style={{
              height: "40px",
              width: "100px",
              borderBottom: "1px solid black",
              marginBottom: "10px",
            }}
          ></View>
          <Text style={styles.text}>
            Presiding Officer of Internal Committee,
          </Text>
          <Text style={styles.text}>On behalf of the Internal Committee,</Text>
          <Text style={styles.text}>[Or all IC members can sign]</Text>
        </View>
      </Page>
    </Document>
  );
};

export default AnnualReportPdf;
