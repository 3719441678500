import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import MiniDownloadButton from "../Buttons/MiniDownloadButton";
import RegistrationLogo from "../../assets/logos/RegistrationLogo.svg";

const RegistrationDialogBox = ({ open, handleCLose, registrationDocument }) => {
  console.log("reg doc------->", registrationDocument);

  return (
    <Dialog open={open} onClose={handleCLose} fullWidth>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        bgcolor={"#DBFFFF"}
      >
        <DialogTitle>
          <img src={RegistrationLogo} alt="" className="tw-mx-4" />
          Registration Doucments
        </DialogTitle>
        <IconButton onClick={handleCLose} className="tw-mr-4">
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent>
        {registrationDocument?.signed_registration_letter && (
          <Box className="tw-m-6 tw-flex tw-justify-between">
            <Typography className="tw-pr-20 tw-text-sm">
              Copy of the signed registration letter
            </Typography>

            <MiniDownloadButton
              label="Download"
              marginY={"2px"}
              marginRight={"1.4rem"}
              marginLeft={"1rem"}
              onClick={() => {
                window.open(
                  `${registrationDocument?.signed_registration_letter}`,
                  "_blank",
                );
              }}
            />
          </Box>
        )}
        {registrationDocument?.courier_reciept && (
          <Box className="tw-m-6 tw-flex tw-justify-between">
            <Typography className="tw-pr-20 tw-text-sm">
              Copy of the reciept of the courier
            </Typography>

            <MiniDownloadButton
              label="Download"
              marginY={"2px"}
              marginRight={"1.5rem"}
              marginLeft={"1rem"}
              onClick={() => {
                window.open(
                  `${registrationDocument?.courier_reciept}`,
                  "_blank",
                );
              }}
            />
          </Box>
        )}

        {registrationDocument?.delivery_confirmation && (
          <Box className="tw-m-6 tw-flex tw-justify-between">
            <Typography className="tw-pr-20 tw-text-sm">
              Copy of the delivery confirmation
            </Typography>

            <MiniDownloadButton
              label="Download"
              marginY={"2px"}
              marginRight={"1.5rem"}
              marginLeft={"1rem"}
              onClick={() => {
                window.open(
                  `${registrationDocument?.delivery_confirmation}`,
                  "_blank",
                );
              }}
            />
          </Box>
        )}
        {registrationDocument?.stamped_letter && (
          <Box className="tw-m-6 tw-flex tw-justify-between">
            <Typography className="tw-pr-20 tw-text-sm">
              Stamped copy of the letter
            </Typography>

            <MiniDownloadButton
              label="Download"
              marginY={"2px"}
              marginRight={"1.5rem"}
              marginLeft={"1rem"}
              onClick={() => {
                window.open(
                  `${registrationDocument?.stamped_letter}`,
                  "_blank",
                );
              }}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RegistrationDialogBox;
