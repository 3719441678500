import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { DownloadButton } from "../Buttons";
import { Controller, useForm } from "react-hook-form";

const OiwApproveFile = ({
  open,
  id,
  updateApi,
  orgId = null,
  onClose = () => null,
  loading,
}) => {
  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    console.log(id);
    const obj = {};
    obj.person_type_oiw = data.person_type_oiw;
    obj.id = id;
    obj.org_id = orgId;
    obj.status = "APPROVE";
    updateApi(obj);
  };

  React.useEffect(() => {
    if (loading) {
      onClose();
    }
  }, [loading]);
  return (
    <Dialog open={open}>
      {console.log("id", id)}
      <Box padding={1}>
        <DialogTitle>Do you want to approve?</DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Typography>Is Authority Selected?</Typography>
            <Controller
              name="person_type_oiw"
              control={control}
              defaultValue=""
              rules={{ required: "Please select an option" }}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="AUTHORIZED"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="UNAUTHORIZED"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              )}
            />
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} marginLeft={10}>
              <DialogActions>
                <DownloadButton
                  label="Submit"
                  loading={loading}
                  styles={{ width: "80px" }}
                />
                <Button onClick={onClose} variant="outlined">
                  Cancel
                </Button>
              </DialogActions>
            </Stack>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default OiwApproveFile;
