import React, { useContext } from "react";
import { SectionHeading } from "../../components/Superadmin";
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DynamicRowTable from "../../components/tables/DynamicRowTable";
import LineGraph from "../../components/Graphs/LineGraph";
import { complaintData } from "../../utils/graph";
import { useGetTrainingStatusQuery } from "../../store/training/TrainingAPI";
import { states } from "../../utils/states";
import { Controller, useForm } from "react-hook-form";
import { ColorModeContext } from "../../constants/theme";
import {
  DoughnutChart,
  PieChart,
  ReminderCard,
  SearchButton,
} from "../../components";
import { useGetAllOrgICStatusQuery } from "../../store/ic/icApi";
import { useGetSuperadminRemindersQuery } from "../../store/reminders/reminderApi";
import { useNavigate } from "react-router-dom";
import { timeDifference } from "../../utils/timeDifference";
import { getKey } from "../../utils/getKey";

function SuperAdminDashboard() {
  const navigate = useNavigate();
  const { mode } = useContext(ColorModeContext);
  const [params, setParams] = React.useState({});
  const [icData, setICData] = React.useState({});
  const [trainingData, setTrainingData] = React.useState({});

  const { handleSubmit, control, watch, resetField } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
  });

  const selectedState = watch("state");
  const { data: ICData, isFetching: icFetching } = useGetAllOrgICStatusQuery();
  const { data: tData, isFetching: TrainingFetching } =
    useGetTrainingStatusQuery();
  const { data: reminders, isFetching: remindersFetching } =
    useGetSuperadminRemindersQuery({ pageSize: 2 });

  React.useEffect(() => {
    let temp = {};
    if (ICData) {
      temp = {
        labels: ["Pending", "Completed"],
        datasets: [
          {
            label: "Organizations",
            data: [ICData?.pending || 0, ICData?.completed || 0],
            backgroundColor: ["#43A5FF", "#00FE93"],
            borderJoinStyle: "bevel",
            cutout: "70%",
          },
        ],
      };
    }
    setICData(temp);
  }, [ICData]);

  React.useEffect(() => {
    let temp = {};
    if (tData) {
      temp = {
        labels: ["Pending", "Completed"],
        datasets: [
          {
            label: "Organizations",
            data: [tData?.pending || 0, tData?.completed || 0],
            backgroundColor: ["#43A5FF", "#00FE93"],
            borderJoinStyle: "bevel",
          },
        ],
      };
    }
    setTrainingData(temp);
  }, [tData]);

  const onSubmit = (data) => {
    console.log("form data --->", data);
    const obj = {};
    if (data.year) {
      obj.year = data.year;
    }
    if (data.state && data.state !== "select") {
      obj.state = data.state;
    }
    if (data.city && data.city !== "select") {
      obj.city = data.city;
    }
    console.log(obj);
    setParams({ ...obj });
  };

  React.useEffect(() => {
    console.log("runing...");
    resetField("city");
  }, [selectedState]);

  return (
    <Box flex={1} display={"flex"} flexDirection={"column"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          paddingX={8}
          paddingY={2}
          sx={{ background: `${mode === "dark" ? "#272727" : "#FBFBFA"}` }}
          borderBottom={1}
          columnGap={5}
          borderColor={"#E4E4E4"}
        >
          <Grid container spacing={6}>
            <Grid item xs={12} sm={4} marginY={{ xs: 2 }}>
              <Stack
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                // paddingX={2}
              >
                <Typography color={"#4B4B4B"}>Year</Typography>
                <Controller
                  name={"year"}
                  control={control}
                  defaultValue={""}
                  render={({ field: { value, onChange, ref } }) => (
                    <TextField
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-root": {
                          height: "35px",
                          borderRadius: "3px",
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      inputRef={ref}
                      placeholder="Enter e.g. 2023"
                      className="tw-border-black  tw-rounded-lg"
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4} marginY={{ xs: 2 }}>
              <Stack
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                // paddingX={2}
              >
                <Typography color={"#4B4B4B"}>State</Typography>

                <Controller
                  name={"state"}
                  control={control}
                  defaultValue={"select"}
                  render={({ field: { value, onChange, ref } }) => (
                    <Select
                      sx={{
                        height: "35px",
                        width: "100%",
                      }}
                      className="tw-border-black"
                      value={value}
                      inputRef={ref}
                      onChange={onChange}
                    >
                      <MenuItem value={"select"}>Select</MenuItem>
                      {Object.keys(states).map((s, i) => (
                        <MenuItem key={getKey(i)} value={s}>
                          {s}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4} marginY={{ xs: 2 }}>
              <Stack
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                // paddingX={2}
              >
                <Typography color={"#4B4B4B"}>District</Typography>
                <Controller
                  name={"city"}
                  control={control}
                  defaultValue={"select"}
                  render={({ field: { value, onChange, ref } }) => (
                    <Select
                      sx={{ height: "35px", width: "100%" }}
                      value={value}
                      inputRef={ref}
                      onChange={onChange}
                      className="tw-border-black"
                    >
                      <MenuItem value={"select"}>Select</MenuItem>
                      {states[selectedState]?.map((c, i) => {
                        return (
                          <MenuItem key={getKey(i)} value={c}>
                            {c}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </Stack>
            </Grid>
          </Grid>

          <Stack
            alignItems={"center"}
            justifyContent="flex-end"
            paddingBottom={"16px"}
          >
            <SearchButton />
          </Stack>
        </Stack>
      </form>

      <Box
        display={"flex"}
        paddingX={8}
        columnGap={4}
        sx={{
          background: `${mode === "dark" ? "#0D0D0D" : "white"}`,
          paddingTop: "25px",
          paddingBottom: "25px",
        }}
      >
        <Stack spacing={4} flex={0.5}>
          <Box
            borderRadius={"16px"}
            height="724px"
            sx={{
              overflow: "auto",
              padding: "40px",
              backgroundColor: `${mode === "dark" ? "#1A1A1A" : "#FFFFFF"}`,
            }}
            boxShadow={"0px 2px 8px #0000001f"}
          >
            <DynamicRowTable params={params} />
          </Box>
          <Box
            borderRadius={"16px"}
            boxShadow={"0px 2px 8px #0000001f"}
            sx={{
              backgroundColor: `${mode === "dark" ? "#1A1A1A" : "#FFFFFF"}`,
            }}
            height="360px"
            display={"flex"}
            flexDirection="column"
            gap={2}
            padding="40px"
          >
            <SectionHeading
              heading={`Reminders (${reminders?.count || ""})`}
              linkText="View All"
              url="/superadmin/reminders"
            />
            {remindersFetching && <CircularProgress />}
            {reminders?.reminders?.map((rem) => {
              return (
                <ReminderCard
                  key={rem.id}
                  heading={rem.heading}
                  // text={`${rem.content}: ${(<strong>{rem.org?.name}</strong>)}`}
                  text={
                    <span>
                      {rem.content}: <strong>{rem.org?.name}</strong>
                    </span>
                  }
                  handleClick={() =>
                    rem.redirect_url ? navigate(rem.redirect_url) : null
                  }
                  timeSince={timeDifference(new Date(rem.createdAt))}
                  border="1px solid #E4E4E4"
                  boxShadow={"0px 2px 8px rgba(0, 0, 0, 0.12)"}
                />
              );
            })}
          </Box>
        </Stack>
        <Stack spacing={4} flex={0.5}>
          <Box
            borderRadius={"16px"}
            boxShadow={"0px 2px 8px #0000001f"}
            height="50%"
            padding="40px"
            display={"flex"}
            flexDirection={"column"}
            rowGap={4}
            justifyContent={"flex-start"}
            sx={{
              backgroundColor: `${mode === "dark" ? "#1A1A1A" : "#FFFFFF"}`,
            }}
          >
            <SectionHeading
              heading="Training Statistics"
              linkText="View full list"
              url="/superadmin/training"
            />
            <PieChart data={trainingData} loading={TrainingFetching} />
          </Box>
          <Box
            borderRadius={"16px"}
            boxShadow={"0px 2px 8px #0000001f"}
            height="50%"
            padding="40px"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            sx={{
              backgroundColor: `${mode === "dark" ? "#1A1A1A" : "#FFFFFF"}`,
            }}
            rowGap={4}
          >
            <SectionHeading
              heading="IC Statistics"
              linkText="View full list"
              url="/superadmin/ic"
            />
            <DoughnutChart data={icData} loading={icFetching} />
          </Box>
        </Stack>
      </Box>

      <Box
        paddingX={8}
        paddingBottom={5}
        sx={{
          backgroundColor: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}`,
        }}
      >
        <Box
          borderRadius={"16px"}
          boxShadow={"0px 2px 8px #0000001f"}
          height={"400px"}
          padding="40px"
          sx={{ backgroundColor: `${mode === "dark" ? "#1A1A1A" : "#FFFFFF"}` }}
        >
          <LineGraph
            data={complaintData}
            options={{ maintainAspectRatio: false }}
            style={{
              width: "100%",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default SuperAdminDashboard;
