import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../constants";

export const orgApi = createApi({
  reducerPath: "orgApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
      console.log("building headers");
      headers.set(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
      );
      return headers;
    },
  }),
  tagTypes: ["getContacts"],
  endpoints: (build) => ({
    getOrgDetails: build.query({
      query: (id) => ({
        url: `/v1/org/getorg/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    getAllOrgs: build.query({
      query: (params) => ({
        url: `/v1/org/getallorgs`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 900,
    }),

    getSingleOrg: build.query({
      query: (params) => ({
        url: `/v1/org/get-single-org`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 900,
    }),

    createOrg: build.mutation({
      query: (body) => ({
        url: "v1/org/create",
        method: "POST",
        body,
      }),
    }),
    updateOrg: build.mutation({
      query: ({ body, id }) => ({
        url: `v1/org/update/${id}`,
        method: "PUT",
        body,
      }),
    }),
    createContacts: build.mutation({
      query: ({ id, body }) => ({
        url: `v1/employee/?orgId=${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["getContacts"],
    }),
    updateContact: build.mutation({
      query: ({ body, id }) => {
        console.log(body, id);
        return {
          url: `v1/employee/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    addPOC: build.mutation({
      query: (body) => ({
        url: `v1/employee/add-poc`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["getContacts"],
    }),
    removePOC: build.mutation({
      query: (body) => ({
        url: `v1/employee/remove-poc`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["getContacts"],
    }),
    getContact: build.query({
      query: (id) => ({
        url: `v1/employee/${id}`,
        method: "GET",
      }),
      // keepUnusedDataFor: 0,
    }),
    getContacts: build.query({
      query: (params) => ({
        url: "v1/employee",
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 600,
      providesTags: ["getContacts"],
    }),
    getEmployeesByLoc: build.query({
      query: ({ id, params }) => ({
        url: `v1/employee/loc/${id}`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 0,
    }),
    getEmployeesCountWithoutAdmin: build.query({
      query: (id) => ({
        url: `v1/employee/count-without-admin/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    deleteContact: build.mutation({
      query: (id) => ({
        url: `v1/employee/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getContacts"],
    }),
    createLocations: build.mutation({
      query: ({ id, body }) => ({
        url: `v1/location/?orgId=${id}`,
        method: "POST",
        body,
      }),
    }),
    updateLocation: build.mutation({
      query: ({ id, body }) => ({
        url: `v1/location/${id}`,
        method: "PUT",
        body,
      }),
    }),
    getLocation: build.query({
      query: (id) => ({
        url: `v1/location/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    getLocationByOrg: build.query({
      query: ({ id, params }) => ({
        url: `v1/location/get-loc-by-org/${id}`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 300,
    }),
    getLocations: build.query({
      query: () => ({
        url: "v1/location",
        method: "GET",
      }),
    }),
    keepUnusedDataFor: 0,
  }),
});

export const {
  useGetOrgDetailsQuery,
  useGetAllOrgsQuery,
  useCreateOrgMutation,
  useUpdateOrgMutation,
  useCreateContactsMutation,
  useUpdateContactMutation,
  useGetContactsQuery,
  useGetContactQuery,
  useGetEmployeesByLocQuery,
  useGetEmployeesCountWithoutAdminQuery,
  useCreateLocationsMutation,
  useGetLocationQuery,
  useGetLocationsQuery,
  useUpdateLocationMutation,
  useDeleteContactMutation,
  useGetSingleOrgQuery,
  useGetLocationByOrgQuery,
  useAddPOCMutation,
  useRemovePOCMutation,
} = orgApi;
