import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { NavigationBar } from "../../../components/General";
import BreadcrumbFont from "../../../components/Typography/BreadcrumbFont";
import { ColorModeContext } from "../../../constants/theme";
import {
  useGetLocationRegistrationMappedQuery,
  useUpdateRegistrationStatusMutation,
} from "../../../store/uploadRegistration/uploadRegistrationApi";
import { Controller, useForm } from "react-hook-form";
import { useGetRegionQuery } from "../../../store/dropdown/dropdownApi";
import {
  ApproveButton,
  CloseButton,
  FilterButton,
  GridActionsLayout,
  SearchButton,
  ViewDetailsButton,
} from "../../../components";
import { DataGrid } from "@mui/x-data-grid";
import RegistrationDialogBox from "../../../components/Dialog/RegistrationDialogBox";
import ReasonRejection from "../../../components/Dialog/ReasonRejection";
import StatusFilters from "../../../components/Filters/StatusFilters";
import { Status } from "../../../utils/options";
import useDebouncedSearch from "../../../utils/hooks/useDebounceSearch";
import { GridColumns } from "../../../utils/gridColumns";

const breadcrumbs = [
  <BreadcrumbFont key={1} color="#4B4B4B" text="AGGREGATE REGISTRATION" />,
  <BreadcrumbFont key={2} color="#005D9E" text="ORGANIZATION LOCATIONS" last />,
];

const getLocStatus = (loc) => {
  if (
    loc?.uploaded_registration_status === "PENDING" ||
    loc?.uploaded_registration_status === "REJECTED"
  ) {
    return "PENDING";
  } else if (
    loc?.uploaded_registration_status === "APPROVAL" ||
    loc?.uploaded_registration_status === "COMPLETED"
  ) {
    return loc?.uploaded_registration_status;
  } else {
    return "";
  }
};

const SingleOrgRegistration = () => {
  const url = window.location.href;
  const ID = url.substring(url.lastIndexOf("/") + 1);

  const { mode } = useContext(ColorModeContext);
  const [rows, setRows] = React.useState([]);
  const { searchText, debouncedResults } = useDebouncedSearch();
  const [selectedStatus, setSelectedStatus] = React.useState("all");
  const [params, setParams] = React.useState({});
  const [region, setRegion] = React.useState();
  const [openPreview, setOpenPreview] = React.useState(false);
  const [registrationDocument, setRegistrationDocument] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [anchor, setAnchor] = React.useState(null);

  const { data: locData, isFetching } = useGetLocationRegistrationMappedQuery({
    ...paginationModel,
    ...searchText,
    ...params,
    status: selectedStatus,
    orgId: ID,
  });

  const [updateRegistrationStatus, { isLoading: updateRegLoading }] =
    useUpdateRegistrationStatusMutation();

  const { data: regionList } = useGetRegionQuery();
  const [rowCountState, setRowCountState] = React.useState(locData?.count || 0);
  const [open, setOpen] = React.useState(false);
  const [openRejectALert, setOpenRejectAlert] = React.useState(false);
  const [currentRegId, setCurrentRegId] = React.useState(null);
  const [currentLocId, setCurrentLocId] = React.useState(null);
  const [currentOrgId, setCurrentOrgId] = React.useState(null);
  const [openApprovePopper, setOpenApprovePopper] = React.useState(false);

  React.useEffect(() => {
    let temp = [];
    console.log(locData?.locations);
    if (locData?.locations) {
      temp = locData?.locations?.map((loc) => {
        return {
          id: loc?.id,
          Location: loc?.location_name || "",
          Status: getLocStatus(loc),
          Region: loc?.region?.region,
          OrgID: loc?.org_id,
          RegistrationDoc:
            loc?.uploaded_registration_data[
              loc?.uploaded_registration_data.length - 1
            ],
        };
      });
    }

    setRows(temp || []);
    setRegion(
      regionList?.regionMapping?.map((reg) => ({
        id: reg.id,
        name: reg.region,
      })),
    );

    console.log("Set rows", rows);
    console.log(locData?.locations);
  }, [locData, regionList]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      locData?.count ? locData?.count : prevRowCountState,
    );
  }, [locData?.count, setRowCountState]);

  const { handleSubmit, control, watch, resetField } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
  });

  const selectedState = watch("state");

  React.useEffect(() => {
    return () => {
      resetField("region");
    };
  }, [selectedState]);

  const handleDialogBox = () => {
    setOpenPreview(!openPreview);
  };

  const ApproveRegistration = () => {
    const updateData = {
      loc_id: currentLocId,
      id: currentRegId,
      status: "APPROVE",
      org_id: currentOrgId,
    };
    updateRegistrationStatus(updateData);
    setCurrentRegId(null);
    setCurrentLocId(null);
    setOpenApprovePopper(false);
    setAnchor(null);
    setCurrentOrgId(null);
  };

  const columns = [
    ...new GridColumns()
      .location()
      .region()
      .status()
      .actions({
        renderCell: (params) => {
          return (
            <GridActionsLayout>
              {params?.row?.Status === "APPROVAL" ? (
                <>
                  <CloseButton
                    handleClick={() => {
                      setCurrentLocId(params?.row?.id);
                      setCurrentRegId(params?.row?.RegistrationDoc?.id);
                      setCurrentOrgId(params?.row?.OrgID);
                      setOpenRejectAlert(!openRejectALert);
                    }}
                  />
                  <ApproveButton
                    handleClick={(e) => {
                      setOpenApprovePopper(!openApprovePopper);
                      setAnchor(e.currentTarget);
                      setCurrentOrgId(params?.row?.OrgID);
                      setCurrentRegId(params?.row?.RegistrationDoc?.id);
                      setCurrentLocId(params?.row?.id);
                    }}
                  />
                </>
              ) : (
                `"`
              )}
            </GridActionsLayout>
          );
        },
        field: "Approval",
      })
      .actions({
        renderCell: (params) => {
          return (
            <GridActionsLayout>
              <ViewDetailsButton
                placeholder="View"
                handleClick={() => {
                  if (params.row.Status === "PENDING") {
                    alert("Registration Document Not Uploaded!");
                  } else {
                    setRegistrationDocument(params.row.RegistrationDoc);
                    handleDialogBox();
                  }
                }}
              />
            </GridActionsLayout>
          );
        },
      })
      .getCols(),
  ];

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const onSubmit = (data) => {
    const params = {};
    if (data.region !== "select") {
      params.region = data.region;
    }
    setParams(params);
    console.log(params);
  };

  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      paddingX={"60px"}
      paddingY={"35px"}
      rowGap={"46px"}
      sx={{ background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}` }}
    >
      <NavigationBar breadcrumbs={breadcrumbs} />
      <Box
        display={"flex"}
        flex={1}
        flexDirection={"column"}
        gap={4}
        className="superadmin-data-grid-div"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            padding={"26px"}
            bgcolor={mode === "dark" ? "#151515" : "#EEF8FF"}
            width={"100%"}
          >
            <Box display={"flex"}>
              <InputLabel
                direction="column"
                justifyContent="center"
                className="tw-mt-1"
              >
                Region
              </InputLabel>
              <Controller
                name={"region"}
                control={control}
                defaultValue={"select"}
                render={({ field: { value, onChange, ref } }) => (
                  <Select
                    sx={{
                      height: "40px",
                      flex: 1,
                      width: "250px",
                      marginLeft: "2rem",
                      marginRight: "5rem",
                    }}
                    className="tw-border-black  tw-rounded-lg"
                    value={value}
                    inputRef={ref}
                    onChange={onChange}
                  >
                    <MenuItem value={"select"}>Select</MenuItem>
                    {region?.map((s) => (
                      <MenuItem key={s.id} value={s.id}>
                        {s.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <SearchButton />
            </Box>
          </Box>
        </form>
        <Stack direction="column" gap={2}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <TextField
              type="text"
              name="search"
              onChange={debouncedResults}
              sx={{
                width: "302px",
                "& .MuiInputBase-root": {
                  height: "32px",
                },
              }}
              placeholder="Search By Location"
            />
            <FilterButton
              buttonStyles={{ height: "32px" }}
              onClick={() => setOpen(!open)}
            />
          </Stack>
        </Stack>
        <StatusFilters
          open={open}
          selectedStatus={selectedStatus}
          handleStatusChange={handleStatusChange}
          status={[Status.APPROVAL, Status.COMPLETE, Status.PENDING]}
        />
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isFetching}
          disableRowSelectionOnClick
          pageSizeOptions={[5]}
          rowCount={rowCountState}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: `${mode === "dark" ? " #181818" : "#EEF8FF"}`,
            },
            "& .MuiDataGrid-toolbarContainer": {
              paddingY: 2,
              paddingX: 1,
            },

            "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
              backgroundColor: "transparent",
              color: "#4B4B4B",
            },
          }}
        />
      </Box>

      <Popper
        modifiers={[
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
        ]}
        placement="bottom"
        open={openApprovePopper}
        anchorEl={anchor}
      >
        <Stack
          className="tw-shadow-md"
          sx={{
            padding: 2,
            backgroundColor: "white",
            borderRadius: "4px",
            gap: 3,
            border: "1px solid #4B4B4B",
          }}
        >
          <Typography>Do you want to approve?</Typography>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Button
              variant="contained"
              sx={{ height: "25px", width: "45px", backgroundColor: "#4B4B4B" }}
              onClick={() => {
                setOpenApprovePopper(false);
                setAnchor(null);
                setCurrentLocId(null);
                setCurrentRegId(null);
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              sx={{ height: "25px", width: "45px", backgroundColor: "#4B4B4B" }}
              onClick={ApproveRegistration}
            >
              Yes
            </Button>
          </Stack>
        </Stack>
      </Popper>
      <RegistrationDialogBox
        open={openPreview}
        handleCLose={handleDialogBox}
        registrationDocument={registrationDocument}
      />
      {openRejectALert && (
        <ReasonRejection
          open={openRejectALert}
          id={currentRegId}
          locId={currentLocId}
          orgId={currentOrgId}
          updateApi={updateRegistrationStatus}
          loading={updateRegLoading}
          onClose={() => {
            setOpenRejectAlert(!openRejectALert);
            setCurrentRegId(null);
          }}
          name="rejection_reason"
        />
      )}
    </Box>
  );
};

export default SingleOrgRegistration;
