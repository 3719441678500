import React from "react";
import { InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

function SelectField2({
  label,
  control,
  name,
  validations,
  error,
  placeholder = "Select",
  menus,
  is_id = "id",
  ...rest
}) {
  return (
    <div className="tw-gap-2 tw-flex tw-flex-col">
      <InputLabel
        className="tw-text-xs sm:tw-text-sm  tw-w-min tw-py-1 tw-rounded-md"
        htmlFor="orgName"
      >
        <Typography>{label}</Typography>
      </InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue=" "
        rules={validations}
        {...rest}
        render={({ field: { value, onChange, ref } }) => (
          <Select value={value} onChange={onChange} inputRef={ref}>
            <MenuItem hidden disabled value=" ">
              {placeholder}
            </MenuItem>
            {menus?.map((m) => {
              return (
                <MenuItem key={m.id} value={is_id === "id" ? m.id : m.name}>
                  {m.name || m.region}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
      {error && <span className="tw-text-red-700 tw-text-xs">{error}</span>}
    </div>
  );
}

export default SelectField2;
