import { Checkbox, InputLabel, Typography } from "@mui/material";
import React from "react";

function CheckboxField({ label, name, register, validations }) {
  return (
    <div className="tw-gap-2 tw-flex tw-items-center tw-h-[100%]">
      <Checkbox
        name={name}
        value={true}
        defaultValue={false}
        {...register(name, { ...validations })}
        sx={{ padding: 0 }}
      />
      <InputLabel
        className="tw-text-xs sm:tw-text-sm  tw-w-min tw-px-2 tw-py-1 tw-rounded-md"
        htmlFor="orgName"
      >
        <Typography>{label}</Typography>
      </InputLabel>
    </div>
  );
}

export default CheckboxField;
