import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import { StyledEngineProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./utils/scrollToTop";
import { ColorContextProvider } from "./constants/theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <React.StrictMode>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <BrowserRouter>
            <ScrollToTop />
            <ColorContextProvider>
              <App />
            </ColorContextProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </React.StrictMode>
    </StyledEngineProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
