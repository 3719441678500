import DoneIcon from "@mui/icons-material/Done";
import React from "react";
import { IconButton } from "@mui/material";

function ApproveButton({
  disabledValue = false,
  handleClick = () => null,
  buttonProps = {},
  styles = {},
}) {
  return (
    <IconButton
      sx={{ ...styles }}
      {...buttonProps}
      onClick={handleClick}
      disabled={disabledValue}
    >
      <DoneIcon />
    </IconButton>
  );
}

export default ApproveButton;
