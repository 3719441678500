const RegistrationList = [
  {
    karanataka: {
      order: `Click here to download the order for the Registration for Karnataka State Commission for Women`,
      translatedOrder: `Click here to download the translated order for the Registration for Karnataka State Commission for Women`,
      confirmation: ` I CONFIRM THAT I HAVE READ THE INSTRUCTIONS PROPOSED KARNATAKA STATE COMMISSION`,
      orderUrl: `https://drive.google.com/file/d/1MXSkoXejuYiCA4MQ7_e4zg-WbaooP1mm/view?usp=sharing`,
      translatedOrderUrl: `https://drive.google.com/file/d/1FnQcVVDv7d-GeIKudIM9IKBE6-gzr808/view?usp=sharin`,
    },
    pune: {
      order: ` Click here to download the order for the Registration for Pune Commission for Women`,
      translatedOrder: `Click here to download the translated order for the Registration for Pune Commission for Women`,
      confirmation: `I CONFIRM THAT I HAVE READ THE INSTRUCTIONS PROPOSED PUNE COMMISSION`,
      orderUrl: `https://drive.google.com/file/d/1NI1lWz7OjT0INawXtb1OY3TxvlzvQIsA/view?usp=sharing`,
      translatedOrderUrl: `https://drive.google.com/file/d/1m4l2ruYVcbMJaevBGowjiVWKaRhWxUjN/view?usp=drive_link`,
    },
    mumbaiCity: {
      order: `Click here to download the order for the Registration for Mumbai City Commission for Women`,
      translatedOrder: ` Click here to download the translated order for the Registration for Mumbai City Commission for Women`,
      confirmation: `I CONFIRM THAT I HAVE READ THE INSTRUCTIONS PROPOSED MUMBAI CITY STATE COMMISSION`,
      orderUrl: `https://drive.google.com/file/d/1WKaONthXiIzU22Sf73mOlh4TQ3jIb4Me/view?usp=sharing`,
      translatedOrderUrl: `https://drive.google.com/file/d/1n8TGY-kJh2MJNUA7BptprTdiLmcqR3-L/view?usp=sharing`,
    },
    telangana: {
      order: ` Click here to download the order of the Telangana State Commission for Women`,
      confirmation: `I CONFIRM THAT I HAVE READ THE INSTRUCTIONS PROPOSED TELANGANA  STATE COMMISSION`,
      orderUrl: `https://drive.google.com/file/d/1vSsEWJRfvBdzucP9M5mpTp_lmidLRxxx/view?usp=sharing`,
    },
  },
];
export default RegistrationList;
