import { Stack, Typography } from "@mui/material";
import React from "react";
import { getKey } from "../../../utils/getKey";

function ClauseLayout1({ body = [], heading = "" }) {
  return (
    <Stack direction={"column"} gap={1}>
      {heading && <Typography fontWeight={700}>{heading}</Typography>}
      {body.map((text, i) => {
        return (
          <Typography key={getKey(i)} paragraph>
            {text}
          </Typography>
        );
      })}
    </Stack>
  );
}

export default ClauseLayout1;
