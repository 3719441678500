import { Box } from "@mui/material";
import React from "react";
import { ColorModeContext } from "../../constants/theme";

const RegistrationLayout = ({ children }) => {
  const { mode } = React.useContext(ColorModeContext);
  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      paddingX={"60px"}
      paddingY={"10px"}
      height={"100%"}
      rowGap={"46px"}
      sx={{ background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}` }}
    >
      <Box>{children}</Box>
    </Box>
  );
};

export default RegistrationLayout;
