import React from "react";
import { Button } from "@mui/material";

function SearchButton({ buttonProps = {}, styles = {} }) {
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: "#4B4B4B",
        borderRadius: "8px",
        height: "35px",
        paddingX: 6,
        fontSize: "14px",
        width: "130px",
        fontWeight: 400,
        ...styles,
      }}
      type="submit"
      {...buttonProps}
    >
      Search
    </Button>
  );
}

export default SearchButton;
