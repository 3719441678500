import { Checkbox, InputLabel, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

function CheckboxField2({ label, name, control, ...rest }) {
  return (
    <div className="tw-gap-2 tw-flex tw-items-center tw-h-[100%]">
      <Controller
        control={control}
        name={name}
        {...rest}
        render={({ field: { value, onChange } }) => (
          <Checkbox checked={value} onChange={onChange} sx={{ padding: 0 }} />
        )}
      />
      <InputLabel className="tw-text-xs sm:tw-text-sm  tw-w-min tw-px-2 tw-py-1 tw-rounded-md ">
        {typeof label === "string" ? <Typography>{label}</Typography> : label}
      </InputLabel>
    </div>
  );
}

export default CheckboxField2;
