import {
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";

function InputField({
  label,
  placeholder,
  register,
  name,
  sm,
  md,
  lg,
  validations,
  error,
  type = "text",
  inputHeight,
  ...restProps
}) {
  const TabScreen = useMediaQuery("(min-width: 640px)");
  const BigScreen = useMediaQuery("(min-width: 1500px)");

  const height = () => {
    if (TabScreen) {
      if (md) {
        return 48;
      } else {
        return "56px";
      }
    }
    if (BigScreen) {
      if (lg) {
        return lg;
      } else {
        return "56px";
      }
    }
    if (sm) {
      return sm;
    } else {
      return "56px";
    }
  };

  return (
    <div className="tw-gap-2 tw-flex tw-flex-col">
      <InputLabel className="tw-text-xs sm:tw-text-sm tw-w-min tw-mb-1">
        {typeof label === "string" ? <Typography>{label}</Typography> : label}
      </InputLabel>
      <TextField
        {...register(name, { ...validations })}
        type={type}
        {...restProps}
        sx={{
          "& .MuiInputBase-root": {
            height: inputHeight || height() || "56px",
          },
          "& .MuiInputBase-input": {
            padding: "7px",
          },
        }}
        placeholder={placeholder}
      />
      {error && <span className="tw-text-red-700 tw-text-xs">{error}</span>}
    </div>
  );
}

export default InputField;
