import React from "react";
import { Chip } from "@mui/material";
import { Status } from "../../utils/options";

function StatusBar({ label, status, styles = {}, ...rest }) {
  const getColors = () => {
    if (status === Status.PENDING || status === Status.REJECTED) {
      return { backgroundColor: "#FEE5EE", color: "#D30B53" };
    } else if (status === Status.COMPLETE) {
      return { backgroundColor: "#EEF6E9", color: "#278500" };
    } else if (status === Status.APPROVAL) {
      return { backgroundColor: "#EEF8FF", color: "#0060A4" };
    }
    return {};
  };
  return (
    <Chip
      label={label}
      variant="filled"
      {...rest}
      sx={{
        fontSize: 12,
        fontWeight: 600,
        ...getColors(),
        ...styles,
      }}
    />
  );
}

export default StatusBar;
