import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { policyStyles } from "../../../PDFStyles";

function FilingOfComplaintWithPolice({ serialNo }) {
  const styles = policyStyles;
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>
        {serialNo}. FILING OF COMPLAINT WITH POLICE
      </Text>
      <Text style={styles.text}>
        Nothing in these rules and procedures shall preclude anybody from
        simultaneously lodging a complaint with the police in respect of any act
        amounting to an offence under the law. Organization will provide
        assistance in this regard, if assistance is sought. This Policy is not
        intended to impair or limit the right of any Employee seeking a remedy
        available under any law. Proceedings under this Policy shall continue
        notwithstanding any proceedings initiated by a Complainant (as defined
        hereinafter) against Respondent under any law in force.
      </Text>
    </View>
  );
}

export default FilingOfComplaintWithPolice;
