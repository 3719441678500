import React, { useContext, useEffect, useState } from "react";
import EmployeeManagerLogo from "../../assets/logos/EmployManagerLogo.svg";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Container,
  InputLabel,
  TextField,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { FileUploadOutlined } from "@mui/icons-material";
import Papa from "papaparse";
import { useSelector } from "react-redux";
import {
  useCreateContactsMutation,
  useGetLocationByOrgQuery,
} from "../../store/org/orgApi";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { BackdropLoader } from "../../components/General";
import { ColorModeContext } from "../../constants/theme";
import { OverviewLayout } from "../../components/Layouts";

const EmployeeManagerStyle = styled(Box)(({ theme }) => ({
  ".container": {
    marginTop: "4em",
    padding: "10px",
    height: "100vh",
    width: "100%",
    display: "flex",
  },
  ".subContainer": {
    width: "100%",
    marginBottom: "4px",
    marginTop: "12px",
  },
  ".boxContainer": {
    padding: "2rem",
    marginTop: "4rem",
    radius: "3px",
  },
  ".subHeading": {
    textAlign: "center",
    marginTop: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
  },
  ".headingText": {
    display: "flex",
    flexDirection: "row",
    fontWeight: "600",
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
  },
  ".logoStyle": {
    width: "25px",
    marginRight: "2rem",
    marginTop: "6px",
    [theme.breakpoints.down("md")]: {
      width: "20px",
      marginRight: "2rem",
      marginTop: "6px",
    },
  },
  ".buttonContainer": {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));

const EmployeeManager = () => {
  const { mode } = useContext(ColorModeContext);
  const { user } = useSelector((state) => state?.auth?.user);
  const allowedFileTypes = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ];

  const [file, setFile] = useState(null);

  const navigate = useNavigate();

  const fileReader = new FileReader();

  const { data: locationData } = useGetLocationByOrgQuery({
    id: user?.org_id,
  });
  const [createContacts, { isLoading, isSuccess, isError, error }] =
    useCreateContactsMutation();
  const [locations, setLocations] = React.useState([]);
  const [selectedLocation, setSelectedLocation] = React.useState("");
  const [alert, setAlert] = React.useState(null);

  React.useEffect(() => {
    let temp = [];
    if (locationData?.location) {
      temp = locationData.location.map((loc) => {
        return { location_name: loc.location_name, id: loc.id };
      });
    }
    setLocations(temp || []);
  }, [locationData]);

  useEffect(() => {
    if (isSuccess) {
      console.log("success");
      Swal.fire({
        icon: "success",
        title: "Training data updated Successfully!",
        showConfirmButton: false,
        timer: 1000,
      });
      navigate("/home/employee-manager-list");
    }
    if (isError) {
      setAlert({
        severity: "error",
        message: error,
      });
    }
  }, [isSuccess, isError]);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
      setFile(e.target.files[0]);
    } else {
      setFile(null);
      setAlert({
        severity: "error",
        message: "Please select a valid file (XLSX, XLS, or CSV)",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = (event) => {
        const csvOutput = event.target.result;
        const parseData = Papa.parse(csvOutput, { header: true });
        let jsonData = JSON.stringify(parseData.data);
        jsonData = JSON.parse(jsonData);
        jsonData = jsonData.map((emp) => {
          return { ...emp, is_poc: false, location_id: selectedLocation };
        });
        jsonData = jsonData.filter((emp) => emp.firstName);
        console.log(jsonData);
        createContacts({
          id: user?.org_id,
          body: { employees: jsonData },
        });
      };
      console.log(file);
      fileReader.readAsText(file);
    } else {
      setAlert({
        severity: "error",
        message: "Please select location and choose  file",
      });
    }
  };

  return (
    <OverviewLayout>
      <EmployeeManagerStyle>
        <Container className="container">
          <Toolbar />
          {isLoading && <BackdropLoader open={isLoading} />}
          {isError && <Alert severity="error">{error}</Alert>}
          <Box className="subContainer tw-flex tw-flex-col tw-space-y-8">
            <Typography className="headingText">
              <Box className="tw-flex tw-flex-row">
                <img src={EmployeeManagerLogo} className="logoStyle" />
              </Box>
              Employee manager
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              columnGap={2}
              alignItems={"center"}
            >
              <InputLabel
                className="tw-text-xs sm:tw-text-sm tw-w-min tw-py-1"
                htmlFor="orgName"
              >
                <Typography>Location</Typography>
              </InputLabel>
              <Autocomplete
                disablePortal
                onChange={(e, v) => {
                  console.log(v);
                  setSelectedLocation(v?.id || "");
                }}
                sx={{
                  width: "200px",
                }}
                options={locations}
                renderOption={(props, op) => {
                  return (
                    <li {...props} key={op.id}>
                      {op.location_name}
                    </li>
                  );
                }}
                getOptionLabel={(op) => op.location_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiInputBase-input": {
                        height: "25px",
                        padding: 0,
                        boxSizing: "border-box",
                      },
                    }}
                    placeholder="Select"
                  />
                )}
              />
            </Box>
            <Box
              className="boxContainer"
              sx={{
                background: `${mode === "dark" ? "#1A1A1A" : " #FFF3FD"}`,
              }}
            >
              <Typography className="subHeading">
                Start adding your employees to equihawk. Bulk register your
                employees profiles.
              </Typography>
              <Alert
                sx={{
                  marginBottom: -4,
                  width: "50%",
                  margin: "auto",
                  visibility: alert ? "visible" : "hidden",
                }}
                severity={alert?.severity}
                onClose={() => setAlert(null)}
              >
                {alert?.message}
              </Alert>
              <Box className="buttonContainer">
                <Typography className="tw-text-base tw-text-center tw-font-normal tw-text-[#4B4B4B] lg:tw-mr-10 lg:tw-mt-1">
                  Upload Excel File
                </Typography>

                <form>
                  <Button
                    className="tw-text-xs tw-font-thin"
                    sx={{ color: "#4B4B4B" }}
                    component="label"
                    disabled={!selectedLocation}
                    variant="outlined"
                  >
                    Choose file
                    <input
                      hidden
                      type={"file"}
                      id={"csvFileInput"}
                      accept={".csv"}
                      onChange={handleChange}
                    />
                  </Button>
                  <Button
                    component="label"
                    variant="contained"
                    className="tw-bg-[#4B4B4B] tw-mx-2 sm:tw-gap-2 tw-text-xs tw-font-thin"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <FileUploadOutlined />
                    Upload
                  </Button>
                </form>
              </Box>
              {file && (
                <Typography className="tw-text-[#4B4B4B]  tw-text-center tw-mt-4 sm:tw-gap-2 tw-text-xs tw-font-thin">
                  {file?.name || ""}
                </Typography>
              )}
            </Box>
          </Box>
        </Container>
      </EmployeeManagerStyle>
    </OverviewLayout>
  );
};

export default EmployeeManager;
