import { Box } from "@mui/material";
import React, { useContext } from "react";
import { ColorModeContext } from "../../../../constants/theme";
import PageHeading from "../../../../components/General/PageHeading";
import ArchiveLogo from "../../../../assets/logos/ArchivesLogo.svg";
import { useSelector } from "react-redux";
import { OiwArchivesCom } from "../../../../components";

const OiwArchives = () => {
  const { mode } = useContext(ColorModeContext);
  const OrganizationDetails = useSelector((state) => state?.auth);
  const { user } = OrganizationDetails;
  const orgId = user?.user?.org_id;

  return (
    <Box
      className="tw-p-10"
      sx={{
        background: `${mode === "dark" ? "#000000" : " #FFF"}`,
        height: "100vh",
        width: "100%",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "40px",
      }}
    >
      <PageHeading label="Order in Writing Archives" logo={ArchiveLogo} />
      <OiwArchivesCom orgId={orgId} styles="data-grid-div" />
    </Box>
  );
};

export default OiwArchives;
