import React from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { ViewAllButton } from "../Buttons";
import { ColorModeContext } from "../../constants/theme";
import { useNavigate } from "react-router-dom";
import { ReminderCard } from "../Cards";
import { timeDifference } from "../../utils/timeDifference";

function ReminderLayout({ remindersFetching, reminders = [], count = "" }) {
  const { mode } = React.useContext(ColorModeContext);
  const navigate = useNavigate();
  return (
    <Stack
      gap={2}
      padding={"40px"}
      bgcolor={mode === "dark" ? "#1A1A1A" : "#EEF8FF"}
      border={mode === "dark" ? "none" : "1px solid #EDEDED"}
      boxShadow={"0px 2px 8px rgba(215, 215, 215, 0.27)"}
      borderRadius={"8px"}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        gap={2}
        alignItems={"center"}
      >
        <Typography
          fontSize={"20px"}
          fontWeight={500}
          color={mode === "dark" ? "#DCDCDC" : "#232323"}
          lineHeight={1}
        >
          Reminders ({count || 0})
        </Typography>
        <ViewAllButton url="/home/reminders" />
      </Stack>
      {remindersFetching && <CircularProgress />}
      <Box className="tw-grid tw-grid-cols-2 tw-gap-y-2 tw-gap-x-4 tw-w-full">
        {!!reminders?.length &&
          reminders?.map((rem) => {
            return (
              <ReminderCard
                key={rem.id}
                heading={rem.heading}
                text={rem.content}
                timeSince={timeDifference(new Date(rem.createdAt))}
                handleClick={() =>
                  rem.redirect_url ? navigate(rem.redirect_url) : null
                }
              />
            );
          })}
      </Box>
    </Stack>
  );
}

export default ReminderLayout;
