import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "./authApi";

const initialState = {
  loading: false,
  user: JSON.parse(localStorage.getItem("user")) || null,
  token: localStorage.getItem("token") || null,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        console.log("company admin payload ----> ", payload);
        localStorage.setItem("token", payload.token);
        localStorage.setItem("user", JSON.stringify(payload));
        state.token = payload.token;
        state.user = payload;
      },
    );
    builder.addMatcher(
      authApi.endpoints.superAdminLogin.matchFulfilled,
      (state, { payload }) => {
        console.log("superadmin payload ----> ", payload);
        localStorage.setItem("token", payload.token);
        localStorage.setItem("user", JSON.stringify(payload));
        state.token = payload.token;
        state.user = payload;
      },
    );
  },
});

export default authSlice.reducer;
