import React, { useEffect } from "react";
import RegistrationLayout from "../../../components/Layouts/RegistrationLayout";
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import UploadLogo from "../../../assets/logos/UploadLogo.svg";
import { ColorModeContext } from "../../../constants/theme";
import { SelectField2 } from "../../../components/Form";
import { useForm } from "react-hook-form";
import DownloadButton from "../../../components/Buttons/DownloadButton";
import SelectField from "../../../components/Form/SelectField";
import { useNavigate } from "react-router-dom";
import {
  useGetLocByRegionQuery,
  useGetRegionQuery,
} from "../../../store/dropdown/dropdownApi";
import { useSelector } from "react-redux";

const Mode = [
  { id: "rtyry", name: "Courier" },
  { id: "dfgf", name: "Delivered by hand" },
];
const UploadRegistrationStyle = styled(Box)(({ theme }) => ({
  ".heading": {
    fontWeight: 700,
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
    },
  },
}));

const UploadRegistration = () => {
  const [region, setRegion] = React.useState();
  const [regionId, setReionId] = React.useState();
  const [loction, setLocation] = React.useState();
  const { mode } = React.useContext(ColorModeContext);

  // Get Org-ID
  const OrganizationDetails = useSelector((state) => state.auth);
  const { user } = OrganizationDetails;

  const ID = user?.user?.org_id;

  const { data: regionList } = useGetRegionQuery();
  const { data: locByRegion } = useGetLocByRegionQuery({
    region_id: regionId,
    org_id: ID,
  });
  const navigate = useNavigate();
  useEffect(() => {
    setRegion(
      regionList?.regionMapping.map((reg) => ({
        id: reg.id,
        name: reg.region,
      })),
      setLocation(
        locByRegion?.regionByLocation?.map((loc) => ({
          id: loc.id,
          name: loc.location_name,
        })),
      ),
    );
  }, [regionList, locByRegion]);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onSubmit",
    defaultValue: "defaultValue",
  });

  const onSubmit = (data) => {
    data.region = regionId;
    data.org_id = ID;
    console.log("new data ----->", data);
    if (data.mode === "Courier") {
      navigate("/home/upload-registration-by-courier", { state: data });
    }
    if (data.mode === "Delivered by hand") {
      navigate("/home/upload-registration-by-hand", { state: data });
    }
  };

  return (
    <RegistrationLayout>
      <UploadRegistrationStyle>
        <Stack
          direction={"row"}
          columnGap={4}
          justifyContent={"flex-start"}
          alignItems={"center"}
          marginBottom={2}
          marginTop={10}
        >
          <img src={UploadLogo} alt="" />
          <Typography
            className="heading"
            sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
          >
            Upload Registration
          </Typography>
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className="tw-my-10">
              <div className="tw-gap-2 tw-flex tw-flex-col">
                <InputLabel
                  className="tw-text-xs sm:tw-text-sm  tw-w-min tw-px-2 tw-py-1 tw-rounded-md"
                  htmlFor="orgName"
                >
                  <Typography>Select region</Typography>
                </InputLabel>
                <Select
                  {...register("region", {
                    validate: (value) => value !== "0" || "Required",
                  })}
                  defaultValue={0}
                  onChange={(e) => {
                    setReionId(e.target.value);
                  }}
                  placeholder="Select"
                >
                  <MenuItem hidden disabled value={0}>
                    Select
                  </MenuItem>
                  {region?.map((m) => {
                    return (
                      <MenuItem key={m?.id} value={m?.id}>
                        {m?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <span className="tw-text-red-700 tw-text-xs">
                  {errors?.region?.message}
                </span>
              </div>
            </Box>
            <Box className=" tw-my-10">
              <SelectField2
                label={"Select location"}
                placeholder={"Select"}
                name={"location"}
                register={register}
                menus={loction}
                control={control}
                validations={{
                  validate: (value) => value !== " " || "Required",
                }}
                error={errors?.location?.message}
              />
            </Box>
            <Box className=" tw-my-10">
              <SelectField
                label={"Select mode of sending"}
                placeholder={"Select"}
                name={"mode"}
                register={register}
                menus={Mode}
                validations={{
                  validate: (value) => value !== "0" || "Required",
                }}
                error={errors?.mode?.message}
                control={control}
              />
            </Box>
            <Box className="tw-my-10">
              <DownloadButton label="Proceed" styles={{ width: "400px" }} />
            </Box>
          </form>
        </Box>
      </UploadRegistrationStyle>
    </RegistrationLayout>
  );
};

export default UploadRegistration;
