export const shortPolicyClauses = {
  background: { para1: true, para2: true },
  scope: true,
  keyDefinitions: true,
  procedureForRedressal: true,
  procedureForFilingAnAppeal: true,
  confidentiality: true,
  nonRetaliation: true,
  filingOfComplaintWithPolice: true,
  amendments: true,
  clause1: false,
  clause2: false,
};

export const longPolicyClauses = {
  whatIsSexualHarassment: true,
  whatIsWorkPlace: true,
  whatIsAnEmployee: true,
  whatIsVisitor: true,
  SHConcernsRedressal: true,
  whoCanFileComplaint: true,
  restrictedVictim: true,
  howToFileComplaint: true,
  externalComplainant: true,
  processToHandleComplaint: true,
  actionDuringPendencyOfInquiry: true,
  whatIsInquiryReport: true,
  procedureForFilingAppeal: true,
  confidentiality: true,
  protectionAgainstRetaliation: true,
  filingOfComplaintAgainstPolice: true,
  availabilityOfMentalHealthProfessionals: true,
  responsibilitiesOfEmployees: true,
  responsibilitiesOfIC: true,
  responsibilitiesOfOrganization: true,
  amendments: true,
  clause1: false,
  clause2: false,
};
