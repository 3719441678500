import React, { useContext } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import InternalCommiteeLogo from "../../../assets/logos/InternalCommiteeLogo.svg";
import { StatusBar } from "../../../components/General";
import { Status } from "../../../utils/options";
import { DataGrid } from "@mui/x-data-grid";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  useDeleteICMemberMutation,
  useGetICByLocationQuery,
} from "../../../store/ic/icApi";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import SnackBar from "../../../components/General/Snackbar";
import { ColorModeContext } from "../../../constants/theme";
import {
  AddNewButton,
  EditButton,
  GridActionsLayout,
} from "../../../components";
import { GridColumns } from "../../../utils/gridColumns";
import Swal from "sweetalert2";

function InternalCommitee() {
  const url = window.location.href;
  const ID = url.substring(url.lastIndexOf("/") + 1);
  const [ICStatus, setICStatus] = React.useState("");
  const { mode } = useContext(ColorModeContext);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const navigate = useNavigate();

  const [deleteICMember, { isSuccess: deleteSuccess, isError: deleteError }] =
    useDeleteICMemberMutation();

  const {
    data: icData,
    isSuccess,
    isFetching,
    refetch: ICRefetch,
  } = useGetICByLocationQuery({ location_id: ID });

  React.useEffect(() => {
    console.log("ic -->", icData);
  }, [icData]);

  const handleRemoveIC = (body) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove IC Memeber?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteICMember(body);
      }
    });
  };
  React.useEffect(() => {
    console.log("ic -->");
    if (deleteSuccess) {
      Swal.fire({
        title: "Removed",
        text: "IC Memeber has been removed",
        icon: "success",
        timer: 1500,
      });
      ICRefetch();
    }
    if (deleteError) {
      setAlertMessage("Please try again");
      setAlertOpen(true);
    }
  }, [deleteSuccess, deleteError]);

  React.useEffect(() => {
    console.log("running");
    let tempRows = [];
    if (isSuccess) {
      tempRows = icData?.ic?.employees?.map((emp) => {
        return {
          id: emp.id,
          Name: `${emp.firstName} ${emp.lastName}`,
          Gender: emp.gender,
          Email: emp.email,
          "Designation in Org": emp.designation,
          "Designation in IC": emp.designation_in_ic?.name,
        };
      });
      setICStatus(icData?.ic?.status || "PENDING");
    } else {
      setICStatus("");
    }
    setRows(tempRows || []);
  }, [icData, isSuccess]);

  const columns = [
    ...new GridColumns()
      .name()
      .gender()
      .email()
      .designationInIC()
      .designationInOrg()
      .actions({
        renderCell: (params) => {
          return (
            <GridActionsLayout>
              <EditButton
                onClick={() =>
                  navigate(`/home/internal-commitee/edit/${params.row.id}`)
                }
              />
              <IconButton onClick={() => handleDelete(params)}>
                <CloseOutlinedIcon />
              </IconButton>
            </GridActionsLayout>
          );
        },
      })
      .getCols(),
  ];

  const handleDelete = (params) => {
    setOpen(false);
    const body = {
      email: params?.row?.Email,
      location_id: ID,
    };
    handleRemoveIC(body);
  };

  return (
    <Box
      className="tw-p-10"
      sx={{
        background: `${mode === "dark" ? "#000000" : " #FFF"}`,
        height: "100vh",
        width: "100%",
        margin: 0,

        display: "flex",
        flexDirection: "column",
        gap: "50px",
        padding: "40px",
      }}
    >
      <ConfirmDialog
        open={open}
        message={"Do you want to remove this member"}
        handleClose={() => setOpen(false)}
        handleClick={handleDelete}
      />
      <SnackBar
        open={alertOpen}
        handleClose={() => setAlertOpen(false)}
        message={alertMessage}
      />
      <Stack
        direction={"row"}
        columnGap={4}
        justifyContent={"flex-start"}
        alignItems={"center"}
        marginBottom={2}
        marginTop={10}
      >
        <img src={InternalCommiteeLogo} alt="" />
        <Typography
          className="tw-font-semibold tw-text-4xl"
          sx={{ color: `${mode === "dark" ? "#FFF" : " #000"}` }}
        >
          Internal Committee
        </Typography>
        {ICStatus && (
          <StatusBar
            label={ICStatus}
            status={ICStatus === "PENDING" ? Status.PENDING : Status.COMPLETE}
          />
        )}
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <AddNewButton
          onClick={() => navigate(`/home/internal-commitee/add/${ID}`)}
          buttonProps={{ disabled: !ID }}
        />
      </Stack>
      <Box
        sx={{
          boxShadow:
            "0px 0px 1px rgba(12, 14, 25, 0.24), 0px 2px 12px rgba(12, 14, 25, 0.12)",
          borderRadius: "6px",
          height: "100%",
          padding: 2,
        }}
        className="data-grid-div"
      >
        <DataGrid
          loading={isFetching}
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          pageSizeOptions={[5]}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: `${mode === "dark" ? " #181818" : "#FFE8F2"}`,
            },
            "& .MuiDataGrid-toolbarContainer": {
              paddingY: 2,
              paddingX: 1,
            },

            "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
              backgroundColor: "transparent",
              color: "#4B4B4B",
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default InternalCommitee;
