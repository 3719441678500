import { Box } from "@mui/material";
import React, { useContext } from "react";

import Logo from "../../assets/LogoWithText.svg";
import DarkLogo from "../../assets/DarkLogoWithText.svg";
import { ColorModeContext } from "../../constants/theme";

function AuthLayout({ children }) {
  const { mode } = useContext(ColorModeContext);
  return (
    <Box
      className="tw-p-10 tw-max-w-screen-2xl tw-flex tw-justify-center tw-items-center tw-min-h-screen"
      sx={{ background: `${mode === "dark" ? "#0D0D0D" : "#FFFFFF"}` }}
    >
      <Box className="tw-w-full tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center ">
        <Box className=" tw-h-32 lg:tw-h-auto tw-w-full lg:tw-w-[40%] xl:tw-w-[45%]">
          <Box
            className="tw-flex tw-justify-center tw-h-200 lg: tw-ml-10"
            sx={{
              background: `${mode === "dark" ? "#000" : "#EEF8FF"}`,
            }}
          >
            <img
              src={mode === "dark" ? DarkLogo : Logo}
              width={"50%"}
              height={"50%"}
              alt="Logo"
              className="tw-my-20 tw-py-20 tw-rounded-md"
            />
          </Box>
        </Box>
        <Box className="lg:tw-h-full tw-w-full lg:tw-w-[60%] xl:tw-w-[55%]">
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default AuthLayout;
