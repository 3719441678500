import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import Roboto from "../../../../../src/assets/font/Roboto-Bold.ttf";
import RobotoRegular from "../../../../../src/assets/font/Roboto-Regular.ttf";
import { currentDate } from "../../../../utils/currentDate";
import { RegistrationStyles } from "../../PDFStyling/Registration/RegistrationStyle";
import RegistrationTable from "../RegistrationTable";

Font.register({
  family: "MyFont",
  fonts: [{ src: Roboto, fontWeight: "bold" }],
});
Font.register({
  family: "RegularFont",
  fonts: [{ src: RobotoRegular, fontWeight: "normal" }],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 80,
    fontSize: 8,
  },
  bottomText: {
    display: "flex",
    fontFamily: "RegularFont",
    textAlign: "left",
  },
});

const KarnatakaLetterDoc = ({ ICEmployee, representativeName, orgData }) => {
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={RegistrationStyles.title}>
          ON LETTERHEAD OF THE ORGANIZATION
        </Text>
        <View style={RegistrationStyles.container}>
          {" "}
          <Text style={RegistrationStyles.date}>Date- {currentDate}</Text>
          <Text style={RegistrationStyles.address}>
            To,{"\n"} Karnataka State Commission for Women {"\n"}No.107, 1st
            Floor,{"\n"} Karnataka Housing Board Building, {"\n"}Cauvery Bhavan,
            K.G Road,{"\n"}
            Bangalore-560009
          </Text>
          <Text style={RegistrationStyles.subject}>
            Subject: Details regarding the Internal Committee (“IC”) constituted
            by {orgData.name} as per the Order dated 25th November 2020 issued
            by the Karnataka State Commission for Women
          </Text>
          <Text style={RegistrationStyles.name}>Dear Madam/Sir</Text>
          <Text style={RegistrationStyles.text}>
            Please find below the details of the members of the IC constituted
            by Organization name, to be autofilled for its workplace situated in
            Karnataka having address at {orgData.address}”
          </Text>
          <View style={{ marginTop: 15 }}>
            <RegistrationTable ICEmployee={ICEmployee} />
          </View>
          <View style={{ paddingTop: 20 }}>
            <Text style={styles.bottomText}>Yours faithfully</Text>
            <Text style={styles.bottomText}>{representativeName}</Text>
            {/* <Text style={styles.bottomText}>Designation</Text> */}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default KarnatakaLetterDoc;
