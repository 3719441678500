import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import ComplaintsLogo from "../../assets/logos/complaintsLogo.svg";
import AnnualReportLogo from "../../assets/logos/AnnulaReportLogo.svg";
import TrainingLogo from "../../assets/logos/TrainingLogo.svg";
import CompanyProfileLogo from "../../assets/logos/CompanyProfileLogo.svg";
import EmployeeManagerLogo from "../../assets/logos/EmployManagerLogo.svg";
import RegistrationLogo from "../../assets/logos/RegistrationLogo.svg";
import OrderWritingLogo from "../../assets/logos/OrderWritingLogo.svg";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ColorModeContext } from "../../constants/theme";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardLogo from "../../assets/logos/DashboardLogo.svg";
import InternalCommitteeLogoSmall from "../../assets/logos/InternalCommitteeLogoSmall.svg";
import CompanyPolicyLogo from "../../assets/logos/CompanyPolicyLogo.svg";
import UploadLogo from "../../assets/logos/UploadLogo.svg";
import ArchivesLogo from "../../assets/logos/ArchivesLogo.svg";
import { useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import ImageAspectRatioIcon from "@mui/icons-material/ImageAspectRatio";

const isSelected = (url) => {
  const x = window.location.pathname.includes(url);
  return url && x;
};

const getActiveColor = (url) => {
  if (isSelected(url)) {
    return "tw-bg-sidebar-hover-light";
  } else {
    return "tw-bg-transparent";
  }
};

function Sidebar() {
  const { mode, toggleMode } = useContext(ColorModeContext);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth?.user);
  const [openCompanyProfile, setOpenCompanyProfile] = React.useState(false);
  const [openCompliances, setOpenCompliances] = React.useState(false);
  const [openArchives, setOpenArchives] = React.useState(false);

  const logoutHandler = () => {
    console.log("sfds");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("mode");
    navigate("/");
    window.location.reload();
  };

  const companyAdminDashboard = {
    text: "Dashboard",
    link: "/home/dashboard",
    img: <img src={DashboardLogo} />,
  };

  const complianceManagerDashboard = {
    text: "Dashboard",
    link: "/home/compliance-manager-dashboard",
    img: <img src={DashboardLogo} />,
  };

  const companyProfile = {
    text: "Company Profile",
    link: "/home/company-profile",
    img: <img src={CompanyProfileLogo} />,
  };

  const employeeManager = {
    text: "Employee Manager",
    link: "/home/employee-manager-list",
    img: <img src={EmployeeManagerLogo} />,
  };

  const archives = [
    {
      id: "1",
      text: "Internal committee",
      link: "/home/archives/internal-committee",
      img: <img src={InternalCommitteeLogoSmall} />,
    },
    {
      text: "Order in writing",
      link: "/home/archives/order-in-writing",
      img: <img src={OrderWritingLogo} />,
      id: "2",
    },
    {
      text: "Company Policy",
      link: "/home/archives/company-policy",
      img: <img src={CompanyPolicyLogo} />,
      id: "3",
    },
  ];

  const compliances = [
    {
      text: "Internal Committees",
      link: "/home/internal-committee",
      img: <img src={InternalCommitteeLogoSmall} />,
      id: "1",
    },
    {
      text: "Order In Writing",
      link: "/home/order-in-writing",
      img: <img src={OrderWritingLogo} />,
      id: "2",
    },
    {
      text: "Company Policy",
      link: "/home/policy",
      img: <img src={CompanyPolicyLogo} />,
      id: "3",
    },
    {
      text: "Registration",
      link: "/home/registration",
      img: <img src={RegistrationLogo} />,
      id: "4",
    },
    {
      text: "Trainings",
      link: "/home/training",
      img: <img src={TrainingLogo} />,
      id: "5",
    },
    {
      text: "Annual Reports",
      link: "/home/annual-report",
      img: <img src={AnnualReportLogo} />,
      id: "6",
    },
    {
      text: "Posters",
      link: "",
      img: <ImageAspectRatioIcon fontSize="small" />,
      id: "7",
    },
    {
      text: "Complaints",
      link: "",
      img: <img src={ComplaintsLogo} />,
      id: "8",
    },
  ];

  const upload = {
    text: "Upload",
    link: "/home/upload",
    img: <img src={UploadLogo} width={"20px"} />,
  };

  return (
    <>
      <List sx={{ border: "none" }}>
        {/* Admin - Dashboard */}
        {user.role === "COMPANY_ADMIN" && (
          <ListItem>
            <Link
              to={companyAdminDashboard.link}
              className={`tw-w-full ${getActiveColor(
                companyAdminDashboard.link,
              )} hover:tw-bg-sidebar-hover-light tw-rounded-lg`}
            >
              <ListItemButton>
                <ListItemIcon>{companyAdminDashboard.img}</ListItemIcon>
                <ListItemText primary={companyAdminDashboard.text} />
              </ListItemButton>
            </Link>
          </ListItem>
        )}

        {/* Compliance Manager - Dashboard */}
        {user.role === "COMPLIANCE_MANAGER" && (
          <ListItem>
            <Link
              to={complianceManagerDashboard.link}
              className={`tw-w-full ${getActiveColor(
                complianceManagerDashboard.link,
              )} hover:tw-bg-sidebar-hover-light tw-rounded-lg`}
            >
              <ListItemButton>
                <ListItemIcon>{complianceManagerDashboard.img}</ListItemIcon>
                <ListItemText primary={complianceManagerDashboard.text} />
              </ListItemButton>
            </Link>
          </ListItem>
        )}

        {/* Organization Details */}
        {user.role === "COMPANY_ADMIN" && (
          <ListItem>
            <Link
              className={`tw-w-full hover:tw-bg-sidebar-hover-light tw-rounded-lg`}
              onClick={() => setOpenCompanyProfile(!openCompanyProfile)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <CorporateFareIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={"Organization Details"} />
                {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </ListItemButton>
            </Link>
          </ListItem>
        )}

        <Collapse in={openCompanyProfile} timeout="auto" unmountOnExit>
          <List sx={{ border: "none", pl: 4 }}>
            {/* Company Profile */}
            <ListItem>
              <Link
                to={companyProfile.link}
                className={`tw-w-full ${getActiveColor(
                  companyProfile.link,
                )} hover:tw-bg-sidebar-hover-light tw-rounded-lg`}
              >
                <ListItemButton sx={{ gap: 2 }}>
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    {companyProfile.img}
                  </ListItemIcon>
                  <ListItemText
                    sx={{ fontSize: 12 }}
                    primary={companyProfile.text}
                  />
                </ListItemButton>
              </Link>
            </ListItem>

            {/* POC */}
            <ListItem>
              <Link
                to={"/home/poc"}
                className={`tw-w-full ${getActiveColor(
                  "/home/poc",
                )} hover:tw-bg-sidebar-hover-light tw-rounded-lg`}
              >
                <ListItemButton sx={{ gap: 2 }}>
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <ContactPageIcon fontSize={"small"} />
                  </ListItemIcon>
                  <ListItemText sx={{ fontSize: 12 }} primary={"POC"} />
                </ListItemButton>
              </Link>
            </ListItem>

            {/* Locations */}
            <ListItem>
              <Link
                to={"/home/locations"}
                className={`tw-w-full ${getActiveColor(
                  "/home/locations",
                )} hover:tw-bg-sidebar-hover-light tw-rounded-lg`}
              >
                <ListItemButton sx={{ gap: 2 }}>
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <LocationCityIcon fontSize={"small"} />
                  </ListItemIcon>
                  <ListItemText sx={{ fontSize: 12 }} primary={"Locations"} />
                </ListItemButton>
              </Link>
            </ListItem>

            {/* Employee Manager */}
            <ListItem>
              <Link
                to={employeeManager.link}
                className={`tw-w-full ${getActiveColor(
                  employeeManager.link,
                )} hover:tw-bg-sidebar-hover-light tw-rounded-lg`}
              >
                <ListItemButton sx={{ gap: 2 }}>
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    {employeeManager.img}
                  </ListItemIcon>
                  <ListItemText
                    sx={{ fontSize: 12 }}
                    primary={employeeManager.text}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
        </Collapse>

        {/* Compliances */}
        {(user.role === "COMPANY_ADMIN" ||
          user.role === "COMPLIANCE_MANAGER") && (
          <ListItem>
            <Link
              className={`tw-w-full hover:tw-bg-sidebar-hover-light tw-rounded-lg`}
              onClick={() => setOpenCompliances(!openCompliances)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <AssuredWorkloadIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={"Compliances"} />
                {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </ListItemButton>
            </Link>
          </ListItem>
        )}

        {/* Compliances List */}
        <Collapse in={openCompliances} timeout="auto" unmountOnExit>
          <List sx={{ border: "none", pl: 4 }}>
            {(user.role === "COMPANY_ADMIN" ||
              user.role === "COMPLIANCE_MANAGER") &&
              compliances.map((obj) => {
                return (
                  <ListItem key={obj.id}>
                    <Link
                      to={obj.link}
                      className={`tw-w-full ${getActiveColor(
                        obj.link,
                      )} hover:tw-bg-sidebar-hover-light tw-rounded-lg`}
                    >
                      <ListItemButton sx={{ gap: 2 }}>
                        <ListItemIcon sx={{ minWidth: 0 }}>
                          {obj.img}
                        </ListItemIcon>
                        <ListItemText
                          sx={{ fontSize: 12 }}
                          primary={obj.text}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                );
              })}
          </List>
        </Collapse>

        {/* Admin - Upload Documents */}
        {(user.role === "COMPANY_ADMIN" ||
          user.role === "COMPLIANCE_MANAGER") && (
          <ListItem>
            <Link
              to={upload.link}
              className={`tw-w-full ${getActiveColor(
                upload.link,
              )} hover:tw-bg-sidebar-hover-light tw-rounded-lg`}
            >
              <ListItemButton>
                <ListItemIcon>{upload.img}</ListItemIcon>
                <ListItemText primary={upload.text} />
              </ListItemButton>
            </Link>
          </ListItem>
        )}

        {/* Admin = Archives */}
        {(user.role === "COMPANY_ADMIN" ||
          user.role === "COMPLIANCE_MANAGER") && (
          <ListItem>
            <Link
              className={`tw-w-full hover:tw-bg-sidebar-hover-light tw-rounded-lg`}
              onClick={() => setOpenArchives(!openArchives)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <img src={ArchivesLogo} alt="Archives Logo" />
                </ListItemIcon>
                <ListItemText primary={"Archives"} />
                {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </ListItemButton>
            </Link>
          </ListItem>
        )}

        {/* Archives List */}
        <Collapse in={openArchives} timeout="auto" unmountOnExit>
          <List sx={{ border: "none", pl: 4 }}>
            {archives.map((obj) => {
              return (
                <ListItem key={obj.id}>
                  <Link
                    to={obj.link}
                    className={`tw-w-full ${getActiveColor(
                      obj.link,
                    )} hover:tw-bg-sidebar-hover-light tw-rounded-lg`}
                  >
                    <ListItemButton sx={{ gap: 2 }}>
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        {obj.img}
                      </ListItemIcon>
                      <ListItemText sx={{ fontSize: 12 }} primary={obj.text} />
                    </ListItemButton>
                  </Link>
                </ListItem>
              );
            })}
          </List>
        </Collapse>

        <ListItem>
          <Link
            className={`tw-w-full hover:tw-bg-sidebar-hover-light tw-rounded-lg`}
          >
            <ListItemButton onClick={toggleMode}>
              <ListItemIcon>
                {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
              </ListItemIcon>
              <ListItemText primary={`${mode} mode`} />
            </ListItemButton>
          </Link>
        </ListItem>

        <ListItem>
          <Link
            className={`tw-w-full hover:tw-bg-sidebar-hover-light tw-rounded-lg`}
            onClick={() => logoutHandler()}
          >
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
    </>
  );
}

export default Sidebar;
